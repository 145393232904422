import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Alert,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  InputAdornment,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Avatar
} from '@mui/material';
import {
  Cloud as CloudIcon,
  Check as CheckIcon,
  Error as ErrorIcon,
  Sync as SyncIcon,
  Lock as LockIcon,
  Link as LinkIcon,
  People as PeopleIcon,
  AttachMoney as AttachMoneyIcon,
  Business as BusinessIcon,
  Webhook as WebhookIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  Settings as SettingsIcon,
  GitHub as GitHubIcon,
  LinkedIn as LinkedInIcon,
  Handshake as HandshakeIcon,
  AutoAwesome as AutoAwesomeIcon,
  Event as EventIcon
} from '@mui/icons-material';
import { pipedriveService } from '../../services/pipedriveService';

// Integration configurations
const INTEGRATIONS = {
  pipedrive: {
    id: 'pipedrive',
    name: 'Pipedrive',
    description: 'Sync your CRM data with Pipedrive',
    icon: <CloudIcon />,
    color: '#00b1e9',
    category: 'crm',
    features: ['Contacts', 'Deals', 'Organizations'],
    status: 'available'
  },
  fireflies: {
    id: 'fireflies',
    name: 'Fireflies.ai',
    description: 'AI meeting notes and conversation intelligence',
    icon: <AutoAwesomeIcon />,
    color: '#6366f1',
    category: 'ai',
    features: ['Meeting Transcription', 'AI Summary', 'Action Items', 'Topic Analysis'],
    status: 'coming_soon'
  },
  hubspot: {
    id: 'hubspot',
    name: 'HubSpot',
    description: 'Connect your HubSpot marketing data',
    icon: <HandshakeIcon />,
    color: '#ff7a59',
    category: 'marketing',
    features: ['Contacts', 'Companies', 'Deals'],
    status: 'coming_soon'
  }
};

const IntegrationCard = ({ integration, onConnect, onDisconnect, status, loading }) => {
  const isConnected = status?.status === 'connected';
  const isComingSoon = integration.status === 'coming_soon';

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: integration.color }}>
            {integration.icon}
          </Avatar>
        }
        title={integration.name}
        action={
          isConnected && (
            <Chip
              size="small"
              icon={<CheckIcon />}
              label="Connected"
              color="success"
              variant="outlined"
            />
          )
        }
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="body2" color="text.secondary" paragraph>
          {integration.description}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Features:
        </Typography>
        <List dense>
          {integration.features.map((feature) => (
            <ListItem key={feature}>
              <ListItemIcon sx={{ minWidth: 32 }}>
                <CheckIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={feature} />
            </ListItem>
          ))}
        </List>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
        {isComingSoon ? (
          <Chip
            label="Coming Soon"
            color="primary"
            variant="outlined"
          />
        ) : isConnected ? (
          <>
            <Button
              size="small"
              startIcon={<SettingsIcon />}
              onClick={() => onConnect(integration.id)}
            >
              Settings
            </Button>
            <Button
              size="small"
              color="error"
              onClick={() => onDisconnect(integration.id)}
              disabled={loading}
            >
              Disconnect
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            onClick={() => onConnect(integration.id)}
            disabled={loading}
          >
            Connect
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

const PipedriveSettingsDialog = ({ 
  open, 
  onClose, 
  onSyncAll, 
  onSyncData, 
  loading, 
  syncStatus, 
  syncingType,
  syncMessage,
  pipedriveStatus,
  webhooks,
  onCreateWebhook,
  onDeleteWebhook
}) => (
  <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
    <DialogTitle>Pipedrive Integration Settings</DialogTitle>
    <DialogContent>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Sync Status
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Last sync: {pipedriveStatus?.lastSync ? new Date(pipedriveStatus.lastSync).toLocaleString() : 'Never'}
        </Typography>
        {syncMessage.success && (
          <Alert severity="success" sx={{ mt: 2 }}>
            Successfully synced {syncMessage.type === 'all' ? 'all data' : syncMessage.type}
          </Alert>
        )}
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Data Synchronization
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={onSyncAll}
              disabled={loading}
              startIcon={loading ? <CircularProgress size={20} /> : <SyncIcon />}
            >
              Sync All Data
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => onSyncData('contacts')}
              disabled={syncingType === 'contacts'}
              startIcon={syncingType === 'contacts' ? <CircularProgress size={20} /> : <PeopleIcon />}
            >
              Sync Contacts
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => onSyncData('deals')}
              disabled={syncingType === 'deals'}
              startIcon={syncingType === 'deals' ? <CircularProgress size={20} /> : <AttachMoneyIcon />}
            >
              Sync Deals
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => onSyncData('organizations')}
              disabled={syncingType === 'organizations'}
              startIcon={syncingType === 'organizations' ? <CircularProgress size={20} /> : <BusinessIcon />}
            >
              Sync Organizations
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => onSyncData('activities')}
              disabled={syncingType === 'activities'}
              startIcon={syncingType === 'activities' ? <CircularProgress size={20} /> : <EventIcon />}
            >
              Sync Activities
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">
            Webhooks
          </Typography>
          <Button
            startIcon={<AddIcon />}
            onClick={onCreateWebhook}
          >
            Add Webhook
          </Button>
        </Box>
        <Box sx={{ bgcolor: 'background.paper', borderRadius: 1 }}>
          {webhooks.length > 0 ? (
            <List>
              {webhooks.map((webhook) => (
                <ListItem
                  key={webhook.id}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => onDeleteWebhook(webhook.id)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemIcon>
                    <WebhookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={webhook.subscription_url}
                    secondary={`${webhook.event_object} - ${webhook.event_action}`}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography color="text.secondary" align="center">
              No webhooks configured
            </Typography>
          )}
        </Box>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>
);

const AccountIntegrations = ({ userData, selectedOrg }) => {
  const [connectDialogOpen, setConnectDialogOpen] = useState(false);
  const [apiToken, setApiToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [pipedriveStatus, setPipedriveStatus] = useState(null);
  const [error, setError] = useState(null);
  const [syncStatus, setSyncStatus] = useState(null);
  const [syncingType, setSyncingType] = useState(null);
  const [syncMessage, setSyncMessage] = useState({ type: null, success: false });
  const [webhooks, setWebhooks] = useState([]);
  const [webhookDialogOpen, setWebhookDialogOpen] = useState(false);
  const [webhookFormData, setWebhookFormData] = useState({
    subscription_url: '',
    event_action: '',
    event_object: '',
    http_auth_user: '',
    http_auth_password: ''
  });
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);

  useEffect(() => {
    fetchPipedriveStatus();
  }, [selectedOrg]);

  useEffect(() => {
    if (pipedriveStatus?.status === 'connected') {
      fetchWebhooks();
    }
  }, [pipedriveStatus]);

  const fetchPipedriveStatus = async () => {
    try {
      const status = await pipedriveService.getStatus(selectedOrg);
      setPipedriveStatus(status);
    } catch (error) {
      console.error('Error fetching Pipedrive status:', error);
      setError('Failed to fetch integration status');
    }
  };

  const fetchWebhooks = async () => {
    try {
      const response = await pipedriveService.listWebhooks(selectedOrg);
      setWebhooks(response.webhooks || []);
    } catch (error) {
      console.error('Error fetching webhooks:', error);
      setError('Failed to fetch webhooks');
    }
  };

  const handleConnect = async () => {
    setLoading(true);
    setError(null);
    try {
      await pipedriveService.connect(apiToken, selectedOrg);
      await fetchPipedriveStatus();
      setConnectDialogOpen(false);
      setApiToken('');
    } catch (error) {
      setError('Failed to connect to Pipedrive. Please check your API token.');
    } finally {
      setLoading(false);
    }
  };

  const handleSyncAll = async () => {
    setLoading(true);
    setSyncStatus('syncing');
    try {
      await pipedriveService.syncAll(selectedOrg);
      setSyncStatus('success');
      setSyncMessage({ type: 'all', success: true });
      setTimeout(() => setSyncMessage({ type: null, success: false }), 3000);
    } catch (error) {
      setSyncStatus('error');
      setError('Failed to sync data');
    } finally {
      setLoading(false);
    }
  };

  const handleSyncData = async (dataType) => {
    setSyncingType(dataType);
    try {
      await pipedriveService.syncData(selectedOrg, dataType);
      setSyncStatus('success');
      setSyncMessage({ type: dataType, success: true });
      setTimeout(() => setSyncMessage({ type: null, success: false }), 3000);
    } catch (error) {
      setSyncStatus('error');
      setError(`Failed to sync ${dataType}`);
    } finally {
      setSyncingType(null);
    }
  };

  const handleDisconnect = async () => {
    setLoading(true);
    try {
      await pipedriveService.disconnect(selectedOrg);
      setPipedriveStatus({ status: 'disconnected' });
    } catch (error) {
      setError('Failed to disconnect from Pipedrive');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateWebhook = async () => {
    try {
      setLoading(true);
      await pipedriveService.createWebhook(selectedOrg, webhookFormData);
      await fetchWebhooks();
      setWebhookDialogOpen(false);
      setWebhookFormData({
        subscription_url: '',
        event_action: '',
        event_object: '',
        http_auth_user: '',
        http_auth_password: ''
      });
    } catch (error) {
      setError('Failed to create webhook');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteWebhook = async (webhookId) => {
    try {
      setLoading(true);
      await pipedriveService.deleteWebhook(selectedOrg, webhookId);
      await fetchWebhooks();
    } catch (error) {
      setError('Failed to delete webhook');
    } finally {
      setLoading(false);
    }
  };

  const handleConnectIntegration = (integrationId) => {
    switch (integrationId) {
      case 'pipedrive':
        if (pipedriveStatus?.status === 'connected') {
          setSettingsDialogOpen(true);
        } else {
          setConnectDialogOpen(true);
        }
        break;
      // Add cases for other integrations
      default:
        console.log('Integration not implemented:', integrationId);
    }
  };

  const handleDisconnectIntegration = async (integrationId) => {
    switch (integrationId) {
      case 'pipedrive':
        await handleDisconnect();
        break;
      // Add cases for other integrations
      default:
        console.log('Integration not implemented:', integrationId);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Paper elevation={3} sx={{ mb: 3 }}>
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Integrations
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Connect your favorite tools and services to enhance your workflow
          </Typography>
        </Box>
      </Paper>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3}>
        {Object.values(INTEGRATIONS).map((integration) => (
          <Grid item xs={12} sm={6} md={4} key={integration.id}>
            <IntegrationCard
              integration={integration}
              onConnect={handleConnectIntegration}
              onDisconnect={handleDisconnectIntegration}
              status={integration.id === 'pipedrive' ? pipedriveStatus : null}
              loading={loading}
            />
          </Grid>
        ))}
      </Grid>

      {/* Connect Dialog */}
      <Dialog 
        open={connectDialogOpen} 
        onClose={() => setConnectDialogOpen(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: { borderRadius: 1 }
        }}
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CloudIcon color="primary" />
            <Typography variant="h6">Connect to Pipedrive</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              How to get your Pipedrive API key:
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Typography variant="body2" color="primary">1.</Typography>
                </ListItemIcon>
                <ListItemText 
                  primary="Log in to your Pipedrive sandbox account"
                  secondary="https://cyberteam-sandbox.pipedrive.com"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Typography variant="body2" color="primary">2.</Typography>
                </ListItemIcon>
                <ListItemText 
                  primary="Go directly to API settings"
                  secondary="https://cyberteam-sandbox.pipedrive.com/settings/api"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Typography variant="body2" color="primary">3.</Typography>
                </ListItemIcon>
                <ListItemText 
                  primary="Copy your API token"
                  secondary="If you don't see an API token, click 'Generate new token'"
                />
              </ListItem>
            </List>
            <Divider sx={{ my: 2 }} />
            <TextField
              autoFocus
              margin="dense"
              label="API Token"
              fullWidth
              value={apiToken}
              onChange={(e) => setApiToken(e.target.value)}
              disabled={loading}
              error={Boolean(error)}
              helperText={error}
              placeholder="Enter your Pipedrive API token"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon fontSize="small" color="action" />
                  </InputAdornment>
                ),
              }}
            />
            <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
              Your API token is securely stored and used only to sync data between Puāwai and Pipedrive.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 0 }}>
          <Button 
            onClick={() => window.open('https://cyberteam-sandbox.pipedrive.com/settings/api', '_blank')}
            color="primary"
          >
            Open Pipedrive API Settings
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button onClick={() => setConnectDialogOpen(false)} disabled={loading}>
            Cancel
          </Button>
          <Button 
            onClick={handleConnect} 
            disabled={loading || !apiToken}
            variant="contained"
            startIcon={loading ? <CircularProgress size={20} /> : <LinkIcon />}
          >
            {loading ? 'Connecting...' : 'Connect'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Settings Dialog */}
      <PipedriveSettingsDialog
        open={settingsDialogOpen}
        onClose={() => setSettingsDialogOpen(false)}
        onSyncAll={handleSyncAll}
        onSyncData={handleSyncData}
        loading={loading}
        syncStatus={syncStatus}
        syncingType={syncingType}
        pipedriveStatus={pipedriveStatus}
        syncMessage={syncMessage}
        webhooks={webhooks}
        onCreateWebhook={() => setWebhookDialogOpen(true)}
        onDeleteWebhook={handleDeleteWebhook}
      />

      {/* Webhook Dialog */}
      <Dialog 
        open={webhookDialogOpen} 
        onClose={() => setWebhookDialogOpen(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: { borderRadius: 1 }
        }}
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <WebhookIcon color="primary" />
            <Typography variant="h6">Add New Webhook</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label="Subscription URL"
              fullWidth
              value={webhookFormData.subscription_url}
              onChange={(e) => setWebhookFormData({
                ...webhookFormData,
                subscription_url: e.target.value
              })}
              required
            />
            <FormControl fullWidth required>
              <InputLabel>Event Object</InputLabel>
              <Select
                value={webhookFormData.event_object}
                onChange={(e) => setWebhookFormData({
                  ...webhookFormData,
                  event_object: e.target.value
                })}
              >
                <MenuItem value="deal">Deal</MenuItem>
                <MenuItem value="person">Person</MenuItem>
                <MenuItem value="organization">Organization</MenuItem>
                <MenuItem value="activity">Activity</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth required>
              <InputLabel>Event Action</InputLabel>
              <Select
                value={webhookFormData.event_action}
                onChange={(e) => setWebhookFormData({
                  ...webhookFormData,
                  event_action: e.target.value
                })}
              >
                <MenuItem value="added">Added</MenuItem>
                <MenuItem value="updated">Updated</MenuItem>
                <MenuItem value="deleted">Deleted</MenuItem>
                <MenuItem value="merged">Merged</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Auth Username (Optional)"
              fullWidth
              value={webhookFormData.http_auth_user}
              onChange={(e) => setWebhookFormData({
                ...webhookFormData,
                http_auth_user: e.target.value
              })}
            />
            <TextField
              label="Auth Password (Optional)"
              fullWidth
              type="password"
              value={webhookFormData.http_auth_password}
              onChange={(e) => setWebhookFormData({
                ...webhookFormData,
                http_auth_password: e.target.value
              })}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 0 }}>
          <Button onClick={() => setWebhookDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleCreateWebhook}
            variant="contained"
            disabled={!webhookFormData.subscription_url || !webhookFormData.event_action || !webhookFormData.event_object}
          >
            Create Webhook
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AccountIntegrations; 