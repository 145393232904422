import React, { useState, useEffect } from 'react';
import {
  Box, Grid, Paper, Typography, Button, IconButton, TextField, Tooltip,
  Chip, useTheme, LinearProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SaveIcon from '@mui/icons-material/Save';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { theme } from '../../../theme';
import PuawaiBrandAIAssistButton from '../../ai/PuawaiBrandAIAssistButton';
import PuawaiBidCanvasAIAssistButton from '../../ai/PuawaiBidCanvasAIAssistButton';

// Register fonts
Font.register({
  family: 'Roboto',
  fonts: [
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf', fontWeight: 300 },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf', fontWeight: 400 },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf', fontWeight: 500 },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf', fontWeight: 700 },
  ]
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 30,
    fontFamily: 'Roboto',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    borderBottom: `2 solid ${theme.palette.primary.main}`,
    paddingBottom: 10,
  },
  logoContainer: {
    width: 150,
    height: 75,
  },
  logo: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  section: {
    margin: '10 0',
    padding: 10,
    borderLeft: `4 solid ${theme.palette.primary.main}`,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginBottom: 5,
  },
  text: {
    fontSize: 12,
    lineHeight: 1.5,
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 30,
    right: 30,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontSize: 10,
    borderTop: `1 solid ${theme.palette.divider}`,
    paddingTop: 10,
  },
});

const BidBaseCanvas = ({
  user, selectedOrg, canvas, onClose, onUpdate, canvasType, sections, tooltips, icon: Icon, onRatingChange, bidName,
  // Add new props for AI assistance
  brandData, toneOfVoiceData, allFormData,
  bidData
}) => {
  const [canvasData, setCanvasData] = useState(canvas || {});
  const [confidenceScore, setConfidenceScore] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    if (canvas) {
      const parsedCanvas = {
        ...canvas,
        ratings: typeof canvas.ratings === 'string' ? JSON.parse(canvas.ratings) : canvas.ratings
      };
      setCanvasData(parsedCanvas);
      calculateConfidenceScore(parsedCanvas);
    }
  }, [canvas]);

  const calculateConfidenceScore = (data) => {
    let totalScore = 0;
    let maxPossibleScore = 0;

    sections.forEach(({ field, weight = 1 }) => {
      if (data.ratings && data.ratings[field]) {
        switch (data.ratings[field]) {
          case 'high':
            totalScore += 3 * weight;
            break;
          case 'medium':
            totalScore += 2 * weight;
            break;
          case 'low':
            totalScore += 1 * weight;
            break;
          default:
            break;
        }
      }
      maxPossibleScore += 3 * weight; // Max score is always 'high' (3) * weight
    });

    const score = Math.round((totalScore / maxPossibleScore) * 100);
    setConfidenceScore(score);
  };

  const handleFieldChange = (field, value) => {
    const updatedCanvas = { ...canvasData, [field]: value };
    setCanvasData(updatedCanvas);
    calculateConfidenceScore(updatedCanvas);
  };

  const handleRatingChange = (field, value) => {
    const updatedCanvas = {
      ...canvasData,
      ratings: {
        ...canvasData.ratings,
        [field]: value
      }
    };
    setCanvasData(updatedCanvas);
    calculateConfidenceScore(updatedCanvas);
    onRatingChange(updatedCanvas);
  };

  const handleSave = (status) => {
    onUpdate({ ...canvasData, status, confidenceScore }, status);
  };

  const CanvasPDF = ({ canvasData, confidenceScore }) => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.logoContainer}>
            <Image 
              src="/images/Puawai_Logo_Green_wText.png" 
              style={styles.logo} 
            />
          </View>
        </View>
        
        {/* Add Bid Name */}
        <View style={[styles.section, { marginTop: 10, marginBottom: 10 }]}>
          <Text style={[styles.text, { fontSize: 20, fontWeight: 'bold' }]}>{bidName}</Text>
        </View>

        {/* Add Canvas Type */}
        <View style={[styles.section, { marginBottom: 10 }]}>
          <Text style={[styles.text, { fontSize: 18, fontWeight: 'bold' }]}>{canvasType}</Text>
        </View>

        {/* Add Confidence Score */}
        <View style={[styles.section, { marginBottom: 20 }]}>
          <Text style={styles.sectionTitle}>Confidence Score</Text>
          <Text style={[styles.text, { fontSize: 18, fontWeight: 'bold' }]}>{confidenceScore}%</Text>
        </View>

        {sections.map(({ title, field }) => (
          <View key={field} style={styles.section}>
            <Text style={styles.sectionTitle}>{title}</Text>
            <Text style={styles.text}>{canvasData[field] || 'Not defined'}</Text>
          </View>
        ))}

        <Text style={styles.footer}>
          © {new Date().getFullYear()} StepFWD Limited. All rights reserved.
        </Text>
      </Page>
    </Document>
  );

  const renderRatingControl = (section) => {
    const currentRating = canvasData.ratings?.[section.field] || 'low';
    return (
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        {['low', 'medium', 'high'].map((rating) => (
          <Chip
            key={rating}
            label={rating.charAt(0).toUpperCase() + rating.slice(1)}
            onClick={() => handleRatingChange(section.field, rating)}
            color={currentRating === rating ? 'primary' : 'default'}
            variant={currentRating === rating ? 'filled' : 'outlined'}
            sx={{
              fontWeight: currentRating === rating ? 'bold' : 'normal',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
                transform: 'scale(1.05)',
              },
            }}
          />
        ))}
        <PuawaiBidCanvasAIAssistButton
          fieldName={section.field}
          fieldTitle={section.title} // Pass the title here
          currentValue={canvasData[section.field] || ''}
          onSuggestion={(suggestion) => handleFieldChange(section.field, suggestion)}
          bidData={bidData}
          canvasType={canvasType}
          currentStepData={canvasData}
          tooltip={tooltips[section.field]}
          allFormData={allFormData}
          selectedOrg={selectedOrg}
        />
      </Box>
    );
  };

  const getFileName = () => {
    const safeBidName = bidName ? bidName.toLowerCase().replace(/\s+/g, '_') : 'unnamed_bid';
    const safeCanvasType = canvasType.toLowerCase().replace(/\s+/g, '_');
    return `${safeBidName}_${safeCanvasType}.pdf`;
  };

  return (
    <Box sx={{ 
      height: '100vh', 
      display: 'flex', 
      flexDirection: 'column', 
      p: 3, 
      bgcolor: theme.palette.background.default,
      color: theme.palette.text.primary,
      backgroundImage: 'url("data:image/svg+xml,%3Csvg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"%3E%3Cg fill="%239C92AC" fill-opacity="0.05" fill-rule="evenodd"%3E%3Ccircle cx="3" cy="3" r="3"/%3E%3Ccircle cx="13" cy="13" r="3"/%3E%3C/g%3E%3C/svg%3E")',
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mr: 3 }}>
            <i className="fas fa-seedling" style={{ fontSize: '28px', marginRight: '12px', color: theme.palette.primary.main }} />
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
              Puāwai
            </Typography>
          </Box>
          <Icon sx={{ verticalAlign: 'middle', mr: 2, fontSize: '32px', color: theme.palette.secondary.main }} />
          <Box>
            <Typography variant="h5" sx={{ fontWeight: 500 }}>
              {bidName} - <span style={{ fontWeight: 400 }}>{canvasType}</span>
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', mr: 2 }}>
            <Typography variant="subtitle1" sx={{ color: theme.palette.text.secondary }}>
              Confidence Score
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <LinearProgress 
                variant="determinate" 
                value={confidenceScore}
                sx={{ width: 100, mr: 2, height: 8, borderRadius: 4 }} 
              />
              <Typography variant="h6" sx={{ color: theme.palette.secondary.main, fontWeight: 'bold' }}>
                {confidenceScore}%
              </Typography>
            </Box>
          </Box>
          <IconButton onClick={onClose} color="inherit" sx={{ p: 1 }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Grid container spacing={3} sx={{ flexGrow: 1, overflowY: 'auto' }}>
        {sections.map((section) => (
          <Grid item xs={12} sm={6} md={section.width} key={section.field}>
            <Paper 
              elevation={3} 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                p: 2,
                bgcolor: theme.palette.background.paper,
                transition: 'box-shadow 0.3s ease',
                '&:hover': {
                  boxShadow: 6,
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" sx={{ color: theme.palette.primary.main, flexGrow: 1, fontWeight: 500 }}>{section.title}</Typography>
                <Tooltip title={tooltips[section.field]}>
                  <IconButton size="small">
                    <HelpOutlineIcon fontSize="small" sx={{ color: theme.palette.info.main }} />
                  </IconButton>
                </Tooltip>
              </Box>
              <TextField
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                value={canvasData[section.field] || ''}
                onChange={(e) => handleFieldChange(section.field, e.target.value)}
                sx={{ flexGrow: 1, mb: 2 }}
              />
              <Box sx={{ mt: 'auto' }}>
                {renderRatingControl(section)}
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
        <PDFDownloadLink 
          document={<CanvasPDF canvasData={canvasData} confidenceScore={confidenceScore} />} 
          fileName={getFileName()}
        >
          {({ blob, url, loading, error }) => (
            <Button 
              startIcon={<PictureAsPdfIcon />}
              sx={{ mr: 2 }}
              disabled={loading}
            >
              {loading ? 'Generating PDF...' : 'Download PDF'}
            </Button>
          )}
        </PDFDownloadLink>
        <Button 
          onClick={() => handleSave('draft')} 
          startIcon={<SaveIcon />}
          sx={{ mr: 2 }}
        >
          Save as Draft
        </Button>
        <Button 
          onClick={() => handleSave('completed')} 
          variant="contained" 
          color="primary"
          startIcon={<CheckCircleIcon />}
        >
          Complete Canvas
        </Button>
      </Box>
    </Box>
  );
};

export default BidBaseCanvas;
