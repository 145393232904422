import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Paper, Grid, Divider, Chip, Dialog, CircularProgress } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import BrandBuilderPDF from '../pdf/BrandBuilderPDF';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PaletteIcon from '@mui/icons-material/Palette';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import FolderIcon from '@mui/icons-material/Folder';
import ShareIcon from '@mui/icons-material/Share';
import AssessmentIcon from '@mui/icons-material/Assessment';
import StoryBrandCanvas from '../orgcanvases/StoryBrandCanvas';
import CampaignIcon from '@mui/icons-material/Campaign';
import DescriptionIcon from '@mui/icons-material/Description';
import { getToken } from '../../services/authService';
import { 
  listOrgCanvases, 
  updateOrgCanvas, 
  createOrgCanvas,
  getOrgCanvas
} from '../../services/organisationcanvasService';
import { toast } from 'react-toastify';

const ToneOfVoiceGuide = ({ brandData, toneOfVoiceData, user, selectedOrg }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [canvasDialogState, setCanvasDialogState] = useState({
    open: false,
    data: null,
    type: null,
  });
  const [canvases, setCanvases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const handleEditClick = () => {
    navigate('/brand-builder/edit');
  };

  useEffect(() => {
    if (selectedOrg) {
      fetchCanvases();
    }
  }, [selectedOrg]);

  const fetchCanvases = async () => {
    try {
      setLoading(true);
      const orgId = typeof selectedOrg === 'string' ? selectedOrg : selectedOrg.id;
      const fetchedCanvases = await listOrgCanvases(orgId);
      setCanvases(fetchedCanvases);
      console.log('Fetched canvases:', fetchedCanvases);
    } catch (error) {
      console.error('Error fetching canvases:', error);
      toast.error('Failed to fetch canvases');
    } finally {
      setLoading(false);
    }
  };

  const handleActionClick = async (action, canvasId = null, canvasType = null) => {
    try {
      if (!selectedOrg) {
        throw new Error('No organization selected');
      }

      const orgId = typeof selectedOrg === 'string' ? selectedOrg : selectedOrg.id;

      if (canvasId) {
        const canvas = await getOrgCanvas(orgId, canvasId);
        setCanvasDialogState({
          open: true,
          data: canvas,
          type: canvas.type,
        });
      } else {
        const newCanvas = await createOrgCanvas(orgId, {
          name: `${canvasType}`,
          type: canvasType,
          createdBy: user?.id,
          status: 'draft',
        });
        setCanvasDialogState({
          open: true,
          data: newCanvas,
          type: canvasType,
        });
        setCanvases([...canvases, newCanvas]);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const handleSaveCanvas = async (updatedCanvas) => {
    try {
      const orgId = typeof selectedOrg === 'string' ? selectedOrg : selectedOrg.id;
      const savedCanvas = await updateOrgCanvas(orgId, updatedCanvas.id, updatedCanvas);
      setCanvases(prevCanvases => 
        prevCanvases.map(c => c.id === savedCanvas.id ? savedCanvas : c)
      );
      toast.success('Canvas saved successfully');
      await fetchCanvases();
    } catch (error) {
      toast.error(`Failed to save canvas: ${error.message}`);
    }
  };

  const handleCloseCanvasDialog = () => {
    setCanvasDialogState({ open: false, data: null, type: null });
  };

  const getStoryBrandStatus = () => {
    const storyBrandCanvas = canvases.find(c => c.type === 'StoryBrand Canvas');
    if (!storyBrandCanvas) return 'not started';
    return storyBrandCanvas.status === 'completed' ? 'completed' : 'in progress';
  };

  const renderPdfDownloadButton = () => {
    if (!toneOfVoiceData) {
      return (
        <Button
          variant="contained"
          color="primary"
          startIcon={<DownloadIcon />}
          disabled
        >
          No Data Available
        </Button>
      );
    }

    return (
      <PDFDownloadLink
        document={
          <BrandBuilderPDF 
            brandData={brandData}
            toneOfVoiceData={toneOfVoiceData}
            user={user}
            selectedOrg={selectedOrg}
          />
        }
        fileName={`${brandData?.name || 'Brand'}-Voice-Guide.pdf`}
      >
        {({ blob, url, loading, error }) => (
          <Button
            variant="contained"
            color="primary"
            startIcon={loading ? <CircularProgress size={20} /> : <DownloadIcon />}
            disabled={loading}
            onClick={() => {
              if (!loading && !error) {
                toast.success('Voice Guide downloaded successfully');
              }
            }}
          >
            {loading ? 'Preparing Download...' : 'Download Voice Guide'}
          </Button>
        )}
      </PDFDownloadLink>
    );
  };

  return (
    <Box>
      {/* Brand Dashboard Header */}
      <Paper elevation={3} sx={{ p: 4, mb: 3, backgroundColor: theme.palette.background.paper }}>
        <Typography variant="h4" color="primary" gutterBottom>
          Brand Dashboard
        </Typography>
        <Typography variant="body1" paragraph>
          Manage and develop your brand identity across all touchpoints
        </Typography>
      </Paper>

      <Grid container spacing={3}>
        {/* Active Features */}
        {/* Tone of Voice Section - Complete */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, borderLeft: `6px solid ${theme.palette.success.main}` }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" color="success.main">
                Tone of Voice
              </Typography>
              <Chip 
                label="Complete" 
                color="success" 
                icon={<CheckCircleIcon />} 
              />
            </Box>
            <Typography variant="body2" paragraph>
              Your brand voice guidelines are complete and ready to use
            </Typography>
            <Button
              variant="outlined"
              color="success"
              startIcon={<EditIcon />}
              onClick={handleEditClick}
            >
              Edit Tone of Voice
            </Button>
          </Paper>
        </Grid>

        {/* StoryBrand Framework Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, borderLeft: `6px solid ${theme.palette.primary.main}` }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" color="primary.main">
                StoryBrand Framework
              </Typography>
              <Chip 
                label={getStoryBrandStatus()} 
                color={getStoryBrandStatus() === 'completed' ? 'success' : 'default'} 
                icon={<CampaignIcon />} 
              />
            </Box>
            <Typography variant="body2" paragraph>
              Craft your brand story using the proven StoryBrand framework
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<DescriptionIcon />}
              onClick={() => {
                const existingCanvas = canvases.find(c => c.type === 'StoryBrand Canvas');
                handleActionClick(
                  existingCanvas ? 'edit' : 'create',
                  existingCanvas?.id,
                  'StoryBrand Canvas'
                );
              }}
            >
              {getStoryBrandStatus() === 'not started' ? 'Start StoryBrand Canvas' : 
               getStoryBrandStatus() === 'completed' ? 'View StoryBrand Canvas' : 'Continue StoryBrand Canvas'}
            </Button>
          </Paper>
        </Grid>

        {/* Quick Actions - Moved to middle, full width */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ 
            p: 3,
            borderLeft: `6px solid ${theme.palette.info.main}`, // Added accent color
            backgroundColor: theme.palette.background.paper
          }}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              mb: 2 
            }}>
              <Typography variant="h6" color="info.main">
                Quick Actions
              </Typography>
            </Box>
            <Box sx={{ 
              display: 'flex', 
              gap: 2, 
              flexWrap: 'wrap',
              justifyContent: 'flex-start' // Align buttons to the left
            }}>
              {renderPdfDownloadButton()}
              <Button
                variant="outlined"
                startIcon={<ShareIcon />}
                onClick={() => toast.info('Sharing functionality coming soon!')}
              >
                Share Guidelines
              </Button>
              <Button
                variant="outlined"
                startIcon={<AssessmentIcon />}
                disabled
              >
                View Analytics
              </Button>
            </Box>
          </Paper>
        </Grid>

        {/* Coming Soon Features - Unified */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ 
            p: 3, 
            borderLeft: `6px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.background.paper
          }}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              mb: 3 
            }}>
              <Typography variant="h6" color="text.secondary">
                Coming Soon
              </Typography>
              <Chip 
                label="In Development" 
                color="default"
              />
            </Box>
            
            <Grid container spacing={3}>
              {/* Feature List */}
              <Grid item xs={12} md={4}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <PaletteIcon sx={{ mr: 2, color: 'text.secondary' }} />
                  <Box>
                    <Typography variant="subtitle1" color="text.primary">
                      Visual Identity
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Manage your logos, colors, typography, and design elements
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={4}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <MenuBookIcon sx={{ mr: 2, color: 'text.secondary' }} />
                  <Box>
                    <Typography variant="subtitle1" color="text.primary">
                      Brand Guidelines
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Create and manage comprehensive brand guidelines
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={4}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <FolderIcon sx={{ mr: 2, color: 'text.secondary' }} />
                  <Box>
                    <Typography variant="subtitle1" color="text.primary">
                      Brand Assets
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Manage and organize all your brand assets
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Box sx={{ mt: 3, borderTop: 1, borderColor: 'divider', pt: 3 }}>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                We're working on these exciting features to help you manage your brand more effectively. Stay tuned for updates!
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                disabled
                size="small"
              >
                Get Notified When Available
              </Button>
            </Box>
          </Paper>
        </Grid>

        {/* Canvas Dialog */}
        <Dialog 
          open={canvasDialogState.open} 
          onClose={handleCloseCanvasDialog}
          fullScreen
          PaperProps={{
            style: {
              backgroundColor: theme.palette.background.default,
            },
          }}
        >
          {canvasDialogState.type === 'StoryBrand Canvas' && (
            <StoryBrandCanvas
              user={user}
              userData={user}
              selectedOrg={selectedOrg}
              canvas={canvasDialogState.data}
              onClose={handleCloseCanvasDialog}
              onSave={handleSaveCanvas}
            />
          )}
        </Dialog>
      </Grid>
    </Box>
  );
};

export default ToneOfVoiceGuide;
