import api from '../api';
import { getAuthHeader } from '../authService';

const apiCall = async (method, endpoint, data = null) => {
  try {
    const token = await getAuthHeader();
    const config = {
      method,
      url: `/api${endpoint}`,
      headers: { 
        Authorization: token,
        'Content-Type': 'application/json'
      },
    };
    if (data) {
      config.data = data;
    }
    const response = await api(config);
    return response.data;
  } catch (error) {
    throw new Error(`API error: ${error.response?.data?.message || error.message}`);
  }
};

const formatContextData = (contextData) => {
  return {
    ...contextData,
    orgData: typeof contextData.orgData === 'string' ? { id: contextData.orgData } : contextData.orgData,
    currentStepData: {
      field: contextData.currentStepData?.field,
      value: contextData.currentStepData?.value
    }
  };
};

export const organisationStrategyChatService = {
  sendMessage: async (message, contextData) => {
    try {
      const formattedContextData = formatContextData(contextData);
      const payload = {
        message,
        orgId: formattedContextData.orgData.id,
        orgData: formattedContextData.orgData,
        fieldName: formattedContextData.currentStepData.field,
        currentValue: formattedContextData.currentStepData.value,
        canvasType: formattedContextData.canvasType,
        tooltip: formattedContextData.tooltip,
        language: formattedContextData.language || 'en',
        locale: formattedContextData.locale || 'en-NZ',
        requestConcise: true // Add this flag to request concise responses
      };

      const response = await apiCall('post', '/puawaiorgstrategyintelligence', payload);
      
      if (response && response.response) {
        // Process the response to ensure conciseness
        response.response = ensureConciseResponse(response.response, contextData.currentStepData.field);
        return response;
      } else {
        throw new Error('Invalid response format from server');
      }
    } catch (error) {
      throw error;
    }
  },

  getOrganisationStrategyDetails: async (orgId) => {
    try {
      return await apiCall('get', `/organisations/${orgId}/strategy`);
    } catch (error) {
      throw error;
    }
  },
};

// Helper function to ensure concise responses
function ensureConciseResponse(response, fieldName) {
  // Remove any explanatory text or metadata
  response = response.replace(/^(Here's|This is|Following is).*?:\s*/i, '');
  response = response.replace(/^In summary:?\s*/i, '');
  
  // For SWOT analysis, ensure it's in the correct format
  if (fieldName.toLowerCase().includes('situation analysis')) {
    const swotParts = response.split(/\n+/);
    const formattedSWOT = swotParts.map(part => {
      const [category, ...items] = part.split(':');
      return `${category.trim()}: ${items.join(':').trim()}`;
    }).join('\n');
    return formattedSWOT;
  }

  // For other fields, return the response as is, but trimmed
  return response.trim();
}

export default organisationStrategyChatService;
