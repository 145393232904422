import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import PageHeader from '../../components/PageHeader/PageHeader';
import Footer from '../../components/Footer/Footer';

function PrivacyPolicy({ onLoginClick }) {
  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      minHeight: '100vh',
      backgroundColor: 'background.default',
    }}>
      <PageHeader onLoginClick={onLoginClick} />
      <Container maxWidth="md" sx={{ mt: 12, mb: 6, flexGrow: 1 }}>
        <Typography variant="h2" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          At Puāwai, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our platform.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Information We Collect
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Personal information you provide to us, such as your name and email address." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Usage data, including how you interact with our platform." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Device information, such as your IP address and browser type." />
          </ListItem>
        </List>
        <Typography variant="h5" gutterBottom>
          How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use the information we collect to provide, maintain, and improve our services, as well as to communicate with you about your account and updates to our platform.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Data Security
        </Typography>
        <Typography variant="body1" paragraph>
          We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about this Privacy Policy, please contact us.
        </Typography>
      </Container>
      <Footer />
    </Box>
  );
}

export default PrivacyPolicy;