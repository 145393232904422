import axios from 'axios';
import { getToken } from './authService';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiCall = async (method, endpoint, data = null) => {
    const token = await getToken();
    if (!token) {
        throw new Error('Authorization token is missing');
    }

    try {
        const response = await axios({
            method,
            url: `${API_BASE_URL}${endpoint}`,
            data,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error(`API ${method} request to ${endpoint} failed:`, error.response || error.message || error);
        throw error;
    }
};

export const listTimeEntries = async (bidId) => {
    return apiCall('get', `/api/bids/${bidId}/timetracking`);
};

export const getTimeEntry = async (bidId, timeEntryId) => {
    return apiCall('get', `/api/bids/${bidId}/timetracking/${timeEntryId}`);
};

export const createTimeEntry = async (bidId, timeEntryData) => {
    return apiCall('post', `/api/bids/${bidId}/timetracking`, timeEntryData);
};

export const updateTimeEntry = async (bidId, timeEntryId, timeEntryData) => {
    return apiCall('put', `/api/bids/${bidId}/timetracking/${timeEntryId}`, timeEntryData);
};

export const deleteTimeEntry = async (bidId, timeEntryId) => {
    return apiCall('delete', `/api/bids/${bidId}/timetracking/${timeEntryId}`);
};

export default {
    listTimeEntries,
    getTimeEntry,
    createTimeEntry,
    updateTimeEntry,
    deleteTimeEntry
};