import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import {
  Box, Paper, Typography, IconButton, TextField,
  Divider, Chip, Grid, Menu, MenuItem,
  List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction,
  Collapse, Tooltip, Button, Accordion, AccordionSummary, AccordionDetails, 
  Switch, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions,
  Select, FormControl, InputLabel
} from '@mui/material';
import {
  DragIndicator as DragIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  DataObject as DataIcon,
  SmartToy as AIIcon,
  Style as StyleIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  ViewColumn as LayoutIcon
} from '@mui/icons-material';
import { SectionEditors } from './SectionEditors';
import { SectionTypes, SectionGroups } from '../config/templateConfig';
import DataVariableSelector, { dataVariables } from './DataVariableSelector';

const VariableSelector = ({ section, onVariableSelect, selectedVariables }) => {
  const [expanded, setExpanded] = useState({});

  const handleToggle = (category) => {
    setExpanded(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  const isVariableSelected = (variableKey) => {
    return selectedVariables.includes(variableKey);
  };

  return (
    <List>
      {Object.entries(dataVariables).map(([category, { label, icon: Icon, variables }]) => (
        <React.Fragment key={category}>
          <ListItem button onClick={() => handleToggle(category)}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={label} />
            {expanded[category] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={expanded[category]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {Object.entries(variables).map(([key, description]) => (
                <ListItem
                  key={key}
                  button
                  sx={{ pl: 4 }}
                  onClick={() => onVariableSelect(key)}
                  selected={isVariableSelected(key)}
                >
                  <ListItemText 
                    primary={description}
                    secondary={`\${${key}}`}
                  />
                  {isVariableSelected(key) && (
                    <ListItemSecondaryAction>
                      <IconButton 
                        edge="end" 
                        size="small"
                        onClick={() => onVariableSelect(key)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      ))}
    </List>
  );
};

const SectionEditor = ({ section, onUpdate, onDelete, onDragStop }) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedSection, setEditedSection] = useState(section);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    setEditedSection(section);
  }, [section]);

  const handleVariableSelect = (variableKey) => {
    const normalizedKey = variableKey.startsWith('${') 
      ? variableKey.slice(2, -1) 
      : variableKey;

    const currentVariables = editedSection.variables || [];
    const newVariables = currentVariables.includes(normalizedKey)
      ? currentVariables.filter(v => v !== normalizedKey)
      : [...currentVariables, normalizedKey];

    const updatedSection = {
      ...editedSection,
      variables: newVariables,
      content: {
        ...editedSection.content,
        dataMapping: {
          ...(editedSection.content?.dataMapping || {})
        }
      }
    };

    setEditedSection(updatedSection);
    onUpdate(updatedSection);
  };

  const handleSave = () => {
    onUpdate(editedSection);
    setEditDialogOpen(false);
  };

  return (
    <>
      <Draggable
        axis="y"
        handle=".drag-handle"
        position={position}
        onStop={(e, data) => {
          setPosition({ x: 0, y: 0 }); // Reset position after drag
          onDragStop(section.id, e, data);
        }}
      >
        <Paper 
          sx={{ 
            p: 2, 
            mb: 2, 
            display: 'flex',
            alignItems: 'center',
            '&:hover': { boxShadow: 3 },
            cursor: 'default'
          }}
          data-section-id={section.id}
        >
          <DragIcon 
            className="drag-handle"
            sx={{ 
              mr: 2, 
              color: 'text.secondary',
              cursor: 'move'
            }} 
          />
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">
              {section.title}
            </Typography>
            <Box sx={{ mt: 1, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              <Chip 
                size="small" 
                label={section.type}
                color="primary" 
                variant="outlined" 
              />
              {section.variables?.map(variable => (
                <Chip
                  key={variable}
                  size="small"
                  label={variable}
                  icon={<DataIcon />}
                  variant="outlined"
                />
              ))}
            </Box>
          </Box>
          <IconButton onClick={() => setEditDialogOpen(true)} size="small">
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => onDelete(section.id)} size="small">
            <DeleteIcon />
          </IconButton>
        </Paper>
      </Draggable>

      <Dialog 
        open={editDialogOpen} 
        onClose={() => setEditDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Section</DialogTitle>
        <DialogContent dividers>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" gutterBottom>
                Section Details
              </Typography>
              <TextField
                fullWidth
                label="Title"
                value={editedSection.title}
                onChange={(e) => setEditedSection(prev => ({
                  ...prev,
                  title: e.target.value
                }))}
                sx={{ mb: 2 }}
              />
              <FormControl fullWidth>
                <InputLabel>Type</InputLabel>
                <Select
                  value={editedSection.type}
                  label="Type"
                  onChange={(e) => {
                    const type = e.target.value;
                    setEditedSection(prev => ({
                      ...prev,
                      type,
                      content: SectionTypes[type.toUpperCase()]?.defaultContent || {}
                    }));
                  }}
                >
                  {Object.entries(SectionTypes).map(([key, { label }]) => (
                    <MenuItem key={key} value={key.toLowerCase()}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" gutterBottom>
                Available Variables
              </Typography>
              <VariableSelector
                section={editedSection}
                onVariableSelect={handleVariableSelect}
                selectedVariables={editedSection.variables || []}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSave} variant="contained">Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const VisualTemplateBuilder = ({ sections, onSectionsChange }) => {
  const [selectedSection, setSelectedSection] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [expandedSection, setExpandedSection] = useState(null);
  const [positions, setPositions] = useState({});

  const handleDragStop = (id, e, data) => {
    // Get all section elements and their positions
    const sectionElements = Array.from(document.querySelectorAll('[data-section-id]'));
    const sectionPositions = sectionElements.map(el => ({
      id: el.getAttribute('data-section-id'),
      top: el.getBoundingClientRect().top
    }));

    // Sort sections by vertical position
    sectionPositions.sort((a, b) => a.top - b.top);

    // Reorder sections based on positions
    const reorderedSections = sectionPositions.map(pos => 
      sections.find(section => section.id === pos.id)
    );

    onSectionsChange(reorderedSections);
  };

  const handleSectionUpdate = (updatedSection) => {
    const updatedSections = sections.map(section =>
      section.id === updatedSection.id ? updatedSection : section
    );
    onSectionsChange(updatedSections);
  };

  const handleAddSection = (type) => {
    const sectionType = SectionTypes[type];
    const newSection = {
      id: `section-${Date.now()}`,
      type: sectionType.value,
      title: sectionType.label,
      content: { 
        ...sectionType.defaultContent,
        dataMapping: {}  // Initialize dataMapping
      },
      variables: [],  // Initialize variables array
      aiRules: {},
      styling: {}
    };
    onSectionsChange([...sections, newSection]);
    setMenuAnchorEl(null);
  };

  const handleDeleteSection = (sectionId) => {
    onSectionsChange(sections.filter(section => section.id !== sectionId));
  };

  const renderDataMappingPanel = (section) => (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Typography variant="subtitle2" gutterBottom>
        <DataIcon sx={{ mr: 1, fontSize: 'small' }} />
        Data Variables
      </Typography>
      <Grid container spacing={2}>
        {Object.entries(dataVariables).map(([category, { icon: Icon, label, variables }]) => (
          <Grid item xs={12} key={category}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Icon sx={{ mr: 1, fontSize: 'small' }} />
                  <Typography variant="subtitle2">{label}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {Object.entries(variables).map(([key, description]) => (
                    <Chip
                      key={key}
                      label={description}
                      size="small"
                      onClick={() => {
                        const updatedSections = sections.map(s =>
                          s.id === section.id
                            ? {
                                ...s,
                                content: {
                                  ...s.content,
                                  text: s.content.text
                                    ? `${s.content.text} \${${key}}`
                                    : `\${${key}}`
                                }
                              }
                            : s
                        );
                        onSectionsChange(updatedSections);
                      }}
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          bgcolor: 'primary.light',
                          color: 'white'
                        }
                      }}
                    />
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );

  return (
    <Box>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6">Template Structure</Typography>
        <Button
          startIcon={<AddIcon />}
          onClick={(e) => setMenuAnchorEl(e.currentTarget)}
        >
          Add Section
        </Button>
      </Box>

      <Box>
        {sections.map(section => (
          <SectionEditor
            key={section.id}
            section={section}
            onUpdate={handleSectionUpdate}
            onDelete={() => handleDeleteSection(section.id)}
            onDragStop={handleDragStop}
          />
        ))}
      </Box>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
      >
        {Object.entries(SectionTypes).map(([key, sectionType]) => (
          <MenuItem 
            key={key} 
            onClick={() => handleAddSection(key)}
          >
            {sectionType.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default VisualTemplateBuilder; 