import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  CircularProgress,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import FundraisingDocuments from './FundraisingDocuments';
import FundingStages from './FundingStages';
import { fundingSourcesService } from '../../services/fundingSourcesService';
import {
  MonetizationOn as FundraisingIcon,
  Assignment as AssignmentIcon,
  Business as PrivateCapitalIcon,
  TrendingUp as PublicOfferingIcon,
  Assessment as AnalyticsIcon,
  AccountBalance as GovernmentIcon,
  Handshake as CommercialIcon,
  Favorite as NonProfitIcon,
  ArrowForward as ArrowForwardIcon,
  Info as InfoIcon,
  Description as DocumentIcon,
  CheckCircle as CheckCircleIcon,
  RadioButtonUnchecked as PendingIcon,
  AttachFile as DocumentsIcon,
  Schedule as TimelineIcon,
} from '@mui/icons-material';
import { fundraisingService } from '../../services/fundraisingService';

const FundraisingJourney = ({ userData, selectedOrg }) => {
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [journeyId, setJourneyId] = useState(null);
  const [fundingSources, setFundingSources] = useState([]);
  const [loadingSources, setLoadingSources] = useState(false);

  useEffect(() => {
    if (location.state?.selectedTypes) {
      setSelectedTypes(location.state.selectedTypes);
      // Initialize journey in the backend
      initializeJourney(location.state.selectedTypes);
    }
  }, [location.state]);

  useEffect(() => {
    const fetchFundingSources = async () => {
      if (selectedTypes.length === 0) return;
      
      setLoadingSources(true);
      try {
        const sources = await Promise.all(
          selectedTypes.map(type => 
            fundingSourcesService.getFundingSources(type)
          )
        );
        setFundingSources(sources.flat());
      } catch (error) {
        console.error('Error fetching funding sources:', error);
      } finally {
        setLoadingSources(false);
      }
    };

    fetchFundingSources();
  }, [selectedTypes]);

  const initializeJourney = async (types) => {
    try {
      const response = await fundraisingService.createFundraisingJourney(selectedOrg, {
        fundingTypes: types,
        status: 'active',
        createdBy: userData?.id
      });
      setJourneyId(response.id);
    } catch (error) {
      console.error('Error initializing journey:', error);
    }
  };

  const steps = [
    'Journey Setup',
    'Document Preparation',
    'Application Process',
    'Due Diligence',
    'Closing'
  ];

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Paper 
          elevation={3} 
          sx={{ 
            p: 3, 
            mb: 3, 
            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
            color: 'white'
          }}
        >
          <Typography variant="h4" gutterBottom>
            Funding Journey
          </Typography>
          <Typography variant="subtitle1">
            Selected Paths: {selectedTypes.join(', ')}
          </Typography>
        </Paper>

        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            {activeStep === 0 && (
              <FundingStages 
                selectedTypes={selectedTypes}
                journeyId={journeyId}
                selectedOrg={selectedOrg}
              />
            )}
            {activeStep === 1 && (
              <FundraisingDocuments 
                selectedTypes={selectedTypes}
                journeyId={journeyId}
                selectedOrg={selectedOrg}
              />
            )}
            {/* Add more step components as needed */}
          </Grid>
        </Grid>

        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" gutterBottom>
            Available Funding Sources
          </Typography>
          {loadingSources ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={2}>
              {fundingSources.map((source) => (
                <Grid item xs={12} md={4} key={source.id}>
                  <Paper elevation={2} sx={{ p: 2 }}>
                    <Typography variant="subtitle1">{source.name}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {source.description}
                    </Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      href={source.url}
                      target="_blank"
                      sx={{ mt: 1 }}
                    >
                      Learn More
                    </Button>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={handleNext}
            disabled={activeStep === steps.length - 1}
          >
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default FundraisingJourney; 