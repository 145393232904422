import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Chip } from '@mui/material';
import { Add as AddIcon, Visibility, Delete, Edit } from '@mui/icons-material';

const CanvasTable = ({ canvasType, canvases, onActionClick, onDeleteCanvas }) => {
  const title = canvasType === 'bidStrategy' ? 'Bid Strategy Canvas' : 'RFP Qualification Canvas';
  const canvas = canvases[0]; // Only use the first canvas

  const getStatusChip = (status) => {
    switch (status) {
      case 'draft':
        return <Chip label="Draft" color="warning" />;
      case 'completed':
        return <Chip label="Completed" color="success" />;
      default:
        return <Chip label="Not Started" color="default" />;
    }
  };

  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>{title}</Typography>
      {canvas ? (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Last Updated</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {getStatusChip(canvas.status)}
                </TableCell>
                <TableCell>{canvas.updatedAt ? new Date(canvas.updatedAt).toLocaleString() : 'N/A'}</TableCell>
                <TableCell>
                  <Button
                    startIcon={canvas.status === 'completed' ? <Visibility /> : <Edit />}
                    onClick={() => onActionClick(canvasType, canvas.id)}
                    size="small"
                    sx={{ mr: 1 }}
                  >
                    {canvas.status === 'completed' ? 'View' : 'Edit'}
                  </Button>
                  <Button
                    startIcon={<Delete />}
                    onClick={() => onDeleteCanvas(canvasType, canvas.id)}
                    size="small"
                    color="error"
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Button
          startIcon={<AddIcon />}
          onClick={() => onActionClick(canvasType)}
          sx={{ mt: 2 }}
        >
          Create Canvas
        </Button>
      )}
    </Paper>
  );
};

export default CanvasTable;