import api from './api';
import { getAuthHeader } from './authService';

const handleApiError = (error) => {
  console.error('Collateral API Error:', error);
  const errorMessage = error.response?.data?.message || error.message;
  throw new Error(`API error: ${errorMessage}`);
};

export const getCollateralLibrary = async (orgId) => {
  try {
    if (!orgId) {
      throw new Error('Organization ID is required');
    }

    console.log('Fetching collateral library for org:', orgId);
    const token = await getAuthHeader();
    const response = await api.get(`/api/collateral/${orgId}/library`, {
      headers: { 
        Authorization: token,
        'Content-Type': 'application/json'
      }
    });
    
    return response.data || [];
  } catch (error) {
    console.error('Error fetching collateral library:', error);
    if (error.response?.status === 404) {
      return []; // Return empty array for 404
    }
    handleApiError(error);
  }
};

export const getCollateralItem = async (orgId, id) => {
  try {
    if (!orgId) {
      throw new Error('Organization ID is required');
    }
    if (!id) {
      throw new Error('Collateral ID is required');
    }

    console.log('Fetching collateral item:', { orgId, id });
    const token = await getAuthHeader();
    const response = await api.get(`/api/collateral/${orgId}/item/${id}`, {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching collateral item:', error);
    handleApiError(error);
  }
};

export const getCollateral = getCollateralItem;

export const createCollateral = async (collateralData, selectedOrg) => {
  try {
    if (!selectedOrg) {
      throw new Error('Organization ID is required');
    }

    const token = await getAuthHeader();
    const cleanedData = {
      ...collateralData,
      organisationId: selectedOrg,
      referenceUrls: collateralData.referenceUrls || []
    };

    const response = await api.post(`/api/collateral/${selectedOrg}`, cleanedData, {
      headers: { 
        Authorization: token,
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const updateCollateral = async (id, collateralData, selectedOrg) => {
  try {
    if (!selectedOrg || !id) {
      throw new Error('Organization ID and Collateral ID are required');
    }

    const token = await getAuthHeader();
    const cleanedData = {
      ...collateralData,
      organisationId: selectedOrg,
      referenceUrls: collateralData.referenceUrls || []
    };

    const response = await api.put(`/api/collateral/${selectedOrg}/${id}`, cleanedData, {
      headers: { 
        Authorization: token,
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const deleteCollateral = async (itemId, orgId) => {
  try {
    if (!itemId || !orgId) {
      throw new Error('Item ID and Organization ID are required');
    }

    const token = await getAuthHeader();
    const response = await api.delete(`/api/collateral/${orgId}/${itemId}`, {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getCollateralDownloadUrl = async (itemId, orgId) => {
  try {
    if (!itemId || !orgId) {
      throw new Error('Item ID and Organization ID are required');
    }

    const token = await getAuthHeader();
    const response = await api.get(`/api/collateral/${orgId}/${itemId}/download`, {
      headers: { Authorization: token }
    });
    return response.data.downloadUrl;
  } catch (error) {
    handleApiError(error);
  }
};

export const uploadCollateral = async (file, orgId) => {
  try {
    if (!orgId) {
      throw new Error('Organization ID is required');
    }

    const token = await getAuthHeader();
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(`/api/collateral/${orgId}/upload`, formData, {
      headers: { 
        Authorization: token,
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const listCollateral = getCollateralLibrary;
