import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { theme } from '../../theme';

// Register fonts (same as in StoryBrandPDF)
Font.register({
  family: 'Roboto',
  fonts: [
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf', fontWeight: 300 },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf', fontWeight: 400 },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf', fontWeight: 500 },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf', fontWeight: 700 },
  ]
});

const PortfolioPDF = ({ portfolioItems, propositions }) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#FFFFFF',
      padding: 30,
      fontFamily: 'Roboto',
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 20,
      borderBottom: `2 solid ${theme.palette.primary.main}`,
      paddingBottom: 10,
    },
    logoContainer: {
      width: 150,
      height: 75,
    },
    logo: {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
    title: {
      fontSize: 24,
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    section: {
      margin: '10 0',
      padding: 10,
      borderLeft: `4 solid ${theme.palette.primary.main}`,
    },
    sectionTitle: {
      fontSize: 18,
      fontWeight: 500,
      color: theme.palette.primary.main,
      marginBottom: 10,
    },
    itemTitle: {
      fontSize: 16,
      fontWeight: 700,
      color: theme.palette.secondary.main,
      marginTop: 15,
      marginBottom: 5,
    },
    label: {
      fontSize: 12,
      fontWeight: 700,
      color: theme.palette.text.primary,
      marginBottom: 2,
    },
    text: {
      fontSize: 10,
      marginBottom: 8,
      lineHeight: 1.4,
    },
    footer: {
      position: 'absolute',
      bottom: 30,
      left: 30,
      right: 30,
      textAlign: 'center',
      color: theme.palette.text.secondary,
      fontSize: 10,
      borderTop: `1 solid ${theme.palette.divider}`,
      paddingTop: 10,
    },
  });

  const getBCGCategory = (marketShare, marketGrowth) => {
    if (marketShare >= 1 && marketGrowth >= 10) return 'Star';
    if (marketShare >= 1 && marketGrowth < 10) return 'Cash Cow';
    if (marketShare < 1 && marketGrowth >= 10) return 'Question Mark';
    return 'Dog';
  };

  const formatArrayOrString = (value) => {
    if (Array.isArray(value)) {
      return value.join(', ');
    }
    return value || 'Not specified';
  };

  const getParentProposition = (item) => {
    return propositions.find(prop => prop.associatedProducts?.includes(item.id));
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.logoContainer}>
            <Image 
              src="/images/Puawai_Logo_Green_wText.png" 
              style={styles.logo} 
            />
          </View>
          <Text style={styles.title}>Portfolio Report</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Propositions</Text>
          {propositions.map((item, index) => (
            <View key={index}>
              <Text style={styles.itemTitle}>{item.name}</Text>
              <Text style={styles.label}>Description</Text>
              <Text style={styles.text}>{item.description || 'Not specified'}</Text>
              <Text style={styles.label}>Target Market</Text>
              <Text style={styles.text}>{item.targetMarket || 'Not specified'}</Text>
              <Text style={styles.label}>Value Proposition</Text>
              <Text style={styles.text}>{item.valueProposition || 'Not specified'}</Text>
              <Text style={styles.label}>Key Benefits</Text>
              <Text style={styles.text}>{item.keyBenefits || 'Not specified'}</Text>
              <Text style={styles.label}>What It Is</Text>
              <Text style={styles.text}>{item.whatItIs || 'Not specified'}</Text>
              <Text style={styles.label}>How It Works</Text>
              <Text style={styles.text}>{item.howItWorks || 'Not specified'}</Text>
              <Text style={styles.label}>Why It Matters</Text>
              <Text style={styles.text}>{item.whyItMatters || 'Not specified'}</Text>
              <Text style={styles.label}>Associated Products</Text>
              <Text style={styles.text}>{formatArrayOrString(item.associatedProducts)}</Text>
              <Text style={styles.label}>Associated Services</Text>
              <Text style={styles.text}>{formatArrayOrString(item.associatedServices)}</Text>
              <Text style={styles.label}>Updated By</Text>
              <Text style={styles.text}>{item.updatedBy || 'Not specified'}</Text>
            </View>
          ))}
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Portfolio Items</Text>
          {portfolioItems.map((item, index) => {
            const parentProposition = getParentProposition(item);
            return (
              <View key={index}>
                <Text style={styles.itemTitle}>{item.name}</Text>
                {parentProposition && (
                  <>
                    <Text style={styles.label}>Parent Proposition</Text>
                    <Text style={styles.text}>{parentProposition.name}</Text>
                  </>
                )}
                <Text style={styles.label}>Description</Text>
                <Text style={styles.text}>{item.description || 'Not specified'}</Text>
                <Text style={styles.label}>Category</Text>
                <Text style={styles.text}>{item.category || 'Not specified'}</Text>
                <Text style={styles.label}>Type</Text>
                <Text style={styles.text}>{item.type || 'Not specified'}</Text>
                <Text style={styles.label}>Delivery Type</Text>
                <Text style={styles.text}>{item.deliveryType || 'Not specified'}</Text>
                {item.deliveryType === 'Third Party' && (
                  <>
                    <Text style={styles.label}>Third Party Provider</Text>
                    <Text style={styles.text}>{item.thirdPartyProvider || 'Not specified'}</Text>
                  </>
                )}
                <Text style={styles.label}>Market Share</Text>
                <Text style={styles.text}>{item.marketShare ? `${item.marketShare}%` : 'Not specified'}</Text>
                <Text style={styles.label}>Market Growth</Text>
                <Text style={styles.text}>{item.marketGrowth ? `${item.marketGrowth}%` : 'Not specified'}</Text>
                <Text style={styles.label}>PLC Stage</Text>
                <Text style={styles.text}>{item.plcStage || 'Not specified'}</Text>
                <Text style={styles.label}>BCG Category</Text>
                <Text style={styles.text}>{getBCGCategory(item.marketShare, item.marketGrowth)}</Text>
                <Text style={styles.label}>Pricing</Text>
                <Text style={styles.text}>{item.pricing || 'Not specified'}</Text>
                <Text style={styles.label}>Features</Text>
                <Text style={styles.text}>{formatArrayOrString(item.features)}</Text>
                <Text style={styles.label}>Benefits</Text>
                <Text style={styles.text}>{formatArrayOrString(item.benefits)}</Text>
                <Text style={styles.label}>Technical Specifications</Text>
                <Text style={styles.text}>{item.technicalSpecifications || 'Not specified'}</Text>
              </View>
            );
          })}
        </View>

        <Text style={styles.footer}>
          © {new Date().getFullYear()} StepFWD Limited. All rights reserved.
        </Text>
      </Page>
    </Document>
  );
};

export default PortfolioPDF;
