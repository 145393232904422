import React, { useState, useEffect, useMemo, useCallback } from 'react';
import bidteamService from '../../services/bidteamService';
import { getUser } from '../../services/userService';
import { getOrganisation, getOrganisationUsers } from '../../services/organisationService';
import { useTheme } from '@mui/material/styles';

import { 
  Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Select, MenuItem, FormControl, InputLabel, Avatar,
  Tooltip, Fade, CircularProgress, Chip, Snackbar, Alert
} from '@mui/material';
import { Add, Delete, Edit, Person, TransferWithinAStation } from '@mui/icons-material';

const BidTeams = ({ bidId, userId, owner, teamMembers: initialTeamMembers, selectedOrg, isLoading, onTeamUpdate, bidVisibility, updateBidState }) => {
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [editingMember, setEditingMember] = useState({ userId: '', role: 'Member' });
  const [error, setError] = useState(null);
  const [localTeamMembers, setLocalTeamMembers] = useState([]);
  const [isLocalLoading, setIsLocalLoading] = useState(true);
  const [ownerDetails, setOwnerDetails] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [openTransferDialog, setOpenTransferDialog] = useState(false);
  const [newOwner, setNewOwner] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [organisation, setOrganisation] = useState(null);

  const isUserOwner = userId === owner;

  useEffect(() => {
    const fetchOwnerDetails = async () => {
      if (owner) {
        try {
          const ownerData = await getUser(owner);
          setOwnerDetails({
            userId: owner,
            role: 'Owner',
            name: `${ownerData.givenName} ${ownerData.surname}`.trim(),
            email: ownerData.email
          });
        } catch (error) {
          setError('Error fetching owner details. Please try again.');
        }
      }
    };

    fetchOwnerDetails();
  }, [owner]);

  const fetchTeamMembers = useCallback(async () => {
    if (!bidId) return;
    setIsLocalLoading(true);
    try {
      const members = await bidteamService.getTeamMembers(bidId);
      setLocalTeamMembers(members.map(member => ({
        ...member,
        name: member.name || 
              (member.givenName && member.surname ? `${member.givenName} ${member.surname}`.trim() : null) ||
              member.email ||
              'Unknown User'
      })));
    } catch (error) {
      setError('Error fetching team members. Please try again.');
    } finally {
      setIsLocalLoading(false);
    }
  }, [bidId]);

  useEffect(() => {
    fetchTeamMembers();
  }, [fetchTeamMembers]);

  useEffect(() => {
    const fetchAllUsers = async () => {
      if (organisation && organisation.members) {
        try {
          const userPromises = organisation.members.map((id) => getUser(id));
          const usersData = await Promise.all(userPromises);
          setAllUsers(usersData);
        } catch (error) {
          setError('Error fetching all users. Please try again.');
        }
      } else {
        setAllUsers([]);
      }
    };

    fetchAllUsers();
  }, [organisation]);

  useEffect(() => {
    const fetchOrganisationData = async () => {
      if (selectedOrg) {
        try {
          const org = await getOrganisation(selectedOrg);
          setOrganisation(org);
          //console.log('Fetched organisation:', org);
        } catch (error) {
          console.error('Error fetching organisation:', error);
          setError('Failed to fetch organisation details');
        }
      }
    };

    fetchOrganisationData();
  }, [selectedOrg]);

  useEffect(() => {
    const fetchOrganizationUsers = async () => {
      if (selectedOrg) {
        try {
          const users = await getOrganisationUsers(selectedOrg);
          setOrganizationUsers(users);
        } catch (error) {
          console.error('Error fetching organization users:', error);
          setError('Failed to fetch organization users. Please try again.');
        }
      }
    };

    fetchOrganizationUsers();
  }, [selectedOrg]);

  const displayedUsers = useMemo(() => {
    let users = [];
    if (ownerDetails) {
      users.push(ownerDetails);
    }
    if (bidVisibility === 'organisation') {
      users = users.concat(allUsers.map(user => ({
        userId: user.id,
        name: `${user.givenName} ${user.surname}`.trim(),
        email: user.email,
        role: localTeamMembers.find(member => member.userId === user.id)?.role || 'Not in team'
      })));
    } else {
      users = users.concat(localTeamMembers);
    }
    return users.filter((user, index, self) =>
      index === self.findIndex((t) => t.userId === user.userId)
    );
  }, [bidVisibility, allUsers, localTeamMembers, ownerDetails]);

  const availableUsers = useMemo(() => {
    const teamMemberIds = new Set(localTeamMembers.map(member => member.userId));
    teamMemberIds.add(owner);

    return organizationUsers.filter(user => 
      !teamMemberIds.has(user.id) && user.id !== userId
    );
  }, [organizationUsers, localTeamMembers, owner, userId]);

  const handleOpenDialog = (type, member = null) => {
    //console.log('Opening dialog. Type:', type, 'Member:', member);
    setDialogType(type);
    setEditingMember(member || { userId: '', role: 'Member' });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingMember({ userId: '', role: 'Member' });
  };

  const handleSaveMember = async () => {
    setIsActionLoading(true);
    try {
      setError(null);
      if (dialogType === 'add') {
        await bidteamService.addTeamMember(bidId, { ...editingMember, role: 'Member' }, selectedOrg);
      } else {
        await bidteamService.updateTeamMember(bidId, editingMember.userId, editingMember, selectedOrg);
      }
      await fetchTeamMembers(); // Refresh the team members list
      setSnackbar({ open: true, message: 'Team member saved successfully', severity: 'success' });
      handleCloseDialog();
      onTeamUpdate(); // Notify parent component of the update
    } catch (error) {
      console.error('Error saving team member:', error);
      setError('Error saving team member. Please try again.');
      setSnackbar({ open: true, message: 'Failed to save team member', severity: 'error' });
    } finally {
      setIsActionLoading(false);
    }
  };

  const handleRemoveMember = async (memberId) => {
    if (memberId === owner) {
      setSnackbar({
        open: true,
        message: "Cannot remove the owner from the team",
        severity: "error"
      });
      return;
    }
    setIsActionLoading(true);
    try {
      await bidteamService.removeTeamMember(bidId, memberId);
      await fetchTeamMembers(); // Refresh the team members list
      setSnackbar({
        open: true,
        message: "Team member removed successfully",
        severity: "success"
      });
      onTeamUpdate(); // Notify parent component of the update
    } catch (error) {
      let errorMessage = 'Failed to remove team member. Please try again.';
      if (error.message.includes('Team member not found')) {
        errorMessage = 'Team member not found. They may have already been removed.';
        await fetchTeamMembers(); // Refresh the list in case the member was already removed
      }
      setSnackbar({
        open: true,
        message: errorMessage,
        severity: "error"
      });
    } finally {
      setIsActionLoading(false);
    }
  };

  const getRoleChip = (role, userId) => {
    if (userId === owner) {
      return <Chip label="Owner" color="primary" size="small" />;
    } else if (role === 'Member') {
      return <Chip label="Member" color="secondary" size="small" />;
    } else if (role === 'Not in team') {
      return <Chip label="Not in team" variant="outlined" size="small" />;
    }
    return null;
  };

  const handleOpenTransferDialog = () => {
    setOpenTransferDialog(true);
  };

  const handleCloseTransferDialog = () => {
    setOpenTransferDialog(false);
    setNewOwner('');
  };

  const handleTransferOwnership = async () => {
    try {
      setError(null);
      if (owner === newOwner) {
        setError('New owner must be different from the current owner.');
        return;
      }
      const result = await bidteamService.transferOwnership(bidId, newOwner, selectedOrg);
      
      if (result && result.owner === newOwner) {
        await fetchTeamMembers(); // Refresh the team members list
        updateBidState(prevState => ({
          ...prevState,
          owner: newOwner
        }));
        onTeamUpdate(); // Notify parent component of the update
        handleCloseTransferDialog();
        setSnackbar({
          open: true,
          message: 'Ownership transferred successfully.',
          severity: 'success'
        });
      } else {
        throw new Error('Ownership transfer failed: Backend did not update the owner');
      }
    } catch (error) {
      setError('Error transferring ownership. Please try again.');
      setSnackbar({
        open: true,
        message: 'Failed to transfer ownership. Please try again.',
        severity: 'error'
      });
    }
  };

  if (isLoading || isLocalLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fade in={true}>
      <Paper elevation={3} sx={{ p: 2, backgroundColor: theme.palette.background.paper }}>
        {error && (
          <Typography color="error" gutterBottom>
            {error}
          </Typography>
        )}
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6">Bid Team Members</Typography>
          <Box>
            {isUserOwner && (
              <Button 
                variant="contained" 
                color="primary" 
                startIcon={<Add />} 
                onClick={() => handleOpenDialog('add')}
                sx={{ mr: 1 }}
              >
                Add Member
              </Button>
            )}
            {isUserOwner && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<TransferWithinAStation />}
                onClick={handleOpenTransferDialog}
              >
                Transfer Ownership
              </Button>
            )}
          </Box>
        </Box>
        {!isUserOwner && (
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            Note: Only the bid owner can add or remove team members.
          </Typography>
        )}
        {displayedUsers.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedUsers.map((member) => (
                  <TableRow key={member.userId}>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Avatar sx={{ mr: 1 }}>
                          {member.name ? member.name[0].toUpperCase() : (member.email ? member.email[0].toUpperCase() : 'U')}
                        </Avatar>
                        {member.name || member.email || 'Unknown User'}
                      </Box>
                    </TableCell>
                    <TableCell>{member.email || 'N/A'}</TableCell>
                    <TableCell>
                      {getRoleChip(member.role, member.userId)}
                    </TableCell>
                    <TableCell>
                      {isUserOwner && member.userId !== owner && (
                        <Tooltip title="Remove member">
                          <IconButton onClick={() => handleRemoveMember(member.userId)} disabled={isActionLoading}>
                            {isActionLoading ? <CircularProgress size={24} /> : <Delete />}
                          </IconButton>
                        </Tooltip>
                      )}
                      {bidVisibility === 'invite-only' && isUserOwner && (
                        <Tooltip title="Edit member">
                          <IconButton onClick={() => handleOpenDialog('edit', member)}>
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      )}
                      {bidVisibility === 'organisation' && member.role === 'Not in team' && isUserOwner && (
                        <Tooltip title="Add to team">
                          <IconButton onClick={() => handleOpenDialog('add', { userId: member.userId, role: 'Member' })}>
                            <Add />
                          </IconButton>
                        </Tooltip>
                      )}
                      {member.userId === owner && userId === owner && (
                        <Tooltip title="Transfer Ownership">
                          <IconButton onClick={handleOpenTransferDialog}>
                            <TransferWithinAStation />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body1" align="center">
            No team members found. {isUserOwner ? 'Add a member to get started.' : 'The bid owner can add team members.'}
          </Typography>
        )}

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>
            {dialogType === 'add' ? 'Add Team Member' : 'Edit Team Member'}
          </DialogTitle>
          <DialogContent>
            {dialogType === 'add' && (
              <FormControl fullWidth margin="dense">
                <InputLabel>User</InputLabel>
                <Select
                  value={editingMember.userId}
                  onChange={(e) => {
                    //console.log('Selected user:', e.target.value);
                    setEditingMember({ ...editingMember, userId: e.target.value, role: 'Member' });
                  }}
                >
                  {availableUsers.map(user => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.givenName} {user.surname} ({user.email})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {dialogType === 'edit' && (
              <Typography variant="body1" gutterBottom>
                Role: Member
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} disabled={isActionLoading}>Cancel</Button>
            <Button 
              onClick={handleSaveMember} 
              variant="contained" 
              color="primary"
              disabled={dialogType === 'add' && !editingMember.userId || isActionLoading}
            >
              {isActionLoading ? <CircularProgress size={24} /> : 'Save'}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openTransferDialog} onClose={handleCloseTransferDialog}>
          <DialogTitle>Transfer Ownership</DialogTitle>
          <DialogContent>
            <FormControl fullWidth margin="dense">
              <InputLabel>New Owner</InputLabel>
              <Select
                value={newOwner}
                onChange={(e) => setNewOwner(e.target.value)}
              >
                {displayedUsers
                  .filter(user => user.userId !== owner)
                  .map(user => (
                    <MenuItem key={user.userId} value={user.userId}>
                      {user.name} ({user.email})
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseTransferDialog}>Cancel</Button>
            <Button onClick={handleTransferOwnership} variant="contained" color="primary">
              Transfer
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        >
          <Alert onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Paper>
    </Fade>
  );
};

export default BidTeams;
