import React, { useState, useEffect, useMemo } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Typography, Chip, IconButton, Tooltip, Button, Dialog, DialogTitle, 
  DialogContent, DialogActions, TextField, MenuItem, Snackbar, Alert
} from '@mui/material';
import { 
  Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, 
  CheckCircle as CheckCircleIcon, Error as ErrorIcon, Schedule as ScheduleIcon
} from '@mui/icons-material';
import { format, isAfter, parseISO, differenceInDays, addDays, isValid } from 'date-fns';
import tasksService from '../../services/tasksService';
import bidteamService from '../../services/bidteamService';
import { getUser } from '../../services/userService';

const BidTasks = ({ bidId, userId, bidVisibility, organisation }) => {
  const [tasks, setTasks] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'dueDate', direction: 'asc' });
  const [openDialog, setOpenDialog] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [dialogMode, setDialogMode] = useState('');
  const [teamMembers, setTeamMembers] = useState([]);
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [availableAssignees, setAvailableAssignees] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchTasks();
    fetchAvailableAssignees();
  }, [bidId, bidVisibility]);

  const fetchTasks = async () => {
    try {
      const fetchedTasks = await tasksService.getBidTasks(bidId);
      setTasks(fetchedTasks);
    } catch (error) {
      setError('Error fetching tasks. Please try again.');
    }
  };

  const fetchAvailableAssignees = async () => {
    try {
      let users;
      if (bidVisibility === 'organisation') {
        users = await getOrganisationUsers(organisation.id);
      } else {
        users = await bidteamService.getTeamMembers(bidId);
      }
      setAvailableAssignees(users.map(user => ({
        id: user.id || user.userId,
        name: `${user.givenName || ''} ${user.surname || ''}`.trim() || user.name || 'Unknown User',
        email: user.email
      })));
    } catch (error) {
      setError('Error fetching available assignees. Please try again.');
    }
  };

  const handleOpenDialog = (mode, task = null) => {
    setDialogMode(mode);
    if (mode === 'add') {
      const tomorrow = addDays(new Date(), 1);
      setEditingTask({
        title: '',
        dueDate: format(tomorrow, "yyyy-MM-dd'T'HH:mm"),
        status: 'not_started',
        assignedTo: ''
      });
    } else {
      setEditingTask({
        ...task,
        dueDate: task.dueDate ? format(parseISO(task.dueDate), "yyyy-MM-dd'T'HH:mm") : '',
        assignedTo: task.assignedTo || ''
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingTask(null);
  };

  const handleSaveTask = async () => {
    try {
      const taskToSave = {
        ...editingTask,
        assignedTo: editingTask.assignedTo || null
      };
      //console.log('Saving task:', { dialogMode, bidId, taskToSave });
      if (dialogMode === 'add') {
        await tasksService.createTask(bidId, taskToSave);
      } else {
        await tasksService.updateTask(bidId, taskToSave.id, taskToSave);
      }
      fetchTasks();
      handleCloseDialog();
    } catch (error) {
      setError('Error saving task. Please try again.');
    }
  };

  const handleDeleteTask = async (taskId) => {
    try {
      await tasksService.deleteTask(bidId, taskId);
      fetchTasks();
    } catch (error) {
      setError('Error deleting task. Please try again.');
    }
  };

  const sortTasks = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortedTasks = () => {
    const sortedTasks = [...tasks];
    sortedTasks.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
    return sortedTasks;
  };

  const getStatusChip = (task) => {
    const now = new Date();
    const dueDate = task.dueDate ? parseISO(task.dueDate) : null;
    
    if (!dueDate || !isValid(dueDate)) {
      return <Chip label="No Due Date" color="default" icon={<ScheduleIcon />} />;
    }

    switch (task.status) {
      case 'completed':
        return <Chip label="Completed" color="success" icon={<CheckCircleIcon />} />;
      case 'in_progress':
        return <Chip label="In Progress" color="primary" icon={<ScheduleIcon />} />;
      case 'not_started':
        return <Chip label="Not Started" color="default" icon={<ScheduleIcon />} />;
      default:
        return isAfter(now, dueDate) 
          ? <Chip label="Overdue" color="error" icon={<ErrorIcon />} />
          : <Chip label="Unknown" color="default" />;
    }
  };

  const getDaysRemaining = (dueDate) => {
    if (!dueDate) return 'No due date set';
    const now = new Date();
    const taskDueDate = parseISO(dueDate);
    if (!isValid(taskDueDate)) return 'Invalid date';
    const days = differenceInDays(taskDueDate, now);
    return days >= 0 ? `${days} days remaining` : `${Math.abs(days)} days overdue`;
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'No date set';
    const date = parseISO(dateString);
    return isValid(date) ? format(date, 'MMM dd, yyyy HH:mm') : 'Invalid Date';
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(null);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
      <Typography variant="h6" gutterBottom>Bid Tasks</Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        sx={{ mb: 2 }}
        onClick={() => handleOpenDialog('add')}
      >
        Add New Task
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell onClick={() => sortTasks('title')}>Title</TableCell>
              <TableCell onClick={() => sortTasks('dueDate')}>Due Date</TableCell>
              <TableCell onClick={() => sortTasks('status')}>Status</TableCell>
              <TableCell onClick={() => sortTasks('assignedTo')}>Assigned To</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getSortedTasks().map((task) => (
              <TableRow key={task.id}>
                <TableCell>{task.title || 'Untitled Task'}</TableCell>
                <TableCell>
                  {formatDate(task.dueDate)}
                  <Typography variant="body2" color="textSecondary">
                    {getDaysRemaining(task.dueDate)}
                  </Typography>
                </TableCell>
                <TableCell>{getStatusChip(task)}</TableCell>
                <TableCell>
                  {task.assignedTo ? 
                    availableAssignees.find(user => user.id === task.assignedTo)?.name || 'Unknown User' 
                    : 'Unassigned'}
                </TableCell>
                <TableCell>
                  <Tooltip title="Edit Task">
                    <IconButton onClick={() => handleOpenDialog('edit', task)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Task">
                    <IconButton onClick={() => handleDeleteTask(task.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Task Edit/Add Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{dialogMode === 'add' ? 'Add New Task' : 'Edit Task'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            fullWidth
            value={editingTask?.title || ''}
            onChange={(e) => setEditingTask({ ...editingTask, title: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Due Date and Time"
            type="datetime-local"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={editingTask?.dueDate || ''}
            onChange={(e) => setEditingTask({ ...editingTask, dueDate: e.target.value })}
          />
          <TextField
            select
            margin="dense"
            label="Status"
            fullWidth
            value={editingTask?.status || 'not_started'}
            onChange={(e) => setEditingTask({ ...editingTask, status: e.target.value })}
          >
            <MenuItem value="not_started">Not Started</MenuItem>
            <MenuItem value="in_progress">In Progress</MenuItem>
            <MenuItem value="completed">Completed</MenuItem>
          </TextField>
          <TextField
            select
            margin="dense"
            label="Assigned To"
            fullWidth
            value={editingTask?.assignedTo || ''}
            onChange={(e) => setEditingTask({ ...editingTask, assignedTo: e.target.value })}
          >
            <MenuItem value="">
              <em>Unassigned</em>
            </MenuItem>
            {availableAssignees.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.name} ({user.email})
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSaveTask} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Snackbar for error messages */}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default BidTasks;
