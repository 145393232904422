import api from '../api';
import { getAuthHeader } from '../authService';
import { getToneOfVoice } from '../brandBuilderService';
import bidService from '../bidService';

const apiCall = async (method, endpoint, data = null) => {
  try {
    const token = await getAuthHeader();
    const config = {
      method,
      url: `/api${endpoint}`,
      headers: { 
        Authorization: token,
        'Content-Type': 'application/json'
      },
    };
    if (data) {
      config.data = data;
    }
    const response = await api(config);
    return response.data;
  } catch (error) {
    throw new Error(`API error: ${error.response?.data?.message || error.message}`);
  }
};

const formatDate = (date) => {
  return date ? new Date(date).toISOString().split('T')[0] : null; // Format: YYYY-MM-DD
};

const formatContextData = (contextData) => {
  return {
    ...contextData,
    bidData: contextData.bidData ? {
      ...contextData.bidData,
      dueDate: formatDate(contextData.bidData.dueDate),
    } : null,
    tasks: Array.isArray(contextData.tasks) ? contextData.tasks.map(task => ({
      ...task,
      dueDate: formatDate(task.dueDate),
    })) : [],
    timeTracking: Array.isArray(contextData.timeTracking) ? contextData.timeTracking.map(entry => ({
      ...entry,
      date: formatDate(entry.date),
    })) : [],
  };
};

export const puawaiBidAIService = {
  sendMessage: async (message, userInfo, contextData) => {
    try {
      let toneOfVoice = contextData?.toneOfVoice || 'default';
      if (toneOfVoice === 'custom' && userInfo?.organisationData?.id) {
        const orgToneOfVoice = await getToneOfVoice(userInfo.organisationData.id);
        toneOfVoice = orgToneOfVoice || 'default';
      }

      const formattedContextData = formatContextData(contextData);

      const response = await apiCall('post', '/bidai', {
        message,
        userInfo,
        contextData: {
          ...formattedContextData,
          personality: contextData.personality || 'default'
        },
        language: userInfo?.language || 'en',
        locale: userInfo?.locale || 'en-NZ'
      });

      if (response && response.response) {
        const processedActions = await puawaiBidAIService.processActions(response.actions, contextData.bidData.id);
        return { ...response, actions: processedActions };
      } else {
        throw new Error('Invalid response format from server');
      }
    } catch (error) {
      throw error;
    }
  },

  processActions: async (actions, bidId) => {
    const processedActions = [];
    for (const action of actions) {
      try {
        let result;
        switch (action.type) {
          case 'CREATE_TASK':
            result = await bidService.executeBidAction(bidId, action);
            processedActions.push({ ...action, result, requiresUserInput: true });
            break;
          // ... (other action types)
        }
      } catch (error) {
        processedActions.push({ ...action, error: error.message });
      }
    }
    return processedActions;
  },

  getBidDetails: async (bidId) => {
    try {
      return await bidService.getBidDetails(bidId);
    } catch (error) {
      throw error;
    }
  },

  createTask: async (bidId, taskData) => {
    try {
      return await bidService.executeBidAction(bidId, { type: 'CREATE_TASK', data: taskData });
    } catch (error) {
      throw error;
    }
  },

  updateTask: async (bidId, taskId, taskData) => {
    try {
      return await bidService.executeBidAction(bidId, { type: 'UPDATE_TASK', data: { id: taskId, ...taskData } });
    } catch (error) {
      throw error;
    }
  },

  // Add more methods here for other bid-related operations if needed
};

export default puawaiBidAIService;
