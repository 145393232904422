import React, { createContext, useContext, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

const StripeContext = createContext(null);

export const StripeProvider = ({ children }) => {
    const [stripe, setStripe] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const initStripe = async () => {
            try {
                if (!process.env.REACT_APP_STRIPE_PUBLIC_KEY) {
                    throw new Error('Stripe public key is missing');
                }
                const stripeInstance = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
                if (!stripeInstance) {
                    throw new Error('Failed to initialize Stripe');
                }
                setStripe(stripeInstance);
            } catch (err) {
                setError(err.message);
                console.error('Stripe initialization error:', err);
            } finally {
                setLoading(false);
            }
        };

        initStripe();
    }, []);

    return (
        <StripeContext.Provider value={{ stripe, loading, error }}>
            {children}
        </StripeContext.Provider>
    );
};

export const useStripeContext = () => {
    const context = useContext(StripeContext);
    if (!context) {
        throw new Error('useStripeContext must be used within a StripeProvider');
    }
    return context;
}; 