import React, { useState, useEffect } from 'react';
import BaseCanvas from './BaseCanvas';
import ScienceIcon from '@mui/icons-material/Science';
import { Box, Button, Typography, useTheme, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const GrowthExperimentsCanvas = ({ user, selectedOrg, canvas, onClose, onSave }) => {
  const [canvasData, setCanvasData] = useState(canvas || {});
  const theme = useTheme();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showUnsavedDialog, setShowUnsavedDialog] = useState(false);

  useEffect(() => {
    if (canvas) {
      setCanvasData(canvas);
    }
  }, [canvas]);

  const handleFieldChange = (field, value) => {
    setCanvasData(prev => ({ ...prev, [field]: value }));
    setHasUnsavedChanges(true);
  };

  const handleCloseAttempt = () => {
    if (hasUnsavedChanges) {
      setShowUnsavedDialog(true);
    } else {
      onClose();
    }
  };

  const handleConfirmedClose = () => {
    setShowUnsavedDialog(false);
    onClose();
  };

  const handleSave = async (updatedData) => {
    try {
      await onSave(updatedData);
      setCanvasData(updatedData);
      setHasUnsavedChanges(false);
      if (updatedData.status) {
        onClose();
      }
      return true;
    } catch (error) {
      console.error('Error saving canvas:', error);
      return false;
    }
  };

  const sections = [
    { field: 'growthGoal', title: 'Growth Goal', rows: 4 },
    { field: 'targetAudience', title: 'Target Audience', rows: 4 },
    { field: 'hypothesis', title: 'Hypothesis', rows: 4 },
    { field: 'experimentDesign', title: 'Experiment Design', rows: 6 },
    { field: 'successMetrics', title: 'Success Metrics', rows: 4 },
    { field: 'resources', title: 'Required Resources', rows: 4 },
    { field: 'timeline', title: 'Timeline', rows: 4 },
    { field: 'risks', title: 'Potential Risks and Mitigation', rows: 4 },
    { field: 'results', title: 'Results and Analysis', rows: 6 },
    { field: 'learningsAndNextSteps', title: 'Learnings and Next Steps', rows: 4 },
  ];

  const tooltips = {
    growthGoal: "State the specific growth objective for this experiment. Format: SMART goal. Example: 'Increase user acquisition by 25% within 3 months'",
    targetAudience: "Define the specific audience for this experiment. Format: Detailed description. Example: 'Tech-savvy professionals, ages 25-40, in urban areas, interested in productivity tools'",
    hypothesis: "Formulate your growth hypothesis. Format: If-then statement. Example: 'If we implement a referral program, then our user acquisition rate will increase by 25%'",
    experimentDesign: "Outline how you will conduct the experiment. Format: Step-by-step plan. Example: '1. Create referral program\n2. Implement tracking system\n3. Launch to 50% of users\n4. Monitor for 30 days'",
    successMetrics: "Define how you'll measure the experiment's success. Format: Key metrics with targets. Example: '• New user signups: +25%\n• Referral rate: 15% of active users\n• Cost per acquisition: Decrease by 20%'",
    resources: "List the resources needed for the experiment. Format: Categorized list. Example: 'Budget: $10,000\nPersonnel: 1 developer, 1 marketer\nTools: A/B testing software'",
    timeline: "Specify the timeline for the experiment. Format: Gantt chart or list. Example: 'Week 1-2: Setup\nWeek 3-6: Run experiment\nWeek 7: Analyze results'",
    risks: "Identify potential risks and mitigation strategies. Format: Risk-mitigation pairs. Example: 'Risk: Low participation\nMitigation: Increase incentives\n\nRisk: Technical issues\nMitigation: Thorough testing before launch'",
    results: "Record the actual results of the experiment. Format: Comparison to metrics. Example: 'New signups: +22% (Goal: 25%)\nReferral rate: 18% (Goal: 15%)\nCost per acquisition: -25% (Goal: -20%)'",
    learningsAndNextSteps: "Summarize key learnings and outline next steps. Format: Bullet points. Example: '• Referral program effective for acquisition\n• Increase incentives for better results\n• Roll out to all users\n• Test variations of referral message'",
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      height: '100vh',
      bgcolor: theme.palette.background.default,
      color: theme.palette.text.primary,
    }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        p: 2, 
        borderBottom: `1px solid ${theme.palette.divider}`
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ScienceIcon sx={{ mr: 1, fontSize: 28, color: theme.palette.secondary.main }} />
          <Typography variant="h5" sx={{ fontWeight: 500 }}>Growth Experiments Canvas</Typography>
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 3 }}>
        <BaseCanvas
          user={user}
          selectedOrg={selectedOrg}
          canvas={canvasData}
          onClose={handleCloseAttempt}
          onUpdate={(field, value) => handleFieldChange(field, value)}
          onSave={handleSave}
          canvasType="Growth Experiments Canvas"
          sections={sections}
          tooltips={tooltips}
          icon={ScienceIcon}
        />
      </Box>

      <Dialog open={showUnsavedDialog} onClose={() => setShowUnsavedDialog(false)}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          <Typography>
            You have unsaved changes. What would you like to do?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowUnsavedDialog(false)}>
            Continue Editing
          </Button>
          <Button onClick={handleConfirmedClose} color="error">
            Discard Changes
          </Button>
          <Button 
            onClick={async () => {
              const success = await handleSave(canvasData);
              if (success) {
                onClose();
              }
            }} 
            variant="contained" 
            color="primary"
          >
            Save & Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GrowthExperimentsCanvas;
