import React, { useState, useRef, useEffect } from 'react';
import { 
  Dialog, DialogTitle, DialogContent, IconButton, Typography, Box, Slide,
  TextField, Button, Paper, CircularProgress, Divider
} from '@mui/material';
import { Close as CloseIcon, Send as SendIcon } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling } from '@fortawesome/free-solid-svg-icons';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const getInitialMessage = (brandData, toneOfVoiceData) => {
  if (brandData?.mission && brandData?.vision) {
    return `Welcome! I see your inspiring brand mission: "${brandData.mission}"
    
And your vision: "${brandData.vision}"

I can help you with several aspects of your brand development:

1. Tone of Voice Development
   - Define your brand voice guidelines
   - Create communication style guides
   - Develop channel adaptations

2. StoryBrand Framework
   - Craft your brand story
   - Define your messaging framework
   - Create compelling narratives

3. Brand Guidelines (Coming Soon)
   - Visual identity management
   - Logo and design elements
   - Typography and color schemes

4. Brand Assets (Coming Soon)
   - Asset organization
   - Template management
   - Collateral development

What would you like to explore?`;
  } else if (toneOfVoiceData?.status === 'completed') {
    return `Welcome! I see you've completed your brand voice guidelines. Great work! 

I can help you with:

1. Download your Voice Guide PDF
2. Share your brand guidelines
3. Work on your StoryBrand Framework
4. Explore upcoming features like Visual Identity and Brand Assets

What would you like to focus on?`;
  } else {
    return `Welcome! I notice you haven't completed your brand's tone of voice yet. 

This is a crucial first step in developing a strong brand identity. Let's get started:

1. Develop Your Tone of Voice
   - Define your brand personality
   - Create voice guidelines
   - Set communication standards

2. Start Your Brand Journey
   - Begin with StoryBrand Framework
   - Plan your visual identity
   - Organize brand assets

3. Learn More About
   - Brand voice importance
   - Effective examples
   - Best practices

Which area interests you?`;
  }
};

const BrandAIAssistant = ({ 
  userProfile, 
  organisation, 
  brandData, 
  toneOfVoiceData, 
  isOpen, 
  onClose,
  topOffset = 64 
}) => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (isOpen && messages.length === 0) {
      const initialMessage = {
        content: getInitialMessage(brandData, toneOfVoiceData),
        sender: 'assistant',
        timestamp: new Date().toISOString()
      };
      setMessages([initialMessage]);
    }
  }, [isOpen, brandData, toneOfVoiceData]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = async () => {
    if (!message.trim()) return;

    const newMessage = {
      content: message,
      sender: 'user',
      timestamp: new Date().toISOString()
    };

    setMessages(prev => [...prev, newMessage]);
    setMessage('');
    setIsLoading(true);

    try {
      // TODO: Implement AI response logic here
      const aiResponse = {
        content: "I'm here to help with your brand development! What would you like to know?",
        sender: 'assistant',
        timestamp: new Date().toISOString()
      };
      
      setTimeout(() => {
        setMessages(prev => [...prev, aiResponse]);
        setIsLoading(false);
      }, 1000);
    } catch (error) {
      console.error('Error getting AI response:', error);
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          position: 'fixed',
          right: 0,
          top: topOffset,
          height: `calc(100vh - ${topOffset}px)`,
          m: 0,
          maxWidth: '400px',
          width: '100%',
          borderRadius: '12px 0 0 12px'
        }
      }}
    >
      <DialogTitle sx={{ 
        bgcolor: 'primary.main', 
        color: 'primary.contrastText',
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        p: 2
      }}>
        <FontAwesomeIcon icon={faSeedling} />
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Brand Intelligence
        </Typography>
        <IconButton 
          onClick={onClose}
          sx={{ color: 'inherit' }}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ 
        p: 0, 
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden'
      }}>
        {/* Messages Area */}
        <Box sx={{ 
          flexGrow: 1, 
          overflow: 'auto',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
          {messages.map((msg, index) => (
            <Box
              key={index}
              sx={{
                alignSelf: msg.sender === 'user' ? 'flex-end' : 'flex-start',
                maxWidth: '80%'
              }}
            >
              <Paper
                elevation={1}
                sx={{
                  p: 2,
                  bgcolor: msg.sender === 'user' ? 'primary.main' : 'grey.100',
                  color: msg.sender === 'user' ? 'primary.contrastText' : 'text.primary',
                  borderRadius: 2
                }}
              >
                <Typography variant="body1">
                  {msg.content}
                </Typography>
              </Paper>
            </Box>
          ))}
          {isLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
              <CircularProgress size={24} />
            </Box>
          )}
          <div ref={messagesEndRef} />
        </Box>

        <Divider />

        {/* Input Area */}
        <Box sx={{ 
          p: 2, 
          bgcolor: 'background.paper',
          borderTop: 1,
          borderColor: 'divider'
        }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <TextField
              fullWidth
              multiline
              maxRows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Ask about your brand development..."
              variant="outlined"
              size="small"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSend}
              disabled={isLoading || !message.trim()}
              sx={{ minWidth: 'auto', px: 2 }}
            >
              <SendIcon />
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default BrandAIAssistant; 