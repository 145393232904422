import React from 'react';
import { Box } from '@mui/material';
import CollateralLibrary from '../components/Collateral/CollateralLibrary';
import { WelcomePanel } from '../components/common/WelcomePanel';

const CollateralPage = ({ selectedOrg }) => {
  return (
    <Box sx={{ p: 3 }}>
      <WelcomePanel 
        title="Document Library"
        subtitle="Create and manage your documents and templates"
        icon="document"
      />
      <CollateralLibrary selectedOrg={selectedOrg} />
    </Box>
  );
};

export default CollateralPage; 