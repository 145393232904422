import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import PageHeader from '../../components/PageHeader/PageHeader';
import Footer from '../../components/Footer/Footer';

function TermsOfService({ onLoginClick }) {
  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      minHeight: '100vh',
      backgroundColor: 'background.default',
    }}>
      <PageHeader onLoginClick={onLoginClick} />
      <Container maxWidth="md" sx={{ mt: 12, mb: 6, flexGrow: 1 }}>
        <Typography variant="h2" component="h1" gutterBottom>
          Terms of Service
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to Puāwai. By using our platform, you agree to these Terms of Service. Please read them carefully.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Use of Our Services
        </Typography>
        <Typography variant="body1" paragraph>
          You must follow any policies made available to you within the Services. You may use our Services only as permitted by law. We may suspend or stop providing our Services to you if you do not comply with our terms or policies or if we are investigating suspected misconduct.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Your Puāwai Account
        </Typography>
        <Typography variant="body1" paragraph>
          You may need a Puāwai Account in order to use some of our Services. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Privacy and Copyright Protection
        </Typography>
        <Typography variant="body1" paragraph>
          Puāwai's privacy policies explain how we treat your personal data and protect your privacy when you use our Services. By using our Services, you agree that Puāwai can use such data in accordance with our privacy policies.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Modifying and Terminating our Services
        </Typography>
        <Typography variant="body1" paragraph>
          We are constantly changing and improving our Services. We may add or remove functionalities or features, and we may suspend or stop a Service altogether.
        </Typography>
        <Typography variant="body1" paragraph>
          You can stop using our Services at any time. We may also stop providing Services to you, or add or create new limits to our Services at any time.
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these Terms of Service, please contact us.
        </Typography>
      </Container>
      <Footer />
    </Box>
  );
}

export default TermsOfService;