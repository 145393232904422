import React, { useState, useEffect, useCallback } from 'react';
import {
  Box, Typography, Grid, Card, CardContent,
  Button, Snackbar, Alert, CircularProgress,
  Tabs, Tab, Paper, IconButton,
  Menu, MenuItem, Chip, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import {
  Add as AddIcon,
  Description as DocumentIcon,
  AutoAwesome as TemplateIcon,
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  FileCopy as DuplicateIcon,
} from '@mui/icons-material';
import { getCollateralLibrary } from '../../services/collateralService';
import { listTemplates } from '../../services/templateService';
import CollateralBuilder from './CollateralBuilder';
import TemplateBuilder from './Templates/TemplateBuilder';
import { EmptyState } from './components/EmptyState';
import { ActionButtons } from './components/ActionButtons';
import { deleteCollateral } from '../../services/collateralService';
import { deleteTemplate } from '../../services/templateService';
import TemplateSelector from './Templates/TemplateSelector';
import TemplateUsage from './Templates/TemplateUsage';

const PreviewBanner = () => (
  <Paper 
    elevation={0}
    sx={{ 
      p: 2, 
      mb: 3, 
      backgroundColor: 'info.light',
      borderRadius: 1,
      border: '1px solid',
      borderColor: 'info.main'
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: 'medium', color: 'info.dark' }}>
          🚀 Preview Feature
        </Typography>
        <Typography variant="body2" color="info.dark">
          Templates and automated document creation are currently in preview. We're actively developing these features and would love your feedback!
        </Typography>
      </Box>
      <Chip 
        label="Preview" 
        color="info" 
        size="small"
        sx={{ ml: 'auto' }}
      />
    </Box>
  </Paper>
);

const CollateralLibrary = ({ selectedOrg }) => {
  const [tabValue, setTabValue] = useState(0);
  const [libraryData, setLibraryData] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [collateralBuilderOpen, setCollateralBuilderOpen] = useState(false);
  const [templateBuilderOpen, setTemplateBuilderOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [templateSelectorOpen, setTemplateSelectorOpen] = useState(false);
  const [templateUsageOpen, setTemplateUsageOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const fetchLibraryData = useCallback(async () => {
    if (!selectedOrg) {
      console.log('No organization selected');
      return;
    }

    try {
      setLoading(true);
      console.log('Fetching collateral library for org:', selectedOrg);
      
      const [collateralData, templatesData] = await Promise.all([
        getCollateralLibrary(selectedOrg),
        listTemplates(selectedOrg).catch(error => {
          console.error('Error fetching templates:', error);
          return []; // Return empty array on error
        })
      ]);

      setLibraryData(collateralData || []);
      setTemplates(templatesData || []);

      console.log('Fetched data:', {
        collateral: collateralData?.length || 0,
        templates: templatesData?.length || 0
      });
    } catch (error) {
      console.error('Error fetching library data:', error);
      setSnackbar({
        open: true,
        message: 'Failed to load library data',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  }, [selectedOrg]);

  useEffect(() => {
    fetchLibraryData();
  }, [fetchLibraryData]);

  const handleCreateCollateral = () => {
    setSelectedItem(null);
    setCollateralBuilderOpen(true);
  };

  const handleCreateTemplate = () => {
    setSelectedItem(null);
    setTemplateBuilderOpen(true);
  };

  const handleMenuOpen = (event, item) => {
    setSelectedItem(item);
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleEdit = () => {
    if (!selectedItem) return;
    
    if (tabValue === 1) {
      if (selectedItem.id) {
        setTemplateBuilderOpen(true);
      } else {
        showSnackbar('Cannot edit template: Invalid template data', 'error');
      }
    } else {
      setCollateralBuilderOpen(true);
    }
    setMenuAnchorEl(null);
  };

  const handleDelete = async () => {
    if (!selectedItem || !selectedOrg) return;

    try {
      setLoading(true);
      if (tabValue === 1) {
        await deleteTemplate(selectedOrg, selectedItem.id);
        showSnackbar('Template deleted successfully');
      } else {
        await deleteCollateral(selectedItem.id, selectedOrg);
        showSnackbar('Document deleted successfully');
      }
      await fetchLibraryData();
    } catch (error) {
      console.error('Error deleting item:', error);
      showSnackbar(`Failed to delete ${tabValue === 1 ? 'template' : 'document'}`, 'error');
    } finally {
      setLoading(false);
      setDeleteDialogOpen(false);
      setMenuAnchorEl(null);
    }
  };

  const handleDuplicate = async () => {
    if (!selectedItem || !selectedOrg) return;

    try {
      setLoading(true);
      if (tabValue === 1) {
        await cloneTemplate(selectedOrg, selectedItem.id);
        showSnackbar('Template duplicated successfully');
      } else {
        const duplicateData = {
          ...selectedItem,
          name: `${selectedItem.name} (Copy)`,
          id: undefined,
          createdAt: undefined,
          updatedAt: undefined
        };
        await createCollateral(duplicateData, selectedOrg);
        showSnackbar('Document duplicated successfully');
      }
      await fetchLibraryData();
    } catch (error) {
      console.error('Error duplicating item:', error);
      showSnackbar(`Failed to duplicate ${tabValue === 1 ? 'template' : 'document'}`, 'error');
    } finally {
      setLoading(false);
      setMenuAnchorEl(null);
    }
  };

  const handleCreateFromTemplate = () => {
    setTemplateSelectorOpen(true);
  };

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
    setTemplateSelectorOpen(false);
    setTemplateUsageOpen(true);
  };

  const renderCard = (item) => {
    const isTemplate = tabValue === 1;
    const title = isTemplate 
      ? item.metadata?.name || item.name 
      : item.name || 'Untitled Document';
    const description = isTemplate 
      ? item.metadata?.description || item.description 
      : item.description || 'No description available';
    const type = isTemplate 
      ? item.metadata?.type 
      : item.type || 'document';
    const createdAt = new Date(item.createdAt).toLocaleDateString();
    const status = item.status || 'draft';

    return (
      <Card 
        sx={{ 
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          '&:hover': {
            boxShadow: 6
          }
        }}
      >
        <CardContent sx={{ flexGrow: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
            <Typography variant="h6" gutterBottom noWrap sx={{ maxWidth: '80%' }}>
              {title}
            </Typography>
            <IconButton onClick={(e) => handleMenuOpen(e, item)} size="small">
              <MoreVertIcon />
            </IconButton>
          </Box>

          <Typography 
            variant="body2" 
            color="text.secondary" 
            sx={{ 
              mb: 2,
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              height: '40px'
            }}
          >
            {description}
          </Typography>

          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 'auto' }}>
            <Chip 
              label={type}
              size="small"
              color={isTemplate ? "primary" : "default"}
              variant="outlined"
            />
            <Chip 
              label={status}
              size="small"
              color={status === 'published' ? "success" : "default"}
              variant="outlined"
            />
            <Typography 
              variant="caption" 
              color="text.secondary" 
              sx={{ ml: 'auto' }}
            >
              Created: {createdAt}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      );
    }

    if (error) {
      return (
        <Alert severity="error" sx={{ m: 2 }}>
          {error}
        </Alert>
      );
    }

    const items = tabValue === 0 ? libraryData : templates;
    
    if (items.length === 0) {
      return (
        <EmptyState 
          type={tabValue === 0 ? 'collateral' : 'templates'}
          onAction={tabValue === 0 ? handleCreateCollateral : handleCreateTemplate}
        />
      );
    }

    return (
      <Grid container spacing={3}>
        {items.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            {renderCard(item)}
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Box>
      <PreviewBanner />
      <ActionButtons 
        onCreateCollateral={handleCreateCollateral}
        onCreateTemplate={handleCreateTemplate}
        onCreateFromTemplate={handleCreateFromTemplate}
      />

      <Paper sx={{ mb: 3 }}>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab 
            icon={<DocumentIcon />} 
            iconPosition="start" 
            label="Documents" 
          />
          <Tab 
            icon={<TemplateIcon />} 
            iconPosition="start" 
            label="Templates" 
          />
        </Tabs>
      </Paper>

      {renderContent()}

      <CollateralBuilder
        open={collateralBuilderOpen}
        onClose={() => {
          setCollateralBuilderOpen(false);
          setSelectedItem(null);
        }}
        selectedOrg={selectedOrg}
        mode={selectedItem ? 'edit' : 'create'}
        collateral={selectedItem}
        onSave={async () => {
          await fetchLibraryData();
          showSnackbar(`Document ${selectedItem ? 'updated' : 'created'} successfully`);
        }}
      />

      {tabValue === 1 && (
        <TemplateBuilder
          open={templateBuilderOpen}
          onClose={() => {
            setTemplateBuilderOpen(false);
            setSelectedItem(null);
          }}
          selectedOrg={selectedOrg}
          mode={selectedItem ? 'edit' : 'create'}
          template={selectedItem}
          onSave={async () => {
            await fetchLibraryData();
            showSnackbar(`Template ${selectedItem ? 'updated' : 'created'} successfully`);
          }}
        />
      )}

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEdit}>
          <EditIcon sx={{ mr: 1 }} /> Edit
        </MenuItem>
        <MenuItem onClick={handleDuplicate}>
          <DuplicateIcon sx={{ mr: 1 }} /> Duplicate
        </MenuItem>
        <MenuItem 
          onClick={() => {
            setDeleteDialogOpen(true);
            setMenuAnchorEl(null);
          }}
          sx={{ color: 'error.main' }}
        >
          <DeleteIcon sx={{ mr: 1 }} /> Delete
        </MenuItem>
      </Menu>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete{' '}
            <strong>{selectedItem?.metadata?.name || selectedItem?.name}</strong>?
            This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeleteDialogOpen(false)}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleDelete}
            color="error"
            variant="contained"
            disabled={loading}
          >
            {loading ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <TemplateSelector
        open={templateSelectorOpen}
        onClose={() => setTemplateSelectorOpen(false)}
        selectedOrg={selectedOrg}
        onSelect={handleTemplateSelect}
      />

      <TemplateUsage
        open={templateUsageOpen}
        onClose={() => {
          setTemplateUsageOpen(false);
          setSelectedTemplate(null);
        }}
        selectedOrg={selectedOrg}
        template={selectedTemplate}
      />
    </Box>
  );
};

export default CollateralLibrary;
