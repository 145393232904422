import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  IconButton,
  Collapse,
  Chip,
  LinearProgress,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  Add as AddIcon,
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  Business as BusinessIcon,
  Assessment as AssessmentIcon,
  AccessTime as AccessTimeIcon,
  SmartToy as SmartToyIcon,
  Chat as ChatIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPencilAlt, faCircle } from '@fortawesome/free-solid-svg-icons';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';
import { listOrgCanvases, createOrgCanvas, deleteOrgCanvas, getOrgCanvas, updateOrgCanvas } from '../../services/organisationcanvasService';
import GrowthStrategyCanvas from '../../components/orgcanvases/GrowthStrategyCanvas';
import BusinessModelCanvas from '../../components/orgcanvases/BusinessModelCanvas';
import BusinessStrategyCanvas from '../../components/orgcanvases/BusinessStrategyCanvas';
import GrowthExperimentsCanvas from '../../components/orgcanvases/GrowthExperimentsCanvas';
import PartnerChannelsCanvas from '../../components/orgcanvases/PartnerChannelsCanvas';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const cardStyles = {
  paper: { 
    p: 2, 
    bgcolor: 'background.default',
    cursor: 'pointer',
    '&:hover': { bgcolor: 'action.hover' },
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: { 
    display: 'flex', 
    alignItems: 'center', 
    mb: 1 
  },
  description: {
    color: 'text.secondary',
    flex: 1
  }
};

const WelcomePanel = ({ title, subtitle }) => {
  const theme = useTheme();
  
  return (
    <Paper 
      sx={{ 
        p: 3, 
        background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
        color: 'white',
        mb: 3 
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <EmojiEventsIcon sx={{ fontSize: '3rem', marginRight: '1rem' }} />
        <Box>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle1">
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

const BusinessStrategy = ({ userData, selectedOrg, user }) => {
  const theme = useTheme();
  const [canvases, setCanvases] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [canvasDialogState, setCanvasDialogState] = useState({
    open: false,
    data: null,
    type: null,
  });

  useEffect(() => {
    console.log('BusinessStrategy - Props:', {
      userData,
      selectedOrg,
      user
    });

    if (!userData && !user) {
      console.error('No user data provided to BusinessStrategy');
      setError('User data is not available. Please try logging in again.');
      setLoading(false);
      return;
    }

    const activeUser = userData || user;

    if (!selectedOrg) {
      console.error('No organization selected');
      setError('Please select an organization to continue.');
      setLoading(false);
      return;
    }

    fetchCanvases();
  }, [userData, selectedOrg, user]);

  const fetchCanvases = async () => {
    try {
      console.log('Fetching canvases for org:', selectedOrg);
      const fetchedCanvases = await listOrgCanvases(selectedOrg);
      console.log('Fetched canvases:', fetchedCanvases);
      setCanvases(fetchedCanvases);
    } catch (error) {
      console.error('Error fetching canvases:', error);
      toast.error('Failed to fetch canvases. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const canvasTypes = [
    { type: 'Business Strategy Canvas', description: 'Define your overall business strategy and goals.' },
    { type: 'Growth Strategy Canvas', description: 'Define your growth strategy and key initiatives.' },
    { type: 'Business Model Canvas', description: 'Outline your business model and value proposition.' },
    { type: 'Partner Channels Canvas', description: 'Map out your partner channels and relationships.' },
    { type: 'Growth Experiments Canvas', description: 'Plan and track growth experiments and their outcomes.' }
  ];

  const handleActionClick = async (action, canvasId = null, canvasType = null) => {
    try {
      if (!selectedOrg) {
        throw new Error('No organization selected');
      }

      if (canvasId) {
        // Open existing canvas
        const canvas = await getOrgCanvas(selectedOrg, canvasId);
        setCanvasDialogState({
          open: true,
          data: canvas,
          type: canvas.type,
        });
      } else {
        // Create new canvas
        const newCanvas = await createOrgCanvas(selectedOrg, {
          name: `${canvasType}`,
          type: canvasType,
          createdBy: userData?.id,
          status: 'draft',
        });
        setCanvasDialogState({
          open: true,
          data: newCanvas,
          type: canvasType,
        });
        setCanvases([...canvases, newCanvas]);
        toast.success('New canvas created successfully');
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const handleDeleteCanvas = async (canvasId) => {
    try {
      await deleteOrgCanvas(selectedOrg, canvasId);
      setCanvases(canvases.filter(canvas => canvas.id !== canvasId));
      toast.success('Canvas deleted successfully');
    } catch (error) {
      toast.error('Failed to delete canvas. Please try again.');
    }
  };

  const handleCloseCanvasDialog = () => {
    setCanvasDialogState({ open: false, data: null, type: null });
  };

  const handleSaveCanvas = async (updatedCanvas) => {
    try {
      const savedCanvas = await updateOrgCanvas(selectedOrg, updatedCanvas.id, updatedCanvas);
      setCanvases(prevCanvases => 
        prevCanvases.map(c => c.id === savedCanvas.id ? savedCanvas : c)
      );
      toast.success('Canvas saved successfully');
      await fetchCanvases(); // Refresh the canvas list
    } catch (error) {
      toast.error(`Failed to save canvas: ${error.message}`);
    }
  };

  const CanvasCard = ({ canvasType, description }) => {
    const [expanded, setExpanded] = useState(false);
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.1,
    });

    const cardSpring = useSpring({
      opacity: inView ? 1 : 0,
      transform: inView ? 'translateY(0px)' : 'translateY(50px)',
    });

    const existingCanvas = canvases.find(c => c.type === canvasType);
    const isCompleted = existingCanvas?.status === 'completed';
    const isDraft = existingCanvas?.status === 'draft';

    let StepIcon;
    let iconColor;
    let statusText;
    if (isCompleted) {
      StepIcon = () => <FontAwesomeIcon icon={faCheck} />;
      iconColor = 'success.main';
      statusText = 'Completed';
    } else if (isDraft) {
      StepIcon = () => <FontAwesomeIcon icon={faPencilAlt} />;
      iconColor = 'warning.main';
      statusText = 'In Progress';
    } else {
      StepIcon = () => <FontAwesomeIcon icon={faCircle} />;
      iconColor = 'text.secondary';
      statusText = 'Not Started';
    }

    return (
      <animated.div style={cardSpring} ref={ref}>
        <Card>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => setExpanded(!expanded)}>
              <Box sx={{ mr: 2, color: iconColor }}>
                <StepIcon />
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle1">{canvasType}</Typography>
                <Chip 
                  label={statusText}
                  color={isCompleted ? 'success' : (isDraft ? 'warning' : 'default')}
                  size="small"
                  sx={{ ml: 2 }}
                />
              </Box>
              <ExpandMoreIcon 
                sx={{ 
                  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: '0.3s'
                }} 
              />
            </Box>
          </CardContent>
          <Collapse in={expanded}>
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                {description}
              </Typography>
              {existingCanvas && (
                <LinearProgress 
                  variant="determinate" 
                  value={existingCanvas.completionPercentage || 0} 
                  sx={{ mt: 2 }}
                />
              )}
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              {existingCanvas ? (
                <>
                  <Button
                    startIcon={<VisibilityIcon />}
                    onClick={() => handleActionClick('view', existingCanvas.id, canvasType)}
                    variant="outlined"
                    size="small"
                  >
                    View/Edit
                  </Button>
                  {(isDraft || isCompleted) && (
                    <IconButton size="small" onClick={() => handleDeleteCanvas(existingCanvas.id)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  )}
                </>
              ) : (
                <Button
                  startIcon={<AddIcon />}
                  onClick={() => handleActionClick('create', null, canvasType)}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Start Canvas
                </Button>
              )}
            </CardActions>
          </Collapse>
        </Card>
      </animated.div>
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      <WelcomePanel 
        title="Business Strategy"
        subtitle="Define and execute your business strategy with AI-powered tools"
      />

      <Grid container spacing={3} sx={{ mt: 2 }}>
        {/* Strategy Canvases Section */}
        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 3, mb: 3, borderLeft: `6px solid ${theme.palette.primary.main}` }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ color: theme.palette.primary.main, display: 'flex', alignItems: 'center' }}>
                  <BusinessIcon sx={{ mr: 1 }} />
                  Strategy Canvases
                </Typography>
                <Tooltip title="AI-Powered Strategy Assistant">
                  <Chip
                    icon={<SmartToyIcon />}
                    label="AI Assistant"
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{ ml: 2 }}
                  />
                </Tooltip>
              </Box>
            </Box>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Create and manage your strategic planning canvases with AI assistance
            </Typography>
            <Grid container spacing={2}>
              {canvasTypes.map((canvas) => (
                <Grid item xs={12} sm={6} key={canvas.type}>
                  <CanvasCard 
                    canvasType={canvas.type} 
                    description={canvas.description}
                  />
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>

        {/* Strategic Tools Section */}
        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 3, mb: 3, borderLeft: `6px solid ${theme.palette.secondary.main}` }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" sx={{ color: theme.palette.secondary.main, display: 'flex', alignItems: 'center' }}>
                <AssessmentIcon sx={{ mr: 1 }} />
                Strategic Tools
              </Typography>
            </Box>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Additional tools to support your strategic planning process
            </Typography>
            <Grid container spacing={3}>
              {/* Strategic Goals */}
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={cardStyles.paper}
                >
                  <Box sx={cardStyles.header}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <BusinessIcon color="secondary" sx={{ mr: 1 }} />
                        <Typography variant="h6" color="secondary.main">Strategic Goals</Typography>
                      </Box>
                      <Tooltip title="Coming Soon">
                        <Chip
                          icon={<AccessTimeIcon />}
                          label="Coming Soon"
                          size="small"
                          color="default"
                          variant="outlined"
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                  <Typography variant="body2" sx={cardStyles.description}>
                    Track and manage your organisation's strategic goals and OKRs
                  </Typography>
                </Paper>
              </Grid>

              {/* Similar structure for Strategy Insights and Timeline sections */}
              {/* ... */}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* AI Assistant Callout */}
      <Box sx={{ 
        mt: 2, 
        p: 2, 
        bgcolor: alpha(theme.palette.primary.main, 0.1), 
        borderRadius: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <SmartToyIcon color="primary" sx={{ mr: 2 }} />
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="subtitle1" color="primary.main">
                Strategy Development AI Assistant
              </Typography>
              <Chip
                label="Coming Soon"
                size="small"
                color="primary"
                variant="outlined"
              />
            </Box>
            <Typography variant="body2" color="text.secondary">
              Get AI-powered guidance for developing your business strategy
            </Typography>
          </Box>
        </Box>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<ChatIcon />}
          disabled
          sx={{ '&.Mui-disabled': { bgcolor: 'background.paper' } }}
        >
          Open Strategy Assistant
        </Button>
      </Box>

      <Dialog 
        open={canvasDialogState.open} 
        onClose={handleCloseCanvasDialog}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
          },
        }}
      >
        <DialogContent sx={{ p: 0 }}>
          {canvasDialogState.type === 'Business Strategy Canvas' && (
            <BusinessStrategyCanvas
              user={userData}
              selectedOrg={selectedOrg}
              canvas={canvasDialogState.data}
              onClose={handleCloseCanvasDialog}
              onSave={handleSaveCanvas}
            />
          )}
          {canvasDialogState.type === 'Growth Strategy Canvas' && (
            <GrowthStrategyCanvas
              user={userData}
              selectedOrg={selectedOrg}
              canvas={canvasDialogState.data}
              onClose={handleCloseCanvasDialog}
              onSave={handleSaveCanvas}
            />
          )}
          {canvasDialogState.type === 'Business Model Canvas' && (
            <BusinessModelCanvas
              user={userData}
              selectedOrg={selectedOrg}
              canvas={canvasDialogState.data}
              onClose={handleCloseCanvasDialog}
              onSave={handleSaveCanvas}
            />
          )}
          {canvasDialogState.type === 'Partner Channels Canvas' && (
            <PartnerChannelsCanvas
              user={userData}
              selectedOrg={selectedOrg}
              canvas={canvasDialogState.data}
              onClose={handleCloseCanvasDialog}
              onSave={handleSaveCanvas}
            />
          )}
          {canvasDialogState.type === 'Growth Experiments Canvas' && (
            <GrowthExperimentsCanvas
              user={userData}
              selectedOrg={selectedOrg}
              canvas={canvasDialogState.data}
              onClose={handleCloseCanvasDialog}
              onSave={handleSaveCanvas}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default BusinessStrategy; 