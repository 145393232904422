import React, { useState } from 'react';
import { Tooltip, Popover, Typography, CircularProgress, Box } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
import { puawaiTooltipService } from '../../services/ai/puawaiTooltipService';

const AITooltip = ({ context, userProfile, organisation }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tooltipContent, setTooltipContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handlePopoverOpen = async (event) => {
    setAnchorEl(event.currentTarget);
    setIsLoading(true);

    try {
      const tooltip = await puawaiTooltipService.getTooltip(
        context,
        {
          userName: userProfile?.givenName || 'User',
          orgName: organisation?.name || 'Unknown Organisation',
          organisationData: organisation,
          language: 'en',
          locale: 'en-NZ'
        },
        {
          toneOfVoice: 'friendly and concise',
        }
      );

      setTooltipContent(tooltip);
    } catch (error) {
      setTooltipContent('Unable to load tooltip content. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setTooltipContent('');
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Tooltip title="Click for more info">
        <InfoIcon 
          fontSize="small" 
          onClick={handlePopoverOpen}
          sx={{ cursor: 'pointer' }}
        />
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, maxWidth: 300 }}>
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <Typography variant="body2">{tooltipContent}</Typography>
          )}
        </Box>
      </Popover>
    </>
  );
};

export default AITooltip;
