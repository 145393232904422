import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Box, Button, Typography, Alert, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCardElement = styled(CardElement)(({ theme }) => ({
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
}));

const PaymentForm = ({ onSubscribe, isLoading }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        if (!stripe) {
            console.log('Waiting for Stripe to initialize...');
        } else {
            console.log('Stripe initialized successfully');
        }
    }, [stripe]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        if (!stripe || !elements) {
            return;
        }

        setProcessing(true);
        setError(null);

        try {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
            });

            if (error) {
                setError(error.message);
                return;
            }

            // Pass the payment method ID to the parent component
            await onSubscribe(paymentMethod.id);
        } catch (err) {
            setError(err.message || 'An error occurred while processing your payment');
        } finally {
            setProcessing(false);
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', maxWidth: 500 }}>
            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}
            
            <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                    Card Details
                </Typography>
                <StyledCardElement 
                    options={{
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }}
                />
            </Box>

            <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={!stripe || processing || isLoading}
                startIcon={processing || isLoading ? <CircularProgress size={20} /> : null}
            >
                {processing || isLoading ? 'Processing...' : 'Subscribe Now'}
            </Button>
        </Box>
    );
};

export default PaymentForm; 