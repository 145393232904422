import React, { createContext, useContext, useState, useEffect } from 'react';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');
  const [locale, setLocale] = useState('en-NZ'); // Default to New Zealand English

  useEffect(() => {
    // You can implement logic here to detect the user's language and locale
    // For now, we'll just use the browser's language but default to NZ English
    const browserLang = navigator.language || navigator.userLanguage;
    setLanguage(browserLang.split('-')[0]);
    setLocale(browserLang.startsWith('en') ? 'en-NZ' : browserLang);
  }, []);

  return (
    <LanguageContext.Provider value={{ language, setLanguage, locale, setLocale }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
