import React from 'react';
import { Box, Typography, Paper, Container } from '@mui/material';

const GrantsManager = ({ userData, selectedOrg }) => {
  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Paper elevation={2} sx={{ p: 3 }}>
          <Typography variant="h5" gutterBottom>
            Grants Management
          </Typography>
          <Typography variant="body1">
            Coming soon: Manage and track grant applications, deadlines, and reporting requirements.
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default GrantsManager; 