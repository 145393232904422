import React from 'react';
import {
  Box, Typography, Grid, Paper, Accordion, AccordionSummary,
  AccordionDetails, FormControl, InputLabel, Select, MenuItem,
  Switch, FormControlLabel, Slider, TextField, Chip, Divider
} from '@mui/material';
import {
  Brush as BrushIcon,
  SmartToy as AIIcon,
  Palette as PaletteIcon,
  TextFormat as TextIcon,
  Psychology as PsychologyIcon,
  Tune as TuneIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';

const BrandAISettings = ({ templateData, onUpdate }) => {
  const handleBrandSettingChange = (setting, value) => {
    onUpdate({
      ...templateData,
      brandSettings: {
        ...templateData.brandSettings,
        [setting]: value
      }
    });
  };

  const handleAISettingChange = (setting, value) => {
    onUpdate({
      ...templateData,
      aiConfig: {
        ...templateData.aiConfig,
        rules: {
          ...(templateData.aiConfig?.rules || {}),
          [setting]: value
        }
      }
    });
  };

  const renderBrandSettings = () => (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <BrushIcon color="primary" />
          <Typography variant="h6">Brand Settings</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper variant="outlined" sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <PaletteIcon sx={{ mr: 1 }} />
                <Typography variant="subtitle1">Visual Identity</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={templateData.brandSettings?.useBrandColors || false}
                        onChange={(e) => handleBrandSettingChange('useBrandColors', e.target.checked)}
                      />
                    }
                    label="Use Brand Colors"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={templateData.brandSettings?.includeLogo || false}
                        onChange={(e) => handleBrandSettingChange('includeLogo', e.target.checked)}
                      />
                    }
                    label="Include Logo"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper variant="outlined" sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <TextIcon sx={{ mr: 1 }} />
                <Typography variant="subtitle1">Typography</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={templateData.brandSettings?.useBrandFonts || false}
                        onChange={(e) => handleBrandSettingChange('useBrandFonts', e.target.checked)}
                      />
                    }
                    label="Use Brand Fonts"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Heading Style</InputLabel>
                    <Select
                      value={templateData.brandSettings?.headingStyle || 'default'}
                      onChange={(e) => handleBrandSettingChange('headingStyle', e.target.value)}
                      label="Heading Style"
                    >
                      <MenuItem value="default">Default</MenuItem>
                      <MenuItem value="modern">Modern</MenuItem>
                      <MenuItem value="classic">Classic</MenuItem>
                      <MenuItem value="bold">Bold</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );

  const renderAISettings = () => (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <AIIcon color="primary" />
          <Typography variant="h6">AI Configuration</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper variant="outlined" sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <PsychologyIcon sx={{ mr: 1 }} />
                <Typography variant="subtitle1">Content Generation</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={templateData.aiConfig?.rules?.useCustomerContext || false}
                        onChange={(e) => handleAISettingChange('useCustomerContext', e.target.checked)}
                      />
                    }
                    label="Use Customer Context"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={templateData.aiConfig?.rules?.adaptToneOfVoice || false}
                        onChange={(e) => handleAISettingChange('adaptToneOfVoice', e.target.checked)}
                      />
                    }
                    label="Adapt Tone of Voice"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={templateData.aiConfig?.rules?.includeCaseStudies || false}
                        onChange={(e) => handleAISettingChange('includeCaseStudies', e.target.checked)}
                      />
                    }
                    label="Include Relevant Case Studies"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper variant="outlined" sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <TuneIcon sx={{ mr: 1 }} />
                <Typography variant="subtitle1">AI Parameters</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography gutterBottom>Creativity Level</Typography>
                  <Slider
                    value={templateData.aiConfig?.rules?.creativityLevel || 0.5}
                    onChange={(e, value) => handleAISettingChange('creativityLevel', value)}
                    step={0.1}
                    marks
                    min={0}
                    max={1}
                    valueLabelDisplay="auto"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom>Content Length</Typography>
                  <Slider
                    value={templateData.aiConfig?.rules?.contentLength || 0.5}
                    onChange={(e, value) => handleAISettingChange('contentLength', value)}
                    step={0.1}
                    marks
                    min={0}
                    max={1}
                    valueLabelDisplay="auto"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <Box>
      {renderBrandSettings()}
      <Box sx={{ my: 3 }}>
        <Divider />
      </Box>
      {renderAISettings()}
    </Box>
  );
};

export default BrandAISettings; 