import axios from 'axios';
import { getToken, getAuthHeader } from './authService';
import api from './api';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

/**
 * Centralized function to handle API requests.
 * @param {string} method - HTTP method (GET, POST, PUT, DELETE).
 * @param {string} endpoint - API endpoint path.
 * @param {object} [data=null] - Data to send with the request (for POST, PUT).
 * @returns {Promise<object>} - The API response data.
 * @throws {Error} - Throws an error if the API call fails.
 */
const apiCall = async (method, endpoint, data = null) => {
    const token = await getToken();
    if (!token) {
        throw new Error('Authorization token is missing');
    }

    try {
        const response = await axios({
            method,
            url: `${API_BASE_URL}${endpoint}`,
            data,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

/**
 * List tasks for a specific bid.
 * @param {string} bidId - The bid ID.
 * @returns {Promise<object[]>} - The list of tasks.
 */
export const listTasks = async (bidId) => {
    return apiCall('get', `/api/bids/${bidId}/tasks`);
};

/**
 * Get a specific task by ID under a bid.
 * @param {string} bidId - The bid ID.
 * @param {string} taskId - The task ID.
 * @returns {Promise<object>} - The task data.
 */
export const getTask = async (bidId, taskId) => {
    return apiCall('get', `/api/bids/${bidId}/tasks/${taskId}`);
};

/**
 * Create a new task under a specific bid.
 * @param {string} bidId - The bid ID.
 * @param {object} taskData - The task data to create.
 * @returns {Promise<object>} - The created task data.
 */
const createTask = async (bidId, taskData) => {
    try {
        const token = await getAuthHeader();
        const response = await api.post(`/api/bids/${bidId}/tasks`, taskData, {
            headers: { Authorization: token }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

/**
 * Update a task by ID under a specific bid.
 * @param {string} bidId - The bid ID.
 * @param {string} taskId - The task ID.
 * @param {object} taskData - The updated task data.
 * @returns {Promise<object>} - The updated task data.
 */
const updateTask = async (bidId, taskId, taskData) => {
    try {
        const token = await getAuthHeader();
        const response = await api.put(`/api/bids/${bidId}/tasks/${taskId}`, taskData, {
            headers: { Authorization: token }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

/**
 * Delete a task by ID under a specific bid.
 * @param {string} bidId - The bid ID.
 * @param {string} taskId - The task ID.
 * @returns {Promise<void>}
 */
const deleteTask = async (bidId, taskId) => {
    await apiCall('delete', `/api/bids/${bidId}/tasks/${taskId}`);
};

const getBidTasks = async (bidId) => {
    try {
        const token = await getAuthHeader();
        const response = await api.get(`/api/bids/${bidId}/tasks`, {
            headers: { Authorization: token }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getBidTasks,
    createTask,
    updateTask,
    deleteTask,
    listTasks,
    getTask
};
