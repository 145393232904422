/**
 * Centralized error logging utility
 * Can be extended to send errors to monitoring service
 */

export const logError = (message, error) => {
  console.error(message, error);
  
  // TODO: Add error reporting service integration
  // Example: Sentry.captureException(error);
};

export const logWarning = (message, warning) => {
  console.warn(message, warning);
  
  // TODO: Add warning reporting
};

export default {
  logError,
  logWarning
}; 