import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  padding: theme.spacing(1, 0),
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const SeedlingIcon = styled('span')(({ theme }) => ({
  fontFamily: '"Font Awesome 5 Free"',
  fontWeight: 900,
  fontSize: '1.5rem',
  marginRight: theme.spacing(1),
  color: theme.palette.primary.main,
  '&::before': {
    content: '"\\f4d8"', // Unicode for seedling icon
  },
}));

function PageHeader({ onLoginClick }) {
  return (
    <StyledAppBar position="fixed">
      <Toolbar>
        <LogoContainer sx={{ flexGrow: 1 }}>
          <RouterLink to="/" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
            <SeedlingIcon />
            <Typography variant="h6" color="primary" component="div">
              Puāwai
            </Typography>
          </RouterLink>
        </LogoContainer>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => onLoginClick('signup')}
          sx={{ mr: 2 }}
        >
          Start Free Trial
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => onLoginClick('login')}
        >
          Login
        </Button>
      </Toolbar>
    </StyledAppBar>
  );
}

export default PageHeader;