import React from 'react';
import {
  Box, Typography, Grid, Paper, Accordion, AccordionSummary,
  AccordionDetails, FormControl, InputLabel, Select, MenuItem,
  TextField, Chip, IconButton, Tooltip
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Help as HelpIcon,
  DataObject as DataIcon
} from '@mui/icons-material';
import { dataVariables } from './DataVariableSelector';

const DataMappingEditor = ({ templateData, onUpdate }) => {
  const handleVariableSelect = (sectionId, field, variable) => {
    const updatedSections = templateData.structure.sections.map(section => {
      if (section.id === sectionId) {
        return {
          ...section,
          content: {
            ...section.content,
            dataMapping: {
              ...(section.content.dataMapping || {}),
              [field]: variable
            }
          }
        };
      }
      return section;
    });

    onUpdate({
      ...templateData,
      structure: {
        ...templateData.structure,
        sections: updatedSections
      }
    });
  };

  const renderVariableSelector = (sectionId, field, currentValue, availableVariables) => (
    <FormControl fullWidth size="small">
      <InputLabel>Select Variable</InputLabel>
      <Select
        value={currentValue || ''}
        onChange={(e) => handleVariableSelect(sectionId, field, e.target.value)}
        label="Select Variable"
      >
        {Object.entries(dataVariables).map(([category, { label, variables }]) => {
          const sectionVariables = Object.entries(variables)
            .filter(([key]) => availableVariables.includes(key));

          if (sectionVariables.length === 0) return null;

          return [
            <MenuItem key={category} disabled divider>
              {label}
            </MenuItem>,
            ...sectionVariables.map(([key, description]) => (
              <MenuItem key={key} value={`\${${key}}`}>
                {description}
              </MenuItem>
            ))
          ];
        })}
      </Select>
    </FormControl>
  );

  const renderSectionMapping = (section) => {
    const mappableFields = {
      header: ['title', 'subtitle'],
      executiveSummary: ['customer.name', 'opportunity.value', 'opportunity.stage'],
      valueProposition: ['portfolio.proposition', 'portfolio.differentiators'],
      solutionOverview: ['portfolio.products', 'portfolio.services', 'portfolio.solutions'],
      productsServices: {
        products: ['portfolio.products'],
        services: ['portfolio.services'],
        pricing: ['opportunity.value', 'opportunity.currency']
      },
      pricingTable: ['opportunity.currency', 'opportunity.value', 'opportunity.discount'],
      caseStudies: ['customer.industry', 'portfolio.caseStudies'],
      nextSteps: ['customer.contact.name', 'customer.contact.email', 'customer.contact.phone']
    };

    const getFieldsForSection = (section) => {
      const fields = mappableFields[section.type];
      if (Array.isArray(fields)) return fields;
      if (typeof fields === 'object') {
        return Object.values(fields).flat();
      }
      return [];
    };

    const fields = getFieldsForSection(section);
    const availableVariables = section.variables || [];

    const normalizeVariable = (variable) => {
      return variable.startsWith('${') ? variable.slice(2, -1) : variable;
    };

    const normalizedVariables = availableVariables.map(normalizeVariable);

    if (availableVariables.length === 0) {
      return (
        <Accordion key={section.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <DataIcon color="primary" />
              <Typography variant="subtitle1">{section.title}</Typography>
              <Chip 
                label="No variables selected"
                size="small"
                color="warning"
                sx={{ ml: 1 }}
              />
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="text.secondary">
              No variables have been selected for this section. 
              Go back to the Structure step to add variables.
            </Typography>
          </AccordionDetails>
        </Accordion>
      );
    }

    return (
      <Accordion key={section.id}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <DataIcon color="primary" />
            <Typography variant="subtitle1">{section.title}</Typography>
            <Chip 
              label={`${availableVariables.length} variables`}
              size="small"
              color="primary"
              sx={{ ml: 1 }}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {fields.map(field => (
              <Grid item xs={12} key={field}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="body2" sx={{ minWidth: 120 }}>
                    {field.split('.').join(' ')}:
                  </Typography>
                  {renderVariableSelector(
                    section.id,
                    field,
                    section.content?.dataMapping?.[field],
                    normalizedVariables
                  )}
                  <Tooltip title={`Map ${field} data`}>
                    <IconButton size="small">
                      <HelpIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Data Source Mapping
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Map your template sections to dynamic data sources. This data will be automatically
          populated when generating documents from this template.
        </Typography>
      </Box>

      <Paper variant="outlined" sx={{ p: 2 }}>
        {templateData.structure.sections.map(renderSectionMapping)}
      </Paper>
    </Box>
  );
};

export default DataMappingEditor; 