import React from 'react';
import { Paper, Typography, Button } from '@mui/material';
import {
  Add as AddIcon,
  Description as DocumentIcon,
  AutoAwesome as TemplateIcon,
} from '@mui/icons-material';

export const EmptyState = ({ type, onAction }) => (
  <Paper sx={{ p: 4, textAlign: 'center' }}>
    {type === 'collateral' ? (
      <>
        <DocumentIcon sx={{ fontSize: 60, color: 'text.secondary', mb: 2 }} />
        <Typography variant="h6" gutterBottom>
          No Documents Yet
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Create your first document or use a template to get started
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={onAction}
          sx={{ mt: 2 }}
        >
          Create Document
        </Button>
      </>
    ) : (
      <>
        <TemplateIcon sx={{ fontSize: 60, color: 'text.secondary', mb: 2 }} />
        <Typography variant="h6" gutterBottom>
          No Templates Yet
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Create reusable templates for your documents
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={onAction}
          sx={{ mt: 2 }}
        >
          Create Template
        </Button>
      </>
    )}
  </Paper>
); 