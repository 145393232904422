import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent,
  Grid, Card, CardContent, CardMedia,
  Typography, Box, IconButton, Button,
  TextField, InputAdornment, CircularProgress,
  Chip
} from '@mui/material';
import {
  Search as SearchIcon,
  Close as CloseIcon,
  Description as DocumentIcon,
  AutoAwesome as TemplateIcon
} from '@mui/icons-material';
import { listTemplates } from '../../../services/templateService';

const TemplateSelector = ({ open, onClose, selectedOrg, onSelect }) => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  useEffect(() => {
    const fetchTemplates = async () => {
      if (!selectedOrg) return;
      
      try {
        setLoading(true);
        const data = await listTemplates(selectedOrg);
        setTemplates(data);
      } catch (error) {
        console.error('Error fetching templates:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, [selectedOrg]);

  const filteredTemplates = templates.filter(template => 
    template.metadata?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    template.metadata?.description?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelect = (template) => {
    setSelectedTemplate(template);
    onSelect(template);
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6">Select Template</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            placeholder="Search templates..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Box>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            {filteredTemplates.map((template) => (
              <Grid item xs={12} sm={6} md={4} key={template.id}>
                <Card 
                  sx={{ 
                    cursor: 'pointer',
                    '&:hover': { boxShadow: 6 }
                  }}
                  onClick={() => handleSelect(template)}
                >
                  <CardMedia
                    component="div"
                    sx={{
                      height: 140,
                      bgcolor: 'grey.100',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <TemplateIcon sx={{ fontSize: 60, color: 'primary.main' }} />
                  </CardMedia>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {template.metadata?.name}
                    </Typography>
                    <Typography 
                      variant="body2" 
                      color="text.secondary"
                      sx={{
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        mb: 1
                      }}
                    >
                      {template.metadata?.description}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Chip 
                        label={template.metadata?.type} 
                        size="small"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip 
                        label={`${template.structure?.sections?.length || 0} sections`}
                        size="small"
                        variant="outlined"
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TemplateSelector; 