import React, { useState, useEffect } from 'react';
import BaseCanvas from './BaseCanvas';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { 
  Box, Button, Typography, useTheme, Dialog, DialogTitle, 
  DialogContent, DialogActions 
} from '@mui/material';

const PartnerChannelsCanvas = ({ user, selectedOrg, canvas, onClose, onSave }) => {
  const [canvasData, setCanvasData] = useState(canvas || {});
  const theme = useTheme();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showUnsavedDialog, setShowUnsavedDialog] = useState(false);

  useEffect(() => {
    if (canvas) {
      setCanvasData(canvas);
    }
  }, [canvas]);

  const handleFieldChange = (field, value) => {
    setCanvasData(prev => ({ ...prev, [field]: value }));
    setHasUnsavedChanges(true);
  };

  const handleCloseAttempt = () => {
    if (hasUnsavedChanges) {
      setShowUnsavedDialog(true);
    } else {
      onClose();
    }
  };

  const handleConfirmedClose = () => {
    setShowUnsavedDialog(false);
    onClose();
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasUnsavedChanges]);

  const handleSave = async (updatedData) => {
    try {
      await onSave(updatedData);
      setCanvasData(updatedData);
      setHasUnsavedChanges(false);
      if (updatedData.status) {
        onClose();
      }
      return true;
    } catch (error) {
      console.error('Error saving canvas:', error);
      return false;
    }
  };

  const sections = [
    { field: 'partnerTypes', title: 'Partner Types', rows: 4 },
    { field: 'partnerProfileIdeal', title: 'Ideal Partner Profile', rows: 4 },
    { field: 'valueProposition', title: 'Value Proposition for Partners', rows: 4 },
    { field: 'channelStrategy', title: 'Channel Strategy', rows: 6 },
    { field: 'partnerRecruitment', title: 'Partner Recruitment Process', rows: 4 },
    { field: 'partnerOnboarding', title: 'Partner Onboarding and Training', rows: 4 },
    { field: 'partnerSupport', title: 'Partner Support and Management', rows: 4 },
    { field: 'revenueModel', title: 'Revenue Model and Incentives', rows: 4 },
    { field: 'performanceMetrics', title: 'Performance Metrics and Evaluation', rows: 4 },
    { field: 'channelConflictResolution', title: 'Channel Conflict Resolution', rows: 4 },
  ];

  const tooltips = {
    partnerTypes: "Identify the types of partners you're targeting. Format: Categorized list. Example: '• Resellers: IT solution providers\n• Affiliates: Industry bloggers and influencers\n• Strategic Partners: Complementary software vendors'",
    partnerProfileIdeal: "Describe the characteristics of your ideal partner. Format: Detailed profile. Example: 'Established IT solution provider with 5+ years in business, serving 100+ SME clients, with a focus on cloud solutions and a strong presence in target geographic markets.'",
    valueProposition: "Articulate the unique value you offer to partners. Format: Bullet points with brief explanations. Example: '• High margins: 30% commission on sales\n• Exclusive territories: Protected geographic regions\n• Co-marketing support: Joint campaign funding'",
    channelStrategy: "Outline your overall strategy for partner channels. Format: Strategic objectives. Example: '1. Expand market reach through 50 new reseller partnerships in the next 12 months\n2. Increase partner-driven revenue to 40% of total sales within 2 years'",
    partnerRecruitment: "Describe your process for identifying and recruiting new partners. Format: Step-by-step process. Example: '1. Identify potential partners through industry events and referrals\n2. Initial outreach and qualification\n3. Partner presentation and value proposition\n4. Negotiation and agreement'",
    partnerOnboarding: "Outline your process for onboarding and training new partners. Format: Training program overview. Example: '1. Initial product training (2 days)\n2. Sales methodology workshop (1 day)\n3. Marketing and lead generation best practices (1 day)\n4. Certification exam'",
    partnerSupport: "Explain how you'll provide ongoing support and manage partner relationships. Format: Support structure. Example: '• Dedicated partner account managers\n• 24/7 technical support hotline\n• Quarterly business reviews\n• Annual partner summit'",
    revenueModel: "Describe how revenue will be shared with partners and what incentives you'll offer. Format: Commission structure and incentives. Example: 'Base commission: 20% of sale\nVolume bonuses: Up to 10% additional\nMDF: 2% of previous quarter's sales'",
    performanceMetrics: "List the metrics you'll use to measure and evaluate partner performance. Format: KPIs with targets. Example: '• Sales volume: Minimum $100K per quarter\n• Lead conversion rate: 20%+\n• Customer satisfaction: NPS of 50+'",
    channelConflictResolution: "Outline how you'll handle potential conflicts between different partner channels. Format: Conflict resolution process. Example: '1. Clear territory and account assignments\n2. Deal registration system\n3. Mediation process for disputes\n4. Regular review of channel policies'",
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      height: '100vh',
      bgcolor: theme.palette.background.default,
      color: theme.palette.text.primary,
    }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        p: 2, 
        borderBottom: `1px solid ${theme.palette.divider}`
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <HandshakeIcon sx={{ mr: 1, fontSize: 28, color: theme.palette.secondary.main }} />
          <Typography variant="h5" sx={{ fontWeight: 500 }}>Partner Channels Canvas</Typography>
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 3 }}>
        <BaseCanvas
          user={user}
          selectedOrg={selectedOrg}
          canvas={canvasData}
          onClose={handleCloseAttempt}
          onUpdate={(field, value) => handleFieldChange(field, value)}
          onSave={handleSave}
          canvasType="Partner Channels Canvas"
          sections={sections}
          tooltips={tooltips}
          icon={HandshakeIcon}
        />
      </Box>

      <Dialog open={showUnsavedDialog} onClose={() => setShowUnsavedDialog(false)}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          <Typography>
            You have unsaved changes. What would you like to do?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowUnsavedDialog(false)}>
            Continue Editing
          </Button>
          <Button onClick={handleConfirmedClose} color="error">
            Discard Changes
          </Button>
          <Button 
            onClick={async () => {
              const success = await handleSave(canvasData);
              if (success) {
                onClose();
              }
            }} 
            variant="contained" 
            color="primary"
          >
            Save & Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PartnerChannelsCanvas;
