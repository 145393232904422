import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
} from '@mui/material';
import {
  Description as DocumentIcon,
  Upload as UploadIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon,
  Folder as FolderIcon,
  Preview as PreviewIcon,
  PictureAsPdf as PDFIcon,
  InsertDriveFile as FileIcon,
  TableChart as SpreadsheetIcon,
  Close as CloseIcon,
  Search as SearchIcon
} from '@mui/icons-material';

const documentCategories = {
  'commercial': [
    { name: 'Due Diligence', folders: ['Financial', 'Legal', 'Market Analysis', 'Business Plan'] },
    { name: 'Pitch Materials', folders: ['Pitch Deck', 'Executive Summary', 'Financial Projections'] },
    { name: 'Term Sheets', folders: ['Draft', 'Signed', 'Historical'] },
    { name: 'Investor Updates', folders: ['Quarterly Reports', 'Annual Reports', 'Presentations'] }
  ],
  'non-profit': [
    { name: 'Grant Applications', folders: ['Active', 'Drafts', 'Submitted', 'Approved'] },
    { name: 'Impact Reports', folders: ['Program Outcomes', 'Beneficiary Stories', 'Statistics'] },
    { name: 'Donor Materials', folders: ['Proposals', 'Reports', 'Thank You Letters'] },
    { name: 'Compliance', folders: ['Tax Documents', 'Annual Reports', 'Certifications'] }
  ],
  'government': [
    { name: 'Grant Proposals', folders: ['RFP Responses', 'Technical Documents', 'Budgets'] },
    { name: 'Compliance Documents', folders: ['Certifications', 'Registrations', 'Permits'] },
    { name: 'Progress Reports', folders: ['Milestones', 'Financial Reports', 'Outcomes'] },
    { name: 'Audit Materials', folders: ['Financial Audits', 'Performance Audits', 'Compliance Audits'] }
  ]
};

const FundraisingDocuments = ({ pathType, fundraisingId }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [newDocument, setNewDocument] = useState({
    name: '',
    type: '',
    description: '',
    file: null
  });
  const [previewDialog, setPreviewDialog] = useState({
    open: false,
    url: null,
    type: null,
    name: null
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const categories = documentCategories[pathType] || [];

  const handleUpload = () => {
    // Implement document upload logic
    console.log('Uploading document:', newDocument);
    setUploadDialog(false);
  };

  const handleDownload = (document) => {
    // Implement document download logic
    console.log('Downloading document:', document);
  };

  const handleDelete = (document) => {
    // Implement document delete logic
    console.log('Deleting document:', document);
  };

  const handlePreview = async (document) => {
    try {
      const previewUrl = await fundraisingDocumentService.getDocumentPreview(
        selectedOrg,
        document.id
      );
      setPreviewDialog({
        open: true,
        url: previewUrl,
        type: document.type || null,
        name: document.name || 'Document'
      });
    } catch (error) {
      console.error('Error getting preview:', error);
      setPreviewDialog({
        open: true,
        url: null,
        type: null,
        name: document.name || 'Document',
        error: 'Failed to load preview'
      });
    }
  };

  const getDocumentIcon = (type) => {
    switch (type) {
      case 'pdf':
        return <PDFIcon />;
      case 'xls':
      case 'xlsx':
        return <SpreadsheetIcon />;
      default:
        return <FileIcon />;
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        {/* Categories List */}
        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Categories</Typography>
            <List>
              {categories.map((category) => (
                <ListItem
                  button
                  key={category.name}
                  selected={selectedCategory === category.name}
                  onClick={() => setSelectedCategory(category.name)}
                >
                  <ListItemIcon>
                    <FolderIcon />
                  </ListItemIcon>
                  <ListItemText primary={category.name} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Folders List */}
        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Folders</Typography>
            {selectedCategory && (
              <List>
                {categories
                  .find(c => c.name === selectedCategory)
                  ?.folders.map((folder) => (
                    <ListItem
                      button
                      key={folder}
                      selected={selectedFolder === folder}
                      onClick={() => setSelectedFolder(folder)}
                    >
                      <ListItemIcon>
                        <FolderIcon />
                      </ListItemIcon>
                      <ListItemText primary={folder} />
                    </ListItem>
                  ))}
              </List>
            )}
          </Paper>
        </Grid>

        {/* Documents List */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Documents</Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField
                  size="small"
                  placeholder="Search documents..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  InputProps={{
                    startAdornment: <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />,
                  }}
                />
                <Button
                  variant="contained"
                  startIcon={<UploadIcon />}
                  onClick={() => setUploadDialog(true)}
                  disabled={!selectedFolder}
                >
                  Upload Document
                </Button>
              </Box>
            </Box>
            {selectedFolder && (
              <List>
                {/* Placeholder for documents */}
                <ListItem>
                  <ListItemIcon>
                    <DocumentIcon />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Sample Document.pdf"
                    secondary="Last modified: 2024-03-20"
                  />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => handlePreview({})}>
                      <PreviewIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDownload({})}>
                      <DownloadIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete({})}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Upload Dialog */}
      <Dialog open={uploadDialog} onClose={() => setUploadDialog(false)}>
        <DialogTitle>Upload Document</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Document Name"
            margin="normal"
            value={newDocument.name}
            onChange={(e) => setNewDocument({ ...newDocument, name: e.target.value })}
          />
          <TextField
            fullWidth
            label="Document Type"
            margin="normal"
            select
            value={newDocument.type}
            onChange={(e) => setNewDocument({ ...newDocument, type: e.target.value })}
          >
            <MenuItem value="pdf">PDF</MenuItem>
            <MenuItem value="doc">Word Document</MenuItem>
            <MenuItem value="xls">Excel Spreadsheet</MenuItem>
          </TextField>
          <TextField
            fullWidth
            label="Description"
            margin="normal"
            multiline
            rows={3}
            value={newDocument.description}
            onChange={(e) => setNewDocument({ ...newDocument, description: e.target.value })}
          />
          <Button
            variant="outlined"
            component="label"
            fullWidth
            sx={{ mt: 2 }}
          >
            Choose File
            <input
              type="file"
              hidden
              onChange={(e) => setNewDocument({ ...newDocument, file: e.target.files[0] })}
            />
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUploadDialog(false)}>Cancel</Button>
          <Button onClick={handleUpload} color="primary">Upload</Button>
        </DialogActions>
      </Dialog>

      {/* Preview Dialog */}
      <Dialog 
        open={previewDialog.open} 
        onClose={() => setPreviewDialog({ open: false, url: null, type: null, name: null })}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          {previewDialog.name}
          <IconButton
            onClick={() => setPreviewDialog({ open: false, url: null, type: null, name: null })}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {previewDialog.type ? (
            previewDialog.type === 'pdf' ? (
              <iframe
                src={previewDialog.url}
                style={{ width: '100%', height: '80vh', border: 'none' }}
                title="Document Preview"
              />
            ) : previewDialog.type.startsWith('image/') ? (
              <img 
                src={previewDialog.url} 
                alt="Preview" 
                style={{ maxWidth: '100%', maxHeight: '80vh' }} 
              />
            ) : (
              <Typography>Preview not available for this file type</Typography>
            )
          ) : (
            <Typography>No preview available</Typography>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default FundraisingDocuments; 