import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { 
  Box, Typography, Grid, Paper, Button, Avatar, 
  List, ListItem, ListItemText, ListItemAvatar,
  Chip, LinearProgress, IconButton, Tooltip,
  Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, MenuItem, useTheme, Alert, Skeleton, Fade,
  CircularProgress, DialogContentText, Fab, Zoom, Drawer,
  Stepper, Step, StepLabel, StepContent, StepButton,
  Accordion, AccordionSummary, AccordionDetails,
  Card, CardContent, CardActions, Collapse,
  SwipeableDrawer, useMediaQuery, Tabs, Tab, ListItemIcon
} from '@mui/material';
import {
  Add as AddIcon,
  TrendingUp as TrendingUpIcon,
  Assignment as AssignmentIcon,
  MoreVert as MoreVertIcon,
  EmojiEvents as EmojiEventsIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Star as StarIcon,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
  CheckCircle as CheckCircleIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  Info as InfoIcon,
  ExpandMore as ExpandMoreIcon,
  Dashboard as DashboardIcon,
  Assessment as AssessmentIcon,
  AccountCircle as AccountCircleIcon,
  Business as BusinessIcon,
  BrandingWatermark as BrandingWatermarkIcon,
  Work as PortfolioIcon,
  People as PeopleIcon,
  LibraryBooks as LibraryBooksIcon,
  Money as MoneyIcon,
  Search as SearchIcon,
  Refresh as RefreshIcon,
  Storefront as StorefrontIcon,
  AccountBalance as AccountBalanceIcon,
  Inventory2 as Inventory2Icon,
  MiscellaneousServices as MiscellaneousServicesIcon,
  Settings as SettingsIcon,
  Category as CategoryIcon,
  BusinessCenter as BusinessCenterIcon,
  ManageAccounts as ManageAccountsIcon,
  AccessTime as AccessTimeIcon,
  Image as ImageIcon,
  AutoStories as AutoStoriesIcon,
  AutoFixHigh as AutoFixHighIcon,
  RecordVoiceOver as RecordVoiceOverIcon,
  Psychology as PsychologyIcon,
  SettingsVoice as SettingsVoiceIcon,
  Brush as BrushIcon,
  SmartToy as SmartToyIcon,
  Chat as ChatIcon,
  Timeline as TimelineIcon,
} from '@mui/icons-material';
import { getActiveAccount, getToken } from '../../services/authService';
import { getOrganisation } from '../../services/organisationService';
import { 
  listOrgCanvases, 
  createOrgCanvas, 
  deleteOrgCanvas, 
  getOrgCanvas,
  updateOrgCanvas,
  completeOrgCanvas,
  saveDraftOrgCanvas
} from '../../services/organisationcanvasService';
import { listBids, createBid } from '../../services/bidService';
import { useNavigate, Routes, Route, useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';
import GrowthStrategyCanvas from '../../components/orgcanvases/GrowthStrategyCanvas';
import BusinessModelCanvas from '../../components/orgcanvases/BusinessModelCanvas';
import BusinessStrategyCanvas from '../../components/orgcanvases/BusinessStrategyCanvas';
import GrowthExperimentsCanvas from '../../components/orgcanvases/GrowthExperimentsCanvas';
import PartnerChannelsCanvas from '../../components/orgcanvases/PartnerChannelsCanvas';
import StoryBrandCanvas from '../../components/orgcanvases/StoryBrandCanvas';
import { getUser, getUserWithSubscriptionStatus } from '../../services/userService';
import CloseIcon from '@mui/icons-material/Close';
import ChatInterface from '../../components/ai/puawaiAIinterface';
import { useLanguage } from '../../contexts/LanguageContext';
import FloatingAIIcon from '../../components/ai/puawaiIntelligenceInterface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import PuawaiIntelligenceInterface from '../../components/ai/puawaiIntelligenceInterface';
import { getPortfolioItems, getPropositions } from '../../services/portfolioService';
import AITooltip from '../../components/ai/AITooltip';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { format } from 'date-fns';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';
import { alpha } from '@mui/material/styles';

const moduleDescriptions = {
  phase1: "Phase 1: Business Strategy - Define your strategic foundation and growth direction",
  phase2a: "Phase 2a: Brand Development - Build a strong, consistent brand identity",
  phase2b: "Phase 2b: Sales Pipeline - Develop and manage market opportunities",
  phase3: "Phase 3: Customer Relationships - Nurture and grow customer relationships",
  phase4: "Phase 4: Operations - Manage products, services, and business operations"
};

const Dashboard = ({ userData, selectedOrgId, toneOfVoiceData }) => {
  const [userProfile, setUserProfile] = useState(null);
  const [organisation, setOrganisation] = useState(null);
  const [canvases, setCanvases] = useState([]);
  const [bids, setBids] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [newItem, setNewItem] = useState({ name: '', client: '', status: 'draft', dueDate: '' });
  const [canvasDialogState, setCanvasDialogState] = useState({
    open: false,
    data: null,
    type: null,
  });
  const navigate = useNavigate();
  const theme = useTheme();
  const [topBannerHeight, setTopBannerHeight] = useState(0);
  const { language, locale } = useLanguage();
  const [topOffset, setTopOffset] = useState(0);
  const layoutRef = useRef(null);
  const [portfolio, setPortfolio] = useState([]);
  const [propositions, setPropositions] = useState([]);
  const [expandedCanvas, setExpandedCanvas] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [deleteConfirmation, setDeleteConfirmation] = useState({ open: false, canvasId: null });
  const [fundingOpportunities, setFundingOpportunities] = useState([]);
  const [isFundingLoading, setIsFundingLoading] = useState(false);
  const [fundingError, setFundingError] = useState(null);
  const [brandMetrics, setBrandMetrics] = useState({
    elements: { total: 0, completed: 0, completion: 0 },
    story: { total: 0, completed: 0, strength: 0 },
    assets: { 
      total: 0, 
      active: 0,
      categories: { logos: 0, colors: 0, typography: 0, templates: 0 }
    }
  });

  const getUserName = (user) => {
    if (user.givenName) return user.givenName;
    if (user.name) return user.name.split(' ')[0];
    if (user.username) return user.username.split('@')[0];
    return 'User';
  };

  useEffect(() => {
    fetchDashboardData();
  }, [selectedOrgId, userData.localAccountId]);

  const fetchDashboardData = async () => {
    const orgId = selectedOrgId?.id || selectedOrgId;
    if (!orgId) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      
      const fetchedUserData = await getUser(userData.localAccountId);
      setUserProfile(fetchedUserData);

      const org = await getOrganisation(orgId);
      setOrganisation(org);

      if (org) {
        const fetchedBids = await listBids(orgId);
        setBids(fetchedBids);

        const fetchedCanvases = await listOrgCanvases(orgId);
        setCanvases(fetchedCanvases);

        const fetchedPortfolio = await getPortfolioItems(orgId);
        setPortfolio(fetchedPortfolio);
      }

    } catch (error) {
      toast.error('Failed to load some dashboard data. Please try refreshing the page.');
    } finally {
      setLoading(false);
    }
  };

  const getGrowthReadiness = () => {
    const completedCanvases = canvases.filter(canvas => canvas.status === 'completed').length;
    const draftCanvases = canvases.filter(canvas => canvas.status === 'draft').length;
    const totalReadiness = (completedCanvases * 20) + (draftCanvases * 10);
    return Math.min(totalReadiness, 100); // Ensure the readiness doesn't exceed 100%
  };

  const getActiveBidsCount = () => {
    return bids.filter(bid => bid.status !== 'closed').length;
  };

  const handleOpenDialog = (type) => {
    setDialogType(type);
    setNewItem({ name: '', client: '', status: 'draft', dueDate: '' });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCreateItem = async () => {
    try {
      if (dialogType === 'bid') {
        const createdBid = await createBid({
          ...newItem,
          organisationId: selectedOrgId?.id || selectedOrgId,
        });
        setBids([...bids, createdBid]);
      } else if (dialogType === 'canvas') {
        const createdCanvas = await createOrgCanvas(organisation.id, { name: newItem.name, type: 'Growth Strategy Canvas' });
        setCanvases([...canvases, createdCanvas]);
      }
      handleCloseDialog();
    } catch (error) {
      toast.error('Error creating item. Please try again.');
    }
  };

  const fetchCanvases = useCallback(async () => {
    if (!selectedOrgId) return;
    try {
      const fetchedCanvases = await listOrgCanvases(selectedOrgId);
      setCanvases(fetchedCanvases);
    } catch (error) {
      toast.error('Failed to fetch canvases. Please try again.');
    }
  }, [selectedOrgId]);

  useEffect(() => {
    fetchCanvases();
  }, [fetchCanvases]);

  const handleOpenCanvas = async (canvasId) => {
    try {
      if (!organisation || !organisation.id) {
        throw new Error('No organization selected');
      }
      const canvas = await getOrgCanvas(organisation.id, canvasId);
      setCanvasDialogState({
        open: true,
        data: {
          ...canvas,
          points: canvas.points || 0, // Ensure points are included, default to 0 if not present
        },
      });
      toast.success('Canvas loaded successfully');
    } catch (error) {
      toast.error('Failed to open canvas. Please try again.');
    }
  };

  const handleCloseCanvasDialog = () => {
    setCanvasDialogState({ open: false, data: null, type: null });
  };

  const handleSaveCanvas = async (updatedCanvas) => {
    try {
      if (!selectedOrgId) {
        throw new Error('No organization selected');
      }
      
      const orgId = typeof selectedOrgId === 'string' ? selectedOrgId : selectedOrgId.id;
      
      let savedCanvas;
      if (updatedCanvas.status === 'completed') {
        savedCanvas = await completeOrgCanvas(orgId, updatedCanvas.id, updatedCanvas);
      } else {
        savedCanvas = await saveDraftOrgCanvas(orgId, updatedCanvas.id, updatedCanvas);
      }
      
      // Update the canvas list
      setCanvases(prevCanvases => 
        prevCanvases.map(c => c.id === savedCanvas.id ? savedCanvas : c)
      );
      
      // Show success message
      toast.success(`Canvas ${updatedCanvas.status === 'completed' ? 'completed' : 'saved as draft'} successfully`);
      
      // Refresh the canvas list
      await fetchCanvases();

      return savedCanvas;
    } catch (error) {
      toast.error(`Failed to save canvas. Please try again. Error: ${error.message}`);
      throw error;
    }
  };

  const handleDeleteCanvas = async (canvasId) => {
    setDeleteConfirmation({ open: true, canvasId });
  };

  const confirmDeleteCanvas = async () => {
    try {
      if (!organisation || !organisation.id) {
        throw new Error('No organization selected');
      }
      await deleteOrgCanvas(organisation.id, deleteConfirmation.canvasId);
      setCanvases(canvases.filter(canvas => canvas.id !== deleteConfirmation.canvasId));
      toast.success('Canvas deleted successfully');
    } catch (error) {
      toast.error('Failed to delete canvas. Please try again.');
    } finally {
      setDeleteConfirmation({ open: false, canvasId: null });
    }
  };

  const canvasTypes = [
    { type: 'Business Strategy Canvas', component: BusinessStrategyCanvas },
    { type: 'Growth Strategy Canvas', component: GrowthStrategyCanvas },
    { type: 'StoryBrand Canvas', component: StoryBrandCanvas },
    { type: 'Business Model Canvas', component: BusinessModelCanvas },
    { type: 'Partner Channels Canvas', component: PartnerChannelsCanvas },
    { type: 'Growth Experiments Canvas', component: GrowthExperimentsCanvas },
  ];

  const getCanvasDescription = (canvasType) => {
    const descriptions = {
      'Growth Strategy Canvas': 'Define your growth strategy and key initiatives.',
      'Business Model Canvas': 'Outline your business model and value proposition.',
      'StoryBrand Canvas': 'Craft your brand story and messaging framework.',
      'Business Strategy Canvas': 'Develop your overall business strategy and goals.',
      'Growth Experiments Canvas': 'Plan and track growth experiments and their outcomes.',
      'Partner Channels Canvas': 'Map out your partner channels and relationships.'
      
    };
    return descriptions[canvasType] || 'Explore this canvas to enhance your business strategy.';
  };

  const CanvasCard = ({ canvas, existingCanvas, onActionClick, onDeleteCanvas }) => {
    const [expanded, setExpanded] = useState(false);
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.1,
    });

    const cardSpring = useSpring({
      opacity: inView ? 1 : 0,
      transform: inView ? 'translateY(0px)' : 'translateY(50px)',
    });

    const isCompleted = existingCanvas && existingCanvas.status === 'completed';
    const isDraft = existingCanvas && existingCanvas.status === 'draft';

    let StepIcon;
    let iconColor;
    let statusText;
    if (isCompleted) {
      StepIcon = () => <FontAwesomeIcon icon={faCheck} />;
      iconColor = 'success.main';
      statusText = 'Completed';
    } else if (isDraft) {
      StepIcon = () => <FontAwesomeIcon icon={faPencilAlt} />;
      iconColor = 'warning.main';
      statusText = 'In Progress';
    } else {
      StepIcon = () => <FontAwesomeIcon icon={faCircle} />;
      iconColor = 'text.secondary';
      statusText = 'Not Started';
    }

    return (
      <animated.div style={cardSpring} ref={ref}>
        <Card>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => setExpanded(!expanded)}>
              <Box sx={{ mr: 2, color: iconColor }}>
                <StepIcon />
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle1">{canvas.type}</Typography>
                <Chip 
                  label={statusText}
                  color={isCompleted ? 'success' : (isDraft ? 'warning' : 'default')}
                  size="small"
                  sx={{ ml: 2 }}
                />
              </Box>
              <ExpandMoreIcon sx={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)', transition: '0.3s' }} />
            </Box>
          </CardContent>
          <Collapse in={expanded}>
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                {getCanvasDescription(canvas.type)}
              </Typography>
              {existingCanvas && (
                <LinearProgress 
                  variant="determinate" 
                  value={existingCanvas.completionPercentage || 0} 
                  sx={{ mt: 2 }}
                />
              )}
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              {existingCanvas ? (
                <>
                  <Button
                    startIcon={<VisibilityIcon />}
                    onClick={() => onActionClick('view', existingCanvas.id, canvas.type)}
                    variant="outlined"
                    size="small"
                  >
                    View/Edit
                  </Button>
                  {(isDraft || isCompleted) && (
                    <Tooltip title="Delete">
                      <IconButton size="small" onClick={() => onDeleteCanvas(existingCanvas.id)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              ) : (
                <Button
                  startIcon={<AddIcon />}
                  onClick={() => onActionClick('create', null, canvas.type)}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Start Canvas
                </Button>
              )}
            </CardActions>
          </Collapse>
        </Card>
      </animated.div>
    );
  };

  const renderCanvasSection = () => (
    <Grid container spacing={2}>
      {canvasTypes.map((canvas, index) => {
        const existingCanvas = canvases.find(c => c.type === canvas.type);
        return (
          <Grid item xs={12} sm={6} md={4} key={canvas.type}>
            <CanvasCard
              canvas={canvas}
              existingCanvas={existingCanvas}
              onActionClick={handleActionClick}
              onDeleteCanvas={handleDeleteCanvas}
            />
          </Grid>
        );
      })}
    </Grid>
  );

  const handleActionClick = async (action, canvasId = null, canvasType = null) => {
    try {
      if (!organisation || !organisation.id) {
        throw new Error('No organization selected');
      }

      if (canvasId) {
        // Open existing canvas
        const canvas = await getOrgCanvas(organisation.id, canvasId);
        setCanvasDialogState({
          open: true,
          data: canvas,
          type: canvas.type,
        });
      } else {
        // Create new canvas without prompting for a name
        const newCanvas = await createOrgCanvas(organisation.id, {
          name: `${organisation.name} - ${canvasType}`,
          type: canvasType,
          createdBy: userData?.id,
          status: 'draft',
        });

        setCanvasDialogState({
          open: true,
          data: newCanvas,
          type: canvasType,
        });
        setCanvases([...canvases, newCanvas]);
        toast.success('New canvas created successfully');
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const handleOpenBid = (bid) => {
    navigate(`/bid-management/${bid.id}`);
  };

  const renderLoadingSkeleton = () => (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={3}>
        {/* Welcome and Quick Stats Skeleton */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3, background: 'linear-gradient(45deg, #f3f3f3 30%, #e0e0e0 90%)' }}>
            <Typography variant="h4" gutterBottom>
              <Skeleton width="60%" />
            </Typography>
            <Typography variant="subtitle1">
              <Skeleton width="40%" />
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Skeleton variant="rectangular" width={150} height={32} />
              <Skeleton variant="rectangular" width={150} height={32} />
            </Box>
          </Paper>
        </Grid>

        {/* Growth Readiness Journey Skeleton */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, height: '100%', position: 'relative', overflow: 'hidden' }}>
            <Typography variant="h5" gutterBottom>
              <Skeleton width="80%" />
            </Typography>
            <Grid container spacing={2}>
              {[1, 2, 3, 4].map((index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
                    <Typography variant="h6" gutterBottom>
                      <Skeleton width="70%" />
                    </Typography>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" width="60%" />
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
                      <Skeleton variant="circular" width={30} height={30} />
                      <Skeleton variant="circular" width={30} height={30} />
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>

        {/* Active Bids Skeleton */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, height: '100%', position: 'relative', overflow: 'hidden' }}>
            <Typography variant="h6" gutterBottom>
              <Skeleton width="50%" />
            </Typography>
            <List>
              {[1, 2, 3].map((index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Skeleton variant="circular" width={40} height={40} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={<Skeleton width="60%" />}
                      secondary={<Skeleton width="40%" />}
                    />
                    <Skeleton variant="rectangular" width={60} height={24} />
                  </ListItem>
                </motion.div>
              ))}
            </List>
            <Skeleton variant="rectangular" width="100%" height={36} sx={{ mt: 2 }} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );

  useEffect(() => {
    const measureTopBanner = () => {
      const topBanner = document.querySelector('.MuiAppBar-root'); // Adjust this selector if needed
      if (topBanner) {
        setTopBannerHeight(topBanner.offsetHeight);
      }
    };

    measureTopBanner();
    window.addEventListener('resize', measureTopBanner);

    return () => window.removeEventListener('resize', measureTopBanner);
  }, []);

  useEffect(() => {
    const measureTopOffset = () => {
      const appBar = document.querySelector('.MuiAppBar-root');
      if (appBar) {
        setTopOffset(appBar.offsetHeight);
      }
    };

    measureTopOffset();
    window.addEventListener('resize', measureTopOffset);

    return () => window.removeEventListener('resize', measureTopOffset);
  }, []);

  const handleCanvasRoute = (canvasId) => {
    const canvas = canvases.find(c => c.id === canvasId);
    if (canvas) {
      setCanvasDialogState({
        open: true,
        data: canvas,
        type: canvas.type,
      });
    }
  };

  const cardStyles = {
    paper: { 
      p: 2, 
      bgcolor: 'background.default',
      cursor: 'pointer',
      '&:hover': { bgcolor: 'action.hover' },
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    header: { 
      display: 'flex', 
      alignItems: 'center', 
      mb: 1 
    },
    description: {
      color: 'text.secondary',
      flex: 1
    },
    stats: {
      mt: 2,
      display: 'flex',
      gap: 2,
      flexWrap: 'wrap'
    }
  };

  const getBrandMetrics = (organisation) => {
    return {
      elements: {
        total: organisation?.brandElements?.length || 0,
        completed: organisation?.brandElements?.filter(e => e.status === 'completed')?.length || 0,
        completion: organisation?.brandStatus === 'completed' ? 100 : 
          organisation?.brandElements?.length ? 
          (organisation.brandElements.filter(e => e.status === 'completed').length / 
          organisation.brandElements.length * 100) : 0
      },
      story: {
        total: organisation?.brandStory?.sections?.length || 0,
        completed: organisation?.brandStory?.sections?.filter(s => s.status === 'completed')?.length || 0,
        strength: organisation?.brandScore || 0
      },
      assets: {
        total: organisation?.brandAssets?.length || 0,
        active: organisation?.brandAssets?.filter(a => a.status === 'active')?.length || 0,
        categories: {
          logos: organisation?.brandAssets?.filter(a => a.type === 'logo')?.length || 0,
          colors: organisation?.brandAssets?.filter(a => a.type === 'color')?.length || 0,
          typography: organisation?.brandAssets?.filter(a => a.type === 'typography')?.length || 0,
          templates: organisation?.brandAssets?.filter(a => a.type === 'template')?.length || 0
        }
      }
    };
  };

  const getPortfolioMetrics = useCallback(() => {
    console.log('Portfolio data:', portfolio);
    console.log('Propositions data:', propositions);
    
    if (!portfolio || !propositions) return {
      propositions: 0,
      products: 0,
      services: 0,
      active: 0
    };

    const metrics = {
      propositions: propositions.length,
      products: portfolio.filter(item => {
        console.log('Checking product:', item);
        return item.category === 'Product';
      }).length,
      services: portfolio.filter(item => {
        console.log('Checking service:', item);
        return item.category === 'Service' || item.type === 'Consulting';
      }).length,
      active: [...portfolio, ...propositions].filter(item => item.status === 'active').length
    };
    
    console.log('Calculated metrics:', metrics);
    return metrics;
  }, [portfolio, propositions]);

  const renderPortfolioSection = () => {
    const metrics = getPortfolioMetrics();
    
    return (
      <Grid item xs={12}>
        <Tooltip title="Products, Services and Value Propositions" placement="top" arrow enterDelay={500}>
          <Paper elevation={2} sx={{ p: 3, mb: 3, borderLeft: `6px solid ${theme.palette.info.main}` }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" sx={{ color: theme.palette.info.main, display: 'flex', alignItems: 'center' }}>
                <PortfolioIcon sx={{ mr: 1 }} />
                Propositions & Portfolio
              </Typography>
            </Box>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Define and manage your core value propositions
            </Typography>
            <Grid container spacing={2}>
              {/* Value Propositions */}
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={cardStyles.paper}
                  onClick={() => navigate('/portfolio')}
                >
                  <Box sx={cardStyles.header}>
                    <StarIcon color="info" sx={{ mr: 1 }} />
                    <Typography variant="h6" color="info.main">Value Propositions</Typography>
                  </Box>
                  <Typography variant="body2" sx={cardStyles.description}>
                    Define and manage your core value propositions
                  </Typography>
                </Paper>
              </Grid>

              {/* Products */}
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={cardStyles.paper}
                  onClick={() => navigate('/portfolio')}
                >
                  <Box sx={cardStyles.header}>
                    <Inventory2Icon color="info" sx={{ mr: 1 }} />
                    <Typography variant="h6" color="info.main">Products</Typography>
                  </Box>
                  <Typography variant="body2" sx={cardStyles.description}>
                    Manage your product catalog and specifications
                  </Typography>
                </Paper>
              </Grid>

              {/* Services */}
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={cardStyles.paper}
                  onClick={() => navigate('/portfolio')}
                >
                  <Box sx={cardStyles.header}>
                    <MiscellaneousServicesIcon color="info" sx={{ mr: 1 }} />
                    <Typography variant="h6" color="info.main">Services</Typography>
                  </Box>
                  <Typography variant="body2" sx={cardStyles.description}>
                    Define and showcase your service offerings
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Tooltip>
      </Grid>
    );
  };

  const renderOverviewTab = () => {
    // Existing metrics
    const strategyCompletion = canvases.filter(c => c.status === 'completed').length / canvasTypes.length * 100;
    const activeBidsCount = bids.filter(bid => bid.status !== 'closed').length;
    const wonBidsCount = bids.filter(bid => bid.status === 'won').length;
    
    // Enhanced metrics
    const totalBidsValue = bids.reduce((sum, bid) => sum + (bid.value || 0), 0);
    const wonBidsValue = bids
      .filter(bid => bid.status === 'won')
      .reduce((sum, bid) => sum + (bid.value || 0), 0);
    const bidWinRate = wonBidsCount / bids.length * 100 || 0;
    
    // Brand metrics
    const brandMetrics = getBrandMetrics(organisation);
    
    // Portfolio metrics
    const portfolioItemCount = portfolio?.length || 0;
    const recentPortfolioItems = portfolio?.filter(item => 
      new Date(item.createdAt) > new Date(Date.now() - 30*24*60*60*1000)
    ).length || 0;
    
    // Updated CRM metrics to match CRM module structure
    const crmMetrics = {
      totalPipelineValue: 0,
      activeDeals: 0,
      totalContacts: 0,
      avgDealSize: 0,
      activeContacts: 0,
      customerEngagement: 0
    };

    try {
      // Get CRM data from the accounts module
      const accountsData = organisation?.accounts || {};
      const deals = accountsData.deals || [];
      const contacts = accountsData.contacts || [];

      // Calculate metrics
      const pipelineValue = deals.reduce((sum, deal) => sum + (parseFloat(deal.value) || 0), 0);
      const activeDealsCount = deals.filter(deal => deal.status === 'active').length;
      const activeContactsCount = contacts.filter(contact => 
        contact.lastInteraction && 
        new Date(contact.lastInteraction) > new Date(Date.now() - 30*24*60*60*1000)
      ).length;

      crmMetrics.totalPipelineValue = pipelineValue;
      crmMetrics.activeDeals = activeDealsCount;
      crmMetrics.totalContacts = contacts.length;
      crmMetrics.avgDealSize = activeDealsCount ? pipelineValue / activeDealsCount : 0;
      crmMetrics.activeContacts = activeContactsCount;
      crmMetrics.customerEngagement = contacts.length ? 
        (activeContactsCount / contacts.length * 100) : 0;
    } catch (error) {
      console.error('Error calculating CRM metrics:', error);
    }

    return (
      <>
        {/* Welcome Header */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3, background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`, color: 'white', mb: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <EmojiEventsIcon sx={{ fontSize: '3rem', marginRight: '1rem' }} />
              <Box>
                <Typography variant="h4" gutterBottom>
                  Welcome back, {userProfile?.givenName || 'User'}!
                </Typography>
                <Typography variant="subtitle1">
                  Your All-in-One Growth Platform
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>

        {/* Business Strategy */}
        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 3, mb: 3, borderLeft: `6px solid ${theme.palette.primary.main}` }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ color: theme.palette.primary.main, display: 'flex', alignItems: 'center' }}>
                  <BusinessIcon sx={{ mr: 1 }} />
                  Business Strategy
                </Typography>
                <Tooltip title="AI-Powered Strategy Assistant">
                  <Chip
                    icon={<SmartToyIcon />}
                    label="AI Assistant"
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{ ml: 2 }}
                  />
                </Tooltip>
              </Box>
            </Box>

            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Define your business direction and growth strategy with AI-powered guidance. Includes business model analysis,
              growth planning, and strategic alignment tools.
            </Typography>

            <Grid container spacing={2}>
              {/* Strategy Canvas */}
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={cardStyles.paper}
                  onClick={() => navigate('/business-strategy')}
                >
                  <Box sx={cardStyles.header}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <AssessmentIcon color="primary" sx={{ mr: 1 }} />
                        <Typography variant="h6" color="primary.main">Strategy Canvas</Typography>
                      </Box>
                      <Tooltip title="AI Strategy Assistant">
                        <SmartToyIcon color="primary" fontSize="small" />
                      </Tooltip>
                    </Box>
                  </Box>
                  <Typography variant="body2" sx={cardStyles.description}>
                    Define your strategic foundation with AI-guided insights and recommendations
                  </Typography>
                </Paper>
              </Grid>

              {/* Growth Planning */}
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={cardStyles.paper}
                  onClick={() => navigate('/business-strategy')}
                >
                  <Box sx={cardStyles.header}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TrendingUpIcon color="primary" sx={{ mr: 1 }} />
                        <Typography variant="h6" color="primary.main">Growth Planning</Typography>
                      </Box>
                      <Tooltip title="AI Growth Assistant">
                        <SmartToyIcon color="primary" fontSize="small" />
                      </Tooltip>
                    </Box>
                  </Box>
                  <Typography variant="body2" sx={cardStyles.description}>
                    Plan growth initiatives with AI-powered market insights and forecasting
                  </Typography>
                </Paper>
              </Grid>

              {/* Business Model */}
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={cardStyles.paper}
                  onClick={() => navigate('/business-strategy')}
                >
                  <Box sx={cardStyles.header}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <BusinessIcon color="primary" sx={{ mr: 1 }} />
                        <Typography variant="h6" color="primary.main">Business Model</Typography>
                      </Box>
                      <Tooltip title="AI Model Assistant">
                        <SmartToyIcon color="primary" fontSize="small" />
                      </Tooltip>
                    </Box>
                  </Box>
                  <Typography variant="body2" sx={cardStyles.description}>
                    Optimize your business model with AI-driven insights and recommendations
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            {/* AI Assistant Callout */}
            <Box sx={{ 
              mt: 2, 
              p: 2, 
              bgcolor: alpha(theme.palette.primary.main, 0.1), 
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <SmartToyIcon color="primary" sx={{ mr: 2 }} />
                <Box>
                  <Typography variant="subtitle1" color="primary.main">
                    Strategy Development AI Assistant
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Get expert guidance and recommendations for your business strategy
                  </Typography>
                </Box>
              </Box>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<ChatIcon />}
                onClick={() => navigate('/business-strategy')}
              >
                Open Strategy Builder
              </Button>
            </Box>
          </Paper>
        </Grid>

        {/* Brand Development */}
        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 3, mb: 3, borderLeft: `6px solid ${theme.palette.secondary.main}` }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <BrandingWatermarkIcon sx={{ mr: 1 }} color="secondary" />
                <Typography variant="h6" sx={{ color: theme.palette.secondary.main }}>
                  Brand Development
                </Typography>
                <Tooltip title="AI-Powered Brand Assistant" placement="right">
                  <Chip
                    icon={<SmartToyIcon />}
                    label="Brand AI"
                    color="secondary"
                    variant="outlined"
                    size="small"
                    sx={{ ml: 2 }}
                  />
                </Tooltip>
              </Box>
            </Box>

            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Create a compelling brand identity with AI-powered assistance. Includes StoryBrand framework, 
              tone of voice development, and brand asset management.
            </Typography>

            <Grid container spacing={2}>
              {/* Brand Story & Framework */}
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={cardStyles.paper}
                  onClick={() => navigate('/brand/story')}
                >
                  <Box sx={cardStyles.header}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <AutoStoriesIcon color="secondary" sx={{ mr: 1 }} />
                        <Typography variant="h6" color="secondary.main">StoryBrand</Typography>
                      </Box>
                      <Tooltip title="AI Writing Assistant">
                        <Chip
                          icon={<SmartToyIcon />}
                          label="AI Assistant"
                          size="small"
                          color="secondary"
                          variant="outlined"
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                  <Typography variant="body2" sx={cardStyles.description}>
                    Craft your brand story using the proven StoryBrand framework with AI guidance
                  </Typography>
                </Paper>
              </Grid>

              {/* Tone of Voice */}
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={cardStyles.paper}
                  onClick={() => navigate('/brand/tone-of-voice')}
                >
                  <Box sx={cardStyles.header}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <RecordVoiceOverIcon color="secondary" sx={{ mr: 1 }} />
                        <Typography variant="h6" color="secondary.main">Voice & Tone</Typography>
                      </Box>
                      <Tooltip title="AI Tone Assistant">
                        <Chip
                          icon={<SmartToyIcon />}
                          label="AI Assistant"
                          size="small"
                          color="secondary"
                          variant="outlined"
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                  <Typography variant="body2" sx={cardStyles.description}>
                    Define and maintain consistent brand voice across all communications
                  </Typography>
                </Paper>
              </Grid>

              {/* Brand Assets */}
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={cardStyles.paper}
                  onClick={() => navigate('/brand')}  // Updated from /brand-builder
                >
                  <Box sx={cardStyles.header}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <BrandingWatermarkIcon color="secondary" sx={{ mr: 1 }} />
                        <Typography variant="h6" color="secondary.main">Brand Assets</Typography>
                      </Box>
                      <Tooltip title="Coming Soon">
                        <Chip
                          icon={<AccessTimeIcon />}
                          label="Coming Soon"
                          size="small"
                          color="default"
                          variant="outlined"
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                  <Typography variant="body2" sx={cardStyles.description}>
                    Manage brand assets with AI-powered design suggestions and consistency checks
                  </Typography>
                  <Box sx={{ mt: 1, display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                    <Chip 
                      size="small"
                      label="Logos"
                      color="secondary" 
                      variant="outlined"
                      disabled
                    />
                    <Chip 
                      size="small"
                      label="Colors"
                      color="secondary" 
                      variant="outlined"
                      disabled
                    />
                    <Chip 
                      size="small"
                      label="Typography"
                      color="secondary" 
                      variant="outlined"
                      disabled
                    />
                  </Box>
                </Paper>
              </Grid>
            </Grid>

            {/* AI Assistant Callout */}
            <Box sx={{ 
              mt: 2, 
              p: 2, 
              bgcolor: alpha(theme.palette.secondary.main, 0.1), 
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <SmartToyIcon color="secondary" sx={{ mr: 2 }} />
                <Box>
                  <Typography variant="subtitle1" color="secondary.main">
                    Brand Development AI Assistant
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {toneOfVoiceData?.status === 'completed' ? (
                      <>
                        AI assistant is personalized to your brand voice and tone. 
                        <Typography component="span" sx={{ fontStyle: 'italic', display: 'block' }}>
                          "{toneOfVoiceData?.summary || 'Professional, clear, and engaging communication'}"
                        </Typography>
                      </>
                    ) : (
                      'Complete your brand voice setup to enable AI-powered brand-aligned communication'
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 1 }}>
                {toneOfVoiceData?.status === 'completed' ? (
                  <Chip
                    icon={<CheckCircleIcon />}
                    label="Brand Voice Active"
                    color="success"
                    size="small"
                    sx={{ mb: 1 }}
                  />
                ) : (
                  <Chip
                    icon={<InfoIcon />}
                    label="Setup Required"
                    color="warning"
                    size="small"
                    sx={{ mb: 1 }}
                  />
                )}
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<ChatIcon />}
                  onClick={() => navigate('/brand')}  // Updated from /brand-builder
                >
                  Open Brand Builder
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>

        {/* Portfolio */}
        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 3, mb: 3, borderLeft: `6px solid ${theme.palette.info.main}` }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" sx={{ color: theme.palette.info.main, display: 'flex', alignItems: 'center' }}>
                <PortfolioIcon sx={{ mr: 1 }} />
                Propositions & Portfolio
              </Typography>
            </Box>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Define and manage your core value propositions
            </Typography>
            <Grid container spacing={2}>
              {/* Value Propositions */}
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={cardStyles.paper}
                  onClick={() => navigate('/portfolio')}
                >
                  <Box sx={cardStyles.header}>
                    <StarIcon color="info" sx={{ mr: 1 }} />
                    <Typography variant="h6" color="info.main">Value Propositions</Typography>
                  </Box>
                  <Typography variant="body2" sx={cardStyles.description}>
                    Define and manage your core value propositions
                  </Typography>
                </Paper>
              </Grid>

              {/* Products */}
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={cardStyles.paper}
                  onClick={() => navigate('/portfolio')}
                >
                  <Box sx={cardStyles.header}>
                    <Inventory2Icon color="info" sx={{ mr: 1 }} />
                    <Typography variant="h6" color="info.main">Products</Typography>
                  </Box>
                  <Typography variant="body2" sx={cardStyles.description}>
                    Manage your product catalog and specifications
                  </Typography>
                </Paper>
              </Grid>

              {/* Services */}
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={cardStyles.paper}
                  onClick={() => navigate('/portfolio')}
                >
                  <Box sx={cardStyles.header}>
                    <MiscellaneousServicesIcon color="info" sx={{ mr: 1 }} />
                    <Typography variant="h6" color="info.main">Services</Typography>
                  </Box>
                  <Typography variant="body2" sx={cardStyles.description}>
                    Define and showcase your service offerings
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Funding Marketplace */}
        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 3, mb: 3, borderLeft: `6px solid ${theme.palette.warning.dark}` }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" sx={{ color: theme.palette.warning.dark, display: 'flex', alignItems: 'center' }}>
                <MoneyIcon sx={{ mr: 1 }} />
                Growth Funding
              </Typography>
            </Box>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Discover and access funding opportunities to fuel your business growth.
            </Typography>
            <Grid container spacing={2}>
              {/* Non-Profit Funding */}
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={cardStyles.paper}
                  onClick={() => navigate('/fundraising')}
                >
                  <Box sx={cardStyles.header}>
                    <PeopleIcon color="warning" sx={{ mr: 1 }} />
                    <Typography variant="h6" color="warning.main">Non-Profit</Typography>
                  </Box>
                  <Typography variant="body2" sx={cardStyles.description}>
                    Access funding opportunities specifically for non-profit organizations
                  </Typography>
                </Paper>
              </Grid>

              {/* Commercial Funding */}
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={cardStyles.paper}
                  onClick={() => navigate('/fundraising')}
                >
                  <Box sx={cardStyles.header}>
                    <BusinessIcon color="warning" sx={{ mr: 1 }} />
                    <Typography variant="h6" color="warning.main">Commercial</Typography>
                  </Box>
                  <Typography variant="body2" sx={cardStyles.description}>
                    Explore commercial funding options including loans and investments
                  </Typography>
                </Paper>
              </Grid>

              {/* Government Funding */}
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={cardStyles.paper}
                  onClick={() => navigate('/fundraising')}
                >
                  <Box sx={cardStyles.header}>
                    <AccountBalanceIcon color="warning" sx={{ mr: 1 }} />
                    <Typography variant="h6" color="warning.main">Government</Typography>
                  </Box>
                  <Typography variant="body2" sx={cardStyles.description}>
                    Find and apply for government grants and funding programs
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* CRM & Pipeline */}
        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 3, mb: 3, borderLeft: `6px solid ${theme.palette.primary.dark}` }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ color: theme.palette.primary.dark, display: 'flex', alignItems: 'center' }}>
                  <PeopleIcon sx={{ mr: 1 }} />
                  Customer Relationships
                </Typography>
                <Box sx={{ ml: 2, display: 'flex', gap: 1 }}>
                  <Tooltip title="Pipedrive Integration Active">
                    <Chip
                      label="Pipedrive"
                      size="small"
                      color="success"
                      variant="outlined"
                      icon={<CheckCircleIcon />}
                    />
                  </Tooltip>
                  <Tooltip title="Fireflies.ai Integration Active">
                    <Chip
                      label="Fireflies.ai"
                      size="small"
                      color="success"
                      variant="outlined"
                      icon={<CheckCircleIcon />}
                    />
                  </Tooltip>
                  <Tooltip title="HubSpot Integration Coming Soon">
                    <Chip
                      label="HubSpot"
                      size="small"
                      color="default"
                      variant="outlined"
                      icon={<AccessTimeIcon />}
                    />
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Build and nurture valuable customer relationships with integrated tools and AI assistance.
            </Typography>
            <Grid container spacing={2}>
              {/* Contact Management */}
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={cardStyles.paper}
                  onClick={() => navigate('/accounts')}
                >
                  <Box sx={cardStyles.header}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <PeopleIcon color="primary" sx={{ mr: 1 }} />
                        <Typography variant="h6" color="primary.main">Contact Management</Typography>
                      </Box>
                      <Tooltip title="Pipedrive Sync Active">
                        <CheckCircleIcon color="success" fontSize="small" />
                      </Tooltip>
                    </Box>
                  </Box>
                  <Typography variant="body2" sx={cardStyles.description}>
                    Centralize and organize all your customer and partner relationships with Pipedrive integration
                  </Typography>
                </Paper>
              </Grid>

              {/* Pipeline Management */}
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={cardStyles.paper}
                  onClick={() => navigate('/accounts')}
                >
                  <Box sx={cardStyles.header}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TrendingUpIcon color="primary" sx={{ mr: 1 }} />
                        <Typography variant="h6" color="primary.main">Pipeline Management</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', gap: 0.5 }}>
                        <Tooltip title="Pipedrive Sync Active">
                          <CheckCircleIcon color="success" fontSize="small" />
                        </Tooltip>
                        <Tooltip title="HubSpot Coming Soon">
                          <AccessTimeIcon color="action" fontSize="small" />
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                  <Typography variant="body2" sx={cardStyles.description}>
                    Track and manage your sales pipeline with integrated CRM tools and automation
                  </Typography>
                </Paper>
              </Grid>

              {/* Meeting Intelligence */}
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={cardStyles.paper}
                  onClick={() => navigate('/accounts')}
                >
                  <Box sx={cardStyles.header}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <RecordVoiceOverIcon color="primary" sx={{ mr: 1 }} />
                        <Typography variant="h6" color="primary.main">Meeting Intelligence</Typography>
                      </Box>
                      <Tooltip title="Fireflies.ai Integration Active">
                        <CheckCircleIcon color="success" fontSize="small" />
                      </Tooltip>
                    </Box>
                  </Box>
                  <Typography variant="body2" sx={cardStyles.description}>
                    AI-powered meeting transcription and insights with Fireflies.ai integration
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            {/* Integration Info */}
            <Box sx={{ 
              mt: 2, 
              p: 2, 
              bgcolor: alpha(theme.palette.primary.main, 0.1), 
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <SmartToyIcon color="primary" sx={{ mr: 2 }} />
                <Box>
                  <Typography variant="subtitle1" color="primary.main">
                    Integrated CRM Tools
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Connected with Pipedrive and Fireflies.ai. HubSpot integration coming soon.
                  </Typography>
                </Box>
              </Box>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<SettingsIcon />}
                onClick={() => navigate('/accounts/integrations')}
              >
                Manage Integrations
              </Button>
            </Box>
          </Paper>
        </Grid>

        {/* Bids Management */}
        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 3, mb: 3, borderLeft: `6px solid ${theme.palette.error.main}` }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ color: theme.palette.error.main, display: 'flex', alignItems: 'center' }}>
                  <AssignmentIcon sx={{ mr: 1 }} />
                  Bid Management
                </Typography>
                <Tooltip title="AI-Powered Bid Assistant">
                  <Chip
                    icon={<SmartToyIcon />}
                    label="AI Assistant"
                    size="small"
                    color="error"
                    variant="outlined"
                    sx={{ ml: 2 }}
                  />
                </Tooltip>
              </Box>
            </Box>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Track and win more business opportunities with AI-powered bid management and assistance.
            </Typography>
            <Grid container spacing={2}>
              {/* Bid & RFP Qualification */}
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={cardStyles.paper}
                  onClick={() => navigate('/bid-management/canvases')}  // Updated to go to canvases tab
                >
                  <Box sx={cardStyles.header}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <AssessmentIcon color="error" sx={{ mr: 1 }} />
                        <Typography variant="h6" color="error.main">Qualification & Strategy</Typography>
                      </Box>
                      <Tooltip title="AI Strategy Assistant">
                        <SmartToyIcon color="error" fontSize="small" />
                      </Tooltip>
                    </Box>
                  </Box>
                  <Typography variant="body2" sx={cardStyles.description}>
                    Evaluate opportunities and develop winning strategies with AI-powered insights
                  </Typography>
                </Paper>
              </Grid>

              {/* Bid Planning */}
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={cardStyles.paper}
                  onClick={() => navigate('/bid-management/planning')}  // Updated to go to planning tab
                >
                  <Box sx={cardStyles.header}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TimelineIcon color="error" sx={{ mr: 1 }} />
                        <Typography variant="h6" color="error.main">Bid Planning</Typography>
                      </Box>
                      <Tooltip title="AI Planning Assistant">
                        <SmartToyIcon color="error" fontSize="small" />
                      </Tooltip>
                    </Box>
                  </Box>
                  <Typography variant="body2" sx={cardStyles.description}>
                    Plan and organize your bid response with AI-guided workflow and timelines
                  </Typography>
                </Paper>
              </Grid>

              {/* Bid Management */}
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={cardStyles.paper}
                  onClick={() => navigate('/bid-management/active')}  // Updated to go to active bids tab
                >
                  <Box sx={cardStyles.header}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <EditIcon color="error" sx={{ mr: 1 }} />
                        <Typography variant="h6" color="error.main">Bid Management</Typography>
                      </Box>
                      <Tooltip title="AI Writing Assistant">
                        <SmartToyIcon color="error" fontSize="small" />
                      </Tooltip>
                    </Box>
                  </Box>
                  <Typography variant="body2" sx={cardStyles.description}>
                    Create and manage winning bids with AI-powered content and collaboration tools
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            {/* AI Assistant Callout */}
            <Box sx={{ 
              mt: 2, 
              p: 2, 
              bgcolor: alpha(theme.palette.error.main, 0.1), 
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <SmartToyIcon color="error" sx={{ mr: 2 }} />
                <Box>
                  <Typography variant="subtitle1" color="error.main">
                    Bid Management AI Assistant
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Get AI-powered assistance for writing, analyzing, and improving your bids
                  </Typography>
                </Box>
              </Box>
              <Button
                variant="outlined"
                color="error"
                startIcon={<ChatIcon />}
                onClick={() => navigate('/bids')}
              >
                Open Bid Manager
              </Button>
            </Box>
          </Paper>
        </Grid>
      </>
    );
  };

  const renderActiveBids = () => (
    <List>
      <AnimatePresence>
        {bids.filter(bid => bid.status !== 'closed').slice(0, 3).map((bid, index) => (
          <motion.div
            key={bid.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
          >
            <ListItem 
              secondaryAction={
                <IconButton edge="end" aria-label="more" onClick={() => handleOpenBid(bid)}>
                  <MoreVertIcon />
                </IconButton>
              }
              sx={{ cursor: 'pointer' }}
              onClick={() => handleOpenBid(bid)}
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: theme.palette.primary.main }}>{bid.name[0]}</Avatar>
              </ListItemAvatar>
              <ListItemText 
                primary={bid.name} 
                secondary={`Due: ${format(new Date(bid.dueDate), 'MMM d, yyyy')}`} 
              />
              <Chip label={bid.status} color="primary" size="small" />
            </ListItem>
          </motion.div>
        ))}
      </AnimatePresence>
    </List>
  );

  const renderAnalyticsTab = () => {
    const growthReadiness = getGrowthReadiness();
    const activeBidsCount = getActiveBidsCount();
    const completedCanvasCount = canvases.filter(canvas => canvas.status === 'completed').length;

    const data = [
      { name: 'Growth Readiness', value: growthReadiness },
      { name: 'Remaining', value: 100 - growthReadiness },
    ];

    const COLORS = [theme.palette.primary.main, theme.palette.grey[300]];

    return (
      <Box sx={{ mt: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper 
              elevation={3}
              sx={{ 
                p: 3, 
                mb: 3, 
                background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
                borderRadius: theme.shape.borderRadius,
                position: 'relative',
              }}
            >
              <Typography variant="h4" gutterBottom sx={{ color: 'white', fontWeight: 'bold' }}>
                Puāwai Intelligence Analytics
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'white' }}>
                Insights powered by AI to drive your growth strategy
              </Typography>
              <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                <AITooltip 
                  context="Puāwai Intelligence Analytics Overview"
                  userProfile={userProfile}
                  organisation={organisation}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ 
              p: 3, 
              height: '100%', 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              boxShadow: theme.shadows[3],
              borderRadius: theme.shape.borderRadius,
              position: 'relative',
            }}>
              <Typography variant="h6" gutterBottom sx={{ color: theme.palette.secondary.main }}>Growth Readiness</Typography>
              <Box sx={{ width: '100%', height: 200, mb: 2 }}>
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={data}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Box>
              <Typography variant="h4" sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>{growthReadiness}%</Typography>
              <Typography variant="body2" sx={{ color: theme.palette.text.secondary, mt: 1 }}>Overall Growth Readiness Score</Typography>
              <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                <AITooltip 
                  context="Growth Readiness Score"
                  userProfile={userProfile}
                  organisation={organisation}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ 
              p: 3, 
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: theme.shadows[3],
              borderRadius: theme.shape.borderRadius,
              position: 'relative',
            }}>
              <Typography variant="h6" gutterBottom sx={{ color: theme.palette.secondary.main }}>Active Bids</Typography>
              <Typography variant="h4" sx={{ color: theme.palette.primary.main, fontWeight: 'bold', mb: 2 }}>{activeBidsCount}</Typography>
              <LinearProgress 
                variant="determinate" 
                value={(activeBidsCount / 10) * 100}
                sx={{ width: '80%', height: 10, borderRadius: 5 }}
              />
              <Typography variant="body2" sx={{ color: theme.palette.text.secondary, mt: 2 }}>Current Active Bids</Typography>
              <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                <AITooltip 
                  context="Active Bids Count"
                  userProfile={userProfile}
                  organisation={organisation}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ 
              p: 3, 
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: theme.shadows[3],
              borderRadius: theme.shape.borderRadius,
              position: 'relative',
            }}>
              <Typography variant="h6" gutterBottom sx={{ color: theme.palette.secondary.main }}>Completed Canvases</Typography>
              <Typography variant="h4" sx={{ color: theme.palette.primary.main, fontWeight: 'bold', mb: 2 }}>{completedCanvasCount}</Typography>
              <Box sx={{ width: '80%', display: 'flex', justifyContent: 'space-between' }}>
                {[...Array(5)].map((_, index) => (
                  <Box 
                    key={index}
                    sx={{
                      width: 30,
                      height: 30,
                      borderRadius: '50%',
                      bgcolor: index < completedCanvasCount ? theme.palette.success.main : theme.palette.grey[300],
                    }}
                  />
                ))}
              </Box>
              <Typography variant="body2" sx={{ color: theme.palette.text.secondary, mt: 2 }}>Out of 5 Total Canvases</Typography>
              <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                <AITooltip 
                  context="Completed Canvases Count"
                  userProfile={userProfile}
                  organisation={organisation}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'overview':
        return renderOverviewTab();
      case 'analytics':
        return renderAnalyticsTab();
      default:
        return null;
    }
  };

  const renderMobileDrawer = () => (
    <SwipeableDrawer
      anchor="left"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      onOpen={() => setDrawerOpen(true)}
    >
      <Box sx={{ width: 250 }} role="presentation">
        <List>
          <ListItem button onClick={() => { setActiveTab('overview'); setDrawerOpen(false); }}>
            <ListItemIcon><DashboardIcon /></ListItemIcon>
            <ListItemText primary="Overview" />
          </ListItem>
          <ListItem button onClick={() => { setActiveTab('analytics'); setDrawerOpen(false); }}>
            <ListItemIcon><AssessmentIcon /></ListItemIcon>
            <ListItemText primary="Analytics" />
          </ListItem>
        </List>
      </Box>
    </SwipeableDrawer>
  );

  const refreshFundingOpportunities = async () => {
    if (!selectedOrgId) {
      setFundingOpportunities([]);
      return;
    }

    try {
      setIsFundingLoading(true);
      setFundingError(null);
      
      const token = await getToken();
      // Get the ID regardless of whether selectedOrgId is an object or string
      const orgId = selectedOrgId?.id || selectedOrgId;
      
      // Call the funding scraper endpoint
      const response = await fetch(`/api/funding/scrape/nz/${orgId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || data.message || 'Failed to fetch funding opportunities');
      }
      
      if (data && data.data) {
        setFundingOpportunities(data.data);
        
        if (data.data.length > 0) {
          toast.success(
            `Found ${data.data.length} funding opportunities\n` +
            `${data.summary?.highMatchOpportunities || 0} high-match opportunities\n` +
            `Total potential funding: $${(data.summary?.totalPotentialFunding || 0).toLocaleString()}`
          );
        } else {
          toast.info('No funding opportunities found at this time. Try again later.');
        }
      } else {
        setFundingOpportunities([]);
        toast.info('No funding data available');
      }
    } catch (error) {
      console.error('Error fetching funding:', error);
      setFundingError(error.message);
      setFundingOpportunities([]);
      toast.error(`Failed to fetch funding opportunities: ${error.message}`);
    } finally {
      setIsFundingLoading(false);
    }
  };

  useEffect(() => {
    // Initialize with empty array, don't fetch automatically
    setFundingOpportunities([]);
  }, [selectedOrgId]);

  useEffect(() => {
    if (organisation) {
      setBrandMetrics(getBrandMetrics(organisation));
    }
  }, [organisation]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [portfolioData, propositionsData] = await Promise.all([
          getPortfolioItems(selectedOrgId),
          getPropositions(selectedOrgId)
        ]);
        setPortfolio(portfolioData);
        setPropositions(propositionsData);
      } catch (error) {
        console.error('Error fetching portfolio data:', error);
      }
    };
    if (selectedOrgId) {
      fetchData();
    }
  }, [selectedOrgId]);

  if (loading) {
    return <CircularProgress />;
  }

  const orgId = selectedOrgId?.id || selectedOrgId;
  if (!orgId) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h5">No organisation selected</Typography>
        <Typography variant="body1">Please select an organisation to view the dashboard.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3 }} ref={layoutRef}>
      {isMobile && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={() => setDrawerOpen(true)}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MoreVertIcon />
        </IconButton>
      )}
      {renderMobileDrawer()}
      {!isMobile && (
        <Paper sx={{ mb: 2 }}>
          <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
            <Tab label="Overview" value="overview" icon={<DashboardIcon />} />
            <Tab label="Analytics" value="analytics" icon={<AssessmentIcon />} />
          </Tabs>
        </Paper>
      )}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Grid container spacing={3}>
          {renderContent()}
        </Grid>
      </motion.div>

      {/* Dialog for creating new items */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{`Create New ${dialogType === 'bid' ? 'Bid' : 'Canvas'}`}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={newItem.name}
            onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
          />
          {dialogType === 'bid' && (
            <>
              <TextField
                margin="dense"
                label="Client"
                fullWidth
                value={newItem.client}
                onChange={(e) => setNewItem({ ...newItem, client: e.target.value })}
              />
              <TextField
                select
                margin="dense"
                label="Status"
                fullWidth
                value={newItem.status}
                onChange={(e) => setNewItem({ ...newItem, status: e.target.value })}
              >
                <MenuItem value="draft">Draft</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="submitted">Submitted</MenuItem>
                <MenuItem value="won">Won</MenuItem>
                <MenuItem value="lost">Lost</MenuItem>
              </TextField>
              <TextField
                margin="dense"
                label="Due Date"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={newItem.dueDate}
                onChange={(e) => setNewItem({ ...newItem, dueDate: e.target.value })}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleCreateItem}>Create</Button>
        </DialogActions>
      </Dialog>

      {/* Canvas Dialog */}
      <Dialog 
        open={canvasDialogState.open} 
        onClose={handleCloseCanvasDialog}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
          },
        }}
      >
        <DialogContent sx={{ p: 0 }}>
          {canvasDialogState.type && (
            React.createElement(
              canvasTypes.find(ct => ct.type === canvasDialogState.type).component,
              {
                user: userProfile,
                selectedOrg: selectedOrgId,
                canvas: canvasDialogState.data,
                onClose: handleCloseCanvasDialog,
                onSave: handleSaveCanvas,  // Make sure this is handleSaveCanvas
                onUpdate: (updatedCanvas) => {
                  const orgId = typeof selectedOrgId === 'string' ? selectedOrgId : selectedOrgId.id;
                  updateOrgCanvas(orgId, updatedCanvas.id, updatedCanvas);
                }
              }
            )
          )}
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmation.open}
        onClose={() => setDeleteConfirmation({ open: false, canvasId: null })}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this canvas? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmation({ open: false, canvasId: null })}>Cancel</Button>
          <Button onClick={confirmDeleteCanvas} color="error">Delete</Button>
        </DialogActions>
      </Dialog>

      {/* Add the FloatingAIIcon component at the end of the return statement */}
      <FloatingAIIcon
        userProfile={userProfile}
        organisation={organisation}
        toneOfVoice={toneOfVoiceData}
        topOffset={topOffset}
        bids={bids}
        canvases={canvases}
        // You can add more props here if needed, e.g.:
        // activeTasks={activeTasks}
        // organisationInsights={organisationInsights}
      />

      <PuawaiIntelligenceInterface
        userProfile={userProfile}
        organisation={{...organisation, portfolio: portfolio}}
        toneOfVoice={toneOfVoiceData}
        topOffset={topOffset}
        bids={bids}
        canvases={canvases}
      />

      <Routes>
        <Route path="/canvas/:canvasId" element={<CanvasRoute handleCanvasRoute={handleCanvasRoute} />} />
      </Routes>
    </Box>
  );
};

const CanvasRoute = ({ handleCanvasRoute }) => {
  const { canvasId } = useParams();
  useEffect(() => {
    handleCanvasRoute(canvasId);
  }, [canvasId, handleCanvasRoute]);
  return null;
};

export default Dashboard;