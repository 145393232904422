import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  TextField,
  CircularProgress,
  Alert,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  Chip,
  FormControl,
  InputLabel,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
  Snackbar,
  Tooltip,
  Divider,
  Switch,
  FormControlLabel
} from '@mui/material';
import {
  Event as EventIcon,
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  CalendarToday as CalendarIcon,
  CheckCircle as CheckCircleIcon,
  Schedule as ScheduleIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  VideoCall as VideoIcon,
  Coffee as CoffeeIcon,
  Business as BusinessIcon,
  FilterList as FilterIcon,
  Sort as SortIcon,
  MoreVert as MoreVertIcon,
  Sync as SyncIcon,
  Person as PersonIcon,
  LocationOn as LocationIcon,
  Description as DescriptionIcon,
  Flag as FlagIcon,
  Link as LinkIcon,
  AttachMoney as AttachMoneyIcon,
  Repeat as RepeatIcon
} from '@mui/icons-material';
import { accountManagementService } from '../../services/accountManagementService';
import { logError } from '../../utils/errorLogging';
import { accountService } from '../../services/accountService';
import { contactService } from '../../services/contactService';
import { pipedriveService } from '../../services/pipedriveService';

const activityTypes = [
  { value: 'call', label: 'Call', icon: <PhoneIcon />, fields: ['outcome'] },
  { value: 'meeting', label: 'Meeting', icon: <BusinessIcon />, fields: ['outcome', 'location'] },
  { value: 'task', label: 'Task', icon: <CheckCircleIcon />, fields: ['outcome'] },
  { value: 'deadline', label: 'Deadline', icon: <CalendarIcon />, fields: [] },
  { value: 'email', label: 'Email', icon: <EmailIcon />, fields: ['outcome', 'template_id'] },
  { value: 'lunch', label: 'Lunch', icon: <CoffeeIcon />, fields: ['outcome', 'location'] }
];

const activityOutcomes = [
  { value: 'completed', label: 'Completed Successfully' },
  { value: 'no_outcome', label: 'No Outcome Yet' },
  { value: 'postponed', label: 'Postponed' },
  { value: 'canceled', label: 'Canceled' },
  { value: 'no_show', label: 'No Show' }
];

const tabLabels = ['All', 'Planned', 'Done', 'Overdue'];

const activityPriorities = [
  { value: 1, label: 'Low', color: 'default' },
  { value: 2, label: 'Medium', color: 'primary' },
  { value: 3, label: 'High', color: 'error' }
];

const ActivitiesHome = () => {
  const { selectedAccount, selectedOrg } = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activities, setActivities] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [sortBy, setSortBy] = useState('date');
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editingActivity, setEditingActivity] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [activityFormData, setActivityFormData] = useState({
    type: '',
    subject: '',
    description: '',
    due_date: new Date().toISOString().split('T')[0],
    due_time: new Date().toTimeString().split(' ')[0].slice(0, 5),
    duration: 30,
    priority: 'medium',
    done: false,
    outcome: 'no_outcome',
    location: '',
    conference_meeting_url: '',
    participants: [],
    public: true,
    busy_flag: true,
    assigned_to_user_id: '',
    lead_id: '',
    deal_id: '',
    account_id: selectedAccount?.id || '',
    contact_id: '',
    note: '',
    template_id: '',
    reminder_minutes: 30,
    recurring: false,
    recurring_pattern: null,
    custom_fields: {},
    attachments: []
  });
  const [operationLoading, setOperationLoading] = useState({
    create: false,
    update: false,
    delete: false
  });
  const [syncing, setSyncing] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [stats, setStats] = useState({
    total: 0,
    planned: 0,
    done: 0,
    overdue: 0
  });
  const [accounts, setAccounts] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [recurringPattern, setRecurringPattern] = useState({
    interval: 1,
    frequency: 'weekly',
    ends_after: null,
    ends_on: null
  });
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);

  useEffect(() => {
    if (selectedOrg) {
      fetchActivities();
      fetchAccounts();
      fetchContacts();
    }
  }, [selectedOrg, selectedAccount, selectedFilters, sortBy, tabValue]);

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const fetchActivities = async () => {
    try {
      setLoading(true);
      const response = await accountManagementService.listActivities(selectedOrg, {
        accountId: selectedAccount?.id,
        filters: {
          type: selectedFilters.length > 0 ? selectedFilters : undefined,
          sortBy,
          status: getStatusFilter(tabValue)
        }
      });

      // Map activities to consistent format
      const mappedActivities = (response.activities || []).map(activity => {
        // Check for Pipedrive activity by looking for pipedriveId or data structure
        if (activity.pipedriveId || activity.data) {
          // Handle Pipedrive activity
          const pipedriveData = activity.data;
          return {
            id: activity.id,
            type: pipedriveData.type?.toLowerCase() || 'task',
            subject: pipedriveData.subject,
            description: pipedriveData.public_description || '',
            due_date: pipedriveData.due_date,
            due_time: pipedriveData.due_time || '',
            duration: pipedriveData.duration || 30,
            priority: pipedriveData.priority || 2, // Default to medium priority
            status: pipedriveData.done ? 'completed' : 'open',
            source: 'pipedrive',
            owner: pipedriveData.owner_name,
            lastUpdated: pipedriveData.update_time || activity.lastSync,
            organization: pipedriveData.org_name,
            person: pipedriveData.person_name,
            location: pipedriveData.location,
            conference_meeting_url: pipedriveData.conference_meeting_url,
            participants: pipedriveData.participants || [],
            public: !pipedriveData.private,
            busy_flag: pipedriveData.busy_flag,
            reference_type: pipedriveData.reference_type,
            reference_id: pipedriveData.reference_id,
            account_id: pipedriveData.org_id ? `${activity.organisationId}_${pipedriveData.org_id}` : null,
            contact_id: pipedriveData.person_id ? `${activity.organisationId}_${pipedriveData.person_id}` : null,
            deal_id: pipedriveData.deal_id ? `${activity.organisationId}_${pipedriveData.deal_id}` : null,
            note: pipedriveData.note,
            outcome: pipedriveData.done ? 'completed' : 'no_outcome'
          };
        }
        // Handle local activity (keep existing structure)
        return {
          id: activity.id,
          type: activity.type,
          subject: activity.subject || activity.title, // Handle both old and new field names
          description: activity.description,
          due_date: activity.due_date || activity.date, // Handle both old and new field names
          due_time: activity.due_time || activity.time, // Handle both old and new field names
          duration: activity.duration,
          priority: activity.priority,
          status: activity.status,
          source: 'local',
          owner: activity.owner,
          lastUpdated: activity.updatedAt,
          organization: activity.organization,
          person: activity.person,
          location: activity.location,
          conference_meeting_url: activity.conference_meeting_url,
          participants: activity.participants || [],
          public: activity.public,
          busy_flag: activity.busy_flag,
          reference_type: activity.reference_type,
          reference_id: activity.reference_id,
          account_id: activity.account_id,
          contact_id: activity.contact_id,
          deal_id: activity.deal_id,
          note: activity.note,
          outcome: activity.outcome
        };
      });

      // Calculate stats
      const now = new Date();
      const stats = {
        total: mappedActivities.length,
        planned: mappedActivities.filter(a => new Date(a.due_date) > now && a.status !== 'completed').length,
        done: mappedActivities.filter(a => a.status === 'completed').length,
        overdue: mappedActivities.filter(a => new Date(a.due_date) < now && a.status !== 'completed').length
      };

      setActivities(mappedActivities);
      setStats(stats);
      setError(null);
    } catch (err) {
      logError('Error fetching activities:', err);
      setError('Failed to load activities');
      showSnackbar('Failed to load activities', 'error');
    } finally {
      setLoading(false);
    }
  };

  const getStatusFilter = (tabIndex) => {
    switch (tabIndex) {
      case 1: // Planned
        return 'planned';
      case 2: // Done
        return 'completed';
      case 3: // Overdue
        return 'overdue';
      default:
        return undefined;
    }
  };

  const handleCreateActivity = async () => {
    if (!selectedOrg) {
      showSnackbar('Organisation ID is required', 'error');
      return;
    }
    try {
      setOperationLoading(prev => ({ ...prev, create: true }));
      const activityData = {
        accountId: selectedAccount?.id,
        type: activityFormData.type,
        title: activityFormData.title,
        description: activityFormData.description,
        date: activityFormData.date,
        time: activityFormData.time,
        duration: parseInt(activityFormData.duration),
        priority: activityFormData.priority,
        status: activityFormData.done ? 'completed' : 'open',
        location: activityFormData.location,
        conference_meeting_url: activityFormData.conference_meeting_url,
        participants: activityFormData.participants,
        public: activityFormData.public,
        busy_flag: activityFormData.busy_flag,
        reference_type: activityFormData.reference_type,
        reference_id: activityFormData.reference_id
      };
      await accountManagementService.createActivity(selectedOrg, activityData);
      setCreateDialogOpen(false);
      resetForm();
      showSnackbar('Activity created successfully');
      fetchActivities();
    } catch (err) {
      logError('Error creating activity:', err);
      showSnackbar('Failed to create activity', 'error');
    } finally {
      setOperationLoading(prev => ({ ...prev, create: false }));
    }
  };

  const handleUpdateActivity = async (activityId) => {
    if (!selectedOrg) {
      showSnackbar('Organisation ID is required', 'error');
      return;
    }
    try {
      setOperationLoading(prev => ({ ...prev, update: true }));
      const activityData = {
        activityId,
        type: activityFormData.type,
        subject: activityFormData.subject,
        description: activityFormData.description,
        due_date: activityFormData.due_date,
        due_time: activityFormData.due_time,
        duration: parseInt(activityFormData.duration),
        priority: activityFormData.priority,
        status: activityFormData.done ? 'completed' : 'open',
        location: activityFormData.location,
        conference_meeting_url: activityFormData.conference_meeting_url,
        participants: activityFormData.participants,
        public: activityFormData.public,
        busy_flag: activityFormData.busy_flag,
        reference_type: activityFormData.reference_type,
        reference_id: activityFormData.reference_id,
        outcome: activityFormData.outcome,
        note: activityFormData.note,
        recurring: activityFormData.recurring,
        recurring_pattern: activityFormData.recurring ? recurringPattern : null,
        contact_id: activityFormData.contact_id,
        deal_id: activityFormData.deal_id,
        account_id: activityFormData.account_id,
        reminder_minutes: parseInt(activityFormData.reminder_minutes)
      };
      await accountManagementService.updateActivity(selectedOrg, activityData);
      setCreateDialogOpen(false);
      resetForm();
      showSnackbar('Activity updated successfully');
      fetchActivities();
    } catch (err) {
      logError('Error updating activity:', err);
      showSnackbar('Failed to update activity', 'error');
    } finally {
      setOperationLoading(prev => ({ ...prev, update: false }));
    }
  };

  const handleDeleteActivity = async (activityId) => {
    if (!selectedOrg) {
      showSnackbar('Organisation ID is required', 'error');
      return;
    }
    try {
      setOperationLoading(prev => ({ ...prev, delete: true }));
      await accountManagementService.deleteActivity(selectedOrg, {
        activityId,
        accountId: selectedAccount?.id
      });
      showSnackbar('Activity deleted successfully');
      fetchActivities();
    } catch (err) {
      logError('Error deleting activity:', err);
      showSnackbar('Failed to delete activity', 'error');
    } finally {
      setOperationLoading(prev => ({ ...prev, delete: false }));
    }
  };

  const handleSyncWithPipedrive = async () => {
    if (!selectedOrg) {
      showSnackbar('Organisation ID is required', 'error');
      return;
    }
    try {
      setSyncing(true);
      await pipedriveService.syncData(selectedOrg, 'activities');
      showSnackbar('Activities synced with Pipedrive', 'success');
      fetchActivities();
    } catch (err) {
      logError('Error syncing with Pipedrive:', err);
      showSnackbar('Failed to sync with Pipedrive', 'error');
    } finally {
      setSyncing(false);
    }
  };

  const resetForm = () => {
    setActivityFormData({
      type: '',
      subject: '',
      description: '',
      due_date: new Date().toISOString().split('T')[0],
      due_time: new Date().toTimeString().split(' ')[0].slice(0, 5),
      duration: 30,
      priority: 'medium',
      done: false,
      outcome: 'no_outcome',
      location: '',
      conference_meeting_url: '',
      participants: [],
      public: true,
      busy_flag: true,
      assigned_to_user_id: '',
      lead_id: '',
      deal_id: '',
      account_id: selectedAccount?.id || '',
      contact_id: '',
      note: '',
      template_id: '',
      reminder_minutes: 30,
      recurring: false,
      recurring_pattern: null,
      custom_fields: {},
      attachments: []
    });
    setFormErrors({});
    setEditingActivity(null);
  };

  const handleEditActivity = (activity) => {
    console.log('Editing activity:', activity);
    setEditingActivity(activity);
    setActivityFormData({
      type: activity.type || '',
      subject: activity.subject || activity.title || '',
      description: activity.description || '',
      due_date: activity.due_date || activity.date || new Date().toISOString().split('T')[0],
      due_time: activity.due_time || activity.time || new Date().toTimeString().split(' ')[0].slice(0, 5),
      duration: activity.duration || 30,
      priority: activity.priority || 'medium',
      done: activity.status === 'completed',
      outcome: activity.outcome || 'no_outcome',
      location: activity.location || '',
      conference_meeting_url: activity.conference_meeting_url || '',
      participants: activity.participants || [],
      public: activity.public ?? true,
      busy_flag: activity.busy_flag ?? true,
      assigned_to_user_id: activity.assigned_to_user_id || '',
      lead_id: activity.lead_id || '',
      deal_id: activity.deal_id || '',
      account_id: activity.account_id || selectedAccount?.id || '',
      contact_id: activity.contact_id || '',
      note: activity.note || '',
      template_id: activity.template_id || '',
      reminder_minutes: activity.reminder_minutes || 30,
      recurring: activity.recurring || false,
      recurring_pattern: activity.recurring_pattern || null,
      custom_fields: activity.custom_fields || {},
      attachments: activity.attachments || []
    });
    setCreateDialogOpen(true);
  };

  const validateForm = () => {
    const errors = {};
    if (!activityFormData.type) errors.type = 'Type is required';
    if (!activityFormData.subject) errors.subject = 'Subject is required';
    if (!activityFormData.due_date) errors.due_date = 'Due Date is required';
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const fetchAccounts = async () => {
    try {
      setLoadingAccounts(true);
      const response = await accountService.listAccounts(selectedOrg);
      setAccounts(response.accounts || []);
    } catch (err) {
      logError('Error fetching accounts:', err);
      showSnackbar('Failed to load accounts', 'error');
    } finally {
      setLoadingAccounts(false);
    }
  };

  const fetchContacts = async () => {
    try {
      setLoadingContacts(true);
      const response = await contactService.listContacts(selectedOrg);
      setContacts(response.contacts || []);
    } catch (err) {
      logError('Error fetching contacts:', err);
      showSnackbar('Failed to load contacts', 'error');
    } finally {
      setLoadingContacts(false);
    }
  };

  const renderActivityItem = (activity) => (
    <ListItem
      key={activity.id}
      sx={{
        '&:hover': {
          backgroundColor: 'action.hover',
          cursor: 'pointer'
        }
      }}
      onClick={() => {
        setSelectedActivity(activity);
        setViewDialogOpen(true);
      }}
    >
      <ListItemIcon>
        {getActivityIcon(activity.type)}
      </ListItemIcon>
      <ListItemText
        primary={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="subtitle1">{activity.subject}</Typography>
            <Chip 
              size="small" 
              label={activity.source}
              color={activity.source === 'pipedrive' ? 'primary' : 'default'}
            />
            <Chip 
              size="small" 
              label={activity.status}
              color={activity.status === 'completed' ? 'success' : 'warning'}
            />
            {activity.priority && (
              <Chip 
                size="small" 
                label={activityPriorities.find(p => p.value === activity.priority)?.label || 'Medium'}
                color={activityPriorities.find(p => p.value === activity.priority)?.color || 'default'}
              />
            )}
          </Box>
        }
        secondary={
          <Box>
            <Typography variant="body2" color="text.secondary">
              {activity.description}
            </Typography>
            <Box sx={{ mt: 1, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
              <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <CalendarIcon fontSize="small" />
                {activity.due_date} {activity.due_time}
              </Typography>
              {activity.duration && (
                <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <ScheduleIcon fontSize="small" />
                  {activity.duration} min
                </Typography>
              )}
              {activity.account_id && (
                <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <BusinessIcon fontSize="small" />
                  {accounts.find(a => a.id === activity.account_id)?.name || 'Unknown Account'}
                </Typography>
              )}
              {activity.contact_id && (
                <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <PersonIcon fontSize="small" />
                  {contacts.find(c => c.id === activity.contact_id)?.name || 'Unknown Contact'}
                </Typography>
              )}
              {activity.outcome && (
                <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <FlagIcon fontSize="small" />
                  {activityOutcomes.find(o => o.value === activity.outcome)?.label || activity.outcome}
                </Typography>
              )}
              {activity.location && (
                <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <LocationIcon fontSize="small" />
                  {activity.location}
                </Typography>
              )}
              {activity.conference_meeting_url && (
                <Tooltip title={activity.conference_meeting_url}>
                  <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <LinkIcon fontSize="small" />
                    Video Conference
                  </Typography>
                </Tooltip>
              )}
            </Box>
          </Box>
        }
      />
      <ListItemSecondaryAction>
        {activity.source !== 'pipedrive' && (
          <>
            <IconButton
              edge="end"
              sx={{ mr: 1 }}
              onClick={(e) => {
                e.stopPropagation();
                handleEditActivity(activity);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              edge="end"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteActivity(activity.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );

  const getActivityIcon = (type) => {
    const activityType = activityTypes.find(t => t.value === type?.toLowerCase());
    return activityType?.icon || <EventIcon />;
  };

  const renderActivityDialog = () => (
    <Dialog 
      open={createDialogOpen} 
      onClose={() => setCreateDialogOpen(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        {editingActivity ? 'Edit Activity' : 'Create Activity'}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!formErrors.type}>
              <InputLabel>Type</InputLabel>
              <Select
                value={activityFormData.type}
                onChange={(e) => {
                  const newType = e.target.value;
                  setActivityFormData(prev => ({
                    ...prev,
                    type: newType,
                    location: '',
                    outcome: 'no_outcome',
                    template_id: ''
                  }));
                }}
                label="Type"
              >
                {activityTypes.map(type => (
                  <MenuItem key={type.value} value={type.value}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {type.icon}
                      {type.label}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
              {formErrors.type && <FormHelperText>{formErrors.type}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Subject"
              value={activityFormData.subject}
              onChange={(e) => setActivityFormData(prev => ({ ...prev, subject: e.target.value }))}
              error={!!formErrors.subject}
              helperText={formErrors.subject}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              multiline
              rows={3}
              value={activityFormData.description}
              onChange={(e) => setActivityFormData(prev => ({ ...prev, description: e.target.value }))}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="date"
              label="Due Date"
              value={activityFormData.due_date || ''}
              onChange={(e) => setActivityFormData(prev => ({ ...prev, due_date: e.target.value }))}
              error={!!formErrors.due_date}
              helperText={formErrors.due_date}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                max: '2099-12-31'
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="time"
              label="Due Time"
              value={activityFormData.due_time || ''}
              onChange={(e) => setActivityFormData(prev => ({ ...prev, due_time: e.target.value }))}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                step: 300 // 5 minutes
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="number"
              label="Duration (minutes)"
              value={activityFormData.duration}
              onChange={(e) => setActivityFormData(prev => ({ ...prev, duration: e.target.value }))}
              InputProps={{ inputProps: { min: 0 } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="number"
              label="Reminder (minutes before)"
              value={activityFormData.reminder_minutes}
              onChange={(e) => setActivityFormData(prev => ({ ...prev, reminder_minutes: e.target.value }))}
              InputProps={{ inputProps: { min: 0 } }}
            />
          </Grid>
          {activityFormData.done && activityTypes.find(t => t.value === activityFormData.type)?.fields.includes('outcome') && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Outcome</InputLabel>
                <Select
                  value={activityFormData.outcome}
                  onChange={(e) => setActivityFormData(prev => ({ ...prev, outcome: e.target.value }))}
                  label="Outcome"
                >
                  {activityOutcomes.map(outcome => (
                    <MenuItem key={outcome.value} value={outcome.value}>
                      {outcome.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {activityTypes.find(t => t.value === activityFormData.type)?.fields.includes('location') && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Location"
                value={activityFormData.location}
                onChange={(e) => setActivityFormData(prev => ({ ...prev, location: e.target.value }))}
              />
            </Grid>
          )}
          {activityFormData.type === 'meeting' && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Conference URL"
                value={activityFormData.conference_meeting_url}
                onChange={(e) => setActivityFormData(prev => ({ ...prev, conference_meeting_url: e.target.value }))}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Account</InputLabel>
              <Select
                value={activityFormData.account_id}
                onChange={(e) => setActivityFormData(prev => ({ ...prev, account_id: e.target.value }))}
                label="Account"
                disabled={loadingAccounts}
              >
                {loadingAccounts ? (
                  <MenuItem value="">
                    <CircularProgress size={20} /> Loading...
                  </MenuItem>
                ) : accounts.length > 0 ? (
                  accounts.map((account) => (
                    <MenuItem key={account.id} value={account.id}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <BusinessIcon fontSize="small" />
                        {account.name}
                        <Chip 
                          size="small" 
                          label={account.source === 'pipedrive' ? 'Pipedrive' : 'Local'} 
                          color={account.source === 'pipedrive' ? 'primary' : 'default'}
                        />
                      </Box>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No accounts available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Contact</InputLabel>
              <Select
                value={activityFormData.contact_id}
                onChange={(e) => setActivityFormData(prev => ({ ...prev, contact_id: e.target.value }))}
                label="Contact"
                disabled={loadingContacts}
              >
                {loadingContacts ? (
                  <MenuItem value="">
                    <CircularProgress size={20} /> Loading...
                  </MenuItem>
                ) : contacts.length > 0 ? (
                  contacts.map((contact) => (
                    <MenuItem key={contact.id} value={contact.id}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <PersonIcon fontSize="small" />
                        {contact.name}
                        <Chip 
                          size="small" 
                          label={contact.source === 'pipedrive' ? 'Pipedrive' : 'Local'} 
                          color={contact.source === 'pipedrive' ? 'primary' : 'default'}
                        />
                      </Box>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No contacts available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={activityFormData.recurring}
                  onChange={(e) => setActivityFormData(prev => ({ ...prev, recurring: e.target.checked }))}
                />
              }
              label="Recurring Activity"
            />
          </Grid>
          {activityFormData.recurring && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Repeat Every"
                  value={recurringPattern.interval}
                  onChange={(e) => setRecurringPattern(prev => ({ ...prev, interval: e.target.value }))}
                  InputProps={{ inputProps: { min: 1 } }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Frequency</InputLabel>
                  <Select
                    value={recurringPattern.frequency}
                    onChange={(e) => setRecurringPattern(prev => ({ ...prev, frequency: e.target.value }))}
                    label="Frequency"
                  >
                    <MenuItem value="daily">Daily</MenuItem>
                    <MenuItem value="weekly">Weekly</MenuItem>
                    <MenuItem value="monthly">Monthly</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={activityFormData.public}
                  onChange={(e) => setActivityFormData(prev => ({ ...prev, public: e.target.checked }))}
                />
              }
              label="Public Activity"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={activityFormData.busy_flag}
                  onChange={(e) => setActivityFormData(prev => ({ ...prev, busy_flag: e.target.checked }))}
                />
              }
              label="Mark as Busy"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={activityFormData.done}
                  onChange={(e) => setActivityFormData(prev => ({ ...prev, done: e.target.checked }))}
                />
              }
              label="Mark as Done"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Priority</InputLabel>
              <Select
                value={activityFormData.priority}
                onChange={(e) => setActivityFormData(prev => ({ ...prev, priority: e.target.value }))}
                label="Priority"
              >
                {activityPriorities.map(priority => (
                  <MenuItem key={priority.value} value={priority.value}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <FlagIcon color={priority.color} />
                      {priority.label}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setCreateDialogOpen(false)}>Cancel</Button>
        <Button
          onClick={editingActivity ? () => handleUpdateActivity(editingActivity.id) : handleCreateActivity}
          variant="contained"
          disabled={operationLoading.create || operationLoading.update}
        >
          {operationLoading.create || operationLoading.update ? 'Saving...' : (editingActivity ? 'Update' : 'Create')}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderActivityViewDialog = () => (
    <Dialog
      open={viewDialogOpen}
      onClose={() => setViewDialogOpen(false)}
      maxWidth="md"
      fullWidth
    >
      {selectedActivity && (
        <>
          <DialogTitle>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {getActivityIcon(selectedActivity.type)}
                <Typography variant="h6">{selectedActivity.subject}</Typography>
              </Box>
              <Box>
                <Chip
                  size="small"
                  label={selectedActivity.source}
                  color={selectedActivity.source === 'pipedrive' ? 'primary' : 'default'}
                  sx={{ mr: 1 }}
                />
                <Chip
                  size="small"
                  label={selectedActivity.status}
                  color={selectedActivity.status === 'completed' ? 'success' : 'warning'}
                />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={3} sx={{ mt: 1 }}>
              {/* Activity Details */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="subtitle1" color="primary" gutterBottom>
                    Activity Details
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                        <CalendarIcon color="action" />
                        <Box>
                          <Typography variant="body2" color="text.secondary">Due Date & Time</Typography>
                          <Typography>{selectedActivity.due_date} {selectedActivity.due_time}</Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                        <ScheduleIcon color="action" />
                        <Box>
                          <Typography variant="body2" color="text.secondary">Duration</Typography>
                          <Typography>{selectedActivity.duration} minutes</Typography>
                        </Box>
                      </Box>
                    </Grid>
                    {selectedActivity.location && (
                      <Grid item xs={12} sm={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                          <LocationIcon color="action" />
                          <Box>
                            <Typography variant="body2" color="text.secondary">Location</Typography>
                            <Typography>{selectedActivity.location}</Typography>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    {selectedActivity.conference_meeting_url && (
                      <Grid item xs={12} sm={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                          <LinkIcon color="action" />
                          <Box>
                            <Typography variant="body2" color="text.secondary">Conference URL</Typography>
                            <Link href={selectedActivity.conference_meeting_url} target="_blank">
                              Join Meeting
                            </Link>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                        <FlagIcon color="action" />
                        <Box>
                          <Typography variant="body2" color="text.secondary">Priority</Typography>
                          <Typography>
                            {activityPriorities.find(p => p.value === selectedActivity.priority)?.label || 'Medium'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    {selectedActivity.outcome && (
                      <Grid item xs={12} sm={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                          <CheckCircleIcon color="action" />
                          <Box>
                            <Typography variant="body2" color="text.secondary">Outcome</Typography>
                            <Typography>
                              {activityOutcomes.find(o => o.value === selectedActivity.outcome)?.label || selectedActivity.outcome}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Grid>

              {/* Related Items */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="subtitle1" color="primary" gutterBottom>
                    Related Items
                  </Typography>
                  <Grid container spacing={2}>
                    {selectedActivity.account_id && (
                      <Grid item xs={12} sm={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                          <BusinessIcon color="action" />
                          <Box>
                            <Typography variant="body2" color="text.secondary">Account</Typography>
                            <Typography>
                              {accounts.find(a => a.id === selectedActivity.account_id)?.name || 'Unknown Account'}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    {selectedActivity.contact_id && (
                      <Grid item xs={12} sm={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                          <PersonIcon color="action" />
                          <Box>
                            <Typography variant="body2" color="text.secondary">Contact</Typography>
                            <Typography>
                              {contacts.find(c => c.id === selectedActivity.contact_id)?.name || 'Unknown Contact'}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    {selectedActivity.deal_id && (
                      <Grid item xs={12} sm={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                          <AttachMoneyIcon color="action" />
                          <Box>
                            <Typography variant="body2" color="text.secondary">Deal</Typography>
                            <Typography>Deal #{selectedActivity.deal_id}</Typography>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Grid>

              {/* Description */}
              {selectedActivity.description && (
                <Grid item xs={12}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle1" color="primary" gutterBottom>
                      Description
                    </Typography>
                    <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                      {selectedActivity.description}
                    </Typography>
                  </Paper>
                </Grid>
              )}

              {/* Notes */}
              {selectedActivity.note && (
                <Grid item xs={12}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle1" color="primary" gutterBottom>
                      Notes
                    </Typography>
                    <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                      {selectedActivity.note}
                    </Typography>
                  </Paper>
                </Grid>
              )}

              {/* Recurring Details */}
              {selectedActivity.recurring && (
                <Grid item xs={12}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="subtitle1" color="primary" gutterBottom>
                      Recurring Details
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <RepeatIcon color="action" />
                      <Typography>
                        Repeats every {selectedActivity.recurring_pattern?.interval || 1}{' '}
                        {selectedActivity.recurring_pattern?.frequency || 'week(s)'}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            {selectedActivity.source !== 'pipedrive' && (
              <Button
                startIcon={<EditIcon />}
                onClick={() => {
                  setViewDialogOpen(false);
                  handleEditActivity(selectedActivity);
                }}
              >
                Edit
              </Button>
            )}
            <Button onClick={() => setViewDialogOpen(false)}>Close</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );

  const getFilteredActivities = () => {
    const now = new Date();
    switch (tabValue) {
      case 1: // Planned
        return activities.filter(a => new Date(a.due_date) > now && a.status !== 'completed');
      case 2: // Done
        return activities.filter(a => a.status === 'completed');
      case 3: // Overdue
        return activities.filter(a => new Date(a.due_date) < now && a.status !== 'completed');
      default: // All
        return activities;
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Header */}
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" component="h1">
          {selectedAccount ? `Activities - ${selectedAccount.name}` : 'All Activities'}
        </Typography>
        <Box>
          <Button
            sx={{ mr: 1 }}
            startIcon={<SyncIcon />}
            onClick={handleSyncWithPipedrive}
            disabled={syncing}
          >
            {syncing ? 'Syncing...' : 'Sync Pipedrive'}
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setEditingActivity(null);
              resetForm();
              setCreateDialogOpen(true);
            }}
          >
            New Activity
          </Button>
        </Box>
      </Box>

      {/* Stats */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        {tabLabels.map((label, index) => (
          <Grid item xs={6} sm={3} key={label}>
            <Paper sx={{ p: 2, textAlign: 'center' }}>
              <Typography variant="h4" color={index === tabValue ? 'primary' : 'text.primary'}>
                {index === 0 ? stats.total :
                 index === 1 ? stats.planned :
                 index === 2 ? stats.done :
                 stats.overdue}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {label}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Filters and Tabs */}
      <Paper sx={{ mb: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={(e, newValue) => setTabValue(newValue)}
            variant="fullWidth"
          >
            {tabLabels.map(label => (
              <Tab key={label} label={label} />
            ))}
          </Tabs>
        </Box>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Button
              startIcon={<FilterIcon />}
              onClick={(e) => setFilterAnchorEl(e.currentTarget)}
              sx={{ mr: 1 }}
            >
              Filter
            </Button>
            <Button
              startIcon={<SortIcon />}
              onClick={(e) => setSortAnchorEl(e.currentTarget)}
            >
              Sort
            </Button>
          </Box>
          <TextField
            size="small"
            placeholder="Search activities..."
            sx={{ width: 300 }}
          />
        </Box>
      </Paper>

      {/* Filter Menu */}
      <Menu
        anchorEl={filterAnchorEl}
        open={Boolean(filterAnchorEl)}
        onClose={() => setFilterAnchorEl(null)}
      >
        {activityTypes.map(type => (
          <MenuItem
            key={type.value}
            onClick={() => {
              setSelectedFilters(prev =>
                prev.includes(type.value)
                  ? prev.filter(f => f !== type.value)
                  : [...prev, type.value]
              );
              setFilterAnchorEl(null);
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {type.icon}
              {type.label}
              {selectedFilters.includes(type.value) && <CheckCircleIcon fontSize="small" />}
            </Box>
          </MenuItem>
        ))}
      </Menu>

      {/* Sort Menu */}
      <Menu
        anchorEl={sortAnchorEl}
        open={Boolean(sortAnchorEl)}
        onClose={() => setSortAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setSortBy('date');
            setSortAnchorEl(null);
          }}
        >
          Date
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSortBy('priority');
            setSortAnchorEl(null);
          }}
        >
          Priority
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSortBy('type');
            setSortAnchorEl(null);
          }}
        >
          Type
        </MenuItem>
      </Menu>

      {/* Main Content */}
      <Paper sx={{ p: 2 }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        ) : getFilteredActivities().length === 0 ? (
          <Box sx={{ textAlign: 'center', p: 3 }}>
            <Typography color="text.secondary">
              No activities found
            </Typography>
          </Box>
        ) : (
          <List>
            {getFilteredActivities().map(activity => renderActivityItem(activity))}
          </List>
        )}
      </Paper>

      {/* Activity Dialog */}
      {renderActivityDialog()}

      {/* Activity View Dialog */}
      {renderActivityViewDialog()}

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        message={snackbar.message}
      />
    </Box>
  );
};

export default ActivitiesHome; 