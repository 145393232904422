import api from './api';
import { getAuthHeader } from './authService';

const apiCall = async (method, endpoint, data = null, orgId = null) => {
  try {
    const token = await getAuthHeader();
    const config = {
      method,
      url: `/api/accounts${endpoint}`,
      headers: { 
        Authorization: token,
        ...(orgId && { 'x-organization-id': orgId })
      }
    };
    if (data) {
      config.data = data;
    }
    const response = await api(config);
    return response.data;
  } catch (error) {
    throw new Error(`API error: ${error.response?.data?.message || error.message}`);
  }
};

// Create the accountService object with the old interface
export const accountService = {
  async listAccounts(organisationId) {
    return apiCall('get', '', null, organisationId);
  },

  async createLocalAccount(organisationId, accountData) {
    return apiCall('post', '', {
      ...accountData,
      source: 'puawai'
    }, organisationId);
  },

  async updateLocalAccount(organisationId, accountId, accountData) {
    return apiCall('put', `/${accountId}`, accountData, organisationId);
  },

  async deleteLocalAccount(organisationId, accountId) {
    return apiCall('delete', `/${accountId}`, null, organisationId);
  },

  async getAccount(organisationId, accountId) {
    return apiCall('get', `/${accountId}`, null, organisationId);
  }
};

// Export both named and default export for backward compatibility
export default accountService; 