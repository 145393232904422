import api from './api';
import { getAuthHeader } from './authService';

const apiCall = async (method, endpoint, data = null) => {
  try {
    const token = await getAuthHeader();
    const config = {
      method,
      url: `/api/bids${endpoint}`,
      headers: { Authorization: token },
    };
    if (data) {
      if (data instanceof FormData) {
        config.headers['Content-Type'] = 'multipart/form-data';
      }
      config.data = data;
    }
    const response = await api(config);
    return response.data;
  } catch (error) {
    console.error(`Error in ${method} ${endpoint}:`, error);
    throw new Error(`API error: ${error.response?.data?.message || error.message}`);
  }
};

export const uploadDocument = async (bidId, folderId, file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('folderId', folderId || 'root');

  //console.log('Uploading document:', { bidId, folderId, fileName: file.name, fileSize: file.size });
  // ... (rest of the function)
};

export const listDocuments = async (bidId, folderId = null) => {
  try {
    //console.log(`Fetching documents for bidId: ${bidId}, folderId: ${folderId}`);
    const token = await getAuthHeader();
    const url = `/api/bids/${bidId}/documents`;
    const params = { folderId: folderId || 'root' };
    //console.log('API call details:', { url, params, token: token.slice(0, 10) + '...' });

    const response = await api.get(url, {
      params,
      headers: { Authorization: token }
    });
    //console.log('Raw response data:', response.data);
    //console.log('Response status:', response.status);
    //console.log('Response headers:', response.headers);
    
    // Ensure we're returning an array
    const documents = Array.isArray(response.data) ? response.data : (response.data.documents || []);
    
    // Map the response to include necessary properties
    const mappedDocuments = documents.map(doc => ({
      id: doc.id,
      name: doc.name,
      type: doc.type || (doc.contentType ? 'file' : 'folder'),
      lastModified: doc.lastModified || doc.createdAt,
      contentType: doc.contentType,
      size: doc.size
    }));

    //console.log('Processed documents:', mappedDocuments);
    return mappedDocuments;
  } catch (error) {
    console.error('Error in listDocuments:', error);
    console.error('Error response:', error.response);
    throw new Error(`An error occurred while fetching documents: ${error.response?.data || error.message}`);
  }
};

export const getFolderDetails = async (bidId, folderId) => {
  try {
    //console.log(`Fetching folder details for bidId: ${bidId}, folderId: ${folderId}`);
    const token = await getAuthHeader();
    const url = `/api/bids/${bidId}/documents/${folderId}`;
    //console.log('API call details:', { url, token: token.slice(0, 10) + '...' });

    const response = await api.get(url, {
      headers: { Authorization: token }
    });
    //console.log('Raw folder details:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in getFolderDetails:', error);
    console.error('Error response:', error.response);
    throw new Error(`An error occurred while fetching folder details: ${error.response?.data || error.message}`);
  }
};

export const createFolder = async (bidId, parentFolderId, folderName) => {
  try {
    return await apiCall('post', `/${bidId}/documents`, {
      type: 'folder',
      parentFolderId,
      folderName
    });
  } catch (error) {
    console.error('Error creating folder:', error);
    throw error;
  }
};

export const deleteItem = async (bidId, itemId) => {
  try {
    await apiCall('delete', `/${bidId}/documents/${itemId}`);
  } catch (error) {
    console.error('Error deleting item:', error);
    throw error;
  }
};

export const uploadDocuments = async (bidId, folderId, files) => {
  const formData = new FormData();
  files.forEach((file, index) => {
    formData.append(`file${index}`, file);
  });
  formData.append('folderId', folderId || 'root');

  try {
    //console.log('Uploading documents:', { bidId, folderId: folderId || 'root', fileCount: files.length });
    const response = await apiCall('post', `/${bidId}/documents/multiple`, formData);
    //console.log('Documents upload response:', response);
    return response;
  } catch (error) {
    console.error('Error uploading documents:', error);
    throw new Error(`Error uploading documents: ${error.message}`);
  }
};