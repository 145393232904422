import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import {
  EmojiEvents as EmojiEventsIcon,
  Description as DocumentIcon,
  Business as BusinessIcon,
  // Add other icons as needed
} from '@mui/icons-material';

const iconMap = {
  document: DocumentIcon,
  trophy: EmojiEventsIcon,
  business: BusinessIcon,
  // Add other icon mappings
};

export const WelcomePanel = ({ title, subtitle, icon = 'trophy' }) => {
  const IconComponent = iconMap[icon] || EmojiEventsIcon;

  return (
    <Paper 
      sx={{ 
        p: 3, 
        background: theme => `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
        color: 'white',
        mb: 3 
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconComponent sx={{ fontSize: '3rem', marginRight: '1rem' }} />
        <Box>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle1">
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}; 