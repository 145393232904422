import React, { useState, useEffect, useRef } from 'react';
import { Box, Fab, Tooltip, Drawer, IconButton, useTheme, Badge, CircularProgress, TextField, Typography, List, ListItem, Avatar, Paper } from '@mui/material';
import { Close as CloseIcon, Send as SendIcon, Person as PersonIcon, Fullscreen as FullscreenIcon, FullscreenExit as FullscreenExitIcon, Minimize as MinimizeIcon } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling } from '@fortawesome/free-solid-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';
import { brandBuilderChatService } from '../../services/ai/puawaiBrandAIService';
import ReactMarkdown from 'react-markdown';
import { keyframes, css } from '@emotion/react';
import { convertToNZEnglish } from '../../utils/languageUtils';
import { useLanguage } from '../../contexts/LanguageContext';

const blink = keyframes`
  0%, 100% { opacity: 0.2; }
  50% { opacity: 1; }
`;

const dotsStyle = css`
  display: inline-block;
  span {
    animation: ${blink} 1.4s infinite both;
    font-size: 1.5rem;
    line-height: 0.1;
    display: inline-block;
    margin-right: 2px;
    &:nth-of-type(2) { animation-delay: 0.2s; }
    &:nth-of-type(3) { animation-delay: 0.4s; }
  }
`;

const AnimatedDots = () => (
  <span css={dotsStyle}>
    <span>.</span><span>.</span><span>.</span>
  </span>
);

const BrandBuilderChatInterface = ({ 
  userProfile, 
  organisation, 
  brandData, 
  toneOfVoiceData, 
  topOffset = 0,
  currentStep,
  currentStepData,
  isOpen,
  onToggle
}) => {
  const { language, locale } = useLanguage();
  const [newMessages, setNewMessages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [isAiResponding, setIsAiResponding] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const theme = useTheme();
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (userProfile && organisation) {
      const userInfoData = {
        userName: userProfile.givenName || 'User',
        orgName: organisation.name || 'Unknown Organization',
        userData: userProfile,
        organisationData: {
          id: organisation.id || userProfile.organisationId,
          name: organisation.name || 'Unknown Organization',
        },
        language: language,
        locale: locale
      };
      setUserInfo(userInfoData);
      sendInitialMessage(userInfoData);
    }
  }, [userProfile, organisation, language, locale]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const sendInitialMessage = async (userInfoData) => {
    setIsLoading(true);
    try {
      const response = await brandBuilderChatService.sendMessage(
        "Hello, I'm the Puāwai Brand Intelligence assistant. How can I help you with your brand development today?",
        userInfoData,
        {
          brandData: brandData,
          toneOfVoiceData: toneOfVoiceData,
          organisationData: userInfoData.organisationData,
          currentStep: currentStep,
          currentStepData: currentStepData
        }
      );
      setMessages([{ text: response.response, sender: 'bot' }]);
    } catch (error) {
      setMessages([{ text: 'Sorry, there was an error starting the chat session. Please try again.', sender: 'bot' }]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSend = async () => {
    if (input.trim() === '') return;

    const newMessages = [...messages, { text: input, sender: 'user' }];
    setMessages(newMessages);
    setInput('');
    setIsAiResponding(true);

    try {
      const contextData = {
        brandData: brandData,
        toneOfVoiceData: toneOfVoiceData,
        organisationData: userInfo.organisationData,
        currentStep: currentStep,
        currentStepData: currentStepData,
        language: language,
        locale: locale
      };
      const response = await brandBuilderChatService.sendMessage(input, contextData);
      setMessages([...newMessages, { text: response.response, sender: 'bot', language: response.language, locale: response.locale }]);
    } catch (error) {
      setMessages([...newMessages, { text: 'Sorry, there was an error processing your request. Please try again.', sender: 'bot' }]);
    } finally {
      setIsAiResponding(false);
    }
  };

  const renderMessage = (message, index) => {
    const isUser = message.sender === 'user';
    const processedText = isUser ? message.text : convertToNZEnglish(message.text);

    return (
      <ListItem key={index} sx={{ justifyContent: isUser ? 'flex-end' : 'flex-start', py: 1, px: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: isUser ? 'row-reverse' : 'row', alignItems: 'flex-start', maxWidth: '90%' }}>
          <Avatar sx={{ 
            bgcolor: isUser ? theme.palette.primary.main : theme.palette.secondary.main, 
            width: 28, 
            height: 28,
            mr: isUser ? 0 : 1,
            ml: isUser ? 1 : 0
          }}>
            {isUser ? <PersonIcon fontSize="small" /> : <FontAwesomeIcon icon={faSeedling} style={{ fontSize: '0.8rem' }} />}
          </Avatar>
          <Paper elevation={1} sx={{ 
            p: 1.5, 
            pl: 2,
            bgcolor: isUser ? theme.palette.primary.light : theme.palette.background.default,
            borderRadius: 2,
            maxWidth: 'calc(100% - 40px)',
            wordBreak: 'break-word'
          }}>
            <ReactMarkdown components={{
              ol: ({node, ...props}) => <ol style={{paddingLeft: '20px'}} {...props} />,
              ul: ({node, ...props}) => <ul style={{paddingLeft: '20px'}} {...props} />
            }}>{processedText}</ReactMarkdown>
          </Paper>
        </Box>
      </ListItem>
    );
  };

  const toggleFullScreen = () => setIsFullScreen(!isFullScreen);

  const floatingIconVariants = {
    initial: { y: 0 },
    animate: {
      y: [0, -10, 0],
      transition: { duration: 1.5, repeat: Infinity, repeatType: "loop", ease: "easeInOut", times: [0, 0.5, 1] }
    },
    hover: {
      scale: 1.1,
      rotate: [0, 10, -10, 0],
      transition: { duration: 0.5, yoyo: Infinity },
    },
  };

  return (
    <>
      <AnimatePresence>
        {!isOpen && (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{ duration: 0.3 }}
            style={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              zIndex: theme.zIndex.drawer + 1,
            }}
          >
            <Tooltip title="Open Puāwai Brand Intelligence">
              <Badge badgeContent={newMessages} color="error">
                <motion.div variants={floatingIconVariants} initial="initial" animate="animate" whileHover="hover">
                  <Fab 
                    color="primary" 
                    onClick={onToggle}
                    sx={{
                      width: 80,
                      height: 80,
                      bgcolor: theme.palette.primary.main,
                      '&:hover': { bgcolor: theme.palette.primary.dark },
                    }}
                  >
                    <FontAwesomeIcon icon={faSeedling} style={{ fontSize: '2rem' }} />
                  </Fab>
                </motion.div>
              </Badge>
            </Tooltip>
          </motion.div>
        )}
      </AnimatePresence>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onToggle}
        PaperProps={{
          sx: {
            width: isFullScreen ? '100%' : '380px',
            maxWidth: isFullScreen ? '100%' : '95vw',
            height: isFullScreen ? `calc(100% - ${topOffset}px)` : '80vh',
            top: isFullScreen ? `${topOffset}px` : 'auto',
            bottom: isFullScreen ? 0 : 16,
            right: isFullScreen ? 0 : 16,
            borderRadius: isFullScreen ? 0 : '16px 0 0 16px',
            overflow: 'hidden',
            bgcolor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            boxShadow: theme.shadows[10],
            transition: 'all 0.3s ease-in-out',
          },
        }}
      >
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ 
            p: 1.5, 
            bgcolor: theme.palette.primary.main, 
            color: theme.palette.primary.contrastText,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Typography variant="h6">Puāwai Brand Intelligence</Typography>
            <Box>
              <IconButton 
                onClick={toggleFullScreen} 
                size="small"
                sx={{ color: theme.palette.primary.contrastText, mr: 0.5 }}
              >
                {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </IconButton>
              <IconButton 
                onClick={onToggle} 
                size="small"
                sx={{ color: theme.palette.primary.contrastText }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <List sx={{ flexGrow: 1, overflow: 'auto', py: 1, px: 1 }}>
            {messages.map(renderMessage)}
            {isAiResponding && (
              <ListItem sx={{ justifyContent: 'flex-start', py: 1, px: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar sx={{ bgcolor: theme.palette.secondary.main, width: 28, height: 28, mr: 1 }}>
                    <FontAwesomeIcon icon={faSeedling} style={{ fontSize: '0.8rem' }} />
                  </Avatar>
                  <AnimatedDots />
                </Box>
              </ListItem>
            )}
            <div ref={messagesEndRef} />
          </List>
          <Box sx={{ p: 2, borderTop: `1px solid ${theme.palette.divider}` }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type your message..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && handleSend()}
              multiline
              maxRows={4}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleSend} disabled={isAiResponding || input.trim() === ''}>
                    <SendIcon />
                  </IconButton>
                ),
                sx: {
                  pl: 2,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.divider,
                  },
                },
              }}
            />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default BrandBuilderChatInterface;
