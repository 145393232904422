import api from '../api';
import { getAuthHeader } from '../authService';
import { getToneOfVoice } from '../brandBuilderService';

const apiCall = async (method, endpoint, data = null) => {
  try {
    const token = await getAuthHeader();
    const config = {
      method,
      url: `/api${endpoint}`,
      headers: { 
        Authorization: token,
        'Content-Type': 'application/json'
      },
    };
    if (data) {
      config.data = data;
    }
    const response = await api(config);
    return response.data;
  } catch (error) {
    throw new Error(`API error: ${error.response?.data?.message || error.message}`);
  }
};

const formatContextData = (contextData) => {
  // Add any specific formatting for brand data if needed
  return contextData;
};

export const brandBuilderChatService = {
  sendMessage: async (message, contextData) => {
    try {
      const payload = {
        message,
        userInfo: {
          userName: contextData.brandData?.givenName || 'User',
          orgName: contextData.organisationData?.name || 'Unknown Organization',
          language: contextData.language || 'en',
          locale: contextData.locale || 'en-NZ'
        },
        contextData: {
          ...contextData,
          organisationData: contextData.organisationData || {
            id: contextData.brandData?.organisationId,
            name: contextData.brandData?.organisationName
          },
          currentStep: contextData.currentStep,
          currentStepData: contextData.currentStepData,
          expectedFormat: contextData.expectedFormat,
          language: contextData.language,
          locale: contextData.locale
        }
      };

      const response = await apiCall('post', '/brandai', payload);

      if (response && response.response) {
        return {
          response: response.response,
          language: response.language || 'en',
          locale: response.locale || 'en-NZ'
        };
      } else {
        throw new Error('Invalid response format from server');
      }
    } catch (error) {
      throw error;
    }
  }
};

export default brandBuilderChatService;
