import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Paper, Typography, Box, Tooltip, useTheme, IconButton, Switch, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Snackbar, Autocomplete, Chip } from '@mui/material';
import { Flag, CheckCircle, Schedule, Event as EventIcon, Star as StarIcon, Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import tasksService from '../../services/tasksService';
import bidService from '../../services/bidService';
import { getUser } from '../../services/userService';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Alert } from '@mui/material';
import bidteamService from '../../services/bidteamService';
import { getOrganisationUsers } from '../../services/organisationService';

const localizer = momentLocalizer(moment);

const CalendarTimeline = ({ bidId, organisationId, bidVisibility }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [workHoursOnly, setWorkHoursOnly] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const theme = useTheme();
  const [nonTaskEntries, setNonTaskEntries] = useState([]);
  const [openEntryDialog, setOpenEntryDialog] = useState(false);
  const [newEntry, setNewEntry] = useState({
    title: '',
    start: new Date(),
    end: new Date(),
    description: '',
    allDay: false,
    assignedUsers: [],
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [availableUsers, setAvailableUsers] = useState([]);
  const [editingEntry, setEditingEntry] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [tasks, bidDetails, entries, teamMembers] = await Promise.all([
          tasksService.getBidTasks(bidId),
          bidService.getBidDetails(bidId, organisationId),
          bidService.getNonTaskEntries(bidId),
          bidteamService.getTeamMembers(bidId)
        ]);

        const calendarEvents = [];
        if (bidDetails.startDate) {
          calendarEvents.push({
            title: 'Bid Start',
            start: new Date(bidDetails.startDate),
            end: new Date(bidDetails.startDate),
            allDay: false,
            type: 'milestone',
            description: 'Beginning of the bid process'
          });
        }
        if (bidDetails.submissionDate) {
          calendarEvents.push({
            title: 'Submission Deadline',
            start: new Date(bidDetails.submissionDate),
            end: new Date(bidDetails.submissionDate),
            allDay: false,
            type: 'milestone',
            description: 'Final date for bid submission'
          });
        }

        const taskEvents = await Promise.all(tasks.map(async task => {
          let assignedToName = 'Unassigned';
          if (task.assignedTo) {
            try {
              const userDetails = await getUser(task.assignedTo);
              assignedToName = `${userDetails.givenName} ${userDetails.surname}`.trim();
            } catch (error) {
              console.error('Error fetching user details:', error);
            }
          }

          const startDate = new Date(task.dueDate);
          const endDate = new Date(startDate.getTime() + 60 * 60 * 1000); // Add 1 hour for event duration

          return {
            title: task.title,
            start: startDate,
            end: endDate,
            allDay: false,
            type: 'task',
            status: task.status,
            id: task.id,
            description: task.description,
            assignedTo: assignedToName
          };
        }));

        const nonTaskEvents = entries.map(entry => ({
          title: entry.title,
          start: new Date(entry.start),
          end: new Date(entry.end),
          allDay: entry.allDay,
          type: 'non-task',
          description: entry.description,
          id: entry.id,
          assignedUsers: entry.assignedUsers || []
        }));

        setEvents([...calendarEvents, ...taskEvents, ...nonTaskEvents]);
        setNonTaskEntries(entries);
        setAvailableUsers(teamMembers.map(member => ({
          id: member.id,
          name: `${member.givenName || ''} ${member.surname || ''}`.trim() || member.name || 'Unknown User',
          email: member.email
        })));
      } catch (error) {
        console.error('Error fetching calendar data:', error);
        setError(`Failed to load calendar data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    if (bidId && organisationId) {
      fetchData();
    } else {
      setError('No bid ID or organisation ID provided. Please select a bid.');
    }
  }, [bidId, organisationId]);

  useEffect(() => {
    const fetchAvailableUsers = async () => {
      try {
        let users;
        if (bidVisibility === 'organisation') {
          users = await getOrganisationUsers(organisationId);
        } else {
          users = await bidteamService.getTeamMembers(bidId);
        }
        setAvailableUsers(users.map(user => ({
          id: user.id || user.userId,
          name: `${user.givenName || ''} ${user.surname || ''}`.trim() || user.name || 'Unknown User',
          email: user.email
        })));
      } catch (error) {
        console.error('Error fetching available users:', error);
        handleOpenSnackbar('Failed to fetch team members', 'error');
      }
    };
    if (bidId && organisationId) {
      fetchAvailableUsers();
    }
  }, [bidId, organisationId, bidVisibility]);

  const handleWorkHoursToggle = () => {
    setWorkHoursOnly(!workHoursOnly);
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  const handleCloseEventPreview = () => {
    setSelectedEvent(null);
  };

  const getEventStyle = (event) => {
    if (event.type === 'milestone') return { backgroundColor: theme.palette.secondary.main };
    if (event.type === 'non-task') return { backgroundColor: theme.palette.warning.main };
    switch (event.status) {
      case 'completed':
        return { backgroundColor: theme.palette.success.main };
      case 'in_progress':
        return { backgroundColor: theme.palette.info.main };
      case 'not_started':
        return { backgroundColor: theme.palette.primary.light };
      case 'overdue':
        return { backgroundColor: theme.palette.error.main };
      default:
        return { backgroundColor: theme.palette.grey[500] };
    }
  };

  const getStatusIcon = (event) => {
    if (event.type === 'milestone') return <Flag />;
    if (event.type === 'non-task') return <StarIcon />;
    switch (event.status) {
      case 'completed':
        return <CheckCircle />;
      case 'in_progress':
        return <Schedule />;
      case 'overdue':
        return <Schedule color="error" />;
      default:
        return <Schedule />;
    }
  };

  const handleDeleteEntry = async (entryId) => {
    try {
      await bidService.deleteNonTaskEntry(bidId, entryId);
      setEvents(prevEvents => prevEvents.filter(event => event.id !== entryId));
      handleOpenSnackbar('Entry deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting entry:', error);
      handleOpenSnackbar('Failed to delete entry', 'error');
    }
  };

  const handleEditEntry = (entry) => {
    setEditingEntry(entry);
    setOpenEntryDialog(true);
  };

  const handleUpdateEntry = async () => {
    try {
      const updatedEntry = await bidService.updateNonTaskEntry(bidId, editingEntry.id, {
        ...editingEntry,
        assignedUsers: editingEntry.assignedUsers.map(user => ({
          id: user.id,
          name: user.name || 'Unknown User'
        }))
      });
      setEvents(prevEvents => prevEvents.map(event => 
        event.id === updatedEntry.id ? { ...event, ...updatedEntry } : event
      ));
      setOpenEntryDialog(false);
      setEditingEntry(null);
      handleOpenSnackbar('Entry updated successfully', 'success');
    } catch (error) {
      console.error('Error updating entry:', error);
      handleOpenSnackbar('Failed to update entry', 'error');
    }
  };

  const EventComponent = ({ event }) => {
    const [isHovered, setIsHovered] = useState(false);
    const eventStyle = getEventStyle(event);

    const getAssignedUsersString = (assignedUsers) => {
      if (!assignedUsers || assignedUsers.length === 0) return 'Unassigned';
      return assignedUsers.map(user => user?.name || 'Unknown User').join(', ');
    };

    const getEventTimeString = (event) => {
      if (event.allDay) {
        return 'All Day';
      }
      return `${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}`;
    };

    return (
      <Box
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
          p: 0.5,
          backgroundColor: eventStyle.backgroundColor,
          color: theme.palette.getContrastText(eventStyle.backgroundColor),
          borderRadius: '4px',
        }}
      >
        <Tooltip
          title={
            <React.Fragment>
              <Typography color="inherit" variant="subtitle2">{event.title}</Typography>
              <Typography variant="body2">{event.description}</Typography>
              {event.type !== 'non-task' && (
                <>
                  <Typography variant="body2">Assigned to: {event.assignedTo || 'Unassigned'}</Typography>
                  <Typography variant="body2">Status: {event.status || 'Not started'}</Typography>
                </>
              )}
              {event.type === 'non-task' && (
                <Typography variant="body2">
                  Assigned to: {getAssignedUsersString(event.assignedUsers)}
                </Typography>
              )}
              <Typography variant="body2">
                Time: {getEventTimeString(event)}
              </Typography>
            </React.Fragment>
          }
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, fontSize: '0.8rem', flexGrow: 1, overflow: 'hidden' }}>
            {getStatusIcon(event)}
            <Typography variant="caption" noWrap>{event.title}</Typography>
          </Box>
        </Tooltip>
        {isHovered && (
          <>
            <Tooltip title="Add to Calendar">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  // Implement add to calendar functionality here
                }}
                size="small"
                sx={{
                  color: 'inherit',
                  opacity: 0.7,
                  '&:hover': { opacity: 1 },
                  padding: 0,
                  fontSize: '0.7rem'
                }}
              >
                <EventIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            {event.type === 'non-task' && (
              <>
                <Tooltip title="Edit Entry">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditEntry(event);
                    }}
                    size="small"
                    sx={{
                      color: 'inherit',
                      opacity: 0.7,
                      '&:hover': { opacity: 1 },
                      padding: 0,
                      fontSize: '0.7rem'
                    }}
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Entry">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteEntry(event.id);
                    }}
                    size="small"
                    sx={{
                      color: 'inherit',
                      opacity: 0.7,
                      '&:hover': { opacity: 1 },
                      padding: 0,
                      fontSize: '0.7rem'
                    }}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </>
        )}
      </Box>
    );
  };

  const minTime = new Date();
  minTime.setHours(workHoursOnly ? 9 : 0, 0, 0);
  const maxTime = new Date();
  maxTime.setHours(workHoursOnly ? 17 : 24, 0, 0);

  const handleAddEntry = async () => {
    try {
      const entryData = {
        ...newEntry,
        assignedUsers: newEntry.assignedUsers.map(user => ({
          id: user.id,
          name: user.name || 'Unknown User'
        }))
      };

      if (entryData.allDay) {
        // For all-day events, set the time to 00:00:00
        entryData.start = moment(entryData.start).startOf('day').toDate();
        entryData.end = moment(entryData.end).endOf('day').toDate();
      }

      const createdEntry = await bidService.createNonTaskEntry(bidId, entryData);
      setEvents(prevEvents => [...prevEvents, {
        ...createdEntry,
        start: new Date(createdEntry.start),
        end: new Date(createdEntry.end),
        type: 'non-task',
        allDay: createdEntry.allDay,
      }]);
      setOpenEntryDialog(false);
      setNewEntry({
        title: '',
        start: new Date(),
        end: new Date(),
        description: '',
        allDay: false,
        assignedUsers: [],
      });
      handleOpenSnackbar('New entry added successfully', 'success');
    } catch (error) {
      console.error('Error adding new entry:', error);
      handleOpenSnackbar('Failed to add new entry', 'error');
    }
  };

  const handleOpenSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 2, height: 'calc(100vh - 100px)', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Bid Timeline</Typography>
        <Box>
          <FormControlLabel
            control={<Switch checked={workHoursOnly} onChange={handleWorkHoursToggle} />}
            label={workHoursOnly ? "Work Hours (9-5)" : "24 Hours"}
          />
          <Button
            startIcon={<AddIcon />}
            onClick={() => setOpenEntryDialog(true)}
            variant="contained"
            color="primary"
            sx={{ ml: 2 }}
          >
            Add Entry
          </Button>
        </Box>
      </Box>
      {loading && <Typography>Loading calendar data...</Typography>}
      {error && <Typography color="error">{error}</Typography>}
      {!loading && !error && events.length === 0 && (
        <Typography>No events to display. Try adding some tasks to your bid.</Typography>
      )}
      {!loading && !error && events.length > 0 && (
        <Box sx={{ flexGrow: 1, height: 'calc(100% - 40px)' }}>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor={(event) => event.allDay ? moment(event.start).startOf('day').toDate() : event.start}
            endAccessor={(event) => event.allDay ? moment(event.end).endOf('day').toDate() : event.end}
            style={{ 
              height: '100%',
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.background.paper,
            }}
            eventPropGetter={event => ({
              style: {
                ...getEventStyle(event),
                fontSize: '0.8rem',
                borderRadius: '4px',
              }
            })}
            components={{
              event: EventComponent
            }}
            formats={{
              timeGutterFormat: (date, culture, localizer) =>
                localizer.format(date, 'HH:mm', culture),
              eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
                `${localizer.format(start, 'HH:mm', culture)} - ${localizer.format(end, 'HH:mm', culture)}`,
            }}
            views={['month', 'week', 'day', 'agenda']}
            defaultView='week'
            step={15}
            timeslots={4}
            min={minTime}
            max={maxTime}
            scrollToTime={minTime}
            onSelectEvent={handleEventClick}
          />
        </Box>
      )}

      {/* Event Preview Dialog */}
      <Dialog open={!!selectedEvent} onClose={handleCloseEventPreview}>
        <DialogTitle>{selectedEvent?.title}</DialogTitle>
        <DialogContent>
          <Typography><strong>Description:</strong> {selectedEvent?.description}</Typography>
          <Typography><strong>Assigned to:</strong> {selectedEvent?.assignedTo}</Typography>
          <Typography><strong>Status:</strong> {selectedEvent?.status}</Typography>
          <Typography><strong>Start:</strong> {selectedEvent?.start.toLocaleString()}</Typography>
          <Typography><strong>End:</strong> {selectedEvent?.end.toLocaleString()}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEventPreview}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* New Entry Dialog */}
      <Dialog open={openEntryDialog} onClose={() => {
        setOpenEntryDialog(false);
        setEditingEntry(null);
      }}>
        <DialogTitle>{editingEntry ? 'Edit Entry' : 'Add New Entry'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            fullWidth
            value={editingEntry ? editingEntry.title : newEntry.title}
            onChange={(e) => {
              if (editingEntry) {
                setEditingEntry({ ...editingEntry, title: e.target.value });
              } else {
                setNewEntry({ ...newEntry, title: e.target.value });
              }
            }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={editingEntry ? editingEntry.allDay : newEntry.allDay}
                onChange={(e) => {
                  if (editingEntry) {
                    setEditingEntry({ ...editingEntry, allDay: e.target.checked });
                  } else {
                    setNewEntry({ ...newEntry, allDay: e.target.checked });
                  }
                }}
              />
            }
            label="All Day Event"
          />
          {(editingEntry ? editingEntry.allDay : newEntry.allDay) && (
            <>
              <TextField
                margin="dense"
                label="Start Date"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={editingEntry ? moment(editingEntry.start).format("YYYY-MM-DD") : moment(newEntry.start).format("YYYY-MM-DD")}
                onChange={(e) => {
                  if (editingEntry) {
                    setEditingEntry({ ...editingEntry, start: new Date(e.target.value) });
                  } else {
                    setNewEntry({ ...newEntry, start: new Date(e.target.value) });
                  }
                }}
              />
              <TextField
                margin="dense"
                label="End Date"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={editingEntry ? moment(editingEntry.end).format("YYYY-MM-DD") : moment(newEntry.end).format("YYYY-MM-DD")}
                onChange={(e) => {
                  if (editingEntry) {
                    setEditingEntry({ ...editingEntry, end: new Date(e.target.value) });
                  } else {
                    setNewEntry({ ...newEntry, end: new Date(e.target.value) });
                  }
                }}
              />
            </>
          )}
          {!(editingEntry ? editingEntry.allDay : newEntry.allDay) && (
            <>
              <TextField
                margin="dense"
                label="Start Date and Time"
                type="datetime-local"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={editingEntry ? moment(editingEntry.start).format("YYYY-MM-DDTHH:mm") : moment(newEntry.start).format("YYYY-MM-DDTHH:mm")}
                onChange={(e) => {
                  if (editingEntry) {
                    setEditingEntry({ ...editingEntry, start: new Date(e.target.value) });
                  } else {
                    setNewEntry({ ...newEntry, start: new Date(e.target.value) });
                  }
                }}
              />
              <TextField
                margin="dense"
                label="End Date and Time"
                type="datetime-local"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={editingEntry ? moment(editingEntry.end).format("YYYY-MM-DDTHH:mm") : moment(newEntry.end).format("YYYY-MM-DDTHH:mm")}
                onChange={(e) => {
                  if (editingEntry) {
                    setEditingEntry({ ...editingEntry, end: new Date(e.target.value) });
                  } else {
                    setNewEntry({ ...newEntry, end: new Date(e.target.value) });
                  }
                }}
              />
            </>
          )}
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            multiline
            rows={4}
            value={editingEntry ? editingEntry.description : newEntry.description}
            onChange={(e) => {
              if (editingEntry) {
                setEditingEntry({ ...editingEntry, description: e.target.value });
              } else {
                setNewEntry({ ...newEntry, description: e.target.value });
              }
            }}
          />
          <Autocomplete
            multiple
            id="assigned-users"
            options={availableUsers}
            getOptionLabel={(option) => option.name}
            value={editingEntry ? editingEntry.assignedUsers : newEntry.assignedUsers}
            onChange={(event, newValue) => {
              if (editingEntry) {
                setEditingEntry({ ...editingEntry, assignedUsers: newValue });
              } else {
                setNewEntry({ ...newEntry, assignedUsers: newValue });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Assigned Users"
                placeholder="Select users"
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ))
            }
          />
          <Typography variant="caption" color="textSecondary" sx={{ mt: 2, display: 'block' }}>
            Note: Tasks are automatically added to the calendar view. This form is for additional non-task entries.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setOpenEntryDialog(false);
            setEditingEntry(null);
          }}>Cancel</Button>
          <Button onClick={editingEntry ? handleUpdateEntry : handleAddEntry} color="primary">
            {editingEntry ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default CalendarTimeline;