import api from './api';

export const stripeService = {
  // Create or update customer
  createOrUpdateCustomer: async (customerData) => {
    try {
      const requestData = {
        email: customerData.email,
        name: `${customerData.firstName} ${customerData.lastName}`.trim(),
        description: `User ID: ${customerData.userId}`,
        metadata: {
          userId: customerData.userId || '',
          firstName: customerData.firstName || '',
          lastName: customerData.lastName || ''
        }
      };

      const response = await api.post('/api/stripe/customers', requestData);
      return response.data;
    } catch (error) {
      console.error('Error creating/updating customer:', error);
      throw error;
    }
  },

  // Attach payment method to customer
  attachPaymentMethod: async (paymentMethodId, customerId) => {
    try {
      const response = await api.post(`/api/stripe/payment-methods/${paymentMethodId}/attach`, {
        customerId
      });
      return response.data;
    } catch (error) {
      console.error('Error attaching payment method:', error);
      throw error;
    }
  },

  // Update customer default payment method - Updated endpoint
  updateDefaultPaymentMethod: async (customerId, paymentMethodId) => {
    try {
      // Use the correct endpoint that matches the backend route
      const response = await api.post(`/api/stripe/payment-methods/${paymentMethodId}/default`, {
        customerId
      });
      return response.data;
    } catch (error) {
      console.error('Error updating default payment method:', error);
      throw error;
    }
  }
};

export default stripeService;