import { getAuthHeader } from './authService';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fundingSourcesService = {
  // Get funding sources
  getFundingSources: async (country, fundingType) => {
    try {
      const authHeader = await getAuthHeader();
      
      // Simplify source types to match exactly what's in the database
      const sourceTypeMap = {
        'government': ['government'],
        'commercial': ['commercial'],
        'non-profit': ['non-profit']
      };

      // Format region to match database format (lowercase with hyphens)
      const formattedRegion = country.toLowerCase().replace(/\s+/g, '-');

      // Query the opportunities container directly
      const response = await axios.get(
        `${API_BASE_URL}/api/fundraising/opportunities`,
        {
          headers: {
            'Authorization': authHeader,
            'Content-Type': 'application/json'
          },
          params: {
            region: formattedRegion,
            sourceTypes: sourceTypeMap[fundingType]?.join(','),
            status: 'active'
          }
        }
      );

      console.log('Raw opportunities response:', response.data);
      const opportunities = response.data?.opportunities || [];
      
      // Filter out expired opportunities and remove deadline for active ones
      const filteredOpportunities = opportunities
        .filter(opp => opp.status !== 'expired')
        .map(opp => {
          const { deadline, ...rest } = opp;
          return rest;
        })
        .filter(opp => 
          opp.sourceType === fundingType
        );

      console.log('Filtered opportunities:', filteredOpportunities);

      return {
        data: filteredOpportunities,
        total: filteredOpportunities.length
      };
    } catch (error) {
      if (error.response?.status === 401) {
        console.error('Authentication error:', error);
        // Try to refresh the token and retry
        try {
          const newAuthHeader = await getAuthHeader();
          const formattedRegion = country.toLowerCase().replace(/\s+/g, '-');
          
          // Retry the request with the new token
          const retryResponse = await axios.get(
            `${API_BASE_URL}/api/fundraising/opportunities`,
            {
              headers: {
                'Authorization': newAuthHeader,
                'Content-Type': 'application/json'
              },
              params: {
                region: formattedRegion,
                sourceTypes: sourceTypeMap[fundingType]?.join(','),
                status: 'active'
              }
            }
          );
          
          const retryOpportunities = retryResponse.data?.opportunities || [];
          const retryFilteredOpportunities = retryOpportunities
            .filter(opp => opp.status !== 'expired')
            .map(opp => {
              const { deadline, ...rest } = opp;
              return rest;
            })
            .filter(opp => 
              opp.sourceType === fundingType
            );
          
          return {
            data: retryFilteredOpportunities,
            total: retryFilteredOpportunities.length
          };
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
          throw refreshError;
        }
      }
      console.error('Error fetching funding sources:', error);
      return {
        data: [],
        total: 0
      };
    }
  },

  // Refresh funding sources data
  refreshFundingSources: async (country, fundingTypes) => {
    try {
      // Simply re-fetch the data for all selected types
      const results = await Promise.all(
        fundingTypes.map(type => 
          fundingSourcesService.getFundingSources(country, type)
        )
      );

      // Combine results
      const combinedData = {};
      fundingTypes.forEach((type, index) => {
        combinedData[type] = results[index].data;
      });

      return {
        data: combinedData,
        total: Object.values(combinedData).reduce((acc, curr) => acc + curr.length, 0)
      };
    } catch (error) {
      console.error('Error refreshing funding sources:', error);
      throw error;
    }
  },

  // Get available countries
  getAvailableCountries: async () => {
    try {
      const authHeader = await getAuthHeader();
      const response = await axios.get(
        `${API_BASE_URL}/api/fundraising/countries`,
        {
          headers: {
            'Authorization': authHeader,
            'Content-Type': 'application/json'
          }
        }
      );
      
      // For now, return a fixed list while API is being developed
      return [
        { 
          id: 'new-zealand',
          name: 'New Zealand',
          sources: 0
        }
      ];
      
    } catch (error) {
      console.error('Error fetching countries:', error);
      return [
        { 
          id: 'new-zealand',
          name: 'New Zealand',
          sources: 0
        }
      ];
    }
  },

  // Trigger funding scrape process
  triggerFundingScrape: async (country, type) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/funding/scrape`,
        { country, type },
        { headers: { 'Authorization': await getAuthHeader(), 'Content-Type': 'application/json' } }
      );

      if (response.data.success) {
        return {
          success: true,
          message: 'Successfully scraped funding opportunities',
          data: response.data.data
        };
      } else {
        throw new Error(response.data.message || 'Failed to scrape funding opportunities');
      }
    } catch (error) {
      console.error('Error triggering funding scrape:', error);
      throw new Error(`Failed to scrape funding opportunities: ${error.message}`);
    }
  },

  // Trigger funding process
  triggerFundingProcess: async () => {
    try {
      const authHeader = await getAuthHeader();
      const response = await axios.post(
        `${API_BASE_URL}/api/funding/process`,
        {},  // Empty body as the backend processes all unprocessed documents
        {
          headers: {
            'Authorization': authHeader,
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (response.data && response.data.message) {
        return {
          success: true,
          message: response.data.message,
          unprocessedCount: response.data.unprocessedCount
        };
      }
      
      return response.data;
    } catch (error) {
      console.error('Error triggering funding process:', error);
      throw {
        success: false,
        message: error.response?.data?.error || 'Failed to trigger funding process',
        error: error.message
      };
    }
  }
};