import React, { useState, useEffect, useRef } from 'react';
import Draggable from 'react-draggable';
import {
  Box,
  Paper,
  Typography,
  Button,
  Card,
  CardContent,
  IconButton,
  Chip,
  Avatar,
  Tooltip,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  InputAdornment,
  Autocomplete,
  FormHelperText,
  Snackbar
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  AttachMoney,
  Business,
  Person,
  CalendarToday,
  Sync as SyncIcon
} from '@mui/icons-material';
import { dealService } from '../../services/dealService';
import { contactService } from '../../services/contactService';
import accountService from '../../services/accountService';
import pipedriveService from '../../services/pipedriveService';

const DealManagement = ({ userData, selectedOrg }) => {
  const [stages, setStages] = useState([]);
  const [deals, setDeals] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [syncStatus, setSyncStatus] = useState(null);
  const [activeDragInfo, setActiveDragInfo] = useState(null);
  const stageRefs = useRef({});
  const [formData, setFormData] = useState({
    title: '',
    value: '',
    currency: 'USD',
    person_id: '',
    account_id: '',
    stage_id: '',
    status: 'open',
    expected_close_date: '',
    probability: null,
    lost_reason: '',
    visible_to: '3',
    add_time: '',
    update_time: '',
    pipeline_id: '',
    won_time: null,
    lost_time: null,
    products_count: 0,
    files_count: 0,
    notes_count: 0,
    followers_count: 0,
    email_messages_count: 0,
    activities_count: 0,
    done_activities_count: 0,
    undone_activities_count: 0,
    participants_count: 0,
    description: ''
  });
  const [organizations, setOrganizations] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [newOrgDialogOpen, setNewOrgDialogOpen] = useState(false);
  const [newContactDialogOpen, setNewContactDialogOpen] = useState(false);
  const [newOrgData, setNewOrgData] = useState({ name: '', industry: '' });
  const [newContactData, setNewContactData] = useState({
    name: '',
    email: '',
    phone: '',
    role: ''
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const defaultStages = [
    { id: '1', name: 'Qualified' },
    { id: '2', name: 'Contact Made' },
    { id: '3', name: 'Needs Defined' },
    { id: '4', name: 'Proposal Made' },
    { id: '5', name: 'Negotiations Started' }
  ];

  useEffect(() => {
    if (selectedOrg) {
      Promise.all([
        fetchDeals(),
        fetchOrganizationsAndContacts()
      ]);
    }
  }, [selectedOrg]);

  const fetchOrganizationsAndContacts = async () => {
    try {
      const [orgsResponse, contactsResponse] = await Promise.all([
        accountService.listAccounts(selectedOrg),
        contactService.listContacts(selectedOrg)
      ]);

      console.log('Fetched organizations:', orgsResponse.accounts);
      console.log('Fetched contacts:', contactsResponse.contacts);

      setOrganizations(orgsResponse.accounts || []);
      setContacts(contactsResponse.contacts || []);
    } catch (error) {
      console.error('Error fetching organizations and contacts:', error);
    }
  };

  const initializeDealsState = (stages, deals) => {
    console.log('Initializing deals state:', { stages, deals });
    const dealsByStage = {};
    stages.forEach(stage => {
      const stageId = stage.id.toString();
      dealsByStage[stageId] = deals.filter(deal => 
        deal.stage_id?.toString() === stageId
      );
      console.log(`Stage ${stageId} deals:`, dealsByStage[stageId]);
    });
    return dealsByStage;
  };

  const fetchDeals = async () => {
    try {
      if (!selectedOrg) {
        setError('No organization selected');
        return;
      }

      setLoading(true);
      setError(null);
      console.log('Fetching deals for organization:', selectedOrg);
      
      const response = await dealService.listDeals(selectedOrg);
      console.log('Deals response:', response);
      
      if (!response.success) {
        throw new Error(response.message || 'Failed to load deals');
      }
      
      // Clear existing deals before setting new ones
      setDeals({});
      
      // Initialize deals state with all stages
      const dealsByStage = initializeDealsState(response.stages, response.deals);
      console.log('Grouped deals by stage:', dealsByStage);
      
      setStages(response.stages);
      setDeals(dealsByStage);
      setError(null);
    } catch (err) {
      console.error('Error in fetchDeals:', {
        error: err.message,
        stack: err.stack,
        originalError: err
      });
      setError(err.message || 'Failed to load deals');
      setStages([]);
      setDeals({});
    } finally {
      setLoading(false);
    }
  };

  const handleDragStart = (dealId, stageId) => {
    setActiveDragInfo({ dealId, sourceStageId: stageId });
  };

  const handleDragStop = async (e, data, deal, currentStageId) => {
    if (!activeDragInfo) return;

    // Find which stage we're dropping onto
    let targetStageId = null;
    for (const [stageId, ref] of Object.entries(stageRefs.current)) {
      const rect = ref.getBoundingClientRect();
      if (
        e.clientX >= rect.left &&
        e.clientX <= rect.right &&
        e.clientY >= rect.top &&
        e.clientY <= rect.bottom
      ) {
        targetStageId = stageId;
        break;
      }
    }

    // Reset position
    setActiveDragInfo(null);

    // If no target found or same stage, do nothing
    if (!targetStageId || targetStageId === currentStageId) return;

    try {
      // Don't allow moving Pipedrive deals
      if (deal.source === 'pipedrive') {
        showSnackbar('Pipedrive deals cannot be moved', 'warning');
        return;
      }

      // Update the deal's stage while preserving all required fields
      const updatedDeal = {
        ...deal,
        stage_id: targetStageId,
        title: deal.title,
        value: deal.value,
        currency: deal.currency || 'USD',
        person_id: deal.person_id,
        account_id: deal.account_id,
        status: deal.status || 'open',
        description: deal.description || ''
      };

      // Optimistically update the UI
      const newDeals = { ...deals };
      newDeals[currentStageId] = newDeals[currentStageId].filter(d => d.id !== deal.id);
      newDeals[targetStageId] = [...(newDeals[targetStageId] || []), updatedDeal];
      setDeals(newDeals);

      // Update in the backend with all required fields
      await dealService.updateDeal(selectedOrg, deal.id, updatedDeal);

      showSnackbar('Deal stage updated successfully');
    } catch (err) {
      console.error('Error updating deal stage:', err);
      showSnackbar('Failed to update deal stage', 'error');
      // Revert the optimistic update by refreshing the deals
      fetchDeals();
    }
  };

  const renderDealCard = (deal, stageId) => {
    const isPipedriveDeal = deal.source === 'pipedrive';
    const isDragging = activeDragInfo?.dealId === deal.id;
    
    return (
      <Draggable
        key={deal.id}
        disabled={isPipedriveDeal}
        onStart={() => handleDragStart(deal.id, stageId)}
        onStop={(e, data) => handleDragStop(e, data, deal, stageId)}
        position={isDragging ? null : { x: 0, y: 0 }}
      >
        <div style={{ 
          position: 'relative',
          zIndex: isDragging ? 10000 : 'auto',
          transform: isDragging ? 'translate3d(0, 0, 999px)' : 'none'
        }}>
          <Card
            sx={{
              mb: 1,
              cursor: isPipedriveDeal ? 'default' : 'grab',
              opacity: isPipedriveDeal ? 0.7 : 1,
              boxShadow: isPipedriveDeal ? 1 : 3,
              position: 'relative',
              pointerEvents: 'auto',
              transition: 'all 0.2s ease',
              transform: isDragging ? 'scale(1.02)' : 'scale(1)',
              '&:hover': {
                boxShadow: 6,
                transform: isPipedriveDeal ? 'scale(1)' : 'scale(1.01)'
              },
              ...(isDragging && {
                boxShadow: 12,
                bgcolor: 'background.paper',
                border: '2px solid',
                borderColor: 'primary.main',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  top: 8,
                  left: 8,
                  right: -8,
                  bottom: -8,
                  background: 'rgba(0, 0, 0, 0.1)',
                  zIndex: -1,
                  borderRadius: 1
                }
              })
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="subtitle1" noWrap>{deal.title}</Typography>
                <Chip 
                  size="small"
                  label={deal.source}
                  color={deal.source === 'pipedrive' ? 'primary' : 'default'}
                />
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                <AttachMoney fontSize="small" />
                <Typography variant="body2">
                  {formatCurrency(deal.value, deal.currency)}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                <Business fontSize="small" />
                <Typography variant="body2" noWrap>
                  {organizations.find(org => org.id === deal.account_id)?.name || 
                    deal.organization_name ||
                    (deal.account_id === 'unassigned' ? 'Unassigned' : 
                    deal.account_id ? `Account: ${deal.account_id}` : 'No Account')}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                <Person fontSize="small" />
                <Typography variant="body2" noWrap>
                  {contacts.find(contact => contact.id === deal.person_id)?.name || 
                    deal.person_name ||
                    (deal.person_id === 'unassigned' ? 'Unassigned' : 
                    deal.person_id ? `Contact: ${deal.person_id}` : 'No Contact')}
                </Typography>
              </Box>

              {deal.expected_close_date && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CalendarToday fontSize="small" />
                  <Typography variant="body2">
                    {new Date(deal.expected_close_date).toLocaleDateString()}
                  </Typography>
                </Box>
              )}

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                {!isPipedriveDeal && (
                  <>
                    <Tooltip title="Edit">
                      <IconButton size="small" onClick={() => handleEdit(deal)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton size="small" onClick={() => handleDelete(deal.id)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </Box>
            </CardContent>
          </Card>
        </div>
      </Draggable>
    );
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  const handleSyncDeals = async () => {
    if (!selectedOrg) {
      setError('No organization selected');
      showSnackbar('No organization selected', 'error');
      return;
    }

    try {
      setSyncStatus('syncing');
      setError(null);
      
      // Call the pipedriveService to sync deals
      await pipedriveService.syncData(selectedOrg, 'deals');
      
      // Refresh the deals list
      await fetchDeals();
      
      setSyncStatus('completed');
      showSnackbar('Deals synced successfully with Pipedrive');
    } catch (err) {
      console.error('Error syncing deals:', err);
      setError(err.message || 'Failed to sync deals with Pipedrive');
      setSyncStatus('error');
      showSnackbar(err.message || 'Failed to sync deals with Pipedrive', 'error');
    }
  };

  const formatCurrency = (value, currency = 'USD') => {
    const currencyFormats = {
      USD: { locale: 'en-US', currency: 'USD' },
      NZD: { locale: 'en-NZ', currency: 'NZD' },
      AUD: { locale: 'en-AU', currency: 'AUD' },
      EUR: { locale: 'en-EU', currency: 'EUR' },
      GBP: { locale: 'en-GB', currency: 'GBP' }
    };

    const format = currencyFormats[currency] || currencyFormats.USD;

    return new Intl.NumberFormat(format.locale, {
      style: 'currency',
      currency: format.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: true
    }).format(value || 0);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError(null);

      // Validate required fields
      if (!formData.title?.trim()) {
        setError('Deal title is required');
        return;
      }

      const value = parseFloat(formData.value);
      if (isNaN(value) || value <= 0) {
        setError('Deal value is required and must be greater than 0');
        return;
      }

      if (!formData.stage_id) {
        setError('Deal stage is required');
        return;
      }

      // Format deal data
      const dealData = {
        title: formData.title.trim(),
        value: value,
        currency: formData.currency || 'USD',
        person_id: formData.person_id || null,
        account_id: formData.account_id || null,
        account: formData.account_id ? {
          id: formData.account_id,
          name: organizations.find(org => org.id === formData.account_id)?.name,
          source: 'puawai'
        } : null,
        contact: formData.person_id ? {
          id: formData.person_id,
          name: contacts.find(contact => contact.id === formData.person_id)?.name,
          source: 'puawai'
        } : null,
        organization_name: organizations.find(org => org.id === formData.account_id)?.name || null,
        person_name: contacts.find(contact => contact.id === formData.person_id)?.name || null,
        stage_id: formData.stage_id,
        status: formData.status || 'open',
        expected_close_date: formData.expected_close_date || null,
        probability: formData.probability ? parseFloat(formData.probability) : null,
        description: formData.description || '',
        visible_to: formData.visible_to || '3',
        pipeline_id: formData.pipeline_id || '1'
      };

      console.log('Submitting deal data:', dealData);

      if (selectedDeal) {
        await dealService.updateDeal(
          selectedOrg,
          selectedDeal.id,
          dealData
        );
      } else {
        await dealService.createDeal(
          selectedOrg,
          dealData
        );
      }

      setDialogOpen(false);
      // Add a small delay before refreshing to ensure backend changes are complete
      setTimeout(() => {
        fetchDeals();
      }, 500);
    } catch (err) {
      console.error('Error saving deal:', err);
      setError(err.message || 'Failed to save deal');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (deal) => {
    // Prevent editing Pipedrive deals
    if (deal.source === 'pipedrive') {
      return;
    }

    console.log('Editing deal:', deal);

    setSelectedDeal(deal);
    setFormData({
      title: deal.title || '',
      value: deal.value || '',
      currency: deal.currency || 'USD',
      person_id: deal.contact?.id || deal.person_id || '',
      account_id: deal.account?.id || deal.account_id || '',
      stage_id: deal.stage_id || '',
      status: deal.status || 'open',
      expected_close_date: deal.expected_close_date || '',
      probability: deal.probability || null,
      lost_reason: deal.lost_reason || '',
      visible_to: deal.visible_to || '3',
      add_time: deal.add_time || '',
      update_time: deal.update_time || '',
      pipeline_id: deal.pipeline_id || '1',
      won_time: deal.won_time || null,
      lost_time: deal.lost_time || null,
      products_count: deal.products_count || 0,
      files_count: deal.files_count || 0,
      notes_count: deal.notes_count || 0,
      followers_count: deal.followers_count || 0,
      email_messages_count: deal.email_messages_count || 0,
      activities_count: deal.activities_count || 0,
      done_activities_count: deal.done_activities_count || 0,
      undone_activities_count: deal.undone_activities_count || 0,
      participants_count: deal.participants_count || 0,
      description: deal.description || '',
      // Add account and contact objects for Autocomplete components
      account: deal.account || (deal.account_id ? {
        id: deal.account_id,
        name: deal.organization_name,
        source: 'puawai'
      } : null),
      contact: deal.contact || (deal.person_id ? {
        id: deal.person_id,
        name: deal.person_name,
        source: 'puawai'
      } : null)
    });

    console.log('Form data set:', formData);
    setDialogOpen(true);
  };

  const handleDelete = async (dealId) => {
    // Get the deal before deleting to check source
    const dealToDelete = Object.values(deals)
      .flat()
      .find(deal => deal.id === dealId);

    if (dealToDelete?.source === 'pipedrive') {
      setError('Pipedrive deals cannot be deleted from this interface');
      return;
    }

    try {
      setLoading(true);
      await dealService.deleteDeal(selectedOrg, dealId);
      await fetchDeals(); // Refresh deals list
    } catch (err) {
      console.error('Error deleting deal:', err);
      setError('Failed to delete deal');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateNewOrg = async () => {
    try {
      const response = await accountService.createAccount(selectedOrg, newOrgData);
      setOrganizations([...organizations, response.account]);
      setFormData({ ...formData, account_id: response.account.id });
      setNewOrgDialogOpen(false);
      setNewOrgData({ name: '', industry: '' });
    } catch (error) {
      console.error('Error creating organization:', error);
    }
  };

  const handleCreateNewContact = async () => {
    try {
      const response = await contactService.createContact(
        selectedOrg,
        formData.account_id,
        newContactData
      );
      setContacts([...contacts, response.contact]);
      setFormData({ ...formData, person_id: response.contact.id });
      setNewContactDialogOpen(false);
      setNewContactData({ name: '', email: '', phone: '', role: '' });
    } catch (error) {
      console.error('Error creating contact:', error);
    }
  };

  // Add initial form state
  const initialFormState = {
    title: '',
    value: '',
    currency: 'USD',
    person_id: '',
    account_id: '',
    stage_id: '2', // Default to 'qualified' stage
    status: 'open',
    expected_close_date: '',
    probability: '',
    lost_reason: '',
    visible_to: '3',
    pipeline_id: '1',
    description: ''
  };

  // Update the Add Deal button click handler
  const handleAddDeal = () => {
    setSelectedDeal(null);
    setFormData(initialFormState);
    setDialogOpen(true);
  };

  // Update the dialog content
  const renderDealDialog = () => {
    // Filter organizations to only show local accounts when creating/editing
    const localOrganizations = organizations.filter(org => org.source === 'puawai');
    const localContacts = contacts.filter(contact => contact.source === 'puawai');

    return (
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          {selectedDeal ? 'Edit Deal' : 'Add New Deal'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {/* Basic Information */}
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="primary" gutterBottom>Basic Information</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Deal Title"
                fullWidth
                required
                error={!formData.title?.trim()}
                helperText={!formData.title?.trim() ? 'Deal title is required' : ''}
                value={formData.title || ''}
                onChange={(e) => {
                  const newTitle = e.target.value;
                  setFormData(prev => ({
                    ...prev,
                    title: newTitle
                  }));
                }}
              />
            </Grid>

            {/* Account Selection - Only show for local deals */}
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={localOrganizations}
                getOptionLabel={(option) => option.name || ''}
                value={localOrganizations.find(org => org.id === formData.account_id) || null}
                onChange={(event, newValue) => {
                  setFormData(prev => ({
                    ...prev,
                    account_id: newValue?.id || '',
                    account: newValue ? {
                      id: newValue.id,
                      name: newValue.name,
                      source: 'puawai'
                    } : null
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Account"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <Business color="action" sx={{ mr: 1 }} />
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Grid>

            {/* Contact Selection - Only show for local deals */}
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={localContacts}
                getOptionLabel={(option) => option.name || ''}
                value={localContacts.find(contact => contact.id === formData.person_id) || null}
                onChange={(event, newValue) => {
                  setFormData(prev => ({
                    ...prev,
                    person_id: newValue?.id || '',
                    contact: newValue ? {
                      id: newValue.id,
                      name: newValue.name,
                      source: 'puawai'
                    } : null
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Contact"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <Person color="action" sx={{ mr: 1 }} />
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Grid>

            {/* Value and Currency */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Deal Value"
                fullWidth
                required
                type="number"
                value={formData.value || ''}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  value: e.target.value
                }))}
                error={!formData.value || parseFloat(formData.value) <= 0}
                helperText={!formData.value || parseFloat(formData.value) <= 0 ? 'Deal value is required and must be greater than 0' : ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoney />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Currency</InputLabel>
                <Select
                  value={formData.currency || 'USD'}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    currency: e.target.value
                  }))}
                >
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="NZD">NZD</MenuItem>
                  <MenuItem value="AUD">AUD</MenuItem>
                  <MenuItem value="EUR">EUR</MenuItem>
                  <MenuItem value="GBP">GBP</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Stage and Status */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel>Stage</InputLabel>
                <Select
                  value={formData.stage_id || ''}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    stage_id: e.target.value
                  }))}
                  error={!formData.stage_id}
                >
                  {stages.map(stage => (
                    <MenuItem key={stage.id} value={stage.id}>
                      {stage.name}
                    </MenuItem>
                  ))}
                </Select>
                {!formData.stage_id && (
                  <FormHelperText error>Stage is required</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  value={formData.status || 'open'}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    status: e.target.value
                  }))}
                >
                  <MenuItem value="open">Open</MenuItem>
                  <MenuItem value="won">Won</MenuItem>
                  <MenuItem value="lost">Lost</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Expected Close Date and Probability */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Expected Close Date"
                type="date"
                fullWidth
                value={formData.expected_close_date || ''}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  expected_close_date: e.target.value
                }))}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarToday />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Probability (%)"
                type="number"
                fullWidth
                value={formData.probability || ''}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  probability: e.target.value
                }))}
                InputProps={{
                  inputProps: { min: 0, max: 100 }
                }}
              />
            </Grid>

            {/* Lost Reason (only shown if status is lost) */}
            {formData.status === 'lost' && (
              <Grid item xs={12}>
                <TextField
                  label="Lost Reason"
                  multiline
                  rows={2}
                  fullWidth
                  value={formData.lost_reason || ''}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    lost_reason: e.target.value
                  }))}
                />
              </Grid>
            )}

            {/* Description */}
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="primary" gutterBottom>Additional Information</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                multiline
                rows={4}
                fullWidth
                value={formData.description || ''}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  description: e.target.value
                }))}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleSubmit}
            disabled={loading || !formData.title?.trim() || !formData.value || parseFloat(formData.value) <= 0 || !formData.stage_id}
          >
            {loading ? <CircularProgress size={24} /> : selectedDeal ? 'Update Deal' : 'Create Deal'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderNewOrgDialog = () => (
    <Dialog
      open={newOrgDialogOpen}
      onClose={() => setNewOrgDialogOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Create New Account</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <TextField
              label="Account Name"
              fullWidth
              value={newOrgData.name}
              onChange={(e) => setNewOrgData({ ...newOrgData, name: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Industry"
              fullWidth
              value={newOrgData.industry}
              onChange={(e) => setNewOrgData({ ...newOrgData, industry: e.target.value })}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setNewOrgDialogOpen(false)}>Cancel</Button>
        <Button 
          variant="contained" 
          onClick={handleCreateNewOrg}
          disabled={!newOrgData.name}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderNewContactDialog = () => (
    <Dialog
      open={newContactDialogOpen}
      onClose={() => setNewContactDialogOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Create New Contact</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <TextField
              label="Contact Name"
              fullWidth
              value={newContactData.name}
              onChange={(e) => setNewContactData({ ...newContactData, name: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              type="email"
              fullWidth
              value={newContactData.email}
              onChange={(e) => setNewContactData({ ...newContactData, email: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Phone"
              fullWidth
              value={newContactData.phone}
              onChange={(e) => setNewContactData({ ...newContactData, phone: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Role</InputLabel>
              <Select
                value={newContactData.role}
                onChange={(e) => setNewContactData({ ...newContactData, role: e.target.value })}
              >
                {contactService.getRoleOptions().map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setNewContactDialogOpen(false)}>Cancel</Button>
        <Button 
          variant="contained" 
          onClick={handleCreateNewContact}
          disabled={!newContactData.name || !newContactData.email}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box sx={{ p: 3 }}>
      <Paper elevation={3} sx={{ mb: 2 }}>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">Deals Pipeline</Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="outlined"
              onClick={handleSyncDeals}
              disabled={syncStatus === 'syncing'}
              startIcon={syncStatus === 'syncing' ? <CircularProgress size={20} /> : <SyncIcon />}
            >
              Sync with Pipedrive
            </Button>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleAddDeal}
            >
              Add Deal
            </Button>
          </Box>
        </Box>
      </Paper>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ 
          display: 'flex', 
          gap: 2, 
          overflowX: 'auto', 
          pb: 2,
          minHeight: 'calc(100vh - 200px)',
          position: 'relative',
          transformStyle: 'preserve-3d',
          perspective: 1000
        }}>
          {stages.map(stage => {
            const stageId = stage.id.toString();
            const isDropTarget = activeDragInfo && activeDragInfo.sourceStageId !== stageId;
            
            return (
              <Paper
                key={stageId}
                ref={el => stageRefs.current[stageId] = el}
                sx={{
                  minWidth: 300,
                  maxWidth: 300,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  transformStyle: 'preserve-3d',
                  transform: 'translate3d(0, 0, 0)',
                  ...(isDropTarget && {
                    boxShadow: 4,
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'primary.main',
                      opacity: 0.08,
                      pointerEvents: 'none',
                      zIndex: 1,
                      borderRadius: 1
                    },
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      height: '100%',
                      border: '2px dashed',
                      borderColor: 'primary.main',
                      opacity: 0.5,
                      pointerEvents: 'none',
                      zIndex: 2,
                      borderRadius: 1
                    }
                  })
                }}
              >
                <Box sx={{ 
                  p: 2, 
                  borderBottom: 1, 
                  borderColor: 'divider',
                  bgcolor: 'background.default',
                  position: 'relative',
                  zIndex: 2
                }}>
                  <Typography variant="subtitle1" gutterBottom>
                    {stage.name}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="caption" color="text.secondary">
                      {deals[stageId]?.length || 0} deals
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      · ${(deals[stageId] || []).reduce((sum, deal) => sum + (parseFloat(deal.value) || 0), 0).toLocaleString()}
                    </Typography>
                  </Box>
                </Box>
                
                <Box
                  sx={{
                    p: 1,
                    flexGrow: 1,
                    overflowY: 'auto',
                    minHeight: '200px',
                    bgcolor: 'background.default',
                    position: 'relative',
                    zIndex: 2,
                    '& > *': {
                      pointerEvents: activeDragInfo ? 'none' : 'auto'
                    }
                  }}
                >
                  {(deals[stageId] || []).map(deal => renderDealCard(deal, stageId))}
                </Box>
              </Paper>
            );
          })}
        </Box>
      )}

      {renderDealDialog()}
      {renderNewOrgDialog()}
      {renderNewContactDialog()}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </Box>
  );
};

export default DealManagement; 