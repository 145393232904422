import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  LinearProgress,
  Tooltip,
  Card,
  CardContent,
  CircularProgress,
} from '@mui/material';
import {
  TrendingUp as TrendingUpIcon,
  AttachMoney as MoneyIcon,
  Timeline as TimelineIcon,
  Assessment as AssessmentIcon,
} from '@mui/icons-material';
import { PieChart, Pie, Cell, ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip } from 'recharts';
import { fundraisingService } from '../../services/fundraisingService';
import AITooltip from '../../components/ai/AITooltip';

const FundraisingAnalytics = ({ userData, selectedOrg }) => {
  const [metrics, setMetrics] = useState({
    totalFundsRaised: 0,
    activeApplications: 0,
    successRate: 0,
    avgTimeToClose: 0,
    fundingByType: [],
    monthlyProgress: [],
    insights: []
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        const data = await fundraisingService.getFundraisingAnalytics(selectedOrg);
        setMetrics(data);
      } catch (error) {
        console.error('Error fetching analytics:', error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedOrg) {
      fetchAnalytics();
    }
  }, [selectedOrg]);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

  const renderInsights = () => (
    <Grid container spacing={3} sx={{ mt: 2 }}>
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">AI-Powered Insights</Typography>
            <AITooltip 
              context="Fundraising Analytics Insights"
              userProfile={userData}
              organisation={selectedOrg}
            />
          </Box>
          <Grid container spacing={2}>
            {metrics.insights.map((insight, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1" color="primary">
                      {insight.title}
                    </Typography>
                    <Typography variant="body2">
                      {insight.description}
                    </Typography>
                    {insight.trend && (
                      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                        <TrendingUpIcon 
                          color={insight.trend > 0 ? "success" : "error"} 
                          sx={{ mr: 1 }} 
                        />
                        <Typography 
                          variant="body2" 
                          color={insight.trend > 0 ? "success.main" : "error.main"}
                        >
                          {insight.trend}%
                        </Typography>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        {/* Overview Cards */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <MoneyIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6">Total Raised</Typography>
              </Box>
              <Typography variant="h4">
                ${metrics.totalFundsRaised.toLocaleString()}
              </Typography>
              <LinearProgress 
                variant="determinate" 
                value={75} 
                sx={{ mt: 2 }} 
              />
            </Paper>
          </Grid>

          <Grid item xs={12} md={3}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <AssessmentIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6">Active Applications</Typography>
              </Box>
              <Typography variant="h4">{metrics.activeApplications}</Typography>
              <Typography variant="body2" color="textSecondary">
                In Progress
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={3}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <TrendingUpIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6">Success Rate</Typography>
              </Box>
              <Typography variant="h4">{metrics.successRate}%</Typography>
              <LinearProgress 
                variant="determinate" 
                value={metrics.successRate} 
                sx={{ mt: 2 }} 
              />
            </Paper>
          </Grid>

          <Grid item xs={12} md={3}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <TimelineIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6">Avg Time to Close</Typography>
              </Box>
              <Typography variant="h4">{metrics.avgTimeToClose}</Typography>
              <Typography variant="body2" color="textSecondary">
                Days
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        {/* Charts */}
        <Grid container spacing={3} sx={{ mt: 2 }}>
          {/* Funding Distribution */}
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 3, height: 400 }}>
              <Typography variant="h6" gutterBottom>
                Funding Sources
              </Typography>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={metrics.fundingByType}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={150}
                    fill="#8884d8"
                    dataKey="value"
                    label={({ name, value }) => `${name}: $${value.toLocaleString()}`}
                  >
                    {metrics.fundingByType.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <ChartTooltip formatter={(value) => `$${value.toLocaleString()}`} />
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>

          {/* Progress Over Time */}
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 3, height: 400 }}>
              <Typography variant="h6" gutterBottom>
                Monthly Progress
              </Typography>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={metrics.monthlyProgress}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis tickFormatter={(value) => `$${value.toLocaleString()}`} />
                  <ChartTooltip formatter={(value) => `$${value.toLocaleString()}`} />
                  <Line 
                    type="monotone" 
                    dataKey="amount" 
                    stroke="#8884d8" 
                    activeDot={{ r: 8 }} 
                  />
                </LineChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
        </Grid>

        {/* Add AI insights */}
        {renderInsights()}
      </Box>
    </Container>
  );
};

export default FundraisingAnalytics; 