import api from '../api';
import { getAuthHeader } from '../authService';
import { getToneOfVoice } from '../brandBuilderService';

const apiCall = async (method, endpoint, data = null) => {
  try {
    const token = await getAuthHeader();
    const config = {
      method,
      url: `/api${endpoint}`,
      headers: { 
        Authorization: token,
        'Content-Type': 'application/json'
      },
    };
    if (data) {
      config.data = data;
    }
    const response = await api(config);
    return response.data;
  } catch (error) {
    console.error(`Error in ${method} ${endpoint}:`, error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }
    throw new Error(`API error: ${error.response?.data?.message || error.message}`);
  }
};

export const puawaiAIService = {
  sendMessage: async (message, userInfo, contextData) => {
    try {
      let toneOfVoice = contextData.toneOfVoice;
      if (toneOfVoice === 'custom') {
        const orgToneOfVoice = await getToneOfVoice(userInfo.organisationData.id);
        toneOfVoice = orgToneOfVoice || 'default';
      }

      const isTooltipRequest = message.startsWith('Provide a brief, context-aware tooltip for:');

      const response = await apiCall('post', '/chatbot', {
        message,
        userInfo,
        contextData: {
          ...contextData,
          toneOfVoice,
          isTooltipRequest,
        },
        language: userInfo.language,
        locale: userInfo.locale
      });
      
      if (response && response.response) {
        return response;
      } else {
        throw new Error('Invalid response format from server');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      throw error;
    }
  }
};

export default puawaiAIService;
