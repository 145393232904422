export const ANZSIC_CODES = [
  { code: 'A', description: 'Agriculture, Forestry and Fishing' },
  { code: 'B', description: 'Mining' },
  { code: 'C', description: 'Manufacturing' },
  { code: 'D', description: 'Electricity, Gas, Water and Waste Services' },
  { code: 'E', description: 'Construction' },
  { code: 'F', description: 'Wholesale Trade' },
  { code: 'G', description: 'Retail Trade' },
  { code: 'H', description: 'Accommodation and Food Services' },
  { code: 'I', description: 'Transport, Postal and Warehousing' },
  { code: 'J', description: 'Information Media and Telecommunications' },
  { code: 'K', description: 'Financial and Insurance Services' },
  { code: 'L', description: 'Rental, Hiring and Real Estate Services' },
  { code: 'M', description: 'Professional, Scientific and Technical Services' },
  { code: 'N', description: 'Administrative and Support Services' },
  { code: 'O', description: 'Public Administration and Safety' },
  { code: 'P', description: 'Education and Training' },
  { code: 'Q', description: 'Health Care and Social Assistance' },
  { code: 'R', description: 'Arts and Recreation Services' },
  { code: 'S', description: 'Other Services' },
];
