import React, { useState, useEffect, useMemo } from 'react';
import {
  Box, Typography, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Grid, Card, CardContent, Button, Divider, Paper, useTheme, Switch, FormControlLabel, Snackbar, Alert
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon, Save as SaveIcon, Cancel as CancelIcon } from '@mui/icons-material';
import bidbudgetService from '../../services/bidbudgetService';
import bidTimeTrackingService from '../../services/bidTimeTrackingService';
import { NumericFormat } from 'react-number-format';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const BidBudget = ({ bidId }) => {
  const theme = useTheme();
  const [budgets, setBudgets] = useState([]);
  const [timeEntries, setTimeEntries] = useState([]);
  const [newItem, setNewItem] = useState({ role: '', hourlyRate: '', totalHours: '', isInternal: false });
  const [editingId, setEditingId] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchBudgets();
    fetchTimeEntries();
  }, [bidId]);

  const fetchBudgets = async () => {
    try {
      const fetchedBudgets = await bidbudgetService.listBidBudgets(bidId);
      setBudgets(fetchedBudgets);
    } catch (error) {
      setError('Error fetching budgets. Please try again.');
    }
  };

  const fetchTimeEntries = async () => {
    try {
      const entries = await bidTimeTrackingService.listTimeEntries(bidId);
      setTimeEntries(entries);
    } catch (error) {
      setError('Error fetching time entries. Please try again.');
    }
  };

  const handleEdit = (budget) => {
    setEditingId(budget.id);
  };

  const handleSave = async (budget) => {
    try {
      let result;
      if (budget.id) {
        result = await bidbudgetService.updateBidBudget(bidId, budget.id, budget);
      } else {
        result = await bidbudgetService.createBidBudget(bidId, budget);
      }
      await fetchBudgets();
      setEditingId(null);
    } catch (error) {
      setError('Error saving budget. Please try again.');
    }
  };

  const handleCancel = () => {
    setEditingId(null);
    fetchBudgets(); // Revert to original data
  };

  const handleDelete = async (budgetId) => {
    if (window.confirm('Are you sure you want to delete this budget item?')) {
      try {
        await bidbudgetService.deleteBidBudget(bidId, budgetId);
        fetchBudgets();
      } catch (error) {
        setError('Error deleting budget. Please try again.');
      }
    }
  };

  const handleInputChange = (e, budget) => {
    const { name, value, checked } = e.target;
    const newValue = name === 'isInternal' ? checked : value;
    if (budget) {
      const updatedBudget = { ...budget, [name]: newValue };
      setBudgets(budgets.map(b => b.id === budget.id ? updatedBudget : b));
    } else {
      setNewItem({ ...newItem, [name]: newValue });
    }
  };

  const handleAddNewItem = () => {
    handleSave(newItem);
    setNewItem({ role: '', hourlyRate: '', totalHours: '', isInternal: false });
  };

  const { totalBudget, totalActual, internalBudget, internalActual, externalBudget, externalActual } = useMemo(() => {
    return budgets.reduce((acc, budget) => {
      const budgetedAmount = parseFloat(budget.hourlyRate) * parseFloat(budget.totalHours) || 0;
      const actualHours = timeEntries
        .filter(entry => entry.role === budget.role)
        .reduce((sum, entry) => sum + parseFloat(entry.hours), 0);
      const actualAmount = actualHours * parseFloat(budget.hourlyRate);

      acc.totalBudget += budgetedAmount;
      acc.totalActual += actualAmount;

      if (budget.isInternal) {
        acc.internalBudget += budgetedAmount;
        acc.internalActual += actualAmount;
      } else {
        acc.externalBudget += budgetedAmount;
        acc.externalActual += actualAmount;
      }

      return acc;
    }, { totalBudget: 0, totalActual: 0, internalBudget: 0, internalActual: 0, externalBudget: 0, externalActual: 0 });
  }, [budgets, timeEntries]);

  const chartData = useMemo(() => {
    return budgets.map(budget => {
      const actualHours = timeEntries
        .filter(entry => entry.role === budget.role)
        .reduce((sum, entry) => sum + parseFloat(entry.hours), 0);
      
      return {
        name: budget.role,
        budgeted: parseFloat(budget.hourlyRate) * parseFloat(budget.totalHours) || 0,
        actual: actualHours * parseFloat(budget.hourlyRate),
        budgetedHours: parseFloat(budget.totalHours) || 0,
        actualHours: actualHours
      };
    });
  }, [budgets, timeEntries]);

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
      <Typography variant="h6" gutterBottom>Bid Budget</Typography>
      
      {/* Combined Budget Summary and Chart */}
      <Card elevation={2} sx={{ mb: 3 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom color="secondary">Budget Summary</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Total Budget:</Typography>
                  <Typography variant="h6" color="primary">${totalBudget.toFixed(2)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Total Spent:</Typography>
                  <Typography variant="h6" color="secondary">${totalActual.toFixed(2)}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Remaining:</Typography>
                  <Typography variant="h6" color={totalBudget - totalActual < 0 ? 'error' : 'success'}>
                    ${(totalBudget - totalActual).toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ my: 1 }} />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Internal:</Typography>
                  <Typography variant="body1" color="info.main">
                    ${internalBudget.toFixed(2)} / ${internalActual.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">External:</Typography>
                  <Typography variant="body1" color="warning.main">
                    ${externalBudget.toFixed(2)} / ${externalActual.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="h6" gutterBottom color="secondary">Budget vs. Actual</Typography>
              <ResponsiveContainer width="100%" height={200}>
                <BarChart data={chartData} layout="vertical" margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" />
                  <YAxis dataKey="name" type="category" width={100} />
                  <Tooltip formatter={(value) => `$${value.toFixed(2)}`} />
                  <Legend />
                  <Bar dataKey="budgeted" fill={theme.palette.primary.main} name="Budgeted" />
                  <Bar dataKey="actual" fill={theme.palette.secondary.main} name="Actual" />
                </BarChart>
              </ResponsiveContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Budget Items Table */}
      <Card elevation={2}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" gutterBottom color="secondary">Budget Items</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddNewItem}
              disabled={!newItem.role || !newItem.hourlyRate || !newItem.totalHours}
              startIcon={<AddIcon />}
            >
              Add Budget Item
            </Button>
          </Box>
          
          <TableContainer component={Paper} sx={{ maxHeight: 400, overflow: 'auto', mb: 2 }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Role</TableCell>
                  <TableCell align="right">Hourly Rate</TableCell>
                  <TableCell align="right">Budgeted Hours</TableCell>
                  <TableCell align="right">Actual Hours</TableCell>
                  <TableCell align="center">Internal</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {budgets.map((budget) => {
                  const isEditing = editingId === budget.id;
                  const actualHours = timeEntries
                    .filter(entry => entry.role === budget.role)
                    .reduce((sum, entry) => sum + parseFloat(entry.hours), 0);
                  return (
                    <TableRow key={budget.id} hover>
                      <TableCell>{isEditing ? <TextField name="role" value={budget.role} onChange={(e) => handleInputChange(e, budget)} size="small" fullWidth /> : budget.role}</TableCell>
                      <TableCell align="right">
                        {isEditing ? (
                          <NumericFormat
                            customInput={TextField}
                            name="hourlyRate"
                            value={budget.hourlyRate}
                            onValueChange={(values) => handleInputChange({ target: { name: 'hourlyRate', value: values.value } }, budget)}
                            thousandSeparator={true}
                            prefix="$"
                            decimalScale={2}
                            allowNegative={false}
                            size="small"
                            fullWidth
                          />
                        ) : (
                          `$${parseFloat(budget.hourlyRate).toFixed(2)}`
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {isEditing ? (
                          <NumericFormat
                            customInput={TextField}
                            name="totalHours"
                            value={budget.totalHours}
                            onValueChange={(values) => handleInputChange({ target: { name: 'totalHours', value: values.value } }, budget)}
                            allowNegative={false}
                            size="small"
                            fullWidth
                          />
                        ) : (
                          budget.totalHours
                        )}
                      </TableCell>
                      <TableCell align="right">{actualHours.toFixed(2)}</TableCell>
                      <TableCell align="center">
                        {isEditing ? (
                          <Switch
                            checked={budget.isInternal}
                            onChange={(e) => handleInputChange(e, budget)}
                            name="isInternal"
                            color="primary"
                          />
                        ) : (
                          budget.isInternal ? 'Yes' : 'No'
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {isEditing ? (
                          <>
                            <IconButton onClick={() => handleSave(budget)} size="small" color="primary"><SaveIcon /></IconButton>
                            <IconButton onClick={handleCancel} size="small" color="error"><CancelIcon /></IconButton>
                          </>
                        ) : (
                          <>
                            <IconButton onClick={() => handleEdit(budget)} size="small" color="primary"><EditIcon /></IconButton>
                            <IconButton onClick={() => handleDelete(budget.id)} size="small" color="error"><DeleteIcon /></IconButton>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Divider sx={{ my: 2 }} />
          
          {/* Add New Budget Item Form */}
          <Typography variant="subtitle1" gutterBottom color="secondary">Add New Budget Item</Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={3}>
              <TextField
                name="role"
                value={newItem.role}
                onChange={(e) => handleInputChange(e)}
                placeholder="Role"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <NumericFormat
                customInput={TextField}
                name="hourlyRate"
                value={newItem.hourlyRate}
                onValueChange={(values) => handleInputChange({ target: { name: 'hourlyRate', value: values.value } })}
                thousandSeparator={true}
                prefix="$"
                decimalScale={2}
                allowNegative={false}
                placeholder="Hourly rate"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <NumericFormat
                customInput={TextField}
                name="totalHours"
                value={newItem.totalHours}
                onValueChange={(values) => handleInputChange({ target: { name: 'totalHours', value: values.value } })}
                allowNegative={false}
                placeholder="Total hours"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControlLabel
                control={
                  <Switch
                    checked={newItem.isInternal}
                    onChange={(e) => handleInputChange(e)}
                    name="isInternal"
                    color="primary"
                  />
                }
                label="Internal"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddNewItem}
                disabled={!newItem.role || !newItem.hourlyRate || !newItem.totalHours}
                startIcon={<AddIcon />}
                fullWidth
              >
                Add Item
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Add Snackbar for error messages - match BidTasks style */}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default BidBudget;