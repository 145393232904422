import React, { useState } from 'react';
import { 
  Paper, Typography, Box, Chip, IconButton, 
  TextField, MenuItem, Button, Grid,
  Tooltip, Divider
} from '@mui/material';
import { 
  Visibility, VisibilityOff, Edit as EditIcon,
  Save as SaveIcon, Cancel as CancelIcon,
  Assignment as AssignmentIcon
} from '@mui/icons-material';

const BidDetails = React.memo(({ 
  bid, 
  ownerDetails,
  onVisibilityChange,
  onUpdateBid,
  bidStatusOptions,
  currentGate,
  onGateChange
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedBid, setEditedBid] = useState(bid);

  const handleEdit = () => {
    setEditedBid(bid);
    setIsEditing(true);
  };

  const handleCancel = () => {
    setEditedBid(bid);
    setIsEditing(false);
  };

  const handleSave = async () => {
    await onUpdateBid(editedBid);
    setIsEditing(false);
  };

  const handleChange = (field) => (event) => {
    setEditedBid(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const gateOptions = [
    { value: 0, label: 'Budget Setup' },
    { value: 1, label: 'Team Assembly' },
    { value: 2, label: 'Strategy Development' },
    { value: 3, label: 'Proposal Development' },
    { value: 4, label: 'Review & Submit' }
  ];

  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Bid Details</Typography>
        {!isEditing ? (
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        ) : (
          <Box>
            <IconButton onClick={handleSave} color="primary">
              <SaveIcon />
            </IconButton>
            <IconButton onClick={handleCancel} color="error">
              <CancelIcon />
            </IconButton>
          </Box>
        )}
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {isEditing ? (
            <TextField
              fullWidth
              label="Bid Name"
              value={editedBid.name}
              onChange={handleChange('name')}
              margin="normal"
            />
          ) : (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" color="text.secondary">Bid Name</Typography>
              <Typography variant="body1">{bid.name}</Typography>
            </Box>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          {isEditing ? (
            <TextField
              fullWidth
              label="Client"
              value={editedBid.client}
              onChange={handleChange('client')}
              margin="normal"
            />
          ) : (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" color="text.secondary">Client</Typography>
              <Typography variant="body1">{bid.client}</Typography>
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ my: 1 }} />
        </Grid>

        <Grid item xs={12} md={6}>
          {isEditing ? (
            <TextField
              select
              fullWidth
              label="Progress Status"
              value={editedBid.currentGate || 0}
              onChange={handleChange('currentGate')}
              margin="normal"
            >
              {gateOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" color="text.secondary">Progress Status</Typography>
              <Chip 
                icon={<AssignmentIcon />}
                label={gateOptions.find(opt => opt.value === currentGate)?.label || 'Not Started'}
                color="primary"
                variant="outlined"
              />
            </Box>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          {isEditing ? (
            <TextField
              select
              fullWidth
              label="Bid Status"
              value={editedBid.status}
              onChange={handleChange('status')}
              margin="normal"
            >
              {bidStatusOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" color="text.secondary">Bid Status</Typography>
              <Chip 
                label={bid.status.charAt(0).toUpperCase() + bid.status.slice(1)}
                color="primary"
                variant="outlined"
              />
            </Box>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          {isEditing ? (
            <TextField
              fullWidth
              label="Due Date"
              type="datetime-local"
              value={editedBid.dueDate}
              onChange={handleChange('dueDate')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          ) : (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" color="text.secondary">Due Date</Typography>
              <Typography variant="body1">
                {new Date(bid.dueDate).toLocaleString()}
              </Typography>
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ my: 1 }} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="text.secondary">Owner</Typography>
            <Typography variant="body1">
              {ownerDetails[bid.owner] ?
                (ownerDetails[bid.owner].name || ownerDetails[bid.owner].email) :
                'Loading...'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="text.secondary">Visibility</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Chip
                icon={bid.visibility === 'organisation' ? <Visibility /> : <VisibilityOff />}
                label={bid.visibility === 'organisation' ? 'Organisation' : 'Invite-only'}
                onClick={() => onVisibilityChange(bid)}
                color={bid.visibility === 'organisation' ? 'primary' : 'default'}
                variant="outlined"
                sx={{ cursor: 'pointer' }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
});

export default BidDetails; 