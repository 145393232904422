import React from 'react';
import { Typography, Box } from '@mui/material';
import PortfolioManager from '../components/Portfolio/PortfolioManager';

function PortfolioPage({ selectedOrg }) {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Portfolio Management
      </Typography>
      <PortfolioManager selectedOrg={selectedOrg} />
    </Box>
  );
}

export default PortfolioPage;
