export const collateralTypes = [
  { value: 'pitchDeck', label: 'Pitch Deck' },
  { value: 'onePager', label: 'One Pager' },
  { value: 'whitePaper', label: 'White Paper' },
  { value: 'casestudy', label: 'Case Study' },
  { value: 'productBrochure', label: 'Product Brochure' },
  { value: 'companyProfile', label: 'Company Profile' },
  { value: 'pressRelease', label: 'Press Release' },
  { value: 'emailCampaign', label: 'Email Campaign' },
  { value: 'socialMediaPost', label: 'Social Media Post' },
];
