import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid,
  Tabs,
  Tab
} from '@mui/material';
import {
  Settings as SettingsIcon,
  Cloud as IntegrationsIcon,
  Security as SecurityIcon,
  Notifications as NotificationsIcon
} from '@mui/icons-material';
import AccountIntegrations from './AccountIntegrations';

const AccountSettings = ({ userData, selectedOrg }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const renderTabContent = () => {
    switch (currentTab) {
      case 0: // General Settings
        return (
          <Box>
            <Typography variant="h6" gutterBottom>General Settings</Typography>
            {/* General settings content */}
          </Box>
        );
      case 1: // Integrations
        return <AccountIntegrations userData={userData} selectedOrg={selectedOrg} />;
      case 2: // Security
        return (
          <Box>
            <Typography variant="h6" gutterBottom>Security Settings</Typography>
            {/* Security settings content */}
          </Box>
        );
      case 3: // Notifications
        return (
          <Box>
            <Typography variant="h6" gutterBottom>Notification Settings</Typography>
            {/* Notification settings content */}
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Paper elevation={3} sx={{ mb: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: 'background.paper' }}>
          <Box sx={{ p: 2 }}>
            <Typography variant="h6">Company Settings</Typography>
          </Box>
          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab icon={<SettingsIcon />} label="General" />
            <Tab icon={<IntegrationsIcon />} label="Integrations" />
            <Tab icon={<SecurityIcon />} label="Security" />
            <Tab icon={<NotificationsIcon />} label="Notifications" />
          </Tabs>
        </Box>
      </Paper>

      <Box sx={{ mt: 3 }}>
        {renderTabContent()}
      </Box>
    </Box>
  );
};

export default AccountSettings; 