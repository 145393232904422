import api from './api';
import { getToken } from './authService';

export const pipedriveService = {
  // Connect Pipedrive integration
  async connect(apiToken, organisationId) {
    try {
      const token = await getToken();
      const response = await api.post('/api/crm/pipedrive/connect', 
        { apiToken }, 
        { 
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'x-organization-id': organisationId
          } 
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error connecting to Pipedrive:', error);
      throw error;
    }
  },

  // Get integration status
  async getStatus(organisationId) {
    try {
      const token = await getToken();
      const response = await api.get('/api/crm/pipedrive/status', {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'x-organization-id': organisationId
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error getting Pipedrive status:', error);
      throw error;
    }
  },

  // Get contacts from Pipedrive
  async getContacts(organisationId, accountId = null) {
    try {
      const token = await getToken();
      const endpoint = accountId ? 
        `/api/crm/pipedrive/contacts/${accountId}` : 
        '/api/crm/pipedrive/contacts';
      
      const response = await api.get(endpoint, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'x-organization-id': organisationId
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error getting Pipedrive contacts:', error);
      return { contacts: [] }; // Return empty array on error
    }
  },

  // Sync all data
  async syncAll(organisationId) {
    try {
      const token = await getToken();
      const response = await api.post('/api/crm/pipedrive/syncAll', null, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'x-organization-id': organisationId
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error syncing Pipedrive data:', error);
      throw error;
    }
  },

  // Sync specific data types
  async syncData(organisationId, dataType) {
    try {
      const token = await getToken();
      const response = await api.post(
        '/api/crm/pipedrive/syncData',
        { type: dataType },
        {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'x-organization-id': organisationId
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error(`Error syncing ${dataType}:`, error);
      throw error;
    }
  },

  // Disconnect integration
  async disconnect(organisationId) {
    try {
      const token = await getToken();
      const response = await api.post('/api/crm/pipedrive/disconnect', null, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'x-organization-id': organisationId
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error disconnecting Pipedrive:', error);
      throw error;
    }
  },

  // Get organizations from Pipedrive
  async getOrganizations(organisationId) {
    try {
      const token = await getToken();
      const response = await api.get('/api/crm/pipedrive/organizations', {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'x-organization-id': organisationId
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error getting Pipedrive organizations:', error);
      return { organizations: [] }; // Return empty array on error
    }
  },

  // Get single organization from Pipedrive
  async getOrganization(organisationId, pipedriveId) {
    try {
      const token = await getToken();
      const response = await api.get(`/api/crm/pipedrive/organizations/${pipedriveId}`, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'x-organization-id': organisationId
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error getting Pipedrive organization:', error);
      throw error;
    }
  },

  // Get deals from Pipedrive
  async getDeals(organisationId) {
    try {
      const token = await getToken();
      const response = await api.get('/api/crm/pipedrive/deals', {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'x-organization-id': organisationId
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error getting Pipedrive deals:', error);
      return { deals: [] }; // Return empty array on error
    }
  },

  // Get pipeline stages from Pipedrive
  async getStages(organisationId) {
    try {
      const token = await getToken();
      const response = await api.get('/api/crm/pipedrive/stages', {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'x-organization-id': organisationId
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error getting Pipedrive stages:', error);
      return { stages: [] }; // Return empty array on error
    }
  },

  // Get single deal from Pipedrive
  async getDeal(organisationId, dealId) {
    try {
      const token = await getToken();
      const response = await api.get(`/api/crm/pipedrive/deals/${dealId}`, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'x-organization-id': organisationId
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error getting Pipedrive deal:', error);
      throw error;
    }
  },

  // List all webhooks
  async listWebhooks(organisationId) {
    try {
      const token = await getToken();
      const response = await api.get('/api/crm/pipedrive/webhooks', {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'x-organization-id': organisationId
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error listing webhooks:', error);
      return { webhooks: [] };
    }
  },

  // Create webhook
  async createWebhook(organisationId, webhookData) {
    try {
      const token = await getToken();
      const response = await api.post('/api/crm/pipedrive/webhooks', webhookData, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'x-organization-id': organisationId
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error creating webhook:', error);
      throw error;
    }
  },

  // Delete webhook
  async deleteWebhook(organisationId, webhookId) {
    try {
      const token = await getToken();
      const response = await api.delete(`/api/crm/pipedrive/webhooks/${webhookId}`, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'x-organization-id': organisationId
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting webhook:', error);
      throw error;
    }
  }
};

export default pipedriveService; 