import React, { useState } from 'react';
import { 
  IconButton, 
  Tooltip, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography, 
  Box,
  TextField,
  Button,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  Chip,
  Stack,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { puawaiCollateralAIService } from '../../services/puawaiCollateralAIService';

const CollateralAssistButton = ({ 
  fieldName, 
  currentValue, 
  onSuggestion, 
  collateralData,
  collateralType,
  tooltip,
  examples,
  onOpenChange
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [aiSuggestions, setAiSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState('');
  const [userPrompt, setUserPrompt] = useState('');
  const [activeContextFields, setActiveContextFields] = useState([]);
  const [activeReferenceUrls, setActiveReferenceUrls] = useState([]);

  const handleOpen = () => {
    setOpen(true);
    onOpenChange(true);
    
    // Set up initial context fields based on dependencies
    const fieldDependencies = {
      headline: ['dateline'],
      leadParagraph: ['dateline', 'headline'],
      bodyParagraphs: ['dateline', 'headline', 'leadParagraph'],
      conclusion: ['dateline', 'headline', 'leadParagraph', 'bodyParagraphs'],
    };

    const availableFields = fieldDependencies[fieldName] || [];
    const initialActiveFields = availableFields.filter(field => collateralData[field]);
    setActiveContextFields(initialActiveFields);
    
    // Set initial active reference URLs
    setActiveReferenceUrls(collateralData.referenceUrls || []);
    
    // Set initial prompt
    const initialPrompt = currentValue ? 
      `Please improve this ${fieldName} while maintaining its core message:\n\n${currentValue}` :
      `Please suggest content for the ${fieldName} field. Consider the following guidance: ${tooltip}`;
    setUserPrompt(initialPrompt);
  };

  const handleClose = () => {
    setOpen(false);
    onOpenChange(false);
  };

  const handleToggleContext = (field) => {
    setActiveContextFields(prev => 
      prev.includes(field) 
        ? prev.filter(f => f !== field)
        : [...prev, field]
    );
  };

  const handleToggleReference = (url) => {
    setActiveReferenceUrls(prev => 
      prev.includes(url) 
        ? prev.filter(u => u !== url)
        : [...prev, url]
    );
  };

  const generatePrompt = (message) => {
    return `Your task is to improve the following ${fieldName} while maintaining its specific subject matter and core message:

Original content:
"${currentValue || "No existing content"}"

Context:
- This is for a ${collateralType}
- Field type: ${fieldName}
- Field guidance: ${tooltip}

Specific requirements:
1. Keep the same core subject matter (in this case about "${currentValue}")
2. Maintain the key elements and facts from the original
3. Enhance the clarity and impact while staying true to the original topic
4. Use the appropriate tone: ${collateralData.toneOfVoice || 'Professional'}
5. Consider the target audience: ${collateralData.targetAudience || 'General audience'}

Additional context from user:
${message}

Please provide three variations that improve the original content while keeping its core subject matter about "${currentValue}". Each variation should be a refined version of the original, not a completely different topic.`;
  };

  const handleGenerateSuggestion = async () => {
    setLoading(true);
    try {
      // Get previous fields' content based on active context fields
      const previousFields = {};
      activeContextFields.forEach(field => {
        if (collateralData[field]) {
          previousFields[field] = collateralData[field];
        }
      });

      // Define field dependencies
      const fieldDependencies = {
        headline: ['dateline'],
        leadParagraph: ['dateline', 'headline'],
        bodyParagraphs: ['dateline', 'headline', 'leadParagraph'],
        conclusion: ['dateline', 'headline', 'leadParagraph', 'bodyParagraphs'],
      };

      // Get previous fields' content
      const requiredFields = fieldDependencies[fieldName] || [];
      
      // Log the current state for debugging
      console.log('Current collateralData:', collateralData);
      console.log('Required fields:', requiredFields);

      requiredFields.forEach(field => {
        if (collateralData[field]) {
          previousFields[field] = collateralData[field];
          console.log(`Found ${field}:`, collateralData[field]); // Debug log
        }
      });

      const contextData = {
        toneOfVoice: 'professional',
        purpose: 'marketing',
        targetAudience: collateralData.targetAudience || '',
        keyMessages: collateralData.keyPoints || '',
        callToAction: collateralData.callToAction || '',
        currentValue: currentValue,
        previousFields: previousFields,
        referenceUrls: activeReferenceUrls // Use only active reference URLs
      };

      console.log('Sending contextData:', contextData); // Debug log

      const collateralInfo = {
        type: collateralType,
        title: collateralData.title || 'Untitled Collateral',
        currentValue: currentValue,
        previousFields: previousFields
      };

      const suggestions = await puawaiCollateralAIService.generateCollateralDescription(
        collateralInfo,
        { 
          organisationData: { id: collateralData.organisationId },
          language: navigator.language || 'en',
          locale: navigator.language || 'en-NZ'
        },
        contextData,
        fieldName,
        tooltip,
        examples || []
      );

      setAiSuggestions(suggestions);
      setSelectedSuggestion(suggestions[0]);
    } catch (error) {
      console.error('Error generating AI suggestion:', error);
      setAiSuggestions(['Error generating suggestion. Please try again.']);
    } finally {
      setLoading(false);
    }
  };

  const handleApplySuggestion = () => {
    onSuggestion(selectedSuggestion);
    handleClose();
  };

  return (
    <>
      <Tooltip title={currentValue ? "Improve existing content" : "Get content suggestions"}>
        <IconButton onClick={handleOpen} size="small" color="primary">
          <FontAwesomeIcon icon={faSeedling} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          Puāwai Intelligence Collateral Assistant
          <Tooltip title={tooltip} placement="right">
            <IconButton size="small" style={{ marginLeft: '10px' }}>
              <FontAwesomeIcon icon={faInfoCircle} />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          {/* Context Chips */}
          {Object.keys(collateralData).length > 0 && (
            <Box mb={2}>
              <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                Context from previous fields:
              </Typography>
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {Object.entries(collateralData)
                  .filter(([key]) => key !== fieldName && collateralData[key])
                  .map(([key, value]) => (
                    <Chip
                      key={key}
                      label={key}
                      color={activeContextFields.includes(key) ? "primary" : "default"}
                      onClick={() => handleToggleContext(key)}
                      onDelete={() => handleToggleContext(key)}
                      sx={{ m: 0.5 }}
                    />
                  ))}
              </Stack>
            </Box>
          )}

          {/* Reference URL Chips */}
          {collateralData.referenceUrls?.length > 0 && (
            <Box mb={2}>
              <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                Reference Content:
              </Typography>
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {collateralData.referenceUrls.map((url, index) => (
                  <Chip
                    key={index}
                    label={`Reference ${index + 1}`}
                    color={activeReferenceUrls.includes(url) ? "primary" : "default"}
                    onClick={() => handleToggleReference(url)}
                    onDelete={() => handleToggleReference(url)}
                    sx={{ m: 0.5 }}
                    icon={
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(url, '_blank');
                        }}
                      >
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </IconButton>
                    }
                  />
                ))}
              </Stack>
            </Box>
          )}

          {/* Current Content Display */}
          <Box mb={2}>
            <Typography variant="subtitle2" color="textSecondary">Current Content:</Typography>
            <Typography variant="body1" 
              sx={{ 
                bgcolor: 'grey.100', 
                p: 2, 
                borderRadius: 1,
                whiteSpace: 'pre-wrap'
              }}
            >
              {currentValue || "No existing content"}
            </Typography>
          </Box>

          <TextField
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            label="Additional guidance for improvement (optional)"
            value={userPrompt}
            onChange={(e) => setUserPrompt(e.target.value)}
            margin="normal"
            placeholder="Add any specific requirements or aspects you'd like to improve..."
          />
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleGenerateSuggestion}
            disabled={loading}
            sx={{ mt: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : 'Generate Improvements'}
          </Button>
          {aiSuggestions.length > 0 && (
            <Box mt={2}>
              <Typography variant="h6">Suggested Improvements:</Typography>
              <Box sx={{ 
                maxHeight: '300px', // Set maximum height
                overflowY: 'auto', // Enable vertical scrolling
                mt: 1,
                pr: 1, // Add some padding for the scrollbar
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#888',
                  borderRadius: '4px',
                  '&:hover': {
                    background: '#666',
                  },
                },
              }}>
                <RadioGroup
                  value={selectedSuggestion}
                  onChange={(e) => setSelectedSuggestion(e.target.value)}
                >
                  {aiSuggestions.map((suggestion, index) => (
                    <FormControlLabel
                      key={index}
                      value={suggestion}
                      control={<Radio />}
                      label={
                        <Box sx={{ 
                          p: 1, 
                          '&:hover': { bgcolor: 'grey.50' },
                          wordBreak: 'break-word' // Handle long text
                        }}>
                          <Typography variant="body1">{suggestion}</Typography>
                        </Box>
                      }
                    />
                  ))}
                </RadioGroup>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleApplySuggestion} 
            color="primary" 
            disabled={!selectedSuggestion}
            variant="contained"
          >
            Apply Selected Improvement
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CollateralAssistButton;
