import api from './api';
import { getToken } from './authService';
import { stripeService } from './stripeService';

export const subscriptionService = {
  // Get user's subscription status
  getUserSubscriptionStatus: async (userId) => {
    try {
      const token = await getToken();
      const response = await api.get(`/api/subscriptions?userId=${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const status = response.data.subscriptionStatus;
      return {
        ...status,
        stripeSubscriptionId: status.id,
        cancelAtPeriodEnd: status.cancelAtPeriodEnd
      };
    } catch (error) {
      if (error.response?.status === 404) {
        return {
          status: 'inactive',
          planId: null,
          trialEndDate: null,
          cancelAtPeriodEnd: false
        };
      }
      console.error('Error fetching subscription status:', error);
      throw error;
    }
  },

  // Create new subscription
  createSubscription: async (paymentMethodId, priceId, firstName, lastName, email, userId) => {
    try {
      // First create or update the customer
      const customerResponse = await stripeService.createOrUpdateCustomer({
        firstName,
        lastName,
        email,
        userId
      });

      // Attach the payment method to the customer
      await stripeService.attachPaymentMethod(paymentMethodId, customerResponse.id);

      // Set it as the default payment method
      await stripeService.updateDefaultPaymentMethod(customerResponse.id, paymentMethodId);

      // Check if user has an active subscription
      const currentSubscription = await subscriptionService.getUserSubscriptionStatus(userId);
      
      if (currentSubscription?.status === 'active') {
        // If upgrading, use the new payment method
        try {
          const response = await api.post('/api/subscriptions/upgrade', {
            paymentMethodId,
            newPriceId: priceId,
            customer: {
              id: customerResponse.id,
              firstName,
              lastName,
              email,
              userId
            },
            currentSubscriptionId: currentSubscription.subscriptionId
          });
          return response.data;
        } catch (upgradeError) {
          console.error('Error upgrading subscription:', upgradeError);
          throw upgradeError;
        }
      } else {
        // If no active subscription, create a new one
        const response = await api.post('/api/subscriptions', {
          paymentMethodId,
          priceId,
          customer: {
            id: customerResponse.id,
            firstName,
            lastName,
            email,
            userId
          }
        });

        if (response.data.success) {
          return response.data;
        } else {
          throw new Error(response.data.error?.message || 'Subscription creation failed');
        }
      }
    } catch (error) {
      console.error('Error creating/upgrading subscription:', error);
      throw error;
    }
  },

  // Update existing subscription
  updateSubscription: async (subscriptionId, updateData) => {
    try {
      const token = await getToken();
      const response = await api.put(`/api/subscriptions/${subscriptionId}`, updateData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating subscription:', error);
      throw error;
    }
  },

  // Cancel subscription
  cancelSubscription: async (subscriptionId) => {
    try {
      const token = await getToken();
      const response = await api.post('/api/subscriptions/cancel', {
        subscriptionId
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error canceling subscription:', error);
      throw error;
    }
  },

  // Reactivate subscription
  reactivateSubscription: async (subscriptionId) => {
    try {
      const token = await getToken();
      const response = await api.post('/api/subscriptions/reactivate', {
        subscriptionId
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error reactivating subscription:', error);
      throw error;
    }
  },

  // Change subscription plan
  changePlan: async (subscriptionId, newPlanId) => {
    try {
      const token = await getToken();
      const response = await api.post(`/api/subscriptions/${subscriptionId}/change-plan`, {
        newPlanId
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error changing subscription plan:', error);
      throw error;
    }
  },

  // Add a new method for handling payment method updates
  updatePaymentMethod: async (customerId, paymentMethodId) => {
    try {
      // Attach the payment method to the customer
      await stripeService.attachPaymentMethod(paymentMethodId, customerId);
      
      // Set it as the default payment method
      await stripeService.updateDefaultPaymentMethod(customerId, paymentMethodId);
      
      return { success: true };
    } catch (error) {
      console.error('Error updating payment method:', error);
      throw error;
    }
  },

  // Update the invoice-related methods
  getInvoices: async (subscriptionId) => {
    try {
      const token = await getToken();
      const response = await api.get(`/api/subscriptions/${subscriptionId}/invoices`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      console.log('Raw invoice response:', response);
      
      if (response?.data?.success && Array.isArray(response.data.data)) {
        console.log('Parsed invoices:', response.data.data);
        return {
          success: true,
          data: response.data.data
        };
      }
      
      console.warn('Unexpected invoice response structure:', response);
      return {
        success: false,
        data: []
      };
    } catch (error) {
      console.error('Error fetching invoices:', error);
      throw error;
    }
  },

  downloadInvoice: async (invoiceId) => {
    try {
      const token = await getToken();
      const response = await api.get(`/api/subscriptions/invoice/${invoiceId}/pdf`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob'
      });
      return response;
    } catch (error) {
      console.error('Error downloading invoice:', error);
      throw error;
    }
  },

  // Add this method to subscriptionService
  getSubscriptionDetails: async (subscriptionId) => {
    try {
      const token = await getToken();
      const response = await api.get(`/api/subscriptions/${subscriptionId}/details`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching subscription details:', error);
      throw error;
    }
  }
};

export const {
  getUserSubscriptionStatus,
  createSubscription,
  updateSubscription,
  cancelSubscription,
  reactivateSubscription,
  changePlan,
  updatePaymentMethod,
  getInvoices,
  downloadInvoice
} = subscriptionService;

export default subscriptionService;