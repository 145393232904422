import React from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  Divider,
  LinearProgress,
  Avatar,
  AvatarGroup,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar
} from '@mui/material';
import {
  TrendingUp,
  People,
  AttachMoney,
  Loyalty,
  Assessment,
  Timeline,
  AccountCircle,
  Business,
  CalendarToday,
  ArrowUpward,
  ArrowDownward
} from '@mui/icons-material';

const CustomerLifecycleDashboard = ({ userData, selectedOrg }) => {
  const { selectedAccount } = useOutletContext();

  // Mock data for all accounts view
  const allAccountsMetrics = {
    totalAccounts: 156,
    activeAccounts: 134,
    totalRevenue: '$2.45M',
    growthRate: '+12%',
    topAccounts: [
      { id: 1, name: 'Acme Corp', revenue: '$500K', growth: '+15%' },
      { id: 2, name: 'Tech Solutions', revenue: '$350K', growth: '+8%' },
      { id: 3, name: 'Global Industries', revenue: '$300K', growth: '+5%' }
    ],
    recentActivity: [
      { id: 1, type: 'New Deal', account: 'Acme Corp', value: '$50K', date: '2h ago' },
      { id: 2, type: 'Meeting', account: 'Tech Solutions', value: 'Product Demo', date: '4h ago' },
      { id: 3, type: 'Proposal', account: 'Global Industries', value: '$75K', date: '1d ago' }
    ],
    accountsByStage: {
      prospect: { count: 45, value: '$2.1M', lastUpdated: '2 days ago' },
      qualified: { count: 32, value: '$1.5M', lastUpdated: '1 day ago' },
      negotiation: { count: 15, value: '$750K', lastUpdated: '5 hours ago' },
      closed: { count: 42, value: '$3.2M', lastUpdated: '1 week ago' }
    },
    accountLifecycle: {
      upsellOpportunities: { count: 12, value: '$450K' },
      crossSellOpportunities: { count: 8, value: '$320K' },
      atRisk: { count: 5, value: '$280K' },
      recentMeetings: [
        { account: 'Acme Corp', date: '2024-01-20', type: 'Quarterly Review' },
        { account: 'Tech Solutions', date: '2024-01-19', type: 'Upsell Discussion' },
        { account: 'Global Industries', date: '2024-01-18', type: 'Product Demo' }
      ]
    }
  };

  // Mock data for single account view
  const singleAccountMetrics = {
    acquisition: {
      title: 'Acquisition',
      icon: <TrendingUp color="primary" />,
      stats: [
        { label: 'New Leads', value: '12', trend: '+20%' },
        { label: 'Conversion Rate', value: '2.4%', trend: '+5%' }
      ]
    },
    engagement: {
      title: 'Engagement',
      icon: <People color="primary" />,
      stats: [
        { label: 'Active Users', value: '45', trend: '+15%' },
        { label: 'Engagement Rate', value: '68%', trend: '+8%' }
      ]
    },
    conversion: {
      title: 'Conversion',
      icon: <AttachMoney color="primary" />,
      stats: [
        { label: 'Revenue', value: '$12,450', trend: '+25%' },
        { label: 'Avg Deal Size', value: '$2,800', trend: '+10%' }
      ]
    },
    retention: {
      title: 'Retention',
      icon: <Loyalty color="primary" />,
      stats: [
        { label: 'Retention Rate', value: '85%', trend: '+5%' },
        { label: 'Churn Rate', value: '15%', trend: '-2%' }
      ]
    }
  };

  const renderAllAccountsView = () => (
    <Box sx={{ p: 3 }}>
      {/* Top Level Metrics */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2, textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom>Total Accounts</Typography>
            <Typography variant="h3" color="primary">{allAccountsMetrics.totalAccounts}</Typography>
            <Typography variant="body2" color="text.secondary">
              {allAccountsMetrics.activeAccounts} Active
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2, textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom>Total Revenue</Typography>
            <Typography variant="h3" color="primary">{allAccountsMetrics.totalRevenue}</Typography>
            <Typography variant="body2" color="success.main">
              {allAccountsMetrics.growthRate} YoY
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Accounts by Stage</Typography>
            <Box sx={{ mt: 2 }}>
              {Object.entries(allAccountsMetrics.accountsByStage).map(([stage, data]) => (
                <Box key={stage} sx={{ mb: 1 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                    <Typography variant="body2">{stage.charAt(0).toUpperCase() + stage.slice(1)}</Typography>
                    <Typography variant="body2">{data.count}</Typography>
                  </Box>
                  <LinearProgress 
                    variant="determinate" 
                    value={(data.count / allAccountsMetrics.totalAccounts) * 100} 
                    sx={{ height: 8, borderRadius: 4 }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
                    <Typography variant="caption" color="text.secondary">
                      Value: {data.value}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      Updated: {data.lastUpdated}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Top Accounts and Recent Activity */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Top Performing Accounts</Typography>
            <List>
              {allAccountsMetrics.topAccounts.map((account) => (
                <ListItem key={account.id}>
                  <ListItemAvatar>
                    <Avatar><Business /></Avatar>
                  </ListItemAvatar>
                  <ListItemText 
                    primary={account.name}
                    secondary={`Revenue: ${account.revenue}`}
                  />
                  <Typography color="success.main">{account.growth}</Typography>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Recent Activity</Typography>
            <List>
              {allAccountsMetrics.recentActivity.map((activity) => (
                <ListItem key={activity.id}>
                  <ListItemAvatar>
                    <Avatar>{activity.type === 'New Deal' ? <AttachMoney /> : <CalendarToday />}</Avatar>
                  </ListItemAvatar>
                  <ListItemText 
                    primary={activity.account}
                    secondary={`${activity.type}: ${activity.value}`}
                  />
                  <Typography color="text.secondary">{activity.date}</Typography>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>

      {/* Enhanced Lifecycle Section */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Account Lifecycle Overview</Typography>
            <Grid container spacing={2}>
              {Object.entries(allAccountsMetrics.accountsByStage).map(([stage, data]) => (
                <Grid item xs={12} sm={6} key={stage}>
                  <Box sx={{ mb: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                      <Typography variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
                        {stage}
                      </Typography>
                      <Typography variant="subtitle2" color="primary">
                        {data.count} Accounts
                      </Typography>
                    </Box>
                    <LinearProgress 
                      variant="determinate" 
                      value={(data.count / allAccountsMetrics.totalAccounts) * 100} 
                      sx={{ height: 8, borderRadius: 4, mb: 1 }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                      <Typography variant="body2" color="text.secondary">
                        Value: {data.value}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Updated: {data.lastUpdated}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <Divider sx={{ my: 3 }} />

            {/* Opportunity Tracking */}
            <Typography variant="h6" gutterBottom>Growth Opportunities</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Paper elevation={1} sx={{ p: 2, bgcolor: 'background.default' }}>
                  <Typography variant="subtitle2">Upsell Opportunities</Typography>
                  <Typography variant="h4" color="primary">
                    {allAccountsMetrics.accountLifecycle.upsellOpportunities.count}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Potential Value: {allAccountsMetrics.accountLifecycle.upsellOpportunities.value}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper elevation={1} sx={{ p: 2, bgcolor: 'background.default' }}>
                  <Typography variant="subtitle2">Cross-sell Opportunities</Typography>
                  <Typography variant="h4" color="primary">
                    {allAccountsMetrics.accountLifecycle.crossSellOpportunities.count}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Potential Value: {allAccountsMetrics.accountLifecycle.crossSellOpportunities.value}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Recent Activity and Risk Assessment */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>Account Health</Typography>
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle2" color="error">
                At Risk Accounts: {allAccountsMetrics.accountLifecycle.atRisk.count}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Value at Risk: {allAccountsMetrics.accountLifecycle.atRisk.value}
              </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Typography variant="subtitle2" gutterBottom>Recent Meetings</Typography>
            <List dense>
              {allAccountsMetrics.accountLifecycle.recentMeetings.map((meeting, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={meeting.account}
                    secondary={
                      <Box>
                        <Typography variant="body2" color="text.secondary">
                          {meeting.type}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {meeting.date}
                        </Typography>
                      </Box>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Paper>

          {/* Quick Actions for Lifecycle Management */}
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Lifecycle Actions</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Button variant="outlined" startIcon={<Assessment />}>
                Run Health Check
              </Button>
              <Button variant="outlined" startIcon={<Timeline />}>
                View Pipeline
              </Button>
              <Button variant="outlined" startIcon={<TrendingUp />}>
                Growth Planning
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );

  const renderSingleAccountView = () => (
    <Box sx={{ p: 3 }}>
      {/* Account Header */}
      <Paper sx={{ p: 3, mb: 3, background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', color: 'white' }}>
          <Avatar sx={{ width: 64, height: 64, mr: 2 }}><Business sx={{ fontSize: 40 }} /></Avatar>
          <Box>
            <Typography variant="h4">{selectedAccount.name}</Typography>
            <Typography variant="subtitle1">Revenue: {selectedAccount.revenue}</Typography>
          </Box>
        </Box>
      </Paper>

      {/* Metrics Grid */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        {Object.values(singleAccountMetrics).map((metric) => (
          <Grid item xs={12} md={3} key={metric.title}>
            <Paper sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                {metric.icon}
                <Typography variant="h6" sx={{ ml: 1 }}>{metric.title}</Typography>
              </Box>
              <Divider sx={{ my: 1 }} />
              {metric.stats.map((stat) => (
                <Box key={stat.label} sx={{ mt: 2 }}>
                  <Typography variant="body2" color="text.secondary">{stat.label}</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="h5">{stat.value}</Typography>
                    <Typography 
                      variant="body2" 
                      color={stat.trend.startsWith('+') ? 'success.main' : 'error.main'}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      {stat.trend.startsWith('+') ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />}
                      {stat.trend}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Quick Actions */}
      <Paper sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>Quick Actions</Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button variant="contained" startIcon={<Assessment />}>View Reports</Button>
          <Button variant="outlined" startIcon={<Timeline />}>Track Progress</Button>
          <Button variant="outlined" startIcon={<People />}>Manage Team</Button>
        </Box>
      </Paper>
    </Box>
  );

  return selectedAccount ? renderSingleAccountView() : renderAllAccountsView();
};

export default CustomerLifecycleDashboard; 