import api from './api';
import { getAuthHeader } from './authService';
import { getToneOfVoice } from './brandBuilderService';

const apiCall = async (method, endpoint, data = null) => {
  try {
    const token = await getAuthHeader();
    const config = {
      method,
      url: `/api${endpoint}`,
      headers: { 
        Authorization: token,
        'Content-Type': 'application/json'
      },
    };
    if (data) {
      config.data = data;
    }
    const response = await api(config);
    return response.data;
  } catch (error) {
    console.error(`Error in ${method} ${endpoint}:`, error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }
    throw new Error(`API error: ${error.response?.data?.message || error.message}`);
  }
};

export const puawaiCollateralAIService = {
  generateCollateralDescription: async (collateralInfo, userInfo, contextData, fieldName, fieldTooltip, fieldExamples) => {
    try {
      let toneOfVoice = contextData.toneOfVoice;
      if (toneOfVoice === 'custom') {
        const orgToneOfVoice = await getToneOfVoice(userInfo.organisationData.id);
        toneOfVoice = orgToneOfVoice || 'default';
      }

      // Pass the complete collateral data for context
      const response = await apiCall('post', '/collateral-ai/generate-description', {
        collateralInfo: {
          ...collateralInfo,
          currentValue: contextData.currentValue,
          // Include all existing field values for context
          ...contextData.allFields || {}
        },
        userInfo,
        contextData: {
          ...contextData,
          toneOfVoice,
        },
        fieldName,
        fieldTooltip,
        fieldExamples: fieldExamples.map(example => ({
          format: example,
          explanation: "This shows the structure and style to follow"
        })),
        language: userInfo.language,
        locale: userInfo.locale
      });
      
      if (response && response.descriptions) {
        return response.descriptions;
      } else {
        throw new Error('Invalid response format from server');
      }
    } catch (error) {
      console.error('Error generating collateral description:', error);
      throw error;
    }
  }
};

// Example function to get suggestions
async function getSuggestions(inputText, context) {
  const prompt = `
    You are a creative assistant specializing in marketing collateral. Given the following context: "${context}", generate a list of three compelling and relevant headlines. Each suggestion should be concise, engaging, and directly related to the context provided. Use a tone that is ${context.toneOfVoice} and consider the target audience: ${context.targetAudience}.
    
    Context: ${inputText}
    
    Suggestions:
  `;

  // Call to AI service with the improved prompt
  const response = await apiCall('post', '/collateral-ai/generate-description', {
    collateralInfo: inputText,
    userInfo: { language: 'en', locale: 'en-US' },
    contextData: { 
      toneOfVoice: 'default',
      targetAudience: 'basketball enthusiasts and community members'
    },
    fieldName: 'headline',
    fieldTooltip: 'A brief description of the headline',
    fieldExamples: ['Example headline 1', 'Example headline 2', 'Example headline 3'],
    language: 'en',
    locale: 'en-US'
  });
  return response.descriptions;
}

export default puawaiCollateralAIService;
