import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import {
  CheckCircle as CompleteIcon,
  RadioButtonUnchecked as PendingIcon,
  Assignment as TaskIcon,
  AttachFile as DocumentIcon,
  Schedule as TimelineIcon,
} from '@mui/icons-material';
import { fundraisingService } from '../../services/fundraisingService';

const FundingStages = ({ pathType, fundraisingId, selectedOrg }) => {
  const [activeStage, setActiveStage] = useState(0);
  const [stages, setStages] = useState([]);
  const [stageDialog, setStageDialog] = useState({
    open: false,
    stage: null,
    type: ''
  });

  const stageConfigs = {
    'commercial': [
      {
        label: 'Initial Assessment',
        tasks: ['Market analysis', 'Financial projections', 'Business plan review'],
        documents: ['Executive summary', 'Financial statements', 'Business plan'],
        timeline: '2-4 weeks'
      },
      {
        label: 'Investor Pitch',
        tasks: ['Pitch deck creation', 'Valuation analysis', 'Term sheet preparation'],
        documents: ['Pitch deck', 'Valuation report', 'Draft term sheet'],
        timeline: '4-6 weeks'
      },
      {
        label: 'Due Diligence',
        tasks: ['Legal review', 'Financial audit', 'Technical assessment'],
        documents: ['Due diligence report', 'Compliance certificates', 'Technical documentation'],
        timeline: '6-8 weeks'
      },
      {
        label: 'Deal Closure',
        tasks: ['Negotiation', 'Documentation', 'Closing conditions'],
        documents: ['Final agreements', 'Closing documents', 'Banking instructions'],
        timeline: '2-4 weeks'
      }
    ],
    'non-profit': [
      {
        label: 'Foundation Research',
        tasks: ['Identify potential foundations', 'Research funding priorities', 'Review eligibility criteria'],
        documents: ['Foundation profiles', 'Program alignment analysis', 'Organization capability statement'],
        timeline: '2-3 weeks'
      },
      {
        label: 'Grant Application',
        tasks: ['Write proposal narrative', 'Develop budget', 'Gather support documents'],
        documents: ['Grant proposal', 'Budget spreadsheet', 'Letters of support'],
        timeline: '4-6 weeks'
      },
      {
        label: 'Due Diligence',
        tasks: ['Submit application', 'Respond to inquiries', 'Site visits'],
        documents: ['Application package', 'Additional documentation', 'Site visit reports'],
        timeline: '6-8 weeks'
      },
      {
        label: 'Grant Management',
        tasks: ['Set up tracking', 'Implementation planning', 'Reporting schedule'],
        documents: ['Grant agreement', 'Project timeline', 'Reporting templates'],
        timeline: '12+ months'
      }
    ],
    'government': [
      {
        label: 'Opportunity Identification',
        tasks: ['Monitor funding announcements', 'Review RFP/grant guidelines', 'Assess eligibility'],
        documents: ['RFP documentation', 'Eligibility checklist', 'Capability statement'],
        timeline: '2-3 weeks'
      },
      {
        label: 'Proposal Development',
        tasks: ['Technical writing', 'Budget development', 'Compliance review'],
        documents: ['Technical proposal', 'Cost proposal', 'Compliance matrix'],
        timeline: '6-8 weeks'
      },
      {
        label: 'Submission Process',
        tasks: ['Internal review', 'Final assembly', 'Submit application'],
        documents: ['Final proposal', 'Required forms', 'Supporting documents'],
        timeline: '2-3 weeks'
      },
      {
        label: 'Post-Award',
        tasks: ['Contract negotiation', 'Project setup', 'Reporting setup'],
        documents: ['Award documents', 'Project plan', 'Compliance documents'],
        timeline: 'Ongoing'
      }
    ]
  };

  useEffect(() => {
    const fetchStages = async () => {
      try {
        const response = await fundraisingService.getStageDetails(fundraisingId);
        setStages(response);
      } catch (error) {
        console.error('Error fetching stages:', error);
      }
    };

    if (fundraisingId) {
      fetchStages();
    }
  }, [fundraisingId]);

  const handleStageComplete = async (stageIndex) => {
    try {
      await fundraisingService.updateStageProgress(fundraisingId, stageIndex, {
        status: 'completed',
        completedAt: new Date().toISOString()
      });
      // Refresh stages
      const updatedStages = await fundraisingService.getStageDetails(fundraisingId);
      setStages(updatedStages);
    } catch (error) {
      console.error('Error updating stage:', error);
    }
  };

  const handleOpenDialog = (stage, type) => {
    setStageDialog({
      open: true,
      stage,
      type
    });
  };

  const handleCloseDialog = () => {
    setStageDialog({
      open: false,
      stage: null,
      type: ''
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Paper elevation={2} sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Funding Journey Progress
        </Typography>
        
        <Stepper activeStep={activeStage} orientation="vertical">
          {stageConfigs[pathType]?.map((stage, index) => (
            <Step key={index}>
              <StepLabel>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant="subtitle1">{stage.label}</Typography>
                  <Chip 
                    label={stages[index]?.status || 'Pending'}
                    color={stages[index]?.status === 'completed' ? 'success' : 'default'}
                    size="small"
                  />
                </Box>
              </StepLabel>
              <StepContent>
                <Box sx={{ mb: 2 }}>
                  <List dense>
                    <ListItem>
                      <ListItemIcon><TaskIcon /></ListItemIcon>
                      <ListItemText 
                        primary="Required Tasks"
                        secondary={`${stage.tasks.length} tasks`}
                      />
                      <Button 
                        size="small" 
                        onClick={() => handleOpenDialog(stage, 'tasks')}
                      >
                        View Tasks
                      </Button>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon><DocumentIcon /></ListItemIcon>
                      <ListItemText 
                        primary="Required Documents"
                        secondary={`${stage.documents.length} documents`}
                      />
                      <Button 
                        size="small"
                        onClick={() => handleOpenDialog(stage, 'documents')}
                      >
                        View Documents
                      </Button>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon><TimelineIcon /></ListItemIcon>
                      <ListItemText 
                        primary="Estimated Timeline"
                        secondary={stage.timeline}
                      />
                    </ListItem>
                  </List>
                  <Box sx={{ mb: 2 }}>
                    <LinearProgress 
                      variant="determinate" 
                      value={stages[index]?.progress || 0}
                      sx={{ mt: 2 }}
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      variant="contained"
                      onClick={() => handleStageComplete(index)}
                      disabled={stages[index]?.status === 'completed'}
                    >
                      Complete Stage
                    </Button>
                  </Box>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Paper>

      <Dialog 
        open={stageDialog.open} 
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {stageDialog.type === 'tasks' ? 'Required Tasks' : 'Required Documents'}
        </DialogTitle>
        <DialogContent>
          <List>
            {stageDialog.stage?.[stageDialog.type === 'tasks' ? 'tasks' : 'documents'].map((item, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  {stageDialog.type === 'tasks' ? <TaskIcon /> : <DocumentIcon />}
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FundingStages; 