import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import PageHeader from '../../components/PageHeader/PageHeader';
import Footer from '../../components/Footer/Footer';

function AboutUs({ onLoginClick }) {
  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      minHeight: '100vh',
      backgroundColor: 'background.default',
    }}>
      <PageHeader onLoginClick={onLoginClick} />
      <Container maxWidth="md" sx={{ mt: 12, mb: 6, flexGrow: 1 }}>
        <Typography variant="h2" component="h1" gutterBottom>
          Our Story
        </Typography>
        <Typography variant="body1" paragraph>
          Puāwai was born from a vision to empower businesses with the tools they need to grow and thrive in today's competitive landscape. Our journey began when we recognised the challenges that many organisations face in managing their growth strategies and complex bidding processes.
        </Typography>
        <Typography variant="body1" paragraph>
          Inspired by the Māori word 'Puāwai', which means to blossom or come to fruition, we set out to create a platform that nurtures business growth and helps companies realise their full potential. Our team of dedicated professionals combines expertise in business strategy, software development, and user experience design to deliver a comprehensive solution that addresses the unique needs of growing businesses.
        </Typography>
        <Typography variant="body1">
          Today, Puāwai stands as a testament to our commitment to innovation and excellence. We continue to evolve our platform, always with the goal of providing our clients with the most effective tools to navigate their growth journey and achieve success.
        </Typography>
      </Container>
      <Footer />
    </Box>
  );
}

export default AboutUs;