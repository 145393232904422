import React from 'react';
import { Box, Button } from '@mui/material';
import {
  Add as AddIcon,
  Description as DocumentIcon,
  AutoAwesome as TemplateIcon,
  FileCopy as CreateFromTemplateIcon
} from '@mui/icons-material';

export const ActionButtons = ({ 
  onCreateCollateral, 
  onCreateTemplate,
  onCreateFromTemplate
}) => {
  return (
    <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
      <Button
        variant="contained"
        startIcon={<DocumentIcon />}
        onClick={onCreateCollateral}
      >
        Create Document
      </Button>

      <Button
        variant="contained"
        startIcon={<CreateFromTemplateIcon />}
        onClick={onCreateFromTemplate}
      >
        Create from Template
      </Button>

      <Button
        variant="contained"
        startIcon={<TemplateIcon />}
        onClick={onCreateTemplate}
      >
        Create Template
      </Button>
    </Box>
  );
}; 