export const SectionTypes = {
  HEADER: {
    value: 'header',
    label: 'Header Section',
    description: 'Add a title and optional subtitle',
    defaultContent: {
      title: '',
      subtitle: '',
      alignment: 'left',
      includeLogo: true
    }
  },
  TEXT: {
    value: 'text',
    label: 'Text Section',
    description: 'Add paragraphs of text content',
    defaultContent: {
      text: '',
      format: 'paragraph'
    }
  },
  IMAGE: {
    value: 'image',
    label: 'Image Section',
    description: 'Add an image',
    defaultContent: {
      imageUrl: ''
    }
  },
  TABLE: {
    value: 'table',
    label: 'Table Section',
    description: 'Add a table',
    defaultContent: {
      tableData: []
    }
  },
  LIST: {
    value: 'list',
    label: 'List Section',
    description: 'Add a list',
    defaultContent: {
      items: []
    }
  },
  DYNAMIC: {
    value: 'dynamic',
    label: 'Dynamic Section',
    description: 'Add a dynamic section',
    defaultContent: {
      content: ''
    }
  },
  EXECUTIVE_SUMMARY: {
    value: 'executiveSummary',
    label: 'Executive Summary',
    description: 'Overview of the proposal with key points',
    defaultContent: {
      title: 'Executive Summary',
      content: '',
      aiRules: {
        useToneOfVoice: true,
        includeContext: true,
        focusPoints: ['problem', 'solution', 'value']
      },
      dataMapping: {
        client: '${customer.name}',
        opportunity: '${opportunity.title}',
        value: '${opportunity.value}'
      }
    }
  },
  SOLUTION_OVERVIEW: {
    value: 'solutionOverview',
    label: 'Solution Overview',
    description: 'Detailed description of proposed solution',
    defaultContent: {
      title: 'Our Solution',
      content: '',
      aiRules: {
        useToneOfVoice: true,
        includePortfolio: true
      },
      dataMapping: {
        products: '${portfolio.relevantProducts}',
        services: '${portfolio.services}'
      }
    }
  },
  CASE_STUDIES: {
    value: 'caseStudies',
    label: 'Case Studies',
    description: 'Relevant success stories',
    defaultContent: {
      title: 'Success Stories',
      maxCases: 2,
      aiRules: {
        filterByIndustry: true,
        filterBySize: true
      },
      dataMapping: {
        industry: '${customer.industry}',
        caseStudies: '${portfolio.caseStudies}'
      }
    }
  },
  PRICING_TABLE: {
    value: 'pricingTable',
    label: 'Pricing & Investment',
    description: 'Pricing and investment details',
    defaultContent: {
      title: 'Investment Details',
      format: 'table',
      items: [],
      showTotal: true,
      showDiscount: true,
      showTax: true,
      dataMapping: {
        currency: '${opportunity.currency}',
        products: '${opportunity.lineItems}',
        total: '${opportunity.totalValue}',
        discount: '${opportunity.discount}',
        tax: '${opportunity.tax}',
        paymentTerms: '${organization.paymentTerms}'
      }
    }
  },
  NEXT_STEPS: {
    value: 'nextSteps',
    label: 'Next Steps',
    description: 'Implementation timeline and action items',
    defaultContent: {
      title: 'Next Steps',
      timeline: [],
      contactInfo: {
        name: '${opportunity.owner.name}',
        email: '${opportunity.owner.email}',
        phone: '${opportunity.owner.phone}'
      }
    }
  },
  VALUE_PROPOSITION: {
    value: 'valueProposition',
    label: 'Value Proposition',
    description: 'Highlight your unique value proposition',
    defaultContent: {
      title: 'Our Value Proposition',
      content: '',
      aiRules: {
        useToneOfVoice: true,
        includePortfolio: true,
        focusPoints: ['uniqueness', 'benefits', 'impact']
      },
      dataMapping: {
        portfolio: {
          proposition: '${portfolio.valueProposition}',
          differentiators: '${portfolio.differentiators}'
        }
      }
    }
  },
  PRODUCTS_SERVICES: {
    value: 'productsServices',
    label: 'Products & Services',
    description: 'Detailed products and services offering',
    defaultContent: {
      title: 'Our Solutions',
      layout: 'grid', // or 'list'
      showPricing: true,
      items: [],
      dataMapping: {
        products: '${portfolio.products}',
        services: '${portfolio.services}',
        pricing: {
          currency: '${opportunity.currency}',
          rates: '${portfolio.rates}',
          customPricing: '${opportunity.customPricing}'
        }
      },
      aiRules: {
        filterRelevant: true,
        includeBenefits: true,
        customizeForIndustry: true
      }
    }
  },
  SIGNATURE: {
    value: 'signature',
    label: 'Digital Signature',
    description: 'DocuSign integration for digital signatures',
    defaultContent: {
      title: 'Agreement & Signatures',
      signatureType: 'docusign',
      content: {
        agreementText: '',
        termsAndConditions: '${organization.termsAndConditions}',
        signers: [
          {
            role: 'client',
            name: '${customer.signingAuthority.name}',
            title: '${customer.signingAuthority.title}',
            email: '${customer.signingAuthority.email}'
          },
          {
            role: 'vendor',
            name: '${organization.signingAuthority.name}',
            title: '${organization.signingAuthority.title}',
            email: '${organization.signingAuthority.email}'
          }
        ]
      },
      dataMapping: {
        docusign: {
          template: '${organization.docusign.templateId}',
          workflow: '${organization.docusign.workflowId}'
        }
      }
    }
  }
};

export const SectionGroups = {
  BASIC: {
    label: 'Basic Sections',
    sections: ['HEADER', 'TEXT', 'IMAGE', 'TABLE', 'LIST']
  },
  PROPOSAL: {
    label: 'Proposal Sections',
    sections: ['EXECUTIVE_SUMMARY', 'VALUE_PROPOSITION', 'SOLUTION_OVERVIEW', 'PRODUCTS_SERVICES', 'CASE_STUDIES', 'PRICING_TABLE', 'NEXT_STEPS', 'SIGNATURE']
  },
  DYNAMIC: {
    label: 'Dynamic Content',
    sections: ['DYNAMIC']
  }
};

export const TemplateTypes = {
  PROPOSAL: {
    value: 'proposal',
    label: 'Client Proposal',
    description: 'Professional proposal template with automatic data integration',
    defaultSections: [
      {
        id: 'header',
        type: 'header',
        title: 'Cover Page',
        content: {
          title: 'Proposal for ${customer.name}',
          subtitle: 'Prepared by ${organization.name}',
          alignment: 'center',
          includeLogo: true
        }
      },
      {
        id: 'executive-summary',
        type: 'executiveSummary',
        title: 'Executive Summary',
        content: SectionTypes.EXECUTIVE_SUMMARY.defaultContent
      },
      {
        id: 'value-proposition',
        type: 'valueProposition',
        title: 'Value Proposition',
        content: SectionTypes.VALUE_PROPOSITION.defaultContent
      },
      {
        id: 'solution-overview',
        type: 'solutionOverview',
        title: 'Our Solution',
        content: SectionTypes.SOLUTION_OVERVIEW.defaultContent
      },
      {
        id: 'products-services',
        type: 'productsServices',
        title: 'Our Solutions',
        content: SectionTypes.PRODUCTS_SERVICES.defaultContent
      },
      {
        id: 'case-studies',
        type: 'caseStudies',
        title: 'Success Stories',
        content: SectionTypes.CASE_STUDIES.defaultContent
      },
      {
        id: 'pricing',
        type: 'pricingTable',
        title: 'Investment',
        content: SectionTypes.PRICING_TABLE.defaultContent
      },
      {
        id: 'next-steps',
        type: 'nextSteps',
        title: 'Next Steps',
        content: SectionTypes.NEXT_STEPS.defaultContent
      },
      {
        id: 'signature',
        type: 'signature',
        title: 'Agreement & Signatures',
        content: SectionTypes.SIGNATURE.defaultContent
      }
    ]
  },
  CUSTOM: {
    value: 'custom',
    label: 'Custom Template',
    description: 'Create a template from scratch',
    defaultSections: []
  }
}; 