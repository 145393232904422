import api from './api';
import { getAuthHeader } from './authService';

/**
 * Function to list all notes for a contact
 * @param {string} organisationId - ID of the organisation
 * @param {string} contactId - ID of the contact
 * @returns {Array} - List of notes for the contact
 */
export const listNotes = async (organisationId, contactId) => {
  try {
    const token = await getAuthHeader();
    const response = await api.post('/api/puawai-account-management', {
      action: 'listContactNotes',
      data: {
        contactId
      }
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'x-organization-id': organisationId
      }
    });

    if (!response.data.success) {
      throw new Error(response.data.error || 'Failed to list contact notes');
    }

    return response.data.notes || [];
  } catch (error) {
    console.error('API call failed:', error.response || error);
    throw new Error(`API error: ${error.response?.data?.message || error.message}`);
  }
};

/**
 * Function to add a note to a contact
 * @param {string} organisationId - ID of the organisation
 * @param {string} contactId - ID of the contact
 * @param {Object} note - Note data to add
 * @returns {Object} - The created note
 */
export const addNote = async (organisationId, contactId, note) => {
  try {
    const token = await getAuthHeader();
    const response = await api.post('/api/puawai-account-management', {
      action: 'addContactNote',
      data: {
        contactId,
        content: note.content,
        author: note.author,
        timestamp: note.timestamp
      }
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'x-organization-id': organisationId
      }
    });

    if (!response.data.success) {
      throw new Error(response.data.error || 'Failed to add contact note');
    }

    return response.data.note;
  } catch (error) {
    console.error('API call failed:', error.response || error);
    throw new Error(`API error: ${error.response?.data?.message || error.message}`);
  }
};

/**
 * Function to delete a note from a contact
 * @param {string} organisationId - ID of the organisation
 * @param {string} contactId - ID of the contact
 * @param {string} noteId - ID of the note to delete
 * @returns {Object} - Response from the server
 */
export const deleteNote = async (organisationId, contactId, noteId) => {
  try {
    const token = await getAuthHeader();
    const response = await api.post('/api/puawai-account-management', {
      action: 'deleteContactNote',
      data: {
        contactId,
        noteId
      }
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'x-organization-id': organisationId
      }
    });

    if (!response.data.success) {
      throw new Error(response.data.error || 'Failed to delete contact note');
    }

    return response.data;
  } catch (error) {
    console.error('API call failed:', error.response || error);
    throw new Error(`API error: ${error.response?.data?.message || error.message}`);
  }
};

/**
 * Function to update a note
 * @param {string} organisationId - ID of the organisation
 * @param {string} contactId - ID of the contact
 * @param {string} noteId - ID of the note to update
 * @param {Object} noteData - Updated note data
 * @returns {Object} - The updated note
 */
export const updateNote = async (organisationId, contactId, noteId, noteData) => {
  try {
    const token = await getAuthHeader();
    const response = await api.post('/api/puawai-account-management', {
      action: 'updateContactNote',
      data: {
        contactId,
        noteId,
        content: noteData.content
      }
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'x-organization-id': organisationId
      }
    });

    if (!response.data.success) {
      throw new Error(response.data.error || 'Failed to update contact note');
    }

    return response.data.note;
  } catch (error) {
    console.error('API call failed:', error.response || error);
    throw new Error(`API error: ${error.response?.data?.message || error.message}`);
  }
};

export default {
  listNotes,
  addNote,
  deleteNote,
  updateNote
}; 