import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Chip,
  LinearProgress,
  Card,
  CardContent,
  CardActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Checkbox,
  useTheme
} from '@mui/material';
import {
  AccountBalance as GovernmentIcon,
  Favorite as NonProfitIcon,
  Refresh as RefreshIcon,
  Launch as LaunchIcon,
  AttachMoney as MoneyIcon,
  AccountBalance,
  EmojiEvents as EmojiEventsIcon
} from '@mui/icons-material';
import { fundingSourcesService } from '../../services/fundingSourcesService';
import { toast } from 'react-toastify';

/**
 * @typedef {Object} FundingSource
 * @property {string} id
 * @property {string} name
 * @property {number} sources
 */

const cardStyles = {
  paper: { 
    p: 2, 
    bgcolor: 'background.default',
    cursor: 'pointer',
    '&:hover': { bgcolor: 'action.hover' },
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: { 
    display: 'flex', 
    alignItems: 'center', 
    mb: 1 
  },
  description: {
    color: 'text.secondary',
    flex: 1
  }
};

const WelcomePanel = ({ title, subtitle }) => {
  const theme = useTheme();
  
  return (
    <Paper 
      sx={{ 
        p: 3, 
        background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
        color: 'white',
        mb: 3 
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <EmojiEventsIcon sx={{ fontSize: '3rem', marginRight: '1rem' }} />
        <Box>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle1">
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

const FundraisingDashboard = ({ userData }) => {
  const theme = useTheme();
  const [selectedFundingTypes, setSelectedFundingTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fundingSources, setFundingSources] = useState({});
  const [selectedCountry, setSelectedCountry] = useState('');
  const [availableCountries, setAvailableCountries] = useState([]);

  const fundingPaths = [
    {
      type: 'commercial',
      title: 'Commercial Funding',
      icon: <GovernmentIcon />,
      stages: [
        { name: 'Seed', description: 'Initial funding to validate business model' },
        { name: 'Series A', description: 'Scale core business operations' },
        { name: 'Series B+', description: 'Accelerate growth and expansion' },
        { name: 'IPO/Exit', description: 'Public offering or strategic exit' },
      ]
    },
    {
      type: 'non-profit',
      title: 'Non-Profit Funding',
      icon: <NonProfitIcon />,
      stages: [
        { name: 'Foundation Grants', description: 'Support from charitable foundations' },
        { name: 'Corporate Sponsorship', description: 'Partnerships with businesses' },
        { name: 'Donor Programs', description: 'Individual and institutional donors' },
        { name: 'Endowments', description: 'Long-term sustainable funding' },
      ]
    },
    {
      type: 'government',
      title: 'Government Funding',
      icon: <GovernmentIcon />,
      stages: [
        { name: 'Research Grants', description: 'R&D and innovation funding' },
        { name: 'Development Grants', description: 'Business growth and expansion' },
        { name: 'Regional Funding', description: 'Local economic development' },
        { name: 'Government Contracts', description: 'Public sector service delivery' },
      ]
    }
  ];

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countries = await fundingSourcesService.getAvailableCountries();
        console.log('Fetched countries:', countries);
        setAvailableCountries(countries);
        
        // Only set selected country if we have countries and none is currently selected
        if (countries.length > 0 && !selectedCountry) {
          const defaultCountry = countries.find(c => c.id === 'new-zealand') || countries[0];
          setSelectedCountry(defaultCountry.id);
        }
      } catch (error) {
        console.error('Error fetching countries:', error);
        toast.error('Failed to fetch available countries');
      }
    };

    fetchCountries();
  }, [selectedCountry]);

  useEffect(() => {
    const fetchFundingSources = async () => {
      setIsLoading(true);
      try {
        // Fetch funding sources for selected types
        const results = await Promise.all(
          selectedFundingTypes.map(async (type) => {
            const result = await fundingSourcesService.getFundingSources(selectedCountry, type);
            console.log(`Rendering ${result.data.length} sources for ${type}:`, result.data);
            return {
              type,
              sources: result.data || []
            };
          })
        );

        // Update state with results
        const newSources = {};
        results.forEach(({ type, sources }) => {
          newSources[type] = sources;
        });
        setFundingSources(newSources);
      } catch (error) {
        console.error('Error fetching funding sources:', error);
        toast.error('Failed to fetch funding sources. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedFundingTypes.length > 0) {
      fetchFundingSources();
    } else {
      setFundingSources({});
    }
  }, [selectedFundingTypes]);

  const handleFundingTypeToggle = (pathType) => {
    setSelectedFundingTypes(prev => 
      prev.includes(pathType)
        ? prev.filter(type => type !== pathType)
        : [...prev, pathType]
    );
  };

  const renderFundingSources = () => {
    if (selectedFundingTypes.length === 0) return null;

    return (
      <Box sx={{ mt: 4 }}>
        {selectedFundingTypes.map(type => {
          const sources = fundingSources[type] || [];
          console.log(`Rendering ${sources.length} sources for ${type}:`, sources);
          
          return (
            <Paper key={type} elevation={2} sx={{ mb: 3, p: 3 }}>
              <Typography variant="h6" gutterBottom>
                {fundingPaths.find(p => p.type === type)?.title} Sources ({sources.length})
              </Typography>
              
              {isLoading ? (
                <LinearProgress />
              ) : sources.length > 0 ? (
                <List>
                  {sources.map((source, index) => (
                    <ListItem 
                      key={source.id || index}
                      sx={{
                        mb: 2,
                        border: '1px solid',
                        borderColor: 'divider',
                        borderRadius: 1,
                        '&:hover': {
                          backgroundColor: 'action.hover'
                        }
                      }}
                    >
                      <ListItemIcon>
                        {getIconForFundingType(type)}
                      </ListItemIcon>
                      <ListItemText 
                        primary={
                          <Typography variant="subtitle1" color="primary">
                            {source.title || source.name}
                          </Typography>
                        }
                        secondary={
                          <Box>
                            <Typography variant="body2" color="text.secondary" paragraph>
                              {source.description}
                            </Typography>
                            <Grid container spacing={2}>
                              {source.amount && (
                                <Grid item>
                                  <Chip 
                                    label={`Amount: ${source.amount}`}
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                  />
                                </Grid>
                              )}
                              {source.deadline && (
                                <Grid item>
                                  <Chip 
                                    label={`Deadline: ${source.deadline}`}
                                    size="small"
                                    color="secondary"
                                    variant="outlined"
                                  />
                                </Grid>
                              )}
                              {source.sourceName && (
                                <Grid item>
                                  <Chip 
                                    label={source.sourceName}
                                    size="small"
                                    variant="outlined"
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Box>
                        }
                      />
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button
                          variant="outlined"
                          size="small"
                          href={source.sourceUrl}
                          target="_blank"
                          startIcon={<LaunchIcon />}
                        >
                          View Source
                        </Button>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Box sx={{ textAlign: 'center', py: 3 }}>
                  <Typography color="textSecondary" gutterBottom>
                    No funding sources available for this type
                  </Typography>
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<RefreshIcon />}
                  >
                    Refresh Sources
                  </Button>
                </Box>
              )}
            </Paper>
          );
        })}
      </Box>
    );
  };

  const getIconForFundingType = (type) => {
    switch (type) {
      case 'commercial':
        return <GovernmentIcon />;
      case 'non-profit':
        return <NonProfitIcon />;
      case 'government':
        return <GovernmentIcon />;
      default:
        return <GovernmentIcon />;
    }
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleRefresh = async () => {
    try {
      setIsLoading(true);
      const result = await fundingSourcesService.refreshFundingSources(selectedCountry, selectedFundingTypes);
      setFundingSources(result.data);
      toast.success('Funding sources refreshed successfully');
    } catch (error) {
      console.error('Error refreshing funding sources:', error);
      toast.error('Failed to refresh funding sources');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFundingSources = async () => {
    if (selectedFundingTypes.length === 0 || !selectedCountry) return;
    
    setIsLoading(true);
    try {
      const sourcesPromises = selectedFundingTypes.map(type => 
        fundingSourcesService.getFundingSources(selectedCountry, type)
      );
      
      const results = await Promise.all(sourcesPromises);
      const sourcesMap = {};
      selectedFundingTypes.forEach((type, index) => {
        sourcesMap[type] = results[index]?.data || [];
      });
      
      setFundingSources(sourcesMap);
    } catch (error) {
      toast.error('Failed to fetch funding sources');
      const emptySourcesMap = {};
      selectedFundingTypes.forEach(type => {
        emptySourcesMap[type] = [];
      });
      setFundingSources(emptySourcesMap);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <WelcomePanel 
        title="Growth Funding"
        subtitle="Access funding opportunities to fuel your business growth"
      />

      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 3, mb: 3, borderLeft: `6px solid ${theme.palette.warning.dark}` }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ color: theme.palette.warning.dark, display: 'flex', alignItems: 'center' }}>
                  <MoneyIcon sx={{ mr: 1 }} />
                  Funding Paths
                </Typography>
              </Box>
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel>Country</InputLabel>
                <Select
                  value={selectedCountry}
                  label="Country"
                  onChange={handleCountryChange}
                >
                  {availableCountries.map((country) => (
                    <MenuItem key={country.id} value={country.id}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Select and combine funding streams for your organization
            </Typography>
            <Grid container spacing={3}>
              {fundingPaths.map((fundingPath) => (
                <Grid item xs={12} md={4} key={fundingPath.type}>
                  <Paper 
                    sx={cardStyles.paper}
                    onClick={() => handleFundingTypeToggle(fundingPath.type)}
                  >
                    <Box sx={cardStyles.header}>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {fundingPath.icon}
                          <Typography variant="h6" color="warning.main" sx={{ ml: 1 }}>
                            {fundingPath.title}
                          </Typography>
                        </Box>
                        <Checkbox
                          checked={selectedFundingTypes.includes(fundingPath.type)}
                          color="warning"
                        />
                      </Box>
                    </Box>
                    <List dense>
                      {fundingPath.stages.map((stage, index) => (
                        <ListItem key={index} sx={{ px: 0 }}>
                          <ListItemIcon sx={{ minWidth: 32 }}>
                            <Box 
                              sx={{ 
                                width: 6, 
                                height: 6, 
                                borderRadius: '50%', 
                                bgcolor: 'warning.main' 
                              }} 
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={stage.name}
                            secondary={stage.description}
                            primaryTypographyProps={{ variant: 'body2' }}
                            secondaryTypographyProps={{ variant: 'caption' }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>

        {selectedFundingTypes.length > 0 && (
          <Grid item xs={12}>
            <Paper elevation={2} sx={{ p: 3, mb: 3, borderLeft: `6px solid ${theme.palette.info.main}` }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" sx={{ color: theme.palette.info.main, display: 'flex', alignItems: 'center' }}>
                  <AccountBalance sx={{ mr: 1 }} />
                  Available Funding Sources
                </Typography>
                <Button
                  variant="outlined"
                  color="info"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  disabled={isLoading}
                >
                  Refresh Sources
                </Button>
              </Box>
              {isLoading ? (
                <LinearProgress />
              ) : (
                renderFundingSources()
              )}
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default FundraisingDashboard; 