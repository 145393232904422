import React, { useState, useEffect, useRef } from 'react';
import { Box, Fab, Tooltip, Drawer, IconButton, useTheme, Badge, CircularProgress, TextField, Typography, List, ListItem, Avatar, Paper, Chip, Button, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem } from '@mui/material';
import { Close as CloseIcon, Send as SendIcon, Person as PersonIcon, Fullscreen as FullscreenIcon, FullscreenExit as FullscreenExitIcon, Minimize as MinimizeIcon } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling } from '@fortawesome/free-solid-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';
import { puawaiBidAIService } from '../../services/ai/puawaiBidAIService';
import ReactMarkdown from 'react-markdown';
import { keyframes, css } from '@emotion/react';
import BidTasks from '../bids/BidTasks';
import BidTeams from '../bids/BidTeams';
import BidBudget from '../bids/BidBudget';
import TimeTracking from '../bids/TimeTracking';
import BidDocuments from '../bids/BidDocuments';
import AnimatedDots from './AnimatedDots';
import CalendarTimeline from '../bids/CalendarTimeline';

const blink = keyframes`
  0%, 100% { opacity: 0.2; }
  50% { opacity: 1; }
`;

const dotsStyle = css`
  display: inline-block;
  span {
    animation: ${blink} 1.4s infinite both;
    font-size: 1.5rem;
    line-height: 0.1;
    display: inline-block;
    margin-right: 2px;
    &:nth-of-type(2) { animation-delay: 0.2s; }
    &:nth-of-type(3) { animation-delay: 0.4s; }
  }
`;


const BidChatInterface = ({ 
  userProfile, 
  organisation, 
  bid, 
  personality,
  toneOfVoice, 
  topOffset = 64, // Assuming 64px is the height of your top navigation bar
  isOpen,
  onToggle,
  onBidUpdate,
  onError,
}) => {
  const [newMessages, setNewMessages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [isAiResponding, setIsAiResponding] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showTaskForm, setShowTaskForm] = useState(false);
  const [taskData, setTaskData] = useState({});
  const theme = useTheme();
  const messagesEndRef = useRef(null);
  const [activeComponent, setActiveComponent] = useState(null);
  const [suggestedActions, setSuggestedActions] = useState([]);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  useEffect(() => {
    if (userProfile && organisation && bid) {
      const userInfoData = {
        userName: userProfile.givenName || 'User',
        orgName: organisation.name || 'Unknown Organization',
        userData: userProfile,
        organisationData: {
          id: organisation.id || userProfile.organisationId,
          name: organisation.name || 'Unknown Organization',
        },
        language: userProfile.language || 'en',
        locale: userProfile.locale || 'en-NZ'
      };
      setUserInfo(userInfoData);
      sendInitialMessage(userInfoData);
    }
  }, [userProfile, organisation, bid]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const sendInitialMessage = async (userInfoData) => {
    setIsLoading(true);
    try {
      const response = await puawaiBidAIService.sendMessage(
        "Hello, I'm the Puāwai Bid Intelligence assistant. How can I help you with your bid management today?",
        userInfoData,
        {
          bidData: bid,
          toneOfVoiceData: toneOfVoice,
          organisationData: userInfoData.organisationData,
          personality: personality
        }
      );
      setMessages([{ text: response.response, sender: 'bot' }]);
    } catch (error) {
      setMessages([{ text: 'Sorry, there was an error starting the chat session. Please try again.', sender: 'bot' }]);
      onError('Error starting chat session');
    } finally {
      setIsLoading(false);
    }
  };

  const extractActions = (text) => {
    const actionRegex = /\[(.*?)\]/g;
    const matches = text.match(actionRegex);
    return matches ? matches.map(match => match.slice(1, -1)) : [];
  };

  const handleSend = async () => {
    if (input.trim() === '') return;

    const newMessages = [...messages, { text: input, sender: 'user' }];
    setMessages(newMessages);
    setInput('');
    setIsAiResponding(true);

    try {
      const contextData = {
        bidData: bid,
        toneOfVoiceData: toneOfVoice,
        organisationData: userInfo.organisationData,
        personality: personality
      };
      const response = await puawaiBidAIService.sendMessage(input, userInfo, contextData);
      const aiMessage = { text: response.response, sender: 'bot' };
      setMessages([...newMessages, aiMessage]);
      
      // Extract suggested actions from the AI response
      const extractedActions = extractActions(response.response);
      setSuggestedActions(extractedActions);

      // Clear any previously opened component
      setActiveComponent(null);
    } catch (error) {
      setMessages([...newMessages, { text: 'Sorry, there was an error processing your request. Please try again.', sender: 'bot' }]);
      onError('Error processing request');
    } finally {
      setIsAiResponding(false);
    }
  };

  const handleActionClick = (action) => {
    switch (action) {
      case 'View Tasks':
        setActiveComponent('BidTasks');
        break;
      case 'View Team':
        setActiveComponent('BidTeams');
        break;
      case 'View Budget':
        setActiveComponent('BidBudget');
        break;
      case 'View Time Tracking':
        setActiveComponent('TimeTracking');
        break;
      case 'View Documents':
        setActiveComponent('BidDocuments');
        break;
      case 'View Timeline':
        setActiveComponent('CalendarTimeline');
        break;
      default:
        break;
    }
  };

  const handleActions = (actions) => {
    actions.forEach(action => {
      if (action.type === 'CREATE_TASK' && action.requiresUserInput) {
        setTaskData(action.data);
        setShowTaskForm(true);
      } else {
        // Handle other action types
      }
    });
  };

  const handleTaskSubmit = async (taskData) => {
    try {
      const result = await puawaiBidAIService.createTask(bid.id, taskData);
      setShowTaskForm(false);
      setTaskData({});
      onBidUpdate();
      setMessages(prevMessages => [...prevMessages, { text: `Task "${result.title}" has been created.`, sender: 'bot' }]);
    } catch (error) {
      onError('Error creating task');
    }
  };

  const renderMessage = (message, index) => {
    const isUser = message.sender === 'user';
    
    // Extract actions and remove them from the message text
    const actions = extractActions(message.text);
    const cleanedText = message.text.replace(/\[.*?\]/g, '')
      .replace(/,\s*,/g, ',')  // Remove double commas
      .replace(/,\s*$/g, '')   // Remove trailing commas
      .replace(/^\s*,\s*/g, '') // Remove leading commas
      .trim();

    return (
      <ListItem key={index} sx={{ justifyContent: isUser ? 'flex-end' : 'flex-start', py: 1, px: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: isUser ? 'row-reverse' : 'row', alignItems: 'flex-start', maxWidth: '90%' }}>
          <Avatar sx={{ 
            bgcolor: isUser ? theme.palette.primary.main : theme.palette.secondary.main, 
            width: 28, 
            height: 28,
            mr: isUser ? 0 : 1,
            ml: isUser ? 1 : 0
          }}>
            {isUser ? <PersonIcon fontSize="small" /> : <FontAwesomeIcon icon={faSeedling} style={{ fontSize: '0.8rem' }} />}
          </Avatar>
          <Paper elevation={1} sx={{ 
            p: 1.5, 
            pl: 2,
            bgcolor: isUser ? theme.palette.primary.light : theme.palette.background.default,
            borderRadius: 2,
            maxWidth: 'calc(100% - 40px)',
            wordBreak: 'break-word'
          }}>
            <ReactMarkdown components={{
              ol: ({node, ...props}) => <ol style={{paddingLeft: '20px'}} {...props} />,
              ul: ({node, ...props}) => <ul style={{paddingLeft: '20px'}} {...props} />
            }}>{cleanedText}</ReactMarkdown>
            {!isUser && actions.length > 0 && (
              <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {actions.map((action, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    size="small"
                    onClick={() => handleActionClick(action)}
                    sx={{ borderRadius: 20, textTransform: 'none' }}
                  >
                    {action}
                  </Button>
                ))}
              </Box>
            )}
          </Paper>
        </Box>
      </ListItem>
    );
  };

  const toggleFullScreen = () => setIsFullScreen(!isFullScreen);

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 'BidTasks':
        return <BidTasks bidId={bid.id} />;
      case 'BidTeams':
        return <BidTeams bidId={bid.id} />;
      case 'BidBudget':
        return <BidBudget bidId={bid.id} />;
      case 'TimeTracking':
        return <TimeTracking bidId={bid.id} />;
      case 'BidDocuments':
        return <BidDocuments bidId={bid.id} />;
      case 'CalendarTimeline':
        return <CalendarTimeline bidId={bid.id} organisationId={organisation.id} />;
      default:
        return null;
    }
  };

  const handleOpen = () => {
    setShowDisclaimer(true);
  };

  const handleAccept = () => {
    setShowDisclaimer(false);
    setIsLoading(true);
    setIsOpen(true);
    setNewMessages(0);
    sendInitialMessage(userInfo);
  };

  const disclaimerText = (
    <>
      <Typography variant="h6" gutterBottom>
        Kia ora! I'm your friendly Puāwai Bid Intelligence assistant.
      </Typography>
      <Typography paragraph>
        I'm here to help you with your bids, but please remember:
      </Typography>
      <Box component="ul" sx={{ pl: 2 }}>
        <Typography component="li">I'm still learning and might make mistakes.</Typography>
        <Typography component="li">I only use your data to assist you directly.</Typography>
        <Typography component="li">I don't store or use your information for training.</Typography>
        <Typography component="li">Your privacy and data security are important to me.</Typography>
      </Box>
      <Typography paragraph>
        Let's work together with kindness and patience to create amazing bids! 🌱✨
      </Typography>
    </>
  );

  return (
    <>
      <Dialog 
        open={showDisclaimer} 
        onClose={() => setShowDisclaimer(false)}
        PaperProps={{
          sx: {
            borderRadius: 2,
            maxWidth: 400,
          }
        }}
      >
        <DialogTitle sx={{ bgcolor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>
          Welcome to Puāwai Bid Intelligence!
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          {disclaimerText}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', px: 3, pb: 3 }}>
          <Button onClick={() => setShowDisclaimer(false)} variant="outlined">
            Maybe Later
          </Button>
          <Button onClick={handleAccept} variant="contained" color="primary">
            Accept & Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onToggle}
        PaperProps={{
          sx: {
            width: isFullScreen ? '100%' : '380px',
            maxWidth: isFullScreen ? '100%' : '95vw',
            height: isFullScreen ? `calc(100% - ${topOffset}px)` : '80vh',
            top: isFullScreen ? `${topOffset}px` : 'auto',
            bottom: isFullScreen ? 0 : 16,
            right: isFullScreen ? 0 : 16,
            borderRadius: isFullScreen ? 0 : '16px 0 0 16px',
            overflow: 'hidden',
            bgcolor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            boxShadow: theme.shadows[10],
            transition: 'all 0.3s ease-in-out',
          },
        }}
      >
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ 
            p: 1.5, 
            bgcolor: theme.palette.primary.main, 
            color: theme.palette.primary.contrastText,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Typography variant="h6">Puāwai Bid Intelligence</Typography>
            <Box>
              <IconButton 
                onClick={toggleFullScreen} 
                size="small"
                sx={{ color: theme.palette.primary.contrastText, mr: 0.5 }}
              >
                {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </IconButton>
              <IconButton 
                onClick={onToggle} 
                size="small"
                sx={{ color: theme.palette.primary.contrastText }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <List sx={{ flexGrow: 1, overflow: 'auto', py: 1, px: 1 }}>
              {messages.map(renderMessage)}
              {isAiResponding && (
                <ListItem sx={{ justifyContent: 'flex-start', py: 1, px: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar sx={{ bgcolor: theme.palette.secondary.main, width: 28, height: 28, mr: 1 }}>
                      <FontAwesomeIcon icon={faSeedling} style={{ fontSize: '0.8rem' }} />
                    </Avatar>
                    <AnimatedDots />
                  </Box>
                </ListItem>
              )}
              <div ref={messagesEndRef} />
            </List>
            {activeComponent && (
              <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2, borderTop: `1px solid ${theme.palette.divider}` }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="h6">{activeComponent.replace('Bid', '')}</Typography>
                  <IconButton onClick={() => setActiveComponent(null)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                {renderActiveComponent()}
              </Box>
            )}
          </Box>
          <Box sx={{ p: 2, borderTop: `1px solid ${theme.palette.divider}` }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type your message..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && handleSend()}
              multiline
              maxRows={4}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleSend} disabled={isAiResponding || input.trim() === ''}>
                    <SendIcon />
                  </IconButton>
                ),
                sx: {
                  pl: 2,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.divider,
                  },
                },
              }}
            />
          </Box>
        </Box>
        {showTaskForm && (
          <TaskForm
            taskData={taskData}
            onSubmit={handleTaskSubmit}
            onCancel={() => setShowTaskForm(false)}
          />
        )}
      </Drawer>
    </>
  );
};

const TaskForm = ({ taskData, onSubmit, onCancel }) => {
  const [formData, setFormData] = useState(taskData);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Dialog open={true} onClose={onCancel}>
      <DialogTitle>Create New Task</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="title"
          label="Title"
          type="text"
          fullWidth
          value={formData.title || ''}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="description"
          label="Description"
          type="text"
          fullWidth
          multiline
          rows={3}
          value={formData.description || ''}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="dueDate"
          label="Due Date"
          type="date"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={formData.dueDate || ''}
          onChange={handleChange}
        />
        <TextField
          select
          margin="dense"
          name="status"
          label="Status"
          fullWidth
          value={formData.status || 'not_started'}
          onChange={handleChange}
        >
          <MenuItem value="not_started">Not Started</MenuItem>
          <MenuItem value="in_progress">In Progress</MenuItem>
          <MenuItem value="completed">Completed</MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={handleSubmit}>Create Task</Button>
      </DialogActions>
    </Dialog>
  );
};

export default BidChatInterface;
