import api from './api';
import { getAuthHeader } from './authService';

const apiCall = async (method, endpoint, data = null) => {
    try {
        const token = await getAuthHeader();
        const config = {
            method,
            url: `/api/bids${endpoint}`,
            headers: { Authorization: token },
        };
        if (data) {
            config.data = data;
        }
        const response = await api(config);
        return response.data;
    } catch (error) {
        throw new Error(`API error: ${error.response?.data?.message || error.message}`);
    }
};

/**
 * Get all teams for a bid
 * @param {string} bidId - The ID of the bid
 * @returns {Promise<Array>} - An array of team objects
 */
export const getTeams = async (bidId) => {
    if (!bidId) {
        throw new Error('bidId is required');
    }
    return apiCall('get', `/${bidId}/teams`);
};

/**
 * Get a specific team
 * @param {string} bidId - The ID of the bid
 * @param {string} teamId - The ID of the team
 * @returns {Promise<Object>} - The team object
 */
export const getTeam = async (bidId, teamId) => {
    if (!bidId || !teamId) {
        throw new Error('bidId and teamId are required');
    }
    return apiCall('get', `/${bidId}/teams/${teamId}`);
};

/**
 * Create a new team
 * @param {string} bidId - The ID of the bid
 * @param {Object} teamData - The data for the new team
 * @returns {Promise<Object>} - The created team object
 */
export const createTeam = async (bidId, teamData) => {
    if (!bidId || !teamData) {
        throw new Error('bidId and teamData are required');
    }
    return apiCall('post', `/${bidId}/teams`, teamData);
};

/**
 * Update a team
 * @param {string} bidId - The ID of the bid
 * @param {string} teamId - The ID of the team
 * @param {Object} teamData - The updated data for the team
 * @returns {Promise<Object>} - The updated team object
 */
export const updateTeam = async (bidId, teamId, teamData) => {
    if (!bidId || !teamId || !teamData) {
        throw new Error('bidId, teamId, and teamData are required');
    }
    return apiCall('put', `/${bidId}/teams/${teamId}`, teamData);
};

/**
 * Delete a team
 * @param {string} bidId - The ID of the bid
 * @param {string} teamId - The ID of the team
 * @returns {Promise<void>}
 */
export const deleteTeam = async (bidId, teamId) => {
    if (!bidId || !teamId) {
        throw new Error('bidId and teamId are required');
    }
    return apiCall('delete', `/${bidId}/teams/${teamId}`);
};

/**
 * Add a member to a team
 * @param {string} bidId - The ID of the bid
 * @param {Object} memberData - The data for the new member
 * @param {string} organisationId - The ID of the organisation
 * @returns {Promise<Object>} - The updated team object
 */
export const addTeamMember = async (bidId, memberData) => {
    if (!bidId || !memberData) {
        throw new Error('bidId and memberData are required');
    }
    return apiCall('post', `/${bidId}/teams/members`, memberData);
};

/**
 * Update a team member
 * @param {string} bidId - The ID of the bid
 * @param {string} memberId - The ID of the member
 * @param {Object} memberData - The updated data for the member
 * @returns {Promise<Object>} - The updated team member object
 */
export const updateTeamMember = async (bidId, memberId, memberData) => {
    if (!bidId || !memberId || !memberData) {
        throw new Error('bidId, memberId, and memberData are required');
    }
    return apiCall('put', `/${bidId}/teams/members/${memberId}`, memberData);
};

/**
 * Remove a member from a team
 * @param {string} bidId - The ID of the bid
 * @param {string} memberId - The ID of the member to remove
 * @returns {Promise<void>}
 */
export const removeTeamMember = async (bidId, memberId) => {
    if (!bidId || !memberId) {
        throw new Error('bidId and memberId are required');
    }
    return apiCall('delete', `/${bidId}/teams/members/${memberId}`);
};

/**
 * Get all team members for a bid
 * @param {string} bidId - The ID of the bid
 * @returns {Promise<Array>} - An array of team member objects
 */
export const getTeamMembers = async (bidId) => {
    if (!bidId) {
        throw new Error('bidId is required');
    }
    return apiCall('get', `/${bidId}/teams/members`);
};

/**
 * Transfer ownership of a bid
 * @param {string} bidId - The ID of the bid
 * @param {string} newOwnerId - The ID of the new owner
 * @returns {Promise<Object>} - The updated bid object
 */
export const transferOwnership = async (bidId, newOwnerId) => {
    if (!bidId || !newOwnerId) {
        throw new Error('bidId and newOwnerId are required');
    }
    try {
        const updatedBid = await apiCall('patch', `/${bidId}`, { owner: newOwnerId });
        return updatedBid;
    } catch (error) {
        throw error;
    }
};

// Export all functions as a default object
export default {
    getTeams,
    getTeam,
    createTeam,
    updateTeam,
    deleteTeam,
    addTeamMember,
    updateTeamMember,
    removeTeamMember,
    getTeamMembers,
    transferOwnership,
};
