import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { 
  Box, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, 
  Select, MenuItem, FormControl, InputLabel, Tabs, Tab,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  useTheme, Tooltip, IconButton, Collapse, Snackbar, Alert, Chip, Checkbox, FormGroup, FormControlLabel,
  Popover, Grid, Divider, Skeleton, Fade, Card, CardContent, CardHeader, CardActions, Switch
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Download as DownloadIcon, HelpOutline as HelpOutlineIcon, Info as InfoIcon, Close as CloseIcon, MoreHoriz as MoreHorizIcon, ViewColumn as ViewColumnIcon, Visibility, Edit, Delete, Save as SaveIcon, Cancel as CancelIcon, EmojiEvents as EmojiEventsIcon } from '@mui/icons-material';
import portfolioService from '../../services/portfolioService';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PortfolioPDF from '../pdf/PortfolioPDF';
import { generateCSVReport } from '../../utils/reportGenerator';
import { motion } from 'framer-motion';

const tabs = [
  {
    label: 'Propositions',
    description: 'Manage your business propositions',
    tip: 'A proposition is a high-level abstraction or positioning of a group of related products and services. It represents how you present your offerings to the market and customers.'
  },
  {
    label: 'Products and Services',
    description: 'Manage individual products and services in your portfolio',
    tip: 'Products and services are specific offerings within your propositions.'
  }
];

const ColumnSelector = ({ columns, visibleColumns, onToggle }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'column-selector-popover' : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        variant="outlined"
        onClick={handleClick}
        startIcon={<ViewColumnIcon />}
        size="small"
      >
        Columns
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2 }}>
          <FormGroup>
            {Object.keys(columns).map((columnName) => (
              <FormControlLabel
                key={columnName}
                control={
                  <Checkbox
                    checked={visibleColumns[columnName]}
                    onChange={() => onToggle(columnName)}
                    size="small"
                  />
                }
                label={columnName.charAt(0).toUpperCase() + columnName.slice(1)}
              />
            ))}
          </FormGroup>
        </Box>
      </Popover>
    </>
  );
};

const WelcomePanel = ({ title, subtitle }) => {
  const theme = useTheme();
  
  return (
    <Paper 
      sx={{ 
        p: 3, 
        background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
        color: 'white',
        mb: 3 
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <EmojiEventsIcon sx={{ fontSize: '3rem', marginRight: '1rem' }} />
        <Box>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle1">
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

const PortfolioManager = ({ selectedOrg }) => {
  const theme = useTheme();
  const [propositions, setPropositions] = useState([]);
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [itemType, setItemType] = useState('portfolioItem');
  const [showTip, setShowTip] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [readMoreDialog, setReadMoreDialog] = useState({ open: false, content: '', title: '' });
  const [visiblePropositionColumns, setVisiblePropositionColumns] = useState({
    name: true,
    description: true,
    parentProposition: true,
    associatedProducts: true,
    associatedServices: true,
    whatItIs: false,
    howItWorks: false,
    whyItMatters: false,
  });
  const [visiblePortfolioColumns, setVisiblePortfolioColumns] = useState({
    name: true,
    description: true,
    category: true,
    associatedPropositions: true, // Changed from parentProposition
    type: false,
    deliveryType: false,
    marketShare: false,
    plcStage: false,
  });
  const [columnSelectionOpen, setColumnSelectionOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editingCell, setEditingCell] = useState({ rowId: null, column: null });
  const [fullEditDialog, setFullEditDialog] = useState({ open: false, item: null });
  const [detailDialog, setDetailDialog] = useState({ open: false, item: null, type: null, isEditing: false });
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });
  const [error, setError] = useState(null);

  const fetchPropositions = useCallback(async () => {
    try {
      const items = await portfolioService.getPropositions(selectedOrg);
      setPropositions(items);
    } catch (error) {
      setError(error.message);
      setSnackbar({ open: true, message: 'Failed to fetch propositions', severity: 'error' });
    }
  }, [selectedOrg]);

  const fetchPortfolioItems = useCallback(async () => {
    try {
      const items = await portfolioService.getPortfolioItems(selectedOrg);
      setPortfolioItems(items);
    } catch (error) {
      setError(error.message);
      setSnackbar({ open: true, message: 'Failed to fetch portfolio items', severity: 'error' });
    }
  }, [selectedOrg]);

  useEffect(() => {
    if (selectedOrg) {
      setLoading(true);
      Promise.all([fetchPropositions(), fetchPortfolioItems()])
        .finally(() => setLoading(false));
    } else {
      setPropositions([]);
      setPortfolioItems([]);
      setLoading(false);
    }
  }, [selectedOrg, fetchPropositions, fetchPortfolioItems]);

  const handleOpenDialog = (item = null, type = 'portfolioItem') => {
    setCurrentItem(item || (type === 'proposition' ? {
      name: '',
      description: '',
      whatItIs: '',
      howItWorks: '',
      whyItMatters: '',
      associatedServices: [],
      associatedProducts: [],
    } : {
      name: '',
      description: '',
      category: '',
      type: '',
      deliveryType: 'Internal',
      thirdPartyProvider: '',
      marketShare: 0,
      marketGrowth: 0,
      plcStage: 'Introduction',
      pricing: '',
      features: [],
      benefits: [],
      technicalSpecifications: '',
    }));
    setIsEditing(!!item);
    setItemType(type);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentItem(null);
    setIsEditing(false);
  };

  const handleSave = async () => {
    try {
      if (itemType === 'proposition') {
        if (isEditing) {
          await portfolioService.updateProposition(selectedOrg, currentItem.id, currentItem);
        } else {
          await portfolioService.createProposition(selectedOrg, currentItem);
        }
        fetchPropositions();
      } else {
        if (isEditing) {
          await portfolioService.updatePortfolioItem(selectedOrg, currentItem.id, currentItem);
        } else {
          await portfolioService.createPortfolioItem(selectedOrg, currentItem);
        }
        fetchPortfolioItems();
      }
      handleCloseDialog();
      setSnackbar({ open: true, message: 'Item saved successfully', severity: 'success' });
    } catch (error) {
      console.error('Error saving item:', error);
      setSnackbar({ open: true, message: `Error saving item: ${error.message}`, severity: 'error' });
    }
  };

  const handleDelete = async (itemId, type) => {
    try {
      if (type === 'proposition') {
        await portfolioService.deleteProposition(selectedOrg, itemId);
        fetchPropositions();
      } else {
        await portfolioService.deletePortfolioItem(selectedOrg, itemId);
        fetchPortfolioItems();
      }
      setSnackbar({ open: true, message: 'Item deleted successfully', severity: 'success' });
    } catch (error) {
      console.error('Error deleting item:', error);
      setSnackbar({ open: true, message: `Error deleting item: ${error.message}`, severity: 'error' });
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const getBCGCategory = (marketShare, marketGrowth) => {
    if (marketShare >= 1 && marketGrowth >= 10) return 'Star';
    if (marketShare >= 1 && marketGrowth < 10) return 'Cash Cow';
    if (marketShare < 1 && marketGrowth >= 10) return 'Question Mark';
    return 'Dog';
  };

  const getPlcStageColor = (stage) => {
    switch (stage) {
      case 'Introduction': return 'blue';
      case 'Growth': return 'green';
      case 'Maturity': return 'yellow';
      case 'Decline': return 'red';
      default: return 'grey';
    }
  };

  const handleGenerateReport = (format) => {
    if (format === 'csv') {
      generateCSVReport(portfolioItems, propositions);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const InfoPanel = ({ tabIndex }) => (
    <Collapse in={showTip}>
      <Paper 
        elevation={3} 
        sx={{ 
          p: 2, 
          mt: 2, 
          mb: 3,
          backgroundColor: 'info.light', 
          color: 'info.contrastText',
          position: 'relative'
        }}
      >
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => setShowTip(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <InfoIcon sx={{ mr: 1 }} />
          <Typography variant="h6">Tip for this section</Typography>
        </Box>
        <Typography variant="body1" sx={{ pl: 3 }}>{tabs[tabIndex].tip}</Typography>
      </Paper>
    </Collapse>
  );

  const renderPropositionTree = (propositions) => {
    const buildTree = (items, parentId = null) => {
      return items
        .filter(item => item.parentId === parentId)
        .map(item => (
          <TreeItem key={item.id} nodeId={item.id} label={item.name}>
            {buildTree(items, item.id)}
          </TreeItem>
        ));
    };

    return buildTree(propositions);
  };

  // Add this function to get linked propositions for a portfolio item
  const getLinkedPropositions = (portfolioItemId) => {
    return propositions.filter(prop => prop.associatedProducts?.includes(portfolioItemId));
  };

  const truncateText = (text, maxLength = 50) => {
    if (!text) return ''; // Handle undefined or null text
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  const handleReadMore = (content, title) => {
    setReadMoreDialog({ open: true, content, title });
  };

  const closeReadMoreDialog = () => {
    setReadMoreDialog({ open: false, content: '', title: '' });
  };

  const getParentProposition = (propositionId) => {
    return propositions.find(prop => prop.id === propositionId);
  };

  const handleColumnSelectionOpen = () => {
    setColumnSelectionOpen(true);
  };

  const handleColumnSelectionClose = () => {
    setColumnSelectionOpen(false);
  };

  const handleColumnToggle = (columnName, isProposition) => {
    if (isProposition) {
      setVisiblePropositionColumns(prev => ({ ...prev, [columnName]: !prev[columnName] }));
    } else {
      setVisiblePortfolioColumns(prev => ({ ...prev, [columnName]: !prev[columnName] }));
    }
  };

  const renderLoadingSkeleton = () => (
    <Box sx={{ p: theme.spacing(3) }}>
      <Skeleton variant="rectangular" width="100%" height={60} sx={{ mb: 2 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Skeleton variant="rectangular" width="100%" height={200} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton variant="rectangular" width="100%" height={200} />
        </Grid>
      </Grid>
      <Skeleton variant="rectangular" width="100%" height={400} sx={{ mt: 3 }} />
    </Box>
  );

  const handleCellEdit = (rowId, column) => {
    setEditingCell({ rowId, column });
    const itemToEdit = activeTab === 0 
      ? propositions.find(item => item.id === rowId)
      : portfolioItems.find(item => item.id === rowId);
    setCurrentItem({ ...itemToEdit });
  };

  const handleCellSave = async () => {
    try {
      if (activeTab === 0) {
        await portfolioService.updateProposition(selectedOrg, currentItem.id, currentItem);
        fetchPropositions();
      } else {
        await portfolioService.updatePortfolioItem(selectedOrg, currentItem.id, currentItem);
        fetchPortfolioItems();
      }
      setEditingCell({ rowId: null, column: null });
      setSnackbar({ open: true, message: 'Item updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Error updating item:', error);
      setSnackbar({ open: true, message: `Error updating item: ${error.message}`, severity: 'error' });
    }
  };

  const handleCellCancel = () => {
    setEditingCell({ rowId: null, column: null });
    setCurrentItem(null);
  };

  const handleFullEdit = (item) => {
    setFullEditDialog({ open: true, item: { ...item } });
  };

  const handleFullEditClose = () => {
    setFullEditDialog({ open: false, item: null });
  };

  const handleFullEditSave = async () => {
    try {
      if (activeTab === 0) {
        await portfolioService.updateProposition(selectedOrg, fullEditDialog.item.id, fullEditDialog.item);
        fetchPropositions();
      } else {
        await portfolioService.updatePortfolioItem(selectedOrg, fullEditDialog.item.id, fullEditDialog.item);
        fetchPortfolioItems();
      }
      setFullEditDialog({ open: false, item: null });
      setSnackbar({ open: true, message: 'Item updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Error updating item:', error);
      setSnackbar({ open: true, message: `Error updating item: ${error.message}`, severity: 'error' });
    }
  };

  const sortItems = (items) => {
    const sortableItems = [...items];
    sortableItems.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    return sortableItems;
  };

  const renderTable = (items, columns, itemType) => {
    const sortedItems = sortItems(items);
    
    return (
      <TableContainer component={Paper} elevation={3}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: theme.palette.primary.main }}>
              {Object.entries(columns).map(([key, visible]) => 
                visible && (
                  <TableCell 
                    key={key} 
                    sx={{ 
                      color: theme.palette.primary.contrastText,
                      cursor: 'pointer',
                      '&:hover': { opacity: 0.8 },
                    }}
                    onClick={() => handleSort(key)}
                  >
                    {getColumnDisplayName(key)}
                    {sortConfig.key === key && (
                      <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                    )}
                  </TableCell>
                )
              )}
              <TableCell sx={{ color: theme.palette.primary.contrastText }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedItems.map((item) => (
              <TableRow key={item.id}>
                {Object.entries(columns).map(([key, visible]) => 
                  visible && (
                    <TableCell key={key}>
                      {editingCell.rowId === item.id && editingCell.column === key ? 
                        renderEditableCell(key, item) : 
                        <Box 
                          onClick={() => handleCellEdit(item.id, key)} 
                          sx={{ 
                            cursor: 'pointer',
                            '&:hover': { backgroundColor: theme.palette.action.hover },
                            minHeight: '24px', // Ensure empty cells have a clickable area
                            padding: '4px'
                          }}
                        >
                          {renderCellContent(item, key)}
                        </Box>
                      }
                    </TableCell>
                  )
                )}
                <TableCell>
                  <IconButton onClick={() => handleViewItem(item, itemType)} color="primary">
                    <Visibility />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(item.id, itemType)} color="error">
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const getColumnDisplayName = (key) => {
    const displayNames = {
      name: 'Name',
      description: 'Description',
      category: 'Category',
      type: 'Type',
      deliveryType: 'Delivery Type',
      plcStage: 'PLC Stage',
      whatItIs: 'What It Is',
      howItWorks: 'How It Works',
      whyItMatters: 'Why It Matters',
      marketShare: 'Market Share',
      marketGrowth: 'Market Growth',
      parentProposition: 'Parent Proposition',
      associatedProducts: 'Associated Products',
      associatedServices: 'Associated Services',
      associatedPropositions: 'Associated Propositions',
    };
    return displayNames[key] || key.charAt(0).toUpperCase() + key.slice(1);
  };

  const renderEditableCell = (key, item) => {
    const handleChange = (e) => {
      setCurrentItem({ ...currentItem, [key]: e.target.value });
    };

    const commonProps = {
      fullWidth: true,
      value: currentItem[key] || '',
      onChange: handleChange,
      onBlur: handleCellSave,
      onKeyPress: (e) => {
        if (e.key === 'Enter') {
          handleCellSave();
        }
      },
      autoFocus: true,
      sx: { minWidth: '200px' }
    };

    switch (key) {
      case 'name':
      case 'description':
      case 'category':
      case 'type':
      case 'deliveryType':
      case 'whatItIs':
      case 'howItWorks':
      case 'whyItMatters':
        return <TextField {...commonProps} multiline={key === 'description'} />;
      case 'marketShare':
      case 'marketGrowth':
        return <TextField {...commonProps} type="number" />;
      case 'plcStage':
        return (
          <Select {...commonProps}>
            <MenuItem value="Introduction">Introduction</MenuItem>
            <MenuItem value="Growth">Growth</MenuItem>
            <MenuItem value="Maturity">Maturity</MenuItem>
            <MenuItem value="Decline">Decline</MenuItem>
          </Select>
        );
      case 'parentProposition':
        return (
          <Select {...commonProps} value={currentItem.parentPropositionId || ''}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {propositions.filter(p => p.id !== item.id).map((prop) => (
              <MenuItem key={prop.id} value={prop.id}>
                {prop.name}
              </MenuItem>
            ))}
          </Select>
        );
      case 'associatedProducts':
      case 'associatedServices':
        return (
          <FormControl fullWidth>
            <InputLabel>{getColumnDisplayName(key)}</InputLabel>
            <Select
              {...commonProps}
              multiple
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => {
                    const associatedItem = portfolioItems.find(p => p.id === value);
                    return associatedItem ? (
                      <Chip
                        key={value}
                        label={associatedItem.name}
                        onDelete={() => {
                          const updatedItems = currentItem[key].filter(id => id !== value);
                          setCurrentItem({ ...currentItem, [key]: updatedItems });
                        }}
                        onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                      />
                    ) : null;
                  })}
                </Box>
              )}
            >
              {portfolioItems.filter(item => item.category === (key === 'associatedProducts' ? 'Product' : 'Service')).map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case 'associatedPropositions':
        const associatedProps = propositions.filter(prop => 
          prop.associatedProducts?.includes(item.id) || prop.associatedServices?.includes(item.id)
        );
        return (
          <FormControl fullWidth>
            <InputLabel>{getColumnDisplayName(key)}</InputLabel>
            <Select
              {...commonProps}
              multiple
              value={associatedProps.map(prop => prop.id)}
              onChange={(e) => {
                const updatedItem = { ...currentItem, associatedPropositions: e.target.value };
                setCurrentItem(updatedItem);
                handleUpdateAssociatedPropositions(updatedItem);
              }}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => {
                    const prop = propositions.find(p => p.id === value);
                    return prop ? (
                      <Chip
                        key={value}
                        label={prop.name}
                        onDelete={() => {
                          const updatedPropositions = selected.filter(id => id !== value);
                          const updatedItem = { ...currentItem, associatedPropositions: updatedPropositions };
                          setCurrentItem(updatedItem);
                          handleUpdateAssociatedPropositions(updatedItem);
                        }}
                        onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                      />
                    ) : null;
                  })}
                </Box>
              )}
            >
              {propositions.map((prop) => (
                <MenuItem key={prop.id} value={prop.id}>
                  {prop.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      default:
        return null;
    }
  };

  const handleUpdateAssociatedPropositions = async (updatedItem) => {
    try {
      // Update the product/service
      await portfolioService.updatePortfolioItem(selectedOrg, updatedItem.id, updatedItem);

      // Update associated propositions
      const allPropositions = [...propositions];
      for (const propId of updatedItem.associatedPropositions) {
        const proposition = allPropositions.find(p => p.id === propId);
        if (proposition) {
          const updatedProp = {
            ...proposition,
            associatedProducts: updatedItem.category === 'Product' 
              ? [...new Set([...proposition.associatedProducts || [], updatedItem.id])]
              : proposition.associatedProducts,
            associatedServices: updatedItem.category === 'Service'
              ? [...new Set([...proposition.associatedServices || [], updatedItem.id])]
              : proposition.associatedServices
          };
          await portfolioService.updateProposition(selectedOrg, propId, updatedProp);
          const index = allPropositions.findIndex(p => p.id === propId);
          allPropositions[index] = updatedProp;
        }
      }

      // Remove item from propositions that are no longer associated
      for (const prop of allPropositions) {
        if (!updatedItem.associatedPropositions.includes(prop.id)) {
          const updatedProp = {
            ...prop,
            associatedProducts: prop.associatedProducts?.filter(id => id !== updatedItem.id) || [],
            associatedServices: prop.associatedServices?.filter(id => id !== updatedItem.id) || []
          };
          if (updatedProp.associatedProducts !== prop.associatedProducts || 
              updatedProp.associatedServices !== prop.associatedServices) {
            await portfolioService.updateProposition(selectedOrg, prop.id, updatedProp);
            const index = allPropositions.findIndex(p => p.id === prop.id);
            allPropositions[index] = updatedProp;
          }
        }
      }

      // Update local state
      setPropositions(allPropositions);
      setPortfolioItems(prev => prev.map(item => item.id === updatedItem.id ? updatedItem : item));
      setSnackbar({ open: true, message: 'Associated propositions updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Error updating associated propositions:', error);
      setSnackbar({ open: true, message: `Error updating associated propositions: ${error.message}`, severity: 'error' });
    }
  };

  const renderCellContent = (item, key) => {
    const maxLength = 50; // Maximum length for truncated text

    const content = item[key];

    switch (key) {
      case 'name':
      case 'description':
      case 'category':
      case 'type':
      case 'deliveryType':
      case 'plcStage':
      case 'whatItIs':
      case 'howItWorks':
      case 'whyItMatters':
        return (
          <Tooltip title={content}>
            <span>{content ? truncateText(content, maxLength) : 'Click to add'}</span>
          </Tooltip>
        );
      case 'marketShare':
      case 'marketGrowth':
        return content ? `${content}%` : 'Click to add';
      case 'parentProposition':
        const parentProposition = getParentProposition(item.parentPropositionId);
        return parentProposition ? (
          <Tooltip title={parentProposition.name}>
            <span>{truncateText(parentProposition.name, maxLength)}</span>
          </Tooltip>
        ) : 'Click to add';
      case 'associatedProducts':
      case 'associatedServices':
        if (!content || content.length === 0) {
          return <span>Click to add</span>;
        }
        return (
          <Box>
            {content.slice(0, 2).map((id) => {
              const associatedItem = portfolioItems.find(p => p.id === id);
              return associatedItem ? (
                <Tooltip key={id} title={associatedItem.name}>
                  <Chip label={truncateText(associatedItem.name, 15)} size="small" sx={{ m: 0.5 }} />
                </Tooltip>
              ) : null;
            })}
            {content.length > 2 && (
              <Chip label={`+${content.length - 2} more`} size="small" sx={{ m: 0.5 }} />
            )}
          </Box>
        );
      case 'associatedPropositions':
        const associatedProps = propositions.filter(prop => 
          prop.associatedProducts?.includes(item.id) || prop.associatedServices?.includes(item.id)
        );
        return (
          <Box>
            {associatedProps.slice(0, 2).map((prop) => (
              <Tooltip key={prop.id} title={prop.name}>
                <Chip label={truncateText(prop.name, 15)} size="small" sx={{ m: 0.5 }} />
              </Tooltip>
            ))}
            {associatedProps.length > 2 && (
              <Chip label={`+${associatedProps.length - 2} more`} size="small" sx={{ m: 0.5 }} />
            )}
            {associatedProps.length === 0 && <span>Click to add</span>}
          </Box>
        );
      default:
        return content || 'Click to add';
    }
  };

  const handleViewItem = (item, type) => {
    setDetailDialog({ open: true, item: { ...item }, type, isEditing: false });
  };

  const handleDetailClose = () => {
    setDetailDialog({ open: false, item: null, type: null, isEditing: false });
  };

  const handleDetailSave = async () => {
    try {
      if (detailDialog.type === 'proposition') {
        await portfolioService.updateProposition(selectedOrg, detailDialog.item.id, detailDialog.item);
        fetchPropositions();
      } else {
        await portfolioService.updatePortfolioItem(selectedOrg, detailDialog.item.id, detailDialog.item);
        fetchPortfolioItems();
      }
      setDetailDialog(prev => ({ ...prev, isEditing: false }));
      setSnackbar({ open: true, message: 'Item updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Error updating item:', error);
      setSnackbar({ open: true, message: `Error updating item: ${error.message}`, severity: 'error' });
    }
  };

  const renderDetailContent = () => {
    const { item, type, isEditing } = detailDialog;
    if (!item) return null;

    const renderField = (label, key, inputType = 'text', options = []) => (
      <Card sx={{ mb: 2 }}>
        <CardHeader title={label} />
        <CardContent>
          {isEditing ? (
            inputType === 'select' ? (
              <FormControl fullWidth>
                <Select
                  value={item[key] || ''}
                  onChange={(e) => setDetailDialog(prev => ({
                    ...prev,
                    item: { ...prev.item, [key]: e.target.value }
                  }))}
                >
                  {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : inputType === 'multiline-array' ? (
              <TextField
                fullWidth
                multiline
                rows={4}
                value={(item[key] || []).join('\n')}
                onChange={(e) => setDetailDialog(prev => ({
                  ...prev,
                  item: { ...prev.item, [key]: e.target.value.split('\n').filter(line => line.trim()) }
                }))}
                helperText="Enter each item on a new line"
              />
            ) : inputType === 'number' ? (
              <TextField
                fullWidth
                type="number"
                value={item[key] || ''}
                onChange={(e) => setDetailDialog(prev => ({
                  ...prev,
                  item: { ...prev.item, [key]: parseFloat(e.target.value) }
                }))}
              />
            ) : (
              <TextField
                fullWidth
                multiline={inputType === 'multiline'}
                rows={inputType === 'multiline' ? 4 : 1}
                type={inputType}
                value={item[key] || ''}
                onChange={(e) => setDetailDialog(prev => ({
                  ...prev,
                  item: { ...prev.item, [key]: e.target.value }
                }))}
              />
            )
          ) : inputType === 'multiline-array' ? (
            <Typography>
              {(item[key] || []).map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            </Typography>
          ) : (
            <Typography>{item[key] || 'N/A'}</Typography>
          )}
        </CardContent>
      </Card>
    );

    return (
      <>
        {renderField('Name', 'name')}
        {renderField('Description', 'description', 'multiline')}
        
        {type === 'proposition' ? (
          <>
            {renderField('What It Is', 'whatItIs', 'multiline')}
            {renderField('How It Works', 'howItWorks', 'multiline')}
            {renderField('Why It Matters', 'whyItMatters', 'multiline')}
            {renderField('Parent Proposition', 'parentPropositionId', 'select', 
              propositions
                .filter(p => p.id !== item.id)
                .map(p => ({ value: p.id, label: p.name }))
            )}
            {renderAssociatedItems('Associated Products', 'associatedProducts')}
            {renderAssociatedItems('Associated Services', 'associatedServices')}
          </>
        ) : (
          <>
            {renderField('Category', 'category', 'select', [
              { value: 'Product', label: 'Product' },
              { value: 'Service', label: 'Service' }
            ])}
            {renderField('Type', 'type', 'select', [
              { value: 'Software', label: 'Software' },
              { value: 'Hardware', label: 'Hardware' },
              { value: 'Consulting', label: 'Consulting' }
            ])}
            {renderField('Delivery Type', 'deliveryType', 'select', [
              { value: 'Internal', label: 'Internal' },
              { value: 'Third Party', label: 'Third Party' }
            ])}
            {item.deliveryType === 'Third Party' && 
              renderField('Third Party Provider', 'thirdPartyProvider')}
            {renderField('Market Share (%)', 'marketShare', 'number')}
            {renderField('Market Growth (%)', 'marketGrowth', 'number')}
            {renderField('PLC Stage', 'plcStage', 'select', [
              { value: 'Introduction', label: 'Introduction' },
              { value: 'Growth', label: 'Growth' },
              { value: 'Maturity', label: 'Maturity' },
              { value: 'Decline', label: 'Decline' }
            ])}
            {renderField('Pricing', 'pricing', 'multiline')}
            {renderField('Features', 'features', 'multiline-array')}
            {renderField('Benefits', 'benefits', 'multiline-array')}
            {renderField('Technical Specifications', 'technicalSpecifications', 'multiline')}
            {renderAssociatedPropositions()}
          </>
        )}
      </>
    );
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const renderAssociatedItems = (label, key) => {
    const { item, isEditing } = detailDialog;
    
    return (
      <Card sx={{ mb: 2 }}>
        <CardHeader title={label} />
        <CardContent>
          {isEditing ? (
            <FormControl fullWidth>
              <Select
                multiple
                value={item[key] || []}
                onChange={(e) => setDetailDialog(prev => ({
                  ...prev,
                  item: { ...prev.item, [key]: e.target.value }
                }))}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => {
                      const associatedItem = portfolioItems.find(p => p.id === value);
                      return associatedItem ? (
                        <Chip
                          key={value}
                          label={associatedItem.name}
                          onDelete={() => {
                            const updatedItems = item[key].filter(id => id !== value);
                            setDetailDialog(prev => ({
                              ...prev,
                              item: { ...prev.item, [key]: updatedItems }
                            }));
                          }}
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                        />
                      ) : null;
                    })}
                  </Box>
                )}
              >
                {portfolioItems
                  .filter(item => item.category === (key === 'associatedProducts' ? 'Product' : 'Service'))
                  .map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          ) : (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {(item[key] || []).map((itemId) => {
                const associatedItem = portfolioItems.find(p => p.id === itemId);
                return associatedItem ? (
                  <Chip
                    key={itemId}
                    label={associatedItem.name}
                    sx={{ m: 0.5 }}
                  />
                ) : null;
              })}
              {(!item[key] || item[key].length === 0) && (
                <Typography color="text.secondary">None assigned</Typography>
              )}
            </Box>
          )}
        </CardContent>
      </Card>
    );
  };

  const renderAssociatedPropositions = () => {
    const { item, isEditing } = detailDialog;
    const associatedProps = propositions.filter(prop => 
      prop.associatedProducts?.includes(item.id) || 
      prop.associatedServices?.includes(item.id)
    );

    return (
      <Card sx={{ mb: 2 }}>
        <CardHeader title="Associated Propositions" />
        <CardContent>
          {isEditing ? (
            <FormControl fullWidth>
              <Select
                multiple
                value={associatedProps.map(prop => prop.id)}
                onChange={(e) => {
                  const updatedItem = { ...item, associatedPropositions: e.target.value };
                  setDetailDialog(prev => ({ ...prev, item: updatedItem }));
                  handleUpdateAssociatedPropositions(updatedItem);
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => {
                      const prop = propositions.find(p => p.id === value);
                      return prop ? (
                        <Chip
                          key={value}
                          label={prop.name}
                          onDelete={() => {
                            const updatedPropositions = selected.filter(id => id !== value);
                            const updatedItem = { ...item, associatedPropositions: updatedPropositions };
                            setDetailDialog(prev => ({ ...prev, item: updatedItem }));
                            handleUpdateAssociatedPropositions(updatedItem);
                          }}
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                        />
                      ) : null;
                    })}
                  </Box>
                )}
              >
                {propositions.map((prop) => (
                  <MenuItem key={prop.id} value={prop.id}>
                    {prop.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {associatedProps.map((prop) => (
                <Chip
                  key={prop.id}
                  label={prop.name}
                  sx={{ m: 0.5 }}
                />
              ))}
              {associatedProps.length === 0 && (
                <Typography color="text.secondary">None assigned</Typography>
              )}
            </Box>
          )}
        </CardContent>
      </Card>
    );
  };

  const sortedItems = useMemo(() => {
    const items = activeTab === 0 ? propositions : portfolioItems;
    return sortItems(items);
  }, [activeTab, propositions, portfolioItems, sortConfig]);

  const visibleColumns = useMemo(() => {
    return activeTab === 0 ? visiblePropositionColumns : visiblePortfolioColumns;
  }, [activeTab, visiblePropositionColumns, visiblePortfolioColumns]);

  if (!selectedOrg) {
    return (
      <Box sx={{ p: theme.spacing(3) }}>
        <Typography variant="h6" color="text.secondary">
          Please select an organization to view and manage its portfolio.
        </Typography>
      </Box>
    );
  }

  if (loading) {
    return (
      <Fade in={true} style={{ transitionDelay: '300ms' }}>
        <div>{renderLoadingSkeleton()}</div>
      </Fade>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <WelcomePanel 
        title="Portfolio & Propositions"
        subtitle="Define and manage your core value propositions, products and services"
      />
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      
      {loading ? (
        renderLoadingSkeleton()
      ) : (
        <>
          <Tabs value={activeTab} onChange={handleTabChange}>
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.label} />
            ))}
          </Tabs>
          
          <InfoPanel tabIndex={activeTab} />
          
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => handleOpenDialog(null, activeTab === 0 ? 'proposition' : 'portfolioItem')}
            >
              Add {activeTab === 0 ? 'Proposition' : 'Portfolio Item'}
            </Button>
            
            <ColumnSelector
              columns={visibleColumns}
              visibleColumns={visibleColumns}
              onToggle={(column) => handleColumnToggle(column, activeTab === 0)}
            />
          </Box>
          
          {renderTable(sortedItems, visibleColumns, activeTab === 0 ? 'proposition' : 'portfolioItem')}
        </>
      )}
      
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogTitle sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>
          {isEditing ? `Edit ${itemType === 'proposition' ? 'Proposition' : 'Portfolio Item'}` : `Add New ${itemType === 'proposition' ? 'Proposition' : 'Portfolio Item'}`}
        </DialogTitle>
        <DialogContent sx={{ pt: theme.spacing(2) }}>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={currentItem?.name || ''}
            onChange={(e) => setCurrentItem({ ...currentItem, name: e.target.value })}
            sx={{ mb: theme.spacing(2) }}
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            multiline
            rows={4}
            value={currentItem?.description || ''}
            onChange={(e) => setCurrentItem({ ...currentItem, description: e.target.value })}
            sx={{ mb: theme.spacing(2) }}
          />
          {itemType === 'proposition' && (
            <>
              <FormControl fullWidth margin="dense" sx={{ mb: theme.spacing(2) }}>
                <InputLabel>Parent Proposition</InputLabel>
                <Select
                  value={currentItem?.parentPropositionId || ''}
                  onChange={(e) => setCurrentItem({ ...currentItem, parentPropositionId: e.target.value })}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {propositions.filter(p => p.id !== currentItem?.id).map((prop) => (
                    <MenuItem key={prop.id} value={prop.id}>
                      {prop.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                margin="dense"
                label="What It Is"
                fullWidth
                multiline
                rows={4}
                value={currentItem?.whatItIs || ''}
                onChange={(e) => setCurrentItem({ ...currentItem, whatItIs: e.target.value })}
                sx={{ mb: theme.spacing(2) }}
              />
              <TextField
                margin="dense"
                label="How It Works"
                fullWidth
                multiline
                rows={4}
                value={currentItem?.howItWorks || ''}
                onChange={(e) => setCurrentItem({ ...currentItem, howItWorks: e.target.value })}
                sx={{ mb: theme.spacing(2) }}
              />
              <TextField
                margin="dense"
                label="Why It Matters"
                fullWidth
                multiline
                rows={4}
                value={currentItem?.whyItMatters || ''}
                onChange={(e) => setCurrentItem({ ...currentItem, whyItMatters: e.target.value })}
                sx={{ mb: theme.spacing(2) }}
              />
              <FormControl fullWidth margin="dense" sx={{ mb: theme.spacing(2) }}>
                <InputLabel>Associated Services</InputLabel>
                <Select
                  multiple
                  value={currentItem?.associatedServices || []}
                  onChange={(e) => setCurrentItem({ ...currentItem, associatedServices: e.target.value })}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {/* Add your service options here */}
                  <MenuItem value="Service 1">Service 1</MenuItem>
                  <MenuItem value="Service 2">Service 2</MenuItem>
                  <MenuItem value="Service 3">Service 3</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin="dense" sx={{ mb: theme.spacing(2) }}>
                <InputLabel>Associated Products</InputLabel>
                <Select
                  multiple
                  value={currentItem?.associatedProducts || []}
                  onChange={(e) => setCurrentItem({ ...currentItem, associatedProducts: e.target.value })}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => {
                        const product = portfolioItems.find(p => p.id === value);
                        return product ? (
                          <Chip key={value} label={product.name} />
                        ) : null;
                      })}
                    </Box>
                  )}
                >
                  {portfolioItems.map((product) => (
                    <MenuItem key={product.id} value={product.id}>
                      {product.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          {itemType === 'portfolioItem' && (
            <>
              <FormControl fullWidth margin="dense" sx={{ mb: theme.spacing(2) }}>
                <InputLabel>Category</InputLabel>
                <Select
                  value={currentItem?.category || ''}
                  onChange={(e) => setCurrentItem({ ...currentItem, category: e.target.value })}
                >
                  <MenuItem value="Product">Product</MenuItem>
                  <MenuItem value="Service">Service</MenuItem>
                  {/* Add more categories as needed */}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="dense" sx={{ mb: theme.spacing(2) }}>
                <InputLabel>Type</InputLabel>
                <Select
                  value={currentItem?.type || ''}
                  onChange={(e) => setCurrentItem({ ...currentItem, type: e.target.value })}
                >
                  <MenuItem value="Software">Software</MenuItem>
                  <MenuItem value="Hardware">Hardware</MenuItem>
                  <MenuItem value="Consulting">Consulting</MenuItem>
                  {/* Add more types as needed */}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="dense" sx={{ mb: theme.spacing(2) }}>
                <InputLabel>Delivery Type</InputLabel>
                <Select
                  value={currentItem?.deliveryType || 'Internal'}
                  onChange={(e) => setCurrentItem({ ...currentItem, deliveryType: e.target.value })}
                >
                  <MenuItem value="Internal">Internal</MenuItem>
                  <MenuItem value="Third Party">Third Party</MenuItem>
                </Select>
              </FormControl>
              {currentItem?.deliveryType === 'Third Party' && (
                <TextField
                  margin="dense"
                  label="Third Party Provider"
                  fullWidth
                  value={currentItem?.thirdPartyProvider || ''}
                  onChange={(e) => setCurrentItem({ ...currentItem, thirdPartyProvider: e.target.value })}
                  sx={{ mb: theme.spacing(2) }}
                />
              )}
              <TextField
                margin="dense"
                label="Market Share (%)"
                fullWidth
                type="number"
                value={currentItem?.marketShare || ''}
                onChange={(e) => setCurrentItem({ ...currentItem, marketShare: parseFloat(e.target.value) })}
                sx={{ mb: theme.spacing(2) }}
              />
              <TextField
                margin="dense"
                label="Market Growth (%)"
                fullWidth
                type="number"
                value={currentItem?.marketGrowth || ''}
                onChange={(e) => setCurrentItem({ ...currentItem, marketGrowth: parseFloat(e.target.value) })}
                sx={{ mb: theme.spacing(2) }}
              />
              <FormControl fullWidth margin="dense" sx={{ mb: theme.spacing(2) }}>
                <InputLabel>PLC Stage</InputLabel>
                <Select
                  value={currentItem?.plcStage || 'Introduction'}
                  onChange={(e) => setCurrentItem({ ...currentItem, plcStage: e.target.value })}
                >
                  <MenuItem value="Introduction">Introduction</MenuItem>
                  <MenuItem value="Growth">Growth</MenuItem>
                  <MenuItem value="Maturity">Maturity</MenuItem>
                  <MenuItem value="Decline">Decline</MenuItem>
                </Select>
              </FormControl>
              <TextField
                margin="dense"
                label="Pricing"
                fullWidth
                value={currentItem?.pricing || ''}
                onChange={(e) => setCurrentItem({ ...currentItem, pricing: e.target.value })}
                sx={{ mb: theme.spacing(2) }}
              />
              <TextField
                margin="dense"
                label="Features"
                fullWidth
                multiline
                rows={4}
                value={currentItem?.features?.join('\n') || ''}
                onChange={(e) => setCurrentItem({ ...currentItem, features: e.target.value.split('\n').map(item => item.trim()) })}
                helperText="Enter each feature on a new line"
                sx={{ mb: theme.spacing(2) }}
              />
              <TextField
                margin="dense"
                label="Benefits"
                fullWidth
                multiline
                rows={4}
                value={currentItem?.benefits?.join('\n') || ''}
                onChange={(e) => setCurrentItem({ ...currentItem, benefits: e.target.value.split('\n').map(item => item.trim()) })}
                helperText="Enter each benefit on a new line"
                sx={{ mb: theme.spacing(2) }}
              />
              <TextField
                margin="dense"
                label="Technical Specifications"
                fullWidth
                multiline
                rows={4}
                value={currentItem?.technicalSpecifications || ''}
                onChange={(e) => setCurrentItem({ ...currentItem, technicalSpecifications: e.target.value })}
                sx={{ mb: theme.spacing(2) }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ p: theme.spacing(3) }}>
          <Button onClick={handleCloseDialog} color="inherit">Cancel</Button>
          <Button onClick={handleSave} variant="contained" color="primary">Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={fullEditDialog.open} onClose={handleFullEditClose} fullWidth maxWidth="md">
        <DialogTitle sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>
          Edit {activeTab === 0 ? 'Proposition' : 'Product/Service'}
        </DialogTitle>
        <DialogContent sx={{ pt: theme.spacing(2) }}>
          {fullEditDialog.item && (
            <>
              <TextField
                autoFocus
                margin="dense"
                label="Name"
                fullWidth
                value={fullEditDialog.item.name || ''}
                onChange={(e) => setFullEditDialog({ ...fullEditDialog, item: { ...fullEditDialog.item, name: e.target.value } })}
                sx={{ mb: theme.spacing(2) }}
              />
              <TextField
                margin="dense"
                label="Description"
                fullWidth
                multiline
                rows={4}
                value={fullEditDialog.item.description || ''}
                onChange={(e) => setFullEditDialog({ ...fullEditDialog, item: { ...fullEditDialog.item, description: e.target.value } })}
                sx={{ mb: theme.spacing(2) }}
              />
              {/* Add more fields here based on whether it's a proposition or product/service */}
              {activeTab === 0 ? (
                // Proposition-specific fields
                <>
                  <TextField
                    margin="dense"
                    label="What It Is"
                    fullWidth
                    multiline
                    rows={4}
                    value={fullEditDialog.item.whatItIs || ''}
                    onChange={(e) => setFullEditDialog({ ...fullEditDialog, item: { ...fullEditDialog.item, whatItIs: e.target.value } })}
                    sx={{ mb: theme.spacing(2) }}
                  />
                  {/* Add more proposition-specific fields */}
                </>
              ) : (
                // Product/Service-specific fields
                <>
                  <FormControl fullWidth margin="dense" sx={{ mb: theme.spacing(2) }}>
                    <InputLabel>Category</InputLabel>
                    <Select
                      value={fullEditDialog.item.category || ''}
                      onChange={(e) => setFullEditDialog({ ...fullEditDialog, item: { ...fullEditDialog.item, category: e.target.value } })}
                    >
                      <MenuItem value="Product">Product</MenuItem>
                      <MenuItem value="Service">Service</MenuItem>
                    </Select>
                  </FormControl>
                  {/* Add more product/service-specific fields */}
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFullEditClose} color="inherit">Cancel</Button>
          <Button onClick={handleFullEditSave} variant="contained" color="primary">Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={detailDialog.open} onClose={handleDetailClose} fullWidth maxWidth="md">
        <DialogTitle sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>
          {detailDialog.type === 'proposition' ? 'Proposition Details' : 'Product/Service Details'}
          <IconButton
            aria-label="close"
            onClick={handleDetailClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {renderDetailContent()}
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            control={
              <Switch
                checked={detailDialog.isEditing}
                onChange={() => setDetailDialog(prev => ({ ...prev, isEditing: !prev.isEditing }))}
                color="primary"
              />
            }
            label="Edit Mode"
          />
          {detailDialog.isEditing && (
            <Button onClick={handleDetailSave} color="primary" variant="contained">
              Save Changes
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Dialog open={readMoreDialog.open} onClose={closeReadMoreDialog} maxWidth="md" fullWidth>
        <DialogTitle>{readMoreDialog.title}</DialogTitle>
        <DialogContent>
          <Typography>{readMoreDialog.content}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeReadMoreDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default React.memo(PortfolioManager);