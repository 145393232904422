import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#4CAF50', // Your Puāwai green
    },
    secondary: {
      main: '#FFA000', // Your secondary color
    },
    // ... other color definitions
  },
  // ... other theme settings
});