import React, { useState, useEffect } from 'react';
import {
  Box, Typography, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Select, MenuItem, FormControl, InputLabel,
  Tooltip, IconButton, Snackbar, Alert, Card, CardContent, Divider, useTheme, useMediaQuery
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, AccessTime as AccessTimeIcon } from '@mui/icons-material';
import { format, parseISO } from 'date-fns';
import bidTimeTrackingService from '../../services/bidTimeTrackingService';
import bidbudgetService from '../../services/bidbudgetService';
import { getUser } from '../../services/userService';

const TimeTracking = ({ bidId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const today = new Date().toISOString().split('T')[0];
  const [timeEntries, setTimeEntries] = useState([]);
  const [newEntry, setNewEntry] = useState({ date: today, hours: '', role: '', description: '' });
  const [budgetRoles, setBudgetRoles] = useState([]);
  const [customRole, setCustomRole] = useState('');
  const [budgets, setBudgets] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [userCache, setUserCache] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [editingEntry, setEditingEntry] = useState(null);

  useEffect(() => {
    fetchTimeEntries();
    fetchBudgetRoles();
    fetchBudgets();
    fetchCurrentUser();
  }, [bidId]);

  const fetchTimeEntries = async () => {
    try {
      const entries = await bidTimeTrackingService.listTimeEntries(bidId);
      setTimeEntries(entries);
      await updateUserCache(entries);
    } catch (error) {
      showSnackbar('Error fetching time entries', 'error');
    }
  };

  const fetchBudgetRoles = async () => {
    try {
      const budgets = await bidbudgetService.listBidBudgets(bidId);
      const roles = budgets.map(budget => budget.role).sort((a, b) => a.localeCompare(b));
      setBudgetRoles(roles);
    } catch (error) {
      showSnackbar('Error fetching budget roles', 'error');
    }
  };

  const fetchBudgets = async () => {
    try {
      const fetchedBudgets = await bidbudgetService.listBidBudgets(bidId);
      setBudgets(fetchedBudgets);
    } catch (error) {
      showSnackbar('Error fetching budgets', 'error');
    }
  };

  const fetchCurrentUser = async () => {
    try {
      const user = await getUser();
      setCurrentUser(user);
      setNewEntry(prev => ({ ...prev, userId: user.id }));
    } catch (error) {
      showSnackbar('Error fetching current user', 'error');
    }
  };

  const updateUserCache = async (entries) => {
    const uniqueUserIds = [...new Set(entries.map(entry => entry.userId))];
    const userDetails = await Promise.all(uniqueUserIds.map(fetchUserDetails));
    const newUserCache = Object.fromEntries(userDetails.map(user => [user.id, user]));
    setUserCache(prevCache => ({ ...prevCache, ...newUserCache }));
  };

  const fetchUserDetails = async (userId) => {
    if (userCache[userId]) return userCache[userId];
    try {
      return await getUser(userId);
    } catch (error) {
      console.error(`Error fetching user details for ${userId}:`, error);
      return { id: userId, displayName: 'Unknown User' };
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEntry(prev => ({ ...prev, [name]: value }));
  };

  const handleRoleChange = (e) => {
    const value = e.target.value;
    if (value === 'Custom') {
      setNewEntry(prev => ({ ...prev, role: '' }));
    } else {
      setNewEntry(prev => ({ ...prev, role: value }));
      setCustomRole('');
    }
  };

  const handleCustomRoleChange = (e) => {
    const value = e.target.value;
    setCustomRole(value);
    setNewEntry(prev => ({ ...prev, role: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const entryWithUserId = { ...newEntry, userId: currentUser.id };
      if (editingEntry) {
        await bidTimeTrackingService.updateTimeEntry(bidId, editingEntry.id, entryWithUserId);
        showSnackbar('Time entry updated successfully', 'success');
      } else {
        await bidTimeTrackingService.createTimeEntry(bidId, entryWithUserId);
        showSnackbar('Time entry added successfully', 'success');
      }
      fetchTimeEntries();
      resetForm();
    } catch (error) {
      showSnackbar('Error saving time entry', 'error');
    }
  };

  const handleEdit = (entry) => {
    setEditingEntry(entry);
    setNewEntry({
      date: entry.date,
      hours: entry.hours,
      role: entry.role,
      description: entry.description
    });
    setCustomRole(budgetRoles.includes(entry.role) ? '' : entry.role);
  };

  const handleDelete = async (entryId) => {
    try {
      await bidTimeTrackingService.deleteTimeEntry(bidId, entryId);
      showSnackbar('Time entry deleted successfully', 'success');
      fetchTimeEntries();
    } catch (error) {
      showSnackbar('Error deleting time entry', 'error');
    }
  };

  const resetForm = () => {
    setNewEntry({ date: today, hours: '', role: '', description: '' });
    setCustomRole('');
    setEditingEntry(null);
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: 'auto', p: 3 }}>
      <Card elevation={3} sx={{ mb: 4, overflow: 'visible' }}>
        <CardContent>
          <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <AccessTimeIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
            Time Tracking
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} alignItems="flex-end">
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  type="date"
                  name="date"
                  label="Date"
                  value={newEntry.date}
                  onChange={handleInputChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  name="hours"
                  label="Hours"
                  type="number"
                  value={newEntry.hours}
                  onChange={handleInputChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Role</InputLabel>
                  <Select
                    value={newEntry.role}
                    onChange={handleRoleChange}
                    label="Role"
                    required
                  >
                    <MenuItem value="">Select a role</MenuItem>
                    {budgetRoles.map((role, index) => (
                      <MenuItem key={index} value={role}>{role}</MenuItem>
                    ))}
                    <MenuItem value="Custom">Custom</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {newEntry.role === 'Custom' && (
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    name="customRole"
                    label="Custom Role"
                    value={customRole}
                    onChange={handleCustomRoleChange}
                    fullWidth
                    required
                  />
                </Grid>
              )}
              <Grid item xs={12} md={3}>
                <TextField
                  name="description"
                  label="Description"
                  value={newEntry.description}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  rows={1}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Button 
                  type="submit" 
                  variant="contained" 
                  color="primary" 
                  fullWidth 
                  startIcon={editingEntry ? <EditIcon /> : <AddIcon />}
                  sx={{ height: '56px' }}
                >
                  {editingEntry ? 'Update' : 'Add'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>

      <Card elevation={3}>
        <CardContent>
          <Typography variant="h6" gutterBottom>Time Entries</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Hours</TableCell>
                  <TableCell>Role</TableCell>
                  {!isMobile && <TableCell>Team Member</TableCell>}
                  {!isMobile && <TableCell>Description</TableCell>}
                  <TableCell>Total Cost</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {timeEntries.map((entry) => {
                  const budget = budgets.find(b => b.role === entry.role);
                  const hourlyRate = budget ? parseFloat(budget.hourlyRate) : 0;
                  const totalCost = hourlyRate * parseFloat(entry.hours);
                  const user = userCache[entry.userId] || { displayName: 'Loading...' };
                  return (
                    <TableRow key={entry.id} hover>
                      <TableCell>{format(parseISO(entry.date), 'dd/MM/yyyy')}</TableCell>
                      <TableCell>{entry.hours}</TableCell>
                      <TableCell>{entry.role}</TableCell>
                      {!isMobile && <TableCell>{user.displayName}</TableCell>}
                      {!isMobile && <TableCell>{entry.description}</TableCell>}
                      <TableCell>${totalCost.toFixed(2)}</TableCell>
                      <TableCell>
                        <Tooltip title="Edit">
                          <IconButton onClick={() => handleEdit(entry)} size="small" sx={{ color: theme.palette.primary.main }}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton onClick={() => handleDelete(entry.id)} size="small" sx={{ color: theme.palette.error.main }}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity} 
          sx={{ width: '100%' }}
          elevation={6}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TimeTracking;