import React, { useState, useEffect } from 'react';
import { Box, Fab, Tooltip, Drawer, IconButton, useTheme, CircularProgress, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Close as CloseIcon, Fullscreen as FullscreenIcon, FullscreenExit as FullscreenExitIcon, Minimize as MinimizeIcon } from '@mui/icons-material';
import ChatInterface from './puawaiAIinterface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling } from '@fortawesome/free-solid-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';

const PuawaiIntelligenceInterface = ({ userProfile, organisation, toneOfVoice, topOffset = 0, bids, canvases }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const theme = useTheme();

  const handleOpen = () => {
    setShowDisclaimer(true);
  };

  const handleAccept = () => {
    setShowDisclaimer(false);
    setIsLoading(true);
    setIsOpen(true);
    setIsMinimized(false);
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsMinimized(false);
    setIsFullScreen(false);
  };

  const handleMinimize = () => {
    setIsMinimized(true);
    setIsOpen(false);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setIsLoading(false), 1000); // Simulating loading time
    }
  }, [isOpen]);

  const floatingIconVariants = {
    initial: { y: 0 },
    animate: {
      y: [-5, 5],
      transition: {
        y: {
          duration: 1.5,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "easeInOut"
        }
      }
    },
    hover: {
      scale: 1.1,
      transition: {
        duration: 0.3
      }
    },
  };

  const disclaimerText = (
    <>
      <Typography variant="h6" gutterBottom>
        Kia ora! I'm your friendly Puāwai Intelligence assistant.
      </Typography>
      <Typography paragraph>
        I'm here to help you, but please remember:
      </Typography>
      <Box component="ul" sx={{ pl: 2 }}>
        <Typography component="li">I'm still learning and might make mistakes.</Typography>
        <Typography component="li">I only use your data to assist you directly.</Typography>
        <Typography component="li">I don't store or use your information for training.</Typography>
        <Typography component="li">Your privacy and data security are important to me.</Typography>
      </Box>
      <Typography paragraph>
        Let's work together with kindness and patience to achieve great things! <FontAwesomeIcon icon={faSeedling} style={{ color: theme.palette.primary.main }} />
      </Typography>
    </>
  );

  return (
    <>
      <AnimatePresence>
        {(!isOpen || isMinimized) && (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{ duration: 0.3 }}
          >
            <Tooltip title="Open Puāwai Intelligence">
              <motion.div
                variants={floatingIconVariants}
                initial="initial"
                animate="animate"
                whileHover="hover"
                style={{
                  position: 'fixed',
                  bottom: 24,
                  right: 24,
                  zIndex: theme.zIndex.drawer + 1
                }}
              >
                <Fab 
                  color="primary" 
                  onClick={handleOpen}
                  sx={{
                    width: 80,
                    height: 80,
                    bgcolor: theme.palette.primary.main,
                    '&:hover': { bgcolor: theme.palette.primary.dark },
                  }}
                >
                  <FontAwesomeIcon icon={faSeedling} style={{ fontSize: '2rem' }} />
                </Fab>
              </motion.div>
            </Tooltip>
          </motion.div>
        )}
      </AnimatePresence>
      <Dialog 
        open={showDisclaimer} 
        onClose={() => setShowDisclaimer(false)}
        PaperProps={{
          sx: {
            borderRadius: 2,
            maxWidth: 400,
          }
        }}
      >
        <DialogTitle sx={{ 
          bgcolor: theme.palette.primary.main, 
          color: theme.palette.primary.contrastText,
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          <FontAwesomeIcon icon={faSeedling} style={{ color: theme.palette.primary.contrastText, fontSize: '1.5em' }} /> Welcome to Puāwai Intelligence!
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          {disclaimerText}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', px: 3, pb: 3 }}>
          <Button onClick={() => setShowDisclaimer(false)} variant="outlined">
            Maybe Later
          </Button>
          <Button onClick={handleAccept} variant="contained" color="primary">
            Accept & Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Drawer
        anchor="right"
        open={isOpen && !isMinimized}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: isFullScreen ? '100%' : '400px',
            maxWidth: isFullScreen ? '100%' : '90vw',
            height: isFullScreen ? `calc(100% - ${topOffset}px)` : '80vh',
            top: isFullScreen ? `${topOffset}px` : 'auto',
            bottom: isFullScreen ? 0 : 16,
            right: isFullScreen ? 0 : 16,
            borderRadius: isFullScreen ? 0 : '16px 0 0 16px',
            overflow: 'hidden',
            bgcolor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            boxShadow: theme.shadows[10],
            transition: 'all 0.3s ease-in-out',
          },
        }}
      >
        <Box sx={{ 
          position: 'absolute', 
          top: 0,
          right: 0,
          zIndex: 1,
          display: 'flex',
          padding: 1,
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          borderRadius: '0 0 0 8px',
        }}>
          <IconButton 
            onClick={handleMinimize} 
            size="small"
            sx={{ color: theme.palette.primary.contrastText }}
          >
            <MinimizeIcon />
          </IconButton>
          <IconButton 
            onClick={toggleFullScreen} 
            size="small"
            sx={{ color: theme.palette.primary.contrastText }}
          >
            {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
          <IconButton 
            onClick={handleClose} 
            size="small"
            sx={{ color: theme.palette.primary.contrastText }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : (
          <ChatInterface
            userProfile={userProfile}
            organisation={organisation}
            toneOfVoice={toneOfVoice}
            isOpen={isOpen && !isMinimized}
            onClose={handleClose}
            theme={theme}
            bids={bids}
            canvases={canvases}
            portfolio={organisation.portfolio}
          />
        )}
      </Drawer>
    </>
  );
};

export default PuawaiIntelligenceInterface;
