import api from './api';
import { getAuthHeader } from './authService';

const apiCall = async (method, endpoint, data = null) => {
  try {
    const token = await getAuthHeader();
    const config = {
      method,
      url: `/api/templates${endpoint}`,
      headers: { 
        Authorization: token,
        'Content-Type': 'application/json'
      }
    };
    
    if (data) {
      config.data = {
        ...data,
        updatedAt: new Date().toISOString()
      };
    }

    console.log(`Making ${method} request to ${endpoint}`, { 
      ...config, 
      headers: { ...config.headers, Authorization: 'REDACTED' } 
    });
    
    const response = await api(config);
    return response.data;
  } catch (error) {
    console.error('Template API error:', error.response || error);
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(`API error: ${errorMessage}`);
  }
};

/**
 * Create a new template
 * @param {string} orgId - Organization ID
 * @param {Object} templateData - Template data including sections, variables, etc.
 * @returns {Object} Created template
 */
export const createTemplate = async (orgId, templateData) => {
  if (!orgId) {
    throw new Error('Organization ID is required');
  }

  try {
    const response = await apiCall('post', `/${orgId}`, {
      ...templateData,
      organisationId: orgId
    });
    return response;
  } catch (error) {
    console.error('Create template error:', error);
    throw new Error(error.response?.data?.message || error.message);
  }
};

/**
 * Get template by ID
 * @param {string} orgId - Organization ID
 * @param {string} templateId - Template ID
 * @returns {Object} Template data
 */
export const getTemplate = async (orgId, templateId) => {
  try {
    return apiCall('get', `/${orgId}/${templateId}`);
  } catch (error) {
    throw new Error(`Failed to get template: ${error.message}`);
  }
};

/**
 * List all templates for an organization
 * @param {string} orgId - Organization ID
 * @param {Object} filters - Optional filters (type, status, etc.)
 * @returns {Array} List of templates
 */
export const listTemplates = async (orgId) => {
  try {
    if (!orgId) {
      throw new Error('Organization ID is required');
    }

    console.log('Fetching templates for org:', orgId);
    const token = await getAuthHeader();
    const response = await api.get(`/api/templates/${orgId}`, {
      headers: { 
        Authorization: token,
        'Content-Type': 'application/json'
      }
    });
    
    // Filter for template type items and ensure they have metadata
    const templates = (response.data || []).filter(item => 
      item.metadata?.type === 'template' || 
      item.type === 'template' ||
      item.metadata?.type === 'proposal' // Include proposal templates
    );

    console.log('Found templates:', templates.length);
    return templates;
  } catch (error) {
    console.error('Error fetching templates:', error);
    if (error.response?.status === 404) {
      return []; // Return empty array for 404
    }
    handleApiError(error);
  }
};

/**
 * Update an existing template
 * @param {string} orgId - Organization ID
 * @param {string} templateId - Template ID
 * @param {Object} updateData - Updated template data
 * @returns {Object} Updated template
 */
export const updateTemplate = async (orgId, templateId, updateData) => {
  try {
    return apiCall('put', `/${orgId}/${templateId}`, updateData);
  } catch (error) {
    throw new Error(`Failed to update template: ${error.message}`);
  }
};

/**
 * Delete a template
 * @param {string} orgId - Organization ID
 * @param {string} templateId - Template ID
 * @returns {Object} Deletion response
 */
export const deleteTemplate = async (orgId, templateId) => {
  try {
    return apiCall('delete', `/${orgId}/${templateId}`);
  } catch (error) {
    throw new Error(`Failed to delete template: ${error.message}`);
  }
};

/**
 * Generate document from template
 * @param {string} orgId - Organization ID
 * @param {string} templateId - Template ID
 * @param {Object} data - Data to populate template (CRM, portfolio data, etc.)
 * @returns {Object} Generated document
 */
export const generateDocument = async (orgId, templateId, data) => {
  try {
    return apiCall('post', `/${orgId}/${templateId}?action=generate`, data);
  } catch (error) {
    throw new Error(`Failed to generate document: ${error.message}`);
  }
};

/**
 * Clone an existing template
 * @param {string} orgId - Organization ID
 * @param {string} templateId - Template ID to clone
 * @param {Object} overrides - Optional data to override in the clone
 * @returns {Object} Cloned template
 */
export const cloneTemplate = async (orgId, templateId, overrides = {}) => {
  try {
    return apiCall('post', `/${orgId}/${templateId}?action=clone`, overrides);
  } catch (error) {
    throw new Error(`Failed to clone template: ${error.message}`);
  }
};

/**
 * Get template variables data
 * @param {string} orgId - Organization ID
 * @param {string} templateId - Template ID
 * @param {Object} context - Context data (opportunity, customer, etc.)
 * @returns {Object} Resolved variable data
 */
export const getTemplateVariables = async (orgId, templateId, context) => {
  try {
    return apiCall('post', `/${orgId}/${templateId}?action=variables`, { context });
  } catch (error) {
    throw new Error(`Failed to get template variables: ${error.message}`);
  }
};

/**
 * Preview template with data
 * @param {string} orgId - Organization ID
 * @param {string} templateId - Template ID
 * @param {Object} data - Preview data
 * @returns {Object} Preview content
 */
export const previewTemplate = async (orgId, templateId, data) => {
  try {
    return apiCall('post', `/${orgId}/${templateId}?action=preview`, data);
  } catch (error) {
    throw new Error(`Failed to preview template: ${error.message}`);
  }
};

/**
 * Export template to different format
 * @param {string} orgId - Organization ID
 * @param {string} templateId - Template ID
 * @param {string} format - Export format (pdf, docx, etc.)
 * @returns {Blob} Exported file
 */
export const exportTemplate = async (orgId, templateId, format = 'pdf') => {
  try {
    const response = await apiCall('get', `/${orgId}/${templateId}/export/${format}`);
    return new Blob([response], { type: `application/${format}` });
  } catch (error) {
    throw new Error(`Failed to export template: ${error.message}`);
  }
};

export default {
  createTemplate,
  getTemplate,
  listTemplates,
  updateTemplate,
  deleteTemplate,
  generateDocument,
  cloneTemplate,
  getTemplateVariables,
  previewTemplate,
  exportTemplate
}; 