import api from './api';
import { getAuthHeader } from './authService';
import { getUser } from './userService';  // Add this import
import axios from 'axios';
import { API_BASE_URL } from './api';

//api service is for api related functions such as getting api details, updating api details, deleting api details, etc.
//authservice is for authorisation and authentication functions which works with msal and azure bsc and azure functions.
//userservice is for user related functions such as getting user details, updating user details, deleting user details, etc.
//organisation service is for organisation related functions such as getting organisation details, updating organisation details, deleting organisation details, etc.
//bid service is for bid related functions such as getting bid details, updating bid details, deleting bid details, etc.
//bidcanvas service is for bidcanvas related functions such as getting bidcanvas details, updating bidcanvas details, deleting bidcanvas details, etc.
//bidteam service is for bidteam related functions such as getting bidteam details, updating bidteam details, deleting bidteam details, etc.
//invitation service is for invitation related functions such as getting invitation details, updating invitation details, deleting invitation details, etc.
//task service is for task related functions such as getting task details, updating task details, deleting task details, etc.
//team service is for team related functions such as getting team details, updating team details, deleting team details, etc.

const apiCall = async (method, endpoint, data = null) => {
  try {
    const token = await getAuthHeader();
    const config = {
      method,
      url: `/api/organisations${endpoint}`,
      headers: { Authorization: token },
    };
    if (data) {
      config.data = data;
    }
    const response = await api(config);
    return response.data;
  } catch (error) {
    throw new Error(`API error: ${error.response?.data?.message || error.message}`);
  }
};

/**
 * Function to create an organisation
 * @param {Object} organisationData - Data of the organisation to create
 * @returns {Object} - The created or existing organisation data
 */
export const createOrganisation = async (organisationData) => {
  try {
    const token = await getAuthHeader();
    const response = await api.post('/api/organisations', organisationData, {
      headers: { Authorization: token },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data || error.message);
  }
};

/**
 * Function to get details of an organisation by ID
 * @param {string} orgId - ID of the organisation
 * @returns {Object} - The organisation data
 */
export const getOrganisation = async (orgId) => {
  try {
    const response = await apiCall('get', `/${orgId}`);
    
    if (!response || typeof response !== 'object' || !response.id) {
      return null;
    }
    
    if (response.id !== orgId) {
      return null;
    }
    
    return response;
  } catch (error) {
    return null;
  }
};

/**
 * Function to get users associated with an organisation by organisation ID
 * @param {string} orgId - ID of the organisation
 * @returns {Array} - List of users associated with the organisation
 */
export const getOrganisationUsers = async (organisationId) => {
  try {
    const organisation = await apiCall('get', `/${organisationId}`);
    
    if (organisation && Array.isArray(organisation.members)) {
      const userPromises = organisation.members.map(userId => getUser(userId));
      const users = await Promise.all(userPromises);
      
      return users.filter(user => user !== null);
    } else {
      return [];
    }
  } catch (error) {
    throw error;
  }
};

/**
 * Function to update an organisation
 * @param {string} orgId - Organisation ID to update
 * @param {Object} organisationData - Updated data for the organisation
 * @returns {Object} - The updated organisation data
 */
export const updateOrganisation = async (orgId, updateData) => {
  try {
    const token = await getAuthHeader();
    const response = await api.put(`/api/organisations/${orgId}`, updateData, {
      headers: { Authorization: token },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Function to delete an organisation
 * @param {string} orgId - ID of the organisation to delete
 * @returns {Object} - The response from the server
 */
export const deleteOrganisation = async (orgId) => {
  return apiCall('delete', `/${orgId}`);
};

/**
 * Function to list all organisations
 * @returns {Array} - List of all organisations
 */
export const listOrganisations = async () => {
  return apiCall('get', '');
};

/**
 * Function to add a user to an organisation
 * @param {string} organisationId - ID of the organisation
 * @param {string} userId - ID of the user to add
 * @returns {Object} - Updated organisation data
 */
export const addUserToOrganisation = async (organisationId, userId) => {
  return apiCall('post', `/${organisationId}/addUser`, { userId });
};

/**
 * Function to remove a user from an organisation
 * @param {string} organisationId - ID of the organisation
 * @param {string} userId - ID of the user to remove
 * @returns {Object} - Updated organisation data
 */
export const removeUserFromOrganisation = async (organisationId, userId) => {
  return apiCall('post', `/${organisationId}/removeUser`, { userId });
};

/**
 * Function to invite a user to join an organisation via email
 * @param {string} organisationId - ID of the organisation
 * @param {string} email - Email address of the user to invite
 * @returns {Object} - Invite response from the server
 */
export const inviteUserToOrganisation = async (organisationId, email) => {
  return apiCall('post', `/${organisationId}/invite`, { email });
};

/**
 * Function to accept an invitation and join an organisation
 * @param {string} inviteCode - Invitation code
 * @returns {Object} - The joined organisation data
 */
export const acceptInvitation = async (inviteCode) => {
  try {
    const token = await getAuthHeader();
    const response = await api.put(`/api/invitations/${inviteCode}`, { action: 'accept' }, {
      headers: { Authorization: token },
    });
    return response.data.organisation;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

// Add this function to your existing organisationService.js file
export const getOrganisationById = async (orgId) => {
  try {
    const token = await getAuthHeader();
    const response = await api.get(`/api/organisations/${orgId}`, {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching organisation:', error);
    throw error;
  }
};

export default {
  createOrganisation,
  getOrganisation,
  getOrganisationUsers,
  updateOrganisation,
  deleteOrganisation,
  listOrganisations,
  addUserToOrganisation,
  removeUserFromOrganisation,
  inviteUserToOrganisation,
  acceptInvitation,
};
