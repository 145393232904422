import React from 'react';
import { Paper, Box, Typography } from '@mui/material';

const BidHeader = React.memo(({ 
  bidName,
  theme 
}) => {
  return (
    <Paper elevation={3} sx={{ mb: 2 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: 'background.paper' }}>
        <Typography variant="h6" sx={{ p: 2, fontWeight: 'medium', color: theme.palette.primary.main }}>
          {bidName}
        </Typography>
      </Box>
    </Paper>
  );
});

export default BidHeader; 