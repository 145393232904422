import React, { useState } from 'react';
import { 
  Box, 
  AppBar, 
  Toolbar, 
  Typography, 
  IconButton, 
  Menu, 
  MenuItem, 
  Avatar, 
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  useMediaQuery,
  Chip,
  Tooltip
} from '@mui/material';
import { 
  Menu as MenuIcon, 
  MoreVert as MoreVertIcon,
  Dashboard as DashboardIcon,
  Assignment as AssignmentIcon,
  Settings as SettingsIcon,
  ExitToApp as LogoutIcon,
  BrandingWatermark as BrandingWatermarkIcon,
  Work as PortfolioIcon,
  People as PeopleIcon,
  LibraryBooks as LibraryBooksIcon,
  Business as BusinessStrategyIcon,
  Business as BusinessIcon,
  MonetizationOn as FundraisingIcon
} from '@mui/icons-material';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useGlobalStyles from '../../styles/globalStyles';
import { signOut } from '../../services/authService';
import { useSnackbar } from 'notistack';

const drawerWidth = 65;

const Layout = ({ user, selectedOrg }) => {
  const globalClasses = useGlobalStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut();
    } catch (error) {
    }
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => { handleMenuClose(); navigate('/profile'); }}>Profile</MenuItem>
      <MenuItem onClick={() => { handleMenuClose(); navigate('/my-organisation'); }}>
        <BusinessIcon sx={{ mr: 1 }} />
        My Organisation
      </MenuItem>
      <MenuItem onClick={() => { handleMenuClose(); navigate('/subscriptions'); }}>Subscriptions</MenuItem>
      <MenuItem onClick={() => { handleMenuClose(); handleLogout(); }}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(mobileMoreAnchorEl)}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <Avatar alt={user?.name} src={user?.profilePicture} />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const drawerContent = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <Tooltip title="Dashboard" placement="right">
          <ListItemButton
            onClick={() => { navigate('/dashboard'); isMobile && setDrawerOpen(false); }}
            sx={{ 
              justifyContent: 'center',
              px: 1.5,
              minHeight: 48,
            }}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <DashboardIcon />
            </ListItemIcon>
          </ListItemButton>
        </Tooltip>

        <Tooltip title="Business Strategy" placement="right">
          <ListItemButton
            onClick={() => { navigate('/business-strategy'); isMobile && setDrawerOpen(false); }}
            sx={{ 
              justifyContent: 'center',
              px: 1.5,
              minHeight: 48,
            }}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <BusinessStrategyIcon />
            </ListItemIcon>
          </ListItemButton>
        </Tooltip>

        <Tooltip title="Brand" placement="right">
          <ListItemButton
            onClick={() => { 
              if (selectedOrg && (selectedOrg.id || typeof selectedOrg === 'string')) {
                navigate('/brand'); 
                isMobile && setDrawerOpen(false); 
              } else {
                enqueueSnackbar('Please select an organization first', { 
                  variant: 'warning'
                });
              }
            }}
            disabled={!selectedOrg}
            sx={{ 
              justifyContent: 'center',
              px: 1.5,
              minHeight: 48,
              opacity: selectedOrg ? 1 : 0.5
            }}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <BrandingWatermarkIcon />
            </ListItemIcon>
          </ListItemButton>
        </Tooltip>

        <Tooltip title="Portfolio" placement="right">
          <ListItemButton
            onClick={() => { navigate('/portfolio'); isMobile && setDrawerOpen(false); }}
            sx={{ 
              justifyContent: 'center',
              px: 1.5,
              minHeight: 48,
            }}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <PortfolioIcon />
            </ListItemIcon>
          </ListItemButton>
        </Tooltip>

        <Tooltip title="Collateral" placement="right">
          <ListItemButton
            onClick={() => { navigate('/collateral'); isMobile && setDrawerOpen(false); }}
            sx={{ 
              justifyContent: 'center',
              px: 1.5,
              minHeight: 48,
            }}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <LibraryBooksIcon />
            </ListItemIcon>
          </ListItemButton>
        </Tooltip>

        <Tooltip title="Fundraising" placement="right">
          <ListItemButton
            onClick={() => { navigate('/fundraising'); isMobile && setDrawerOpen(false); }}
            sx={{ 
              justifyContent: 'center',
              px: 1.5,
              minHeight: 48,
            }}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <FundraisingIcon />
            </ListItemIcon>
          </ListItemButton>
        </Tooltip>

        <Tooltip title="Customer Relationship Manager" placement="right">
          <ListItemButton
            onClick={() => { navigate('/accounts'); isMobile && setDrawerOpen(false); }}
            sx={{ 
              justifyContent: 'center',
              px: 1.5,
              minHeight: 48,
            }}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <PeopleIcon />
            </ListItemIcon>
          </ListItemButton>
        </Tooltip>

        <Tooltip title="Bids" placement="right">
          <ListItemButton
            onClick={() => { navigate('/bids'); isMobile && setDrawerOpen(false); }}
            sx={{ 
              justifyContent: 'center',
              px: 1.5,
              minHeight: 48,
            }}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <AssignmentIcon />
            </ListItemIcon>
          </ListItemButton>
        </Tooltip>
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <i className="fas fa-seedling" style={{ fontSize: '1.5rem', marginRight: '0.5rem' }}></i>
            <Typography variant="h6" noWrap component="div">
              Puāwai
            </Typography>
            <Chip 
              label="beta" 
              size="small" 
              sx={{ 
                ml: 1, 
                fontSize: '0.7rem', 
                height: '20px',
                fontWeight: 'bold',
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                textTransform: 'lowercase',
                borderRadius: '12px',
                '& .MuiChip-label': {
                  px: 1,
                },
              }} 
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {selectedOrg && (
              <Typography variant="subtitle1" sx={{ mr: 2 }}>
                {selectedOrg.name}
              </Typography>
            )}
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Avatar alt={user?.name} src={user?.profilePicture} />
              </IconButton>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreVertIcon />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <Drawer
        variant="temporary"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawerContent}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { 
            boxSizing: 'border-box', 
            width: drawerWidth,
            overflowX: 'hidden',
            '& .MuiListItemIcon-root': {
              justifyContent: 'center',
            },
            '& .MuiListItem-root': {
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            },
          },
        }}
        open
      >
        {drawerContent}
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          mt: '64px',
          p: 2,
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
