import api from './api';
import { getAuthHeader } from './authService';

const handleApiError = (error) => {
  console.error('Brand API Error:', error);
  const errorMessage = error.response?.data?.message || error.message;
  throw new Error(`API error: ${errorMessage}`);
};

export const getBrandProfile = async (orgId) => {
  try {
    if (!orgId) {
      throw new Error('Organization ID is required');
    }

    const token = await getAuthHeader();
    const response = await api.get(`/api/brand/${orgId}`, {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getBrandData = getBrandProfile;

export const updateBrandData = async (orgId, brandData) => {
  try {
    if (!orgId) {
      throw new Error('Organization ID is required');
    }

    const token = await getAuthHeader();
    const response = await api.put(`/api/brand/${orgId}`, brandData, {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const createBrandData = async (orgId, brandData) => {
  try {
    if (!orgId) {
      throw new Error('Organization ID is required');
    }

    const token = await getAuthHeader();
    const response = await api.post(`/api/brand/${orgId}`, brandData, {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
}; 