import { PublicClientApplication, InteractionStatus, LogLevel } from "@azure/msal-browser";
import { callApi } from '../utils/apiUtils';

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority: `https://${process.env.REACT_APP_B2C_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_B2C_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_B2C_POLICY_NAME}`,
    knownAuthorities: [`${process.env.REACT_APP_B2C_TENANT_NAME}.b2clogin.com`],
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        if (level === LogLevel.Error) {
          console.error(message);
        }
      },
      logLevel: LogLevel.Error,
      piiLoggingEnabled: false
    }
  }
};

export const loginRequest = {
  scopes: [
    "openid",
    "profile",
    "https://puawaiplatform.onmicrosoft.com/36921f40-a3e5-444c-9f05-8ed8abc85694/access_as_user"
  ]
};

const msalInstance = new PublicClientApplication(msalConfig);

// Initialize MSAL instance
const initializeMsal = msalInstance.initialize();

const checkActiveAccount = () => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw new Error('No active account! Verify a user is signed in and setActiveAccount has been called.');
  }
  return account;
};

export const signIn = async () => {
  if (msalInstance.getActiveAccount() !== null) {
    return;
  }

  try {
    const response = await msalInstance.loginPopup(loginRequest);
    msalInstance.setActiveAccount(response.account);
    return response.account;
  } catch (error) {
    console.error("Error during sign-in:", error);
    throw error;
  }
};

export const signOut = async () => {
  try {
    await msalInstance.logoutRedirect({
      postLogoutRedirectUri: window.location.origin,
    });
  } catch (error) {
    console.error("Error during sign-out:", error);
    throw error;
  }
};

export const handleRedirectPromise = async () => {
  try {
    const response = await msalInstance.handleRedirectPromise();
    if (response) {
      msalInstance.setActiveAccount(response.account);
      return response.account;
    } else {
      const currentAccounts = msalInstance.getAllAccounts();
      if (currentAccounts.length > 0) {
        const selectedAccount = await selectAccountPrompt(currentAccounts);
        msalInstance.setActiveAccount(selectedAccount);
        return selectedAccount;
      }
    }
    return null;
  } catch (error) {
    console.error("Error handling redirect:", error);
    throw error;
  }
};

const selectAccountPrompt = async (accounts) => {
  return accounts[0];
};

export const setActiveAccount = (account) => {
  msalInstance.setActiveAccount(account);
};

export const getActiveAccount = async () => {
  return callApi(async () => {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      // If there's no active account, try to get the first account
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
        return accounts[0];
      }
      // If still no account, throw an error
      throw new Error('No active account and no accounts found');
    }
    return account;
  });
};

export const ensureAuth = async () => {
  try {
    const account = await getActiveAccount();
    if (!account) {
      // If no account, redirect to sign in
      await signIn();
    } else {
      // Try to refresh the token
      const newToken = await refreshToken();
      if (!newToken) {
        // If refresh failed, redirect to sign in
        await signIn();
      }
    }
    return account;
  } catch (error) {
    console.error("Error ensuring auth:", error);
    // Redirect to sign in page or show an error message
    throw error;
  }
};

export const getAuthHeader = async () => {
  try {
    const account = checkActiveAccount();
    const response = await msalInstance.acquireTokenSilent({
      scopes: ['https://puawaiplatform.onmicrosoft.com/36921f40-a3e5-444c-9f05-8ed8abc85694/access_as_user'],
      account: account
    });
    return `Bearer ${response.accessToken}`;
  } catch (error) {
    console.error('Error getting auth header:', error);
    throw error;
  }
};

export const getToken = async () => {
  const account = checkActiveAccount();

  try {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account
    });
    if (!response.accessToken) {
      throw new Error('Access token is empty');
    }
    return response.accessToken;
  } catch (error) {
    console.error('Error acquiring token:', error);
    if (error.name === "InteractionRequiredAuthError") {
      try {
        const response = await msalInstance.acquireTokenPopup(loginRequest);
        return response.accessToken;
      } catch (popupError) {
        console.error('Error acquiring token via popup:', popupError);
        throw popupError;
      }
    }
    throw error;
  }
};

export const signUp = async () => {
  if (msalInstance.getActiveAccount() !== null) {
    return null;
  }

  try {
    const response = await msalInstance.loginPopup();
    if (response) {
      msalInstance.setActiveAccount(response.account);
      return response.account;
    }
    return null;
  } catch (error) {
    console.error("Error during sign-up:", error);
    throw error;
  }
};

export const refreshToken = async () => {
  try {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw new Error('No active account');
    }
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account
    });
    return response.accessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    if (error.name === "InteractionRequiredAuthError") {
      // Token expired and requires user interaction to refresh
      return null;
    }
    throw error;
  }
};

// Export the initialized MSAL instance promise
export { initializeMsal, msalInstance };