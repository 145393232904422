import api from './api';
import { getAuthHeader } from './authService';

export const accountManagementService = {
  // Contact Management
  async listContacts(organisationId, accountId = null) {
    try {
      const token = await getAuthHeader();
      const response = await api.post('/api/puawai-account-management', {
        action: 'listContacts',
        data: accountId ? { accountId } : {}
      }, {
        headers: { 
          Authorization: token,
          'x-organization-id': organisationId 
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error in listContacts:', error);
      throw error;
    }
  },

  // Activity Management
  async listActivities(organisationId, data = {}) {
    try {
      const token = await getAuthHeader();
      const response = await api.post('/api/puawai-account-management', {
        action: 'listActivities',
        data: {
          accountId: data.accountId,
          filters: data.filters || {}
        }
      }, {
        headers: { 
          Authorization: token,
          'x-organization-id': organisationId 
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error in listActivities:', error);
      throw error;
    }
  },

  // Analytics
  async getAccountAnalytics(organisationId, accountId, accountSource) {
    try {
      const token = await getAuthHeader();
      const response = await api.post('/api/puawai-account-management', {
        action: 'getAccountAnalytics',
        data: {
          accountId,
          accountSource
        }
      }, {
        headers: { 
          Authorization: token,
          'x-organization-id': organisationId 
        }
      });
      return { data: response.data.metrics };
    } catch (error) {
      console.error('Error getting account analytics:', error);
      throw error;
    }
  },

  async getRecentActivities(organisationId, accountId = null) {
    try {
      const token = await getAuthHeader();
      const response = await api.post('/api/puawai-account-management', {
        action: 'listActivities',
        data: accountId ? { accountId } : {}
      }, {
        headers: { 
          Authorization: token,
          'x-organization-id': organisationId 
        }
      });
      return response.data;
    } catch (error) {
      console.warn('Error getting recent activities:', error);
      return { activities: [] };
    }
  },

  async getAccountHealth(organisationId, accountId = null) {
    try {
      const token = await getAuthHeader();
      const response = await api.post('/api/puawai-account-management', {
        action: 'getAccountHealth',
        data: accountId ? { accountId } : {}
      }, {
        headers: { 
          Authorization: token,
          'x-organization-id': organisationId 
        }
      });
      return response.data;
    } catch (error) {
      console.warn('Error getting account health:', error);
      return { health: {} };
    }
  },

  // Account Management
  async listAccounts(organisationId) {
    try {
      const token = await getAuthHeader();
      const response = await api.post('/api/puawai-account-management', {
        action: 'listAccounts'
      }, {
        headers: { 
          Authorization: token,
          'x-organization-id': organisationId 
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error listing accounts:', error);
      throw error;
    }
  },

  async getAccount(organisationId, accountId) {
    try {
      const token = await getAuthHeader();
      const response = await api.post('/api/puawai-account-management', {
        action: 'getAccount',
        data: { accountId }
      }, {
        headers: { 
          Authorization: token,
          'x-organization-id': organisationId 
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error getting account:', error);
      throw error;
    }
  },

  async createAccount(organisationId, data) {
    try {
      const token = await getAuthHeader();
      const response = await api.post('/api/puawai-account-management', {
        action: 'createAccount',
        data
      }, {
        headers: { 
          Authorization: token,
          'x-organization-id': organisationId 
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error creating account:', error);
      throw error;
    }
  },

  async updateAccount(organisationId, accountId, data) {
    try {
      const token = await getAuthHeader();
      const response = await api.post('/api/puawai-account-management', {
        action: 'updateAccount',
        data: {
          accountId,
          ...data
        }
      }, {
        headers: { 
          Authorization: token,
          'x-organization-id': organisationId 
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating account:', error);
      throw error;
    }
  },

  async deleteAccount(organisationId, accountId) {
    try {
      const token = await getAuthHeader();
      const response = await api.post('/api/puawai-account-management', {
        action: 'deleteAccount',
        data: { accountId }
      }, {
        headers: { 
          Authorization: token,
          'x-organization-id': organisationId 
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting account:', error);
      throw error;
    }
  },

  async createActivity(organisationId, data) {
    try {
      const token = await getAuthHeader();
      const response = await api.post('/api/puawai-account-management', {
        action: 'createActivity',
        data
      }, {
        headers: { 
          Authorization: token,
          'x-organization-id': organisationId 
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error creating activity:', error);
      throw error;
    }
  },

  async updateActivity(organisationId, data) {
    try {
      const token = await getAuthHeader();
      const response = await api.post('/api/puawai-account-management', {
        action: 'updateActivity',
        data
      }, {
        headers: { 
          Authorization: token,
          'x-organization-id': organisationId 
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating activity:', error);
      throw error;
    }
  },

  async deleteActivity(organisationId, data) {
    try {
      const token = await getAuthHeader();
      const response = await api.post('/api/puawai-account-management', {
        action: 'deleteActivity',
        data
      }, {
        headers: { 
          Authorization: token,
          'x-organization-id': organisationId 
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting activity:', error);
      throw error;
    }
  }
};

export default accountManagementService; 