import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, Box, Typography, Stepper, Step, StepLabel,
  Paper, IconButton, CircularProgress, Alert,
  Grid, TextField, Divider
} from '@mui/material';
import {
  Close as CloseIcon,
  Save as SaveIcon,
  Preview as PreviewIcon
} from '@mui/icons-material';
import { generateDocument } from '../../../services/templateService';
import TemplatePreview from './components/TemplatePreview';

const steps = [
  'Select Data',
  'Review & Customize',
  'Generate Document'
];

const TemplateUsage = ({ open, onClose, selectedOrg, template }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [customData, setCustomData] = useState({});
  const [preview, setPreview] = useState(null);

  const handleGenerate = async () => {
    try {
      setLoading(true);
      const generatedDoc = await generateDocument(selectedOrg, template.id, {
        customData
      });
      setPreview(generatedDoc);
      setActiveStep(2);
    } catch (error) {
      console.error('Error generating document:', error);
      setError('Failed to generate document');
    } finally {
      setLoading(false);
    }
  };

  const renderContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Deal Data
            </Typography>
            {/* Add data selection/preview UI */}
          </Box>
        );
      case 1:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Customize Content
            </Typography>
            {/* Add customization UI */}
          </Box>
        );
      case 2:
        return preview ? (
          <TemplatePreview 
            sections={template.structure.sections}
            preview={preview}
          />
        ) : null;
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6">
            Generate Document from Template
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Stepper activeStep={activeStep} sx={{ mb: 3 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          renderContent()
        )}
      </DialogContent>

      <DialogActions>
        <Button 
          onClick={onClose}
          disabled={loading}
        >
          Cancel
        </Button>
        {activeStep < steps.length - 1 ? (
          <Button
            variant="contained"
            onClick={() => setActiveStep(prev => prev + 1)}
            disabled={loading}
          >
            Next
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleGenerate}
            disabled={loading}
            startIcon={<SaveIcon />}
          >
            Generate
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TemplateUsage; 