import React, { useState } from 'react';
import { Fab, Tooltip, useTheme, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import BrandAIAssistant from './BrandAIAssistant';

const BrandIntelligenceInterface = ({ userProfile, organisation, brandData, toneOfVoiceData, currentStep, currentStepData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const theme = useTheme();

  const handleOpen = () => {
    setShowDisclaimer(true);
  };

  const handleAccept = () => {
    setShowDisclaimer(false);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const floatingIconVariants = {
    initial: { y: 0 },
    animate: {
      y: [-5, 5],
      transition: {
        y: {
          duration: 1.5,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "easeInOut"
        }
      }
    },
    hover: {
      scale: 1.1,
      transition: {
        duration: 0.3
      }
    },
  };

  const disclaimerText = (
    <>
      <Typography variant="h6" gutterBottom>
        Kia ora! I'm your friendly Puāwai Brand Intelligence assistant.
      </Typography>
      <Typography paragraph>
        I'm here to help you, but please remember:
      </Typography>
      <Box component="ul" sx={{ pl: 2 }}>
        <Typography component="li">I'm still learning and might make mistakes.</Typography>
        <Typography component="li">I only use your data to assist you directly.</Typography>
        <Typography component="li">I don't store or use your information for training.</Typography>
        <Typography component="li">Your privacy and data security are important to me.</Typography>
      </Box>
      <Typography paragraph>
        Let's work together with kindness and patience to achieve great things! <FontAwesomeIcon icon={faSeedling} style={{ color: theme.palette.primary.main }} />
      </Typography>
    </>
  );

  return (
    <>
      <Tooltip title="Open Puāwai Brand Intelligence">
        <motion.div
          initial="initial"
          animate="animate"
          whileHover="hover"
          variants={floatingIconVariants}
          style={{
            position: 'fixed',
            bottom: 24,
            right: 24,
            zIndex: theme.zIndex.drawer + 1
          }}
        >
          <Fab 
            color="primary" 
            onClick={handleOpen}
            sx={{
              width: 80,
              height: 80,
              bgcolor: theme.palette.primary.main,
              '&:hover': { bgcolor: theme.palette.primary.dark },
            }}
          >
            <FontAwesomeIcon icon={faSeedling} style={{ fontSize: '2rem' }} />
          </Fab>
        </motion.div>
      </Tooltip>

      <Dialog 
        open={showDisclaimer} 
        onClose={() => setShowDisclaimer(false)}
        PaperProps={{
          sx: {
            borderRadius: 2,
            maxWidth: 400,
          }
        }}
      >
        <DialogTitle sx={{ 
          bgcolor: theme.palette.primary.main, 
          color: theme.palette.primary.contrastText,
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          <FontAwesomeIcon icon={faSeedling} style={{ color: theme.palette.primary.contrastText, fontSize: '1.5em' }} /> Puāwai Brand Intelligence
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          {disclaimerText}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', px: 3, pb: 3 }}>
          <Button onClick={() => setShowDisclaimer(false)} variant="outlined">
            Maybe Later
          </Button>
          <Button onClick={handleAccept} variant="contained" color="primary">
            Accept & Continue
          </Button>
        </DialogActions>
      </Dialog>

      <BrandAIAssistant
        userProfile={userProfile}
        organisation={organisation}
        brandData={brandData}
        toneOfVoiceData={toneOfVoiceData}
        currentStep={currentStep}
        currentStepData={currentStepData}
        isOpen={isOpen}
        onClose={handleClose}
        isEmbedded={true}
        topOffset={64}
      />
    </>
  );
};

export default BrandIntelligenceInterface; 