import api from './api';
import { getToken } from './authService';
import { pipedriveService } from './pipedriveService';

export const dealService = {
  async listDeals(organisationId, account = null) {
    try {
      if (!organisationId) {
        throw new Error('Organisation ID is required');
      }

      const token = await getToken();

      // Get local deals
      const payload = {
        action: 'listDeals',
        data: account ? {
          accountId: account.id,
          accountSource: account.source,
          pipedriveId: account.source === 'pipedrive' ? account.pipedriveId : null,
          includeAccountData: true
        } : {
          includeAccountData: true
        }
      };

      const response = await api.post(
        `/api/puawai-account-management`,
        payload,
        {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'x-organization-id': organisationId
          }
        }
      ).catch(error => {
        console.error('API Error Details:', {
          status: error.response?.status,
          statusText: error.response?.statusText,
          data: error.response?.data,
          config: {
            url: error.config?.url,
            method: error.config?.method,
            headers: error.config?.headers
          }
        });
        throw error;
      });

      // Get Pipedrive deals if integrated
      let pipedriveDeals = [];
      try {
        const pipedriveResponse = await pipedriveService.getDeals(organisationId, account?.pipedriveId);
        if (pipedriveResponse?.success) {
          pipedriveDeals = pipedriveResponse.deals || [];
        }
      } catch (error) {
        console.warn('Failed to fetch Pipedrive deals:', error);
      }

      // Combine and normalize both sets of deals
      const allDeals = [
        ...(response.data?.deals || []).map(deal => ({
          ...deal,
          source: 'puawai',
          // Ensure account info is properly structured
          organization_name: deal.organization_name || deal.account?.name || null,
          account: deal.account || (deal.account_id ? {
            id: deal.account_id,
            name: deal.organization_name,
            source: 'puawai'
          } : null),
          // Ensure contact info is properly structured
          person_name: deal.person_name || deal.contact?.name || null,
          contact: deal.contact || (deal.person_id ? {
            id: deal.person_id,
            name: deal.person_name,
            source: 'puawai'
          } : null)
        })),
        ...pipedriveDeals.map(deal => ({
          ...deal,
          source: 'pipedrive',
          // Normalize Pipedrive account info
          organization_name: deal.pipedriveData?.org_id?.name || deal.organization_name || null,
          account: {
            // Use numeric ID for Pipedrive accounts
            id: deal.pipedriveData?.org_id?.value || deal.organization_id || null,
            name: deal.pipedriveData?.org_id?.name || deal.organization_name,
            source: 'pipedrive'
          },
          // Normalize Pipedrive contact info
          person_name: deal.pipedriveData?.person_id?.name || deal.person_name || null,
          contact: {
            id: deal.pipedriveData?.person_id?.value || deal.person_id || null,
            name: deal.pipedriveData?.person_id?.name || deal.person_name,
            source: 'pipedrive'
          }
        }))
      ];

      const defaultStages = [
        { id: '1', name: 'Qualified' },
        { id: '2', name: 'Contact Made' },
        { id: '3', name: 'Needs Defined' },
        { id: '4', name: 'Proposal Made' },
        { id: '5', name: 'Negotiations Started' }
      ];

      return { 
        success: true,
        deals: allDeals,
        stages: defaultStages
      };
    } catch (error) {
      console.error('Error in listDeals:', error);
      throw error;
    }
  },

  async createDeal(organisationId, dealData) {
    try {
      const token = await getToken();
      
      // Validate required fields
      if (!dealData.title?.trim()) {
        throw new Error('Deal title is required');
      }

      const value = typeof dealData.value === 'string' ? parseFloat(dealData.value) : dealData.value;
      if (isNaN(value) || value <= 0) {
        throw new Error('Deal value is required and must be greater than 0');
      }

      // Always set stage_id to '1' (Qualified) for new deals if not specified
      const stage_id = dealData.stage_id || '1';

      // Normalize account data
      const account = dealData.account ? {
        id: dealData.account.id,
        name: dealData.account.name,
        source: dealData.account.source || 'puawai'
      } : dealData.account_id ? {
        id: dealData.account_id,
        source: 'puawai'
      } : null;

      // Normalize contact data
      const contact = dealData.contact ? {
        id: dealData.contact.id,
        name: dealData.contact.name,
        source: dealData.contact.source || 'puawai'
      } : dealData.person_id ? {
        id: dealData.person_id,
        source: 'puawai'
      } : null;
      
      const payload = {
        action: 'createDeal',
        data: {
          title: dealData.title.trim(),
          value: value,
          currency: dealData.currency || 'USD',
          account,
          contact,
          account_id: account?.id || null,
          person_id: contact?.id || null,
          organization_name: account?.name || null,
          person_name: contact?.name || null,
          stage_id: stage_id,
          status: dealData.status || 'open',
          expected_close_date: dealData.expected_close_date || null,
          probability: dealData.probability ? parseFloat(dealData.probability) : null,
          description: dealData.description || '',
          visible_to: dealData.visible_to || '3',
          pipeline_id: dealData.pipeline_id || '1',
          source: 'puawai',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        }
      };

      const response = await api.post(
        `/api/puawai-account-management`,
        payload,
        {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'x-organization-id': organisationId
          }
        }
      );
      
      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to create deal');
      }
      
      return response.data;
    } catch (error) {
      console.error('Error creating deal:', error);
      throw error;
    }
  },

  async updateDeal(organisationId, dealId, dealData) {
    try {
      const token = await getToken();
      
      // Validate required fields
      if (!dealId) {
        throw new Error('Deal ID is required');
      }

      if (!dealData.title?.trim()) {
        throw new Error('Deal title is required');
      }

      const value = typeof dealData.value === 'string' ? parseFloat(dealData.value) : dealData.value;
      if (isNaN(value) || value <= 0) {
        throw new Error('Deal value is required and must be greater than 0');
      }
      
      const payload = {
        action: 'updateDeal',
        data: {
          dealId,
          title: dealData.title.trim(),
          value: value,
          currency: dealData.currency || 'USD',
          person_id: dealData.contact?.id || dealData.person_id,
          account_id: dealData.account?.id || dealData.account_id,
          contact: dealData.contact || (dealData.person_id ? {
            id: dealData.person_id,
            source: 'puawai'
          } : null),
          account: dealData.account || (dealData.account_id ? {
            id: dealData.account_id,
            source: 'puawai'
          } : null),
          stage_id: dealData.stage_id,
          status: dealData.status,
          expected_close_date: dealData.expected_close_date,
          probability: dealData.probability ? parseFloat(dealData.probability) : null,
          description: dealData.description,
          visible_to: dealData.visible_to,
          pipeline_id: dealData.pipeline_id,
          source: 'puawai'
        }
      };

      const response = await api.post(
        `/api/puawai-account-management`,
        payload,
        {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'x-organization-id': organisationId
          }
        }
      );
      
      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to update deal');
      }
      
      return response.data;
    } catch (error) {
      console.error('Error updating deal:', error);
      throw error;
    }
  },

  async updateDealStage(organisationId, dealId, newStageId) {
    try {
      const token = await getToken();
      
      const payload = {
        action: 'updateDealStage',
        data: {
          dealId,
          stageId: newStageId
        }
      };

      const response = await api.post(
        `/api/puawai-account-management`,
        payload,
        {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'x-organization-id': organisationId
          }
        }
      );
      
      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to update deal stage');
      }
      
      return response.data;
    } catch (error) {
      console.error('Error updating deal stage:', error);
      throw error;
    }
  },

  async deleteDeal(organisationId, dealId) {
    try {
      const token = await getToken();
      
      const payload = {
        action: 'deleteDeal',
        data: {
          dealId
        }
      };

      const response = await api.post(
        `/api/puawai-account-management`,
        payload,
        {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'x-organization-id': organisationId
          }
        }
      );
      
      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to delete deal');
      }
      
      return response.data;
    } catch (error) {
      console.error('Error deleting deal:', error);
      throw error;
    }
  },

  async syncPipedriveDeals(organisationId) {
    try {
      const token = await getToken();
      const response = await api.post(
        `/api/accountmanagement/syncPipedriveDeals`,
        {},
        {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'x-organization-id': organisationId
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error syncing Pipedrive deals:', error);
      throw error;
    }
  }
};

// Default pipeline stages
const defaultStages = [
  { id: '1', name: 'Qualified' },
  { id: '2', name: 'Contact Made' },
  { id: '3', name: 'Needs Defined' },
  { id: '4', name: 'Proposal Made' },
  { id: '5', name: 'Negotiations Started' }
];

export default dealService; 