import api from './api';
import { getAuthHeader } from './authService';

const apiCall = async (method, endpoint, data = null, customConfig = {}) => {
  try {
    const token = await getAuthHeader();
    const config = {
      ...customConfig,
      method,
      url: `/api${endpoint}`,
      headers: { 
        ...customConfig.headers,
        Authorization: token 
      },
    };
    if (data) {
      config.data = data;
    }
    const response = await api(config);
    return response.data;
  } catch (error) {
    console.error(`Error in ${method} ${endpoint}:`, error);
    if (error.response) {
      throw new Error(`API error: ${error.response.data.message || error.response.statusText}`);
    }
    throw new Error(`API error: ${error.message}`);
  }
};

// Proposition functions
export const getPropositions = async (selectedOrgId) => {
  const response = await apiCall('get', `/portfolios?type=proposition&organizationId=${selectedOrgId}`);
  //console.log('Raw propositions data:', response);
  return response;
};

export const createProposition = async (selectedOrgId, data) => {
  return apiCall('post', `/portfolios?type=proposition&organizationId=${selectedOrgId}`, {
    ...data,
    organisationId: selectedOrgId,
    associatedProducts: data.associatedProducts || [],
  });
};

export const updateProposition = async (selectedOrgId, itemId, data) => {
  return apiCall('put', `/portfolios/${itemId}?type=proposition&organizationId=${selectedOrgId}`, {
    ...data,
    organisationId: selectedOrgId,
    associatedProducts: data.associatedProducts || [],
  });
};

export const deleteProposition = async (selectedOrgId, itemId) => {
  return apiCall('delete', `/portfolios/${itemId}?type=proposition&organizationId=${selectedOrgId}`);
};

// Portfolio Item functions
export const getPortfolioItems = async (selectedOrgId) => {
  return apiCall('get', `/portfolios?organizationId=${selectedOrgId}`);
};

export const getPortfolioItem = async (selectedOrgId, itemId) => {
  return apiCall('get', `/portfolios/${itemId}?organizationId=${selectedOrgId}`);
};

export const createPortfolioItem = async (selectedOrgId, data) => {
  return apiCall('post', `/portfolios?organizationId=${selectedOrgId}`, {
    ...data,
    organisationId: selectedOrgId,
  });
};

export const updatePortfolioItem = async (selectedOrgId, itemId, data) => {
  return apiCall('put', `/portfolios/${itemId}?organizationId=${selectedOrgId}`, {
    ...data,
    organisationId: selectedOrgId,
  });
};

export const deletePortfolioItem = async (selectedOrgId, itemId) => {
  return apiCall('delete', `/portfolios/${itemId}?organizationId=${selectedOrgId}`);
};

export const uploadFile = async (selectedOrgId, file) => {
  const formData = new FormData();
  formData.append('file', file);
  return apiCall('post', `/upload?organizationId=${selectedOrgId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export default {
  // Proposition functions
  getPropositions,
  createProposition,
  updateProposition,
  deleteProposition,
  
  // Portfolio Item functions
  getPortfolioItems,
  getPortfolioItem,
  createPortfolioItem,
  updatePortfolioItem,
  deletePortfolioItem,
  uploadFile,
};
