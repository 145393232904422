import { useState, useEffect, useCallback } from 'react';
import { getUser } from '../services/userService';
import { getBidDetails } from '../services/bidService';
import bidteamService from '../services/bidteamService';
import { getOrganisationUsers } from '../services/organisationService';

export const useBidData = (selectedBid, organisation) => {
  const [ownerDetails, setOwnerDetails] = useState({});
  const [teamMembers, setTeamMembers] = useState([]);
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchBidDetails = useCallback(async () => {
    if (selectedBid && organisation) {
      setIsLoading(true);
      try {
        const [ownerData, teamMembersData, orgUsersData] = await Promise.all([
          getUser(selectedBid.owner),
          bidteamService.getTeamMembers(selectedBid.id),
          getOrganisationUsers(organisation.id)
        ]);

        setOwnerDetails(prev => ({ ...prev, [selectedBid.owner]: ownerData }));
        setTeamMembers(teamMembersData);
        setOrganizationUsers(Array.isArray(orgUsersData) ? orgUsersData : []);
      } catch (error) {
        console.error('Error fetching bid details:', error);
        setError('Failed to fetch bid details. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    }
  }, [selectedBid?.id, organisation?.id]);

  useEffect(() => {
    fetchBidDetails();
  }, [fetchBidDetails]);

  return {
    ownerDetails,
    teamMembers,
    organizationUsers,
    isLoading,
    error,
    refreshData: fetchBidDetails
  };
}; 