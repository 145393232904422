import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

const AnimatedDots = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length >= 3 ? '' : prevDots + '.'));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ display: 'inline-block', minWidth: '24px' }}>
      <Typography variant="body1">{dots}</Typography>
    </Box>
  );
};

export default AnimatedDots;
