import React, { useState, useEffect } from 'react';
import BaseCanvas from './BaseCanvas';
import BusinessIcon from '@mui/icons-material/Business';
import { Box, Button, Typography, useTheme, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';


const BusinessStrategyCanvas = ({ user, selectedOrg, canvas, onClose, onSave }) => {
  console.log('BusinessStrategyCanvas - Component Mounted');

  if (!user || !selectedOrg) {
    console.error('BusinessStrategyCanvas - Missing required props:', { user, selectedOrg });
    return <Typography color="error">Missing required data</Typography>;
  }

  const [canvasData, setCanvasData] = useState(canvas || {});
  const theme = useTheme();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showUnsavedDialog, setShowUnsavedDialog] = useState(false);

  useEffect(() => {
    if (canvas) {
      setCanvasData(canvas);
    }
  }, [canvas]);

  useEffect(() => {
    console.log('BusinessStrategyCanvas - Props:', { user, selectedOrg, canvas });
    console.log('BusinessStrategyCanvas - State:', { canvasData });
    console.log('BusinessStrategyCanvas - Sections:', sections);
  }, [user, selectedOrg, canvas, canvasData]);

  const handleFieldChange = (field, value) => {
    setCanvasData(prev => ({ ...prev, [field]: value }));
    setHasUnsavedChanges(true);
  };

  const handleCloseAttempt = () => {
    if (hasUnsavedChanges) {
      setShowUnsavedDialog(true);
    } else {
      onClose();
    }
  };

  const handleConfirmedClose = () => {
    setShowUnsavedDialog(false);
    onClose();
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasUnsavedChanges]);

  const handleSave = async (updatedData) => {
    try {
      await onSave(updatedData);
      setCanvasData(updatedData);
      setHasUnsavedChanges(false);
      if (updatedData.status) {
        onClose();
      }
      return true;
    } catch (error) {
      console.error('Error saving canvas:', error);
      return false;
    }
  };

  const sections = [
    { field: 'vision', title: 'Vision', rows: 4, inputType: 'richtext' },
    { field: 'mission', title: 'Mission', rows: 4, inputType: 'richtext' },
    { field: 'coreValues', title: 'Core Values', rows: 4, inputType: 'richtext' },
    { 
      field: 'swotAnalysis', 
      title: 'SWOT Analysis', 
      rows: 8, 
      inputType: 'swot',
      hideAIAssist: true,
      preventWarning: true,
      subSections: [
        { field: 'strengths', title: 'Strengths', rows: 2, preventWarning: true },
        { field: 'weaknesses', title: 'Weaknesses', rows: 2, preventWarning: true },
        { field: 'opportunities', title: 'Opportunities', rows: 2, preventWarning: true },
        { field: 'threats', title: 'Threats', rows: 2, preventWarning: true },
      ]
    },
    { field: 'longTermObjectives', title: 'Long-term Objectives', rows: 4, inputType: 'richtext' },
    { field: 'competitiveAdvantage', title: 'Competitive Advantage', rows: 4, inputType: 'richtext' },
    { field: 'marketPositioning', title: 'Market Positioning', rows: 4, inputType: 'richtext' },
    { field: 'keyStrategies', title: 'Key Strategies', rows: 6, inputType: 'richtext' },
    { field: 'resourceAllocation', title: 'Resource Allocation', rows: 4, inputType: 'richtext' },
    { field: 'keyPerformanceIndicators', title: 'Key Performance Indicators', rows: 4, inputType: 'richtext' },
  ];

  // Validate sections
  if (!sections || sections.length === 0) {
    console.error('BusinessStrategyCanvas - Sections not defined properly');
    return <Typography color="error">Error loading canvas sections</Typography>;
  }

  console.log('BusinessStrategyCanvas - Sections defined:', sections);

  const tooltips = {
    vision: "Define your long-term vision for the company. Format: Concise statement. Example: 'To become the global leader in sustainable energy solutions by 2030.'",
    mission: "State your company's purpose and primary objectives. Format: Brief paragraph. Example: 'Our mission is to accelerate the world's transition to sustainable energy through innovative technology and exceptional customer service.'",
    coreValues: "List the fundamental beliefs that guide your company's actions. Format: Bullet points. Example: '• Integrity\n• Innovation\n• Sustainability\n• Customer-centricity'",
    strengths: "List internal factors that give your organization an advantage over others. Consider unique resources, capabilities, and competitive advantages. Format: Bullet points. Example: '• Strong brand recognition\n• Proprietary technology\n• Experienced leadership team'",
    weaknesses: "Identify internal factors that place your organization at a disadvantage relative to others. Consider areas for improvement or limitations. Format: Bullet points. Example: '• Limited market presence in certain regions\n• High production costs\n• Lack of digital marketing expertise'",
    opportunities: "List external factors that your organization could capitalize on to its advantage. Consider market trends, industry changes, or new customer needs. Format: Bullet points. Example: '• Growing demand for sustainable products\n• Emerging markets in Asia\n• Potential for strategic partnerships'",
    threats: "Identify external factors that could cause trouble for your organization. Consider competitive pressures, regulatory changes, or economic factors. Format: Bullet points. Example: '• Increasing competition from new market entrants\n• Changing consumer preferences\n• Potential economic downturn'",
    longTermObjectives: "Define specific, measurable long-term goals. Format: Numbered list. Example: '1. Achieve 30% market share in renewable energy sector by 2025\n2. Expand operations to 50 countries within 5 years'",
    competitiveAdvantage: "Identify what sets you apart from competitors. Format: Bullet points with brief explanations. Example: '• Proprietary technology: 40% more efficient than industry standard\n• Strong partnerships: Exclusive deals with key suppliers'",
    marketPositioning: "Describe how you want to be perceived in the market. Format: Concise statement. Example: 'The premium provider of innovative and reliable sustainable energy solutions for environmentally conscious businesses.'",
    keyStrategies: "Outline your high-level plans to achieve your objectives. Format: Numbered list with brief descriptions. Example: '1. Product Innovation: Invest 15% of revenue in R&D\n2. Market Expansion: Enter 3 new markets annually'",
    resourceAllocation: "Specify how you'll allocate resources to support your strategy. Format: Percentage breakdown. Example: 'R&D: 15%\nMarketing: 20%\nOperations: 40%\nHuman Resources: 15%\nAdministration: 10%'",
    keyPerformanceIndicators: "List the metrics you'll use to measure success. Format: Bullet points with targets. Example: '• Revenue growth: 20% year-over-year\n• Customer satisfaction: Net Promoter Score > 50\n• Market share: Increase by 2% annually'",
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      height: '100vh',
      bgcolor: theme.palette.background.default,
      color: theme.palette.text.primary,
    }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        p: 2, 
        borderBottom: `1px solid ${theme.palette.divider}`
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <BusinessIcon sx={{ mr: 1, fontSize: 28, color: theme.palette.secondary.main }} />
          <Typography variant="h5" sx={{ fontWeight: 500 }}>Business Model Canvas</Typography>
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 3 }}>
        <BaseCanvas
          user={user}
          selectedOrg={selectedOrg}
          canvas={canvasData}
          onClose={handleCloseAttempt}
          onUpdate={(field, value) => handleFieldChange(field, value)}
          onSave={handleSave}
          canvasType="Business Strategy Canvas"
          sections={sections}
          tooltips={tooltips}
          icon={BusinessIcon}
        />
      </Box>

      <Dialog open={showUnsavedDialog} onClose={() => setShowUnsavedDialog(false)}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          <Typography>
            You have unsaved changes. What would you like to do?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowUnsavedDialog(false)}>
            Continue Editing
          </Button>
          <Button onClick={handleConfirmedClose} color="error">
            Discard Changes
          </Button>
          <Button 
            onClick={async () => {
              const success = await handleSave(canvasData);
              if (success) {
                onClose();
              }
            }} 
            variant="contained" 
            color="primary"
          >
            Save & Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BusinessStrategyCanvas;
