import React, { useState } from 'react';
import {
  Box, Chip, Tooltip, IconButton, Menu, MenuItem,
  ListSubheader, TextField, Typography, Popover,
  Paper, List, ListItem, ListItemText, ListItemIcon
} from '@mui/material';
import {
  Business as BusinessIcon,
  Person as PersonIcon,
  AttachMoney as OpportunityIcon,
  Inventory as PortfolioIcon,
  ContentCopy as CopyIcon,
  Search as SearchIcon,
  Inventory2 as Inventory2Icon,
  MiscellaneousServices as MiscellaneousServicesIcon,
  Assessment as AssessmentIcon,
  Cases as CaseStudyIcon,
  Brush as BrushIcon,
  DataObject as DataIcon
} from '@mui/icons-material';

// Define available variables by category
export const dataVariables = {
  customer: {
    icon: BusinessIcon,
    label: 'Customer',
    variables: {
      'customer.name': 'Customer Name',
      'customer.industry': 'Industry',
      'customer.size': 'Company Size',
      'customer.signingAuthority.name': 'Signing Authority Name',
      'customer.signingAuthority.title': 'Signing Authority Title',
      'customer.signingAuthority.email': 'Signing Authority Email'
    }
  },
  contact: {
    icon: PersonIcon,
    label: 'Contact',
    variables: {
      'contact.name': 'Contact Name',
      'contact.title': 'Contact Title',
      'contact.email': 'Contact Email',
      'contact.phone': 'Contact Phone'
    }
  },
  opportunity: {
    icon: OpportunityIcon,
    label: 'Opportunity',
    variables: {
      'opportunity.title': 'Opportunity Title',
      'opportunity.value': 'Deal Value',
      'opportunity.currency': 'Currency',
      'opportunity.lineItems': 'Line Items',
      'opportunity.customPricing': 'Custom Pricing',
      'opportunity.discount': 'Discount',
      'opportunity.tax': 'Tax',
      'opportunity.stage': 'Sales Stage'
    }
  },
  portfolio: {
    icon: PortfolioIcon,
    label: 'Portfolio',
    variables: {
      'portfolio.valueProposition': 'Value Proposition',
      'portfolio.differentiators': 'Differentiators',
      'portfolio.overview': 'Portfolio Overview',
      'portfolio.products': 'Products',
      'portfolio.services': 'Services',
      'portfolio.solutions': 'Solutions'
    }
  },
  products: {
    icon: Inventory2Icon,
    label: 'Products',
    variables: {
      'products.list': 'All Products',
      'products.featured': 'Featured Products',
      'products.categories': 'Product Categories',
      'products.current.name': 'Current Product Name',
      'products.current.description': 'Current Product Description',
      'products.current.features': 'Current Product Features',
      'products.current.benefits': 'Current Product Benefits',
      'products.current.specifications': 'Current Product Specifications',
      'products.current.pricing.base': 'Base Price',
      'products.current.pricing.discounted': 'Discounted Price',
      'products.current.pricing.custom': 'Custom Pricing',
      'products.recommended': 'Recommended Products',
      'products.related': 'Related Products'
    }
  },
  services: {
    icon: MiscellaneousServicesIcon,
    label: 'Services',
    variables: {
      'services.list': 'All Services',
      'services.categories': 'Service Categories',
      'services.featured': 'Featured Services',
      'services.current.name': 'Current Service Name',
      'services.current.description': 'Current Service Description',
      'services.current.deliverables': 'Service Deliverables',
      'services.current.methodology': 'Service Methodology',
      'services.current.timeline': 'Service Timeline',
      'services.current.pricing.hourly': 'Hourly Rate',
      'services.current.pricing.fixed': 'Fixed Price',
      'services.current.pricing.custom': 'Custom Pricing',
      'services.recommended': 'Recommended Services',
      'services.complementary': 'Complementary Services'
    }
  },
  caseStudies: {
    icon: CaseStudyIcon,
    label: 'Case Studies',
    variables: {
      'caseStudies.all': 'All Case Studies',
      'caseStudies.featured': 'Featured Case Studies',
      'caseStudies.byIndustry': 'Industry-Specific Cases',
      'caseStudies.current.title': 'Case Study Title',
      'caseStudies.current.client': 'Client Name',
      'caseStudies.current.industry': 'Client Industry',
      'caseStudies.current.challenge': 'Client Challenge',
      'caseStudies.current.solution': 'Our Solution',
      'caseStudies.current.approach': 'Our Approach',
      'caseStudies.current.results': 'Results Achieved',
      'caseStudies.current.testimonial': 'Client Testimonial',
      'caseStudies.current.metrics': 'Key Metrics',
      'caseStudies.current.roi': 'ROI Achieved',
      'caseStudies.similar': 'Similar Case Studies',
      'caseStudies.relevant': 'Relevant to Current Opportunity'
    }
  },
  metrics: {
    icon: AssessmentIcon,
    label: 'Success Metrics',
    variables: {
      'metrics.clientSatisfaction': 'Client Satisfaction Rate',
      'metrics.projectSuccess': 'Project Success Rate',
      'metrics.deliveryTime': 'Average Delivery Time',
      'metrics.roi': 'Average Client ROI',
      'metrics.industryBenchmarks': 'Industry Benchmarks',
      'metrics.certifications': 'Certifications & Awards'
    }
  },
  branding: {
    icon: BrushIcon,
    label: 'Brand Elements',
    variables: {
      'brand.colors.primary': 'Primary Color',
      'brand.colors.secondary': 'Secondary Color',
      'brand.colors.accent': 'Accent Color',
      'brand.colors.text': 'Text Color',
      'brand.typography.headingFont': 'Heading Font',
      'brand.typography.bodyFont': 'Body Font',
      'brand.typography.headingSizes.h1': 'H1 Size',
      'brand.typography.headingSizes.h2': 'H2 Size',
      'brand.typography.headingSizes.h3': 'H3 Size',
      'brand.typography.bodySize': 'Body Text Size',
      'brand.spacing.section': 'Section Spacing',
      'brand.spacing.paragraph': 'Paragraph Spacing',
      'brand.assets.logo.primary': 'Primary Logo URL',
      'brand.assets.logo.secondary': 'Secondary Logo URL',
      'brand.assets.patterns.primary': 'Primary Pattern',
      'brand.assets.patterns.secondary': 'Secondary Pattern',
      'brand.style.borderRadius': 'Border Radius',
      'brand.style.shadowStyle': 'Shadow Style'
    }
  }
};

const DataVariableSelector = ({ onVariableSelect, currentValue = '', textField = true }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [previewAnchorEl, setPreviewAnchorEl] = useState(null);
  const [previewValue, setPreviewValue] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedCategory(null);
    setSearchTerm('');
  };

  const handleVariableSelect = (variable) => {
    onVariableSelect(`\${${variable}}`);
    handleClose();
  };

  const handleVariableHover = (event, value, preview) => {
    setPreviewValue({ value, preview });
    setPreviewAnchorEl(event.currentTarget);
  };

  const handleVariableHoverExit = () => {
    setPreviewAnchorEl(null);
    setPreviewValue(null);
  };

  const filterVariables = (variables) => {
    if (!searchTerm) return variables;
    
    return Object.entries(variables).reduce((acc, [key, value]) => {
      if (
        key.toLowerCase().includes(searchTerm.toLowerCase()) ||
        value.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        acc[key] = value;
      }
      return acc;
    }, {});
  };

  const renderVariableMenu = () => (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: 400,
          width: 350
        }
      }}
    >
      <Box sx={{ p: 1 }}>
        <TextField
          fullWidth
          size="small"
          placeholder="Search variables..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon sx={{ mr: 1, color: 'text.secondary' }} />
          }}
        />
      </Box>

      {Object.entries(dataVariables).map(([category, { icon: Icon, label, variables }]) => {
        const filteredVariables = filterVariables(variables);
        if (Object.keys(filteredVariables).length === 0) return null;

        return (
          <React.Fragment key={category}>
            <ListSubheader sx={{ bgcolor: 'background.paper' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', py: 1 }}>
                <Icon sx={{ mr: 1 }} />
                {label}
              </Box>
            </ListSubheader>
            {Object.entries(filteredVariables).map(([variable, description]) => (
              <MenuItem
                key={variable}
                onClick={() => handleVariableSelect(variable)}
                onMouseEnter={(e) => handleVariableHover(e, variable, description)}
                onMouseLeave={handleVariableHoverExit}
              >
                <ListItemText 
                  primary={description}
                  secondary={variable}
                />
                <Tooltip title="Copy">
                  <IconButton 
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(`\${${variable}}`);
                    }}
                  >
                    <CopyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MenuItem>
            ))}
          </React.Fragment>
        );
      })}
    </Menu>
  );

  return (
    <Box>
      {textField ? (
        <TextField
          fullWidth
          value={currentValue}
          onChange={(e) => onVariableSelect(e.target.value)}
          placeholder="Enter content or use variables"
          InputProps={{
            endAdornment: (
              <Tooltip title="Insert Variable">
                <IconButton onClick={handleClick}>
                  <DataIcon />
                </IconButton>
              </Tooltip>
            )
          }}
        />
      ) : (
        <Tooltip title="Insert Variable">
          <IconButton onClick={handleClick}>
            <DataIcon />
          </IconButton>
        </Tooltip>
      )}

      {renderVariableMenu()}

      <Popover
        open={Boolean(previewAnchorEl)}
        anchorEl={previewAnchorEl}
        onClose={handleVariableHoverExit}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Paper sx={{ p: 2, maxWidth: 300 }}>
          <Typography variant="subtitle2" gutterBottom>
            {previewValue?.preview}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Variable: ${previewValue?.value}
          </Typography>
        </Paper>
      </Popover>
    </Box>
  );
};

export default DataVariableSelector; 