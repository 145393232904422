import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Paper, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getInvitationDetails, acceptInvitation } from '../services/invitationService';
import { useMsal } from '@azure/msal-react';
import Confetti from 'react-confetti';
import { toast } from 'react-toastify';

function FirstLoginHandler({ userId, email, name, onCreateOrganization }) {
  const [invitationCode, setInvitationCode] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [invitationOrganizationName, setInvitationOrganizationName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);
  const navigate = useNavigate();
  const { instance } = useMsal();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('invitationCode') || localStorage.getItem('invitationCode');
    if (code) {
      setInvitationCode(code);
      handleInvitationSubmit(code);
    }
  }, []);

  const handleInvitationSubmit = async (code = null) => {
    setLoading(true);
    setError('');
    try {
      const inviteCode = code || invitationCode;
      const invitationDetails = await getInvitationDetails(inviteCode);
      if (invitationDetails && invitationDetails.organisationName) {
        setInvitationOrganizationName(invitationDetails.organisationName);
      } else {
        setError('Invalid invitation or missing organization details.');
      }
    } catch (error) {
      console.error('Error fetching invitation details:', error);
      setError(error.message || 'An error occurred while fetching invitation details. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleAcceptInvitation = async () => {
    setLoading(true);
    setError('');
    try {
      await acceptInvitation(invitationCode);
      ////console.log('Invitation accepted successfully');
      localStorage.removeItem('invitationCode');
      setShowConfetti(true);
      toast.success('Invitation accepted successfully!');
      setTimeout(() => {
        window.location.reload();
      }, 3000); // Reload after 3 seconds
    } catch (error) {
      console.error('Error accepting invitation:', error);
      setError(error.message || 'Error accepting invitation. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateOrg = async (e) => {
    e.preventDefault();
    if (organizationName.trim()) {
      setLoading(true);
      setError('');
      try {
        const newOrg = await onCreateOrganization({ name: organizationName.trim() });
        setShowConfetti(true);
        // Remove the toast notification from here
        setTimeout(() => {
          window.location.reload();
        }, 3000); // Reload after 3 seconds
      } catch (error) {
        console.error('Error creating organization:', error);
        setError(`Failed to create organization: ${error.message}`);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundImage: 'url("/growthbackground.png")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
      {showConfetti && <Confetti />}
      <Paper elevation={3} sx={{ p: 4, maxWidth: 400, backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
        <Typography variant="h4" gutterBottom sx={{ color: '#2BA84A' }}>
          Welcome to Puāwai!
        </Typography>
        <Typography variant="body1" paragraph>
          Puāwai is your AI-powered bid management platform. Let's get you started!
        </Typography>
        <Typography variant="body1" paragraph>
          Please enter your invitation code or create a new organization.
        </Typography>
        <form onSubmit={(e) => { e.preventDefault(); handleInvitationSubmit(); }}>
          <TextField
            fullWidth
            label="Invitation Code"
            value={invitationCode}
            onChange={(e) => setInvitationCode(e.target.value)}
            margin="normal"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
            sx={{ mt: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : 'Check Invitation'}
          </Button>
        </form>
        {invitationOrganizationName && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1">
              You're invited to join: <strong>{invitationOrganizationName}</strong>
            </Typography>
            <Button
              onClick={handleAcceptInvitation}
              variant="contained"
              color="secondary"
              fullWidth
              sx={{ mt: 2 }}
            >
              Accept Invitation
            </Button>
          </Box>
        )}
        <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
          Or create a new organization:
        </Typography>
        <form onSubmit={handleCreateOrg}>
          <TextField
            fullWidth
            label="Organization Name"
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
            margin="normal"
          />
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            fullWidth
            disabled={loading}
            sx={{ mt: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : 'Create Organization'}
          </Button>
        </form>
        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
        <Button
          onClick={handleLogout}
          variant="outlined"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
        >
          Logout
        </Button>
      </Paper>
    </Box>
  );
}

export default FirstLoginHandler;
