import React, { useState, useEffect } from 'react';
import { Typography, Box, Container, Paper, Grid, Button, Chip, Card, CardContent, CardActions, List, ListItem, ListItemIcon, ListItemText, Alert, Skeleton, Fade, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Divider, ToggleButtonGroup, ToggleButton, Table, TableBody, TableCell, TableHead, TableRow, Accordion, AccordionSummary, AccordionDetails, IconButton, Tooltip } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import StarIcon from '@mui/icons-material/Star';
import { getUserWithSubscriptionStatus, getUser } from '../../services/userService';
import { subscriptionService } from '../../services/subscriptionService';
import { motion } from 'framer-motion';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../../components/PaymentForm/PaymentForm';
import { useStripeContext } from '../../contexts/StripeContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CancelIcon from '@mui/icons-material/Cancel';
import LoopIcon from '@mui/icons-material/Loop';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaymentIcon from '@mui/icons-material/Payment';
import SecurityIcon from '@mui/icons-material/Security';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import LockIcon from '@mui/icons-material/Lock';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DownloadIcon from '@mui/icons-material/Download';
import { TableContainer } from '@mui/material';
import { format } from 'date-fns';


const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2)
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(3)
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(4)
  }
}));

const FeatureItem = ({ text }) => {
  const theme = useTheme();
  return (
    <ListItem>
      <ListItemIcon>
        <CheckCircleOutlineIcon color="primary" />
      </ListItemIcon>
      <ListItemText primary={text} primaryTypographyProps={{ style: theme.typography.body1 }} />
    </ListItem>
  );
};

const PRICE_IDS = {
  STANDARD: {
    MONTHLY: 'price_1QGHRw02Su2f9R4bwMnRryCF',
    ANNUAL: process.env.REACT_APP_STRIPE_STANDARD_ANNUAL_PRICE_ID
  },
  INTELLIGENCE: {
    MONTHLY: process.env.REACT_APP_STRIPE_INTELLIGENCE_PRICE_ID,
    ANNUAL: process.env.REACT_APP_STRIPE_INTELLIGENCE_ANNUAL_PRICE_ID
  }
};

const SUBSCRIPTION_PLANS = [
  {
    title: "Standard",
    description: "Perfect for growing businesses",
    monthlyPrice: 19.95,
    annualPrice: 200.00,
    features: [
      "Comprehensive growth strategy tools",
      "Strategic canvases for visualisation",
      "Basic bid management and RFP handling",
      "Team collaboration features",
      "Task management for projects",
      "Standard analytics and reporting"
    ],
    isAI: false,
    billing: {
      monthly: {
        id: PRICE_IDS.STANDARD.MONTHLY,
        price: 19.95,
        savings: null
      },
      annual: {
        id: PRICE_IDS.STANDARD.ANNUAL,
        price: 200.00,
        savings: "Save 16%"
      }
    }
  },
  {
    title: "Intelligence+",
    description: "AI-powered growth acceleration",
    monthlyPrice: 49.95,
    annualPrice: 400.00,
    features: [
      "All Standard features, plus:",
      "AI-powered growth strategy assistant",
      "Generative AI copilot",
      "Advanced AI-assisted bid management",
      "Intelligent RFP analysis",
      "Predictive analytics",
      "Priority support"
    ],
    isAI: true,
    billing: {
      monthly: {
        id: PRICE_IDS.INTELLIGENCE.MONTHLY,
        price: 49.95,
        savings: null
      },
      annual: {
        id: PRICE_IDS.INTELLIGENCE.ANNUAL,
        price: 400.00,
        savings: "Save 33%"
      }
    },
    highlight: true
  }
];

const getPlanNameFromId = (planId) => {
  // First check if it's one of our known price IDs
  if (planId === PRICE_IDS.STANDARD.MONTHLY) return 'Standard';
  if (planId === PRICE_IDS.STANDARD.ANNUAL) return 'Standard';
  if (planId === PRICE_IDS.INTELLIGENCE.MONTHLY) return 'Intelligence+';
  if (planId === PRICE_IDS.INTELLIGENCE.ANNUAL) return 'Intelligence+';

  // If not found in price IDs, try to find in SUBSCRIPTION_PLANS
  const plan = SUBSCRIPTION_PLANS.find(p => 
    p.billing.monthly.id === planId || 
    p.billing.annual.id === planId
  );

  return plan ? plan.title : 'Unknown Plan';
};

const PlanCard = ({ 
  plan, 
  currentSubscription,
  onSubscribe,
  onCancel,
  onReactivate,
  isProcessingCancel,
  allPlans 
}) => {
  const theme = useTheme();
  const [billingCycle, setBillingCycle] = useState('monthly');
  
  const isCurrentPlanType = currentSubscription?.status === 'active' && 
    (currentSubscription?.planId === plan.billing.monthly.id || 
     currentSubscription?.planId === plan.billing.annual.id);

  const isExactPlan = currentSubscription?.status === 'active' && 
    currentSubscription?.planId === plan.billing[billingCycle].id;

  const monthlyEquivalent = billingCycle === 'annual' ? 
    (plan.billing[billingCycle].price / 12).toFixed(2) : 
    plan.billing[billingCycle].price;

  const getCurrentPlanPrice = () => {
    if (!currentSubscription || currentSubscription.status !== 'active') {
      return 0;
    }

    const currentPlan = allPlans?.find(p => 
      p.billing.monthly.id === currentSubscription.planId || 
      p.billing.annual.id === currentSubscription.planId
    );

    return currentPlan?.monthlyPrice || 0;
  };

  const currentPrice = getCurrentPlanPrice();
  const isUpgrade = plan.monthlyPrice > currentPrice;
  const isDowngrade = plan.monthlyPrice < currentPrice;

  const getActionButton = () => {
    if (isCurrentPlanType) {
      if (isExactPlan) {
        return (
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Chip 
              label="Current Plan" 
              color="success" 
              sx={{ mb: 1 }}
            />
            {currentSubscription.cancelAtPeriodEnd ? (
              <Box>
                <Chip 
                  label={`Cancellation scheduled for ${new Date(currentSubscription.currentPeriodEnd).toLocaleDateString()}`}
                  color="warning"
                  sx={{ mb: 1, width: '100%' }}
                />
                <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 1 }}>
                  Access available until end of billing period
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={() => onReactivate(currentSubscription.subscriptionId)}
                  startIcon={<AutorenewIcon />}
                >
                  Resume Subscription
                </Button>
              </Box>
            ) : (
              <Button
                variant="outlined"
                color="error"
                fullWidth
                onClick={onCancel}
                disabled={isProcessingCancel}
                startIcon={isProcessingCancel ? <LoopIcon className="spinning" /> : <CancelIcon />}
                sx={{ mt: 1 }}
              >
                {isProcessingCancel ? 'Cancelling...' : 'Cancel Subscription'}
              </Button>
            )}
          </Box>
        );
      } else {
        const isDowngradingToMonthly = billingCycle === 'monthly' && 
          currentSubscription.planId === plan.billing.annual.id;

        return (
          <Box sx={{ width: '100%' }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => onSubscribe(plan.billing[billingCycle].id, billingCycle)}
              startIcon={<SwapVertIcon />}
            >
              Switch to {billingCycle === 'annual' ? 'Annual' : 'Monthly'} Billing
            </Button>
            {isDowngradingToMonthly && (
              <Typography 
                variant="caption" 
                color="text.secondary" 
                sx={{ display: 'block', textAlign: 'center', mt: 1 }}
              >
                Change will take effect at the end of your current billing period 
                ({format(new Date(currentSubscription.currentPeriodEnd), 'dd/MM/yyyy')})
              </Typography>
            )}
            {billingCycle === 'annual' && (
              <Typography 
                variant="caption" 
                color="success.main" 
                sx={{ display: 'block', textAlign: 'center', mt: 1 }}
              >
                Switch now to start saving with annual billing
              </Typography>
            )}
          </Box>
        );
      }
    }

    if (currentSubscription?.status === 'active') {
      if (plan.title !== getPlanNameFromId(currentSubscription.planId)) {
        return (
          <Button
            variant="contained"
            color={isUpgrade ? "secondary" : "primary"}
            fullWidth
            onClick={() => onSubscribe(plan.billing[billingCycle].id, billingCycle)}
            startIcon={isUpgrade ? <StarIcon /> : <SwapVertIcon />}
          >
            {isUpgrade ? 'Upgrade to Intelligence+' : 'Downgrade to Standard'}
          </Button>
        );
      }
      return null;
    }

    return (
      <Button
        variant="contained"
        color={plan.isAI ? "secondary" : "primary"}
        fullWidth
        onClick={() => onSubscribe(plan.billing[billingCycle].id, billingCycle)}
      >
        {`Subscribe ${billingCycle === 'annual' ? 'Annually' : 'Monthly'}`}
      </Button>
    );
  };

  return (
    <StyledCard 
      elevation={plan.highlight ? 3 : 1}
      sx={plan.highlight ? {
        border: `2px solid ${theme.palette.primary.main}`,
        position: 'relative'
      } : {}}
    >
      {plan.highlight && (
        <Chip
          label="Most Popular"
          color="primary"
          sx={{
            position: 'absolute',
            top: -12,
            right: 24,
          }}
        />
      )}
      
      {currentSubscription?.status === 'active' && !isCurrentPlanType && (
        <Chip
          label={isUpgrade ? "Recommended Upgrade" : "Available Plan"}
          color={isUpgrade ? "secondary" : "default"}
          sx={{
            position: 'absolute',
            top: -12,
            right: 24,
          }}
        />
      )}
      
      <CardContent>
        <Typography variant="h4" gutterBottom>
          {plan.title}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          {plan.description}
        </Typography>

        <Box sx={{ mb: 3 }}>
          <ToggleButtonGroup
            value={billingCycle}
            exclusive
            onChange={(e, newValue) => newValue && setBillingCycle(newValue)}
            fullWidth
            size="small"
          >
            <ToggleButton value="monthly">Monthly</ToggleButton>
            <ToggleButton value="annual">Annual</ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <Typography variant="h3" color="primary">
            ${monthlyEquivalent}
            <Typography variant="caption" component="span" sx={{ ml: 1 }}>
              /month
            </Typography>
          </Typography>
          {plan.billing[billingCycle].savings && (
            <Typography variant="subtitle2" color="success.main">
              {plan.billing[billingCycle].savings} with annual billing
            </Typography>
          )}
          {billingCycle === 'annual' && (
            <Typography variant="body2" color="text.secondary">
              Billed ${plan.billing[billingCycle].price} annually
            </Typography>
          )}
        </Box>

        <Divider sx={{ my: 2 }} />

        <List>
          {plan.features.map((feature, index) => (
            <ListItem key={index} sx={{ py: 0.5 }}>
              <ListItemIcon>
                <CheckCircleOutlineIcon color={plan.isAI ? "secondary" : "primary"} />
              </ListItemIcon>
              <ListItemText primary={feature} />
            </ListItem>
          ))}
        </List>
      </CardContent>

      <CardActions sx={{ p: 2, pt: 0 }}>
        {getActionButton()}
      </CardActions>
    </StyledCard>
  );
};

const TrialChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  fontWeight: 'bold',
  fontSize: '1rem',
  padding: theme.spacing(1, 2),
}));

const CurrentSubscriptionCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
}));

const StatusChip = styled(Chip)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1rem',
  padding: theme.spacing(1, 2),
}));

const CallToActionAlert = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  '& .MuiAlert-message': {
    width: '100%',
  },
}));

const StripeWrapper = ({ children }) => {
  const { stripe, loading, error } = useStripeContext();

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" p={3}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        {error}
      </Alert>
    );
  }

  return (
    <Elements stripe={stripe}>
      {children}
    </Elements>
  );
};

const PageHeader = styled(Box)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
  color: theme.palette.common.white,
  padding: theme.spacing(6, 3),
  textAlign: 'center',
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(4),
}));

const SubscriptionDetails = ({ 
  subscription, 
  currentSubscription, 
  onReactivate, 
  onCancel,
  isProcessing 
}) => {
  const theme = useTheme();
  const [invoices, setInvoices] = useState([]);
  const [loadingInvoices, setLoadingInvoices] = useState(false);

  const getPlanName = (planId) => {
    // First check if it's one of our known price IDs
    if (planId === PRICE_IDS.STANDARD.MONTHLY) return 'Standard (Monthly)';
    if (planId === PRICE_IDS.STANDARD.ANNUAL) return 'Standard (Annual)';
    if (planId === PRICE_IDS.INTELLIGENCE.MONTHLY) return 'Intelligence+ (Monthly)';
    if (planId === PRICE_IDS.INTELLIGENCE.ANNUAL) return 'Intelligence+ (Annual)';

    // If not found in price IDs, try to find in SUBSCRIPTION_PLANS
    const plan = SUBSCRIPTION_PLANS.find(p => 
      p.billing.monthly.id === planId || 
      p.billing.annual.id === planId
    );

    // Check if it's an annual plan
    const isAnnual = planId === plan?.billing.annual.id;
    return plan ? `${plan.title} (${isAnnual ? 'Annual' : 'Monthly'})` : 'Unknown Plan';
  };

  const formatDate = (dateString) => {
    if (!dateString) return '—';
    
    try {
      // Handle Unix timestamps (seconds since epoch)
      if (typeof dateString === 'number') {
        return format(new Date(dateString * 1000), "dd/MM/yyyy, h:mm a");
      }
      
      // Handle ISO strings
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        console.warn('Invalid date:', dateString);
        return '—';
      }
      
      return format(date, "dd/MM/yyyy, h:mm a");
    } catch (error) {
      console.error('Error formatting date:', error, 'Date string:', dateString);
      return '—';
    }
  };

  // Add this new function to format currency
  const formatCurrency = (amount, currency = 'NZD') => {
    return new Intl.NumberFormat('en-NZ', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2
    }).format(amount);
  };

  useEffect(() => {
    const fetchInvoices = async () => {
      if (!currentSubscription?.subscriptionId) return;
      
      setLoadingInvoices(true);
      try {
        const response = await subscriptionService.getInvoices(currentSubscription.subscriptionId);
        console.log('Full invoices response:', response);
        
        if (response?.success && Array.isArray(response.data)) {
          console.log('Setting invoices:', response.data);
          setInvoices(response.data);
        } else {
          console.warn('Invalid invoice data structure:', response);
          setInvoices([]);
        }
      } catch (error) {
        console.error('Error fetching invoices:', error);
        toast.error('Failed to load invoices');
        setInvoices([]);
      } finally {
        setLoadingInvoices(false);
      }
    };

    fetchInvoices();
  }, [currentSubscription?.subscriptionId]);

  const handleDownloadInvoice = async (invoiceId) => {
    try {
      const response = await subscriptionService.downloadInvoice(invoiceId);
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `invoice-${invoiceId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading invoice:', error);
      toast.error('Failed to download invoice');
    }
  };

  const renderInvoicesTable = () => {
    console.log('Current invoices state:', invoices);
    
    return (
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Amount</TableCell>
              <TableCell>Frequency</TableCell>
              <TableCell>Invoice number</TableCell>
              <TableCell>Customer email</TableCell>
              <TableCell>Due</TableCell>
              <TableCell>Created</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingInvoices ? (
              <TableRow>
                <TableCell colSpan={7}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                    <CircularProgress size={24} />
                  </Box>
                </TableCell>
              </TableRow>
            ) : (!invoices || invoices.length === 0) ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No invoices found ({invoices ? invoices.length : 0} invoices)
                </TableCell>
              </TableRow>
            ) : (
              invoices.map((invoice) => (
                <TableRow key={invoice.id} hover>
                  <TableCell>
                    <Typography
                      color={invoice.amount_paid < 0 ? 'error.main' : 'inherit'}
                      sx={{ fontWeight: 'medium' }}
                    >
                      {formatCurrency(invoice.amount_paid / 100)} NZD
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {invoice.lines?.data[0]?.plan?.interval === 'year' ? 'Yearly' : 'Monthly'}
                  </TableCell>
                  <TableCell>{invoice.number}</TableCell>
                  <TableCell>{invoice.customer_email}</TableCell>
                  <TableCell>
                    {invoice.due_date ? formatDate(invoice.due_date * 1000) : '—'}
                  </TableCell>
                  <TableCell>
                    {formatDate(invoice.created * 1000)}
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Download Invoice">
                      <IconButton
                        onClick={() => handleDownloadInvoice(invoice.id)}
                        size="small"
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  // Add helper function to determine if downgrading from annual
  const isDowngradingFromAnnual = () => {
    const currentPlanIsAnnual = currentSubscription.planId === PRICE_IDS.STANDARD.ANNUAL || 
                               currentSubscription.planId === PRICE_IDS.INTELLIGENCE.ANNUAL;
    const hasScheduledChange = currentSubscription.scheduledPlanId;
    
    return currentPlanIsAnnual && hasScheduledChange;
  };

  return (
    <Paper sx={{ p: 2, mb: 4, backgroundColor: theme.palette.background.paper }}>
      {/* Header Section */}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={4}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexDirection: 'column', alignItems: 'flex-start' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="h6" sx={{ fontWeight: 'medium' }}>
                {getPlanName(currentSubscription.planId)}
              </Typography>
              <Chip 
                label={currentSubscription.status.toUpperCase()}
                color={currentSubscription.status === 'active' ? 'success' : 'default'}
                size="small"
              />
            </Box>
            {currentSubscription.scheduledPlanId && (
              <Box sx={{ mt: 1 }}>
                <Chip
                  label={`Changing to ${getPlanName(currentSubscription.scheduledPlanId)} on ${format(new Date(currentSubscription.currentPeriodEnd), 'dd/MM/yyyy')}`}
                  color="warning"
                  variant="outlined"
                  size="small"
                />
                <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1 }}>
                  Your annual subscription remains active until the end of your billing period
                </Typography>
              </Box>
            )}
            <Typography variant="caption" color="text.secondary">
              ID: {currentSubscription.subscriptionId}
              <IconButton 
                size="small" 
                onClick={() => {
                  navigator.clipboard.writeText(currentSubscription.subscriptionId);
                  toast.success('Subscription ID copied');
                }}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={3}>
              <Typography variant="caption" color="text.secondary">Current Period</Typography>
              <Typography variant="body2">
                {currentSubscription.currentPeriodStart ? 
                  formatDate(currentSubscription.currentPeriodStart) : '—'} - {' '}
                {currentSubscription.currentPeriodEnd ? 
                  formatDate(currentSubscription.currentPeriodEnd) : '—'}
              </Typography>
              {isDowngradingFromAnnual() && (
                <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                  Annual plan active until end of period
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="caption" color="text.secondary">Payment Method</Typography>
              <Typography variant="body2">
                •••• {currentSubscription.paymentMethod?.last4 || '****'}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="caption" color="text.secondary">Next Invoice</Typography>
              <Typography variant="body2">
                {currentSubscription.cancelAtPeriodEnd ? 'None' : format(new Date(currentSubscription.currentPeriodEnd), 'dd/MM/yyyy')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              {currentSubscription.cancelAtPeriodEnd ? (
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => onReactivate(currentSubscription.subscriptionId)}
                  startIcon={<AutorenewIcon />}
                >
                  Resume
                </Button>
              ) : (
                <Button
                  size="small"
                  variant="outlined"
                  color="error"
                  onClick={onCancel}
                  startIcon={<CancelIcon />}
                >
                  Cancel
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>

        {isDowngradingFromAnnual() && (
          <Grid item xs={12}>
            <Alert severity="info" sx={{ mt: 1 }}>
              You are currently on the annual {getPlanName(currentSubscription.planId)}. 
              Your subscription will change to {getPlanName(currentSubscription.scheduledPlanId)} on {' '}
              {format(new Date(currentSubscription.currentPeriodEnd), 'dd/MM/yyyy')} at the end of your annual period.
            </Alert>
          </Grid>
        )}
      </Grid>

      {/* Invoices Section - Collapsible */}
      <Accordion sx={{ mt: 2, boxShadow: 'none', '&:before': { display: 'none' } }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle2">
            <ReceiptIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
            Billing History
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {renderInvoicesTable()}
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

// Add this component after the SubscriptionDetails component
const FeatureComparisonTable = () => {
  const theme = useTheme();
  
  const features = [
    {
      name: 'Growth Strategy Tools',
      standard: true,
      intelligence: true,
      description: 'Access to core growth strategy planning tools'
    },
    {
      name: 'Strategic Canvases',
      standard: true,
      intelligence: true,
      description: 'Visual strategy mapping and planning tools'
    },
    {
      name: 'Bid Management',
      standard: 'Basic',
      intelligence: 'Advanced AI',
      description: 'Tools for managing and tracking bids'
    },
    {
      name: 'Team Collaboration',
      standard: '5 members',
      intelligence: 'Unlimited',
      description: 'Collaborate with team members'
    },
    {
      name: 'AI Strategy Assistant',
      standard: false,
      intelligence: true,
      description: 'AI-powered strategy recommendations'
    },
    {
      name: 'Generative AI Copilot',
      standard: false,
      intelligence: true,
      description: 'AI assistance in content creation'
    },
    {
      name: 'Analytics & Reporting',
      standard: 'Basic',
      intelligence: 'Advanced',
      description: 'Track and analyze performance'
    },
    {
      name: 'Support',
      standard: 'Email',
      intelligence: 'Priority',
      description: 'Access to customer support'
    }
  ];

  return (
    <Box sx={{ mt: 8, mb: 6 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Feature Comparison
      </Typography>
      <TableContainer component={Paper} elevation={2}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Feature</TableCell>
              <TableCell align="center">Standard</TableCell>
              <TableCell align="center">Intelligence+</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {features.map((feature) => (
              <TableRow key={feature.name} hover>
                <TableCell>
                  <Typography variant="subtitle2">{feature.name}</Typography>
                  <Typography variant="caption" color="text.secondary">
                    {feature.description}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {typeof feature.standard === 'boolean' ? (
                    feature.standard ? (
                      <CheckIcon color="success" />
                    ) : (
                      <CloseIcon color="error" />
                    )
                  ) : (
                    feature.standard
                  )}
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: theme.palette.action.hover }}>
                  {typeof feature.intelligence === 'boolean' ? (
                    feature.intelligence ? (
                      <CheckIcon color="success" />
                    ) : (
                      <CloseIcon color="error" />
                    )
                  ) : (
                    <Typography color="secondary.main" fontWeight="bold">
                      {feature.intelligence}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

// Add these components after the FeatureComparisonTable component
const FAQSection = () => {
  const faqs = [
    {
      question: 'What payment methods do you accept?',
      answer: 'We accept all major credit cards including Visa, Mastercard, and American Express.'
    },
    {
      question: 'Can I switch plans at any time?',
      answer: 'Yes, you can upgrade or downgrade your plan at any time. Upgrades take effect immediately, while downgrades take effect at the end of your billing cycle.'
    },
    {
      question: 'Is there a contract or minimum commitment?',
      answer: 'No, our plans are month-to-month or annual with no long-term commitment required. You can cancel at any time.'
    },
    {
      question: 'What happens when I cancel my subscription?',
      answer: "When you cancel, you'll maintain access to your plan features until the end of your current billing period. No refunds are provided for partial months."
    }
  ];

  return (
    <Box sx={{ mt: 8, mb: 6 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Frequently Asked Questions
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="text.secondary">{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

const Subscriptions = ({ userData, selectedOrg }) => {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [selectedPriceId, setSelectedPriceId] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [stripeError, setStripeError] = useState(null);
  const [error, setError] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [isCancelling, setIsCancelling] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      if (!userData?.localAccountId) return;

      setLoading(true);
      try {
        // Get subscription status from backend
        const status = await subscriptionService.getUserSubscriptionStatus(userData.localAccountId);
        console.log('Fetched subscription status:', status);
        
        // If we have an active subscription, fetch the full subscription details from Stripe
        if (status.status === 'active' && status.subscriptionId) {
          try {
            const stripeSubscription = await subscriptionService.getSubscriptionDetails(status.subscriptionId);
            setSubscriptionData({
              ...status,
              ...stripeSubscription,
              default_payment_method: stripeSubscription.default_payment_method
            });
          } catch (error) {
            console.error('Error fetching Stripe subscription details:', error);
            setSubscriptionData(status);
          }
        } else {
          setSubscriptionData(status);
        }

        // Also fetch user profile if needed
        if (!userProfile) {
          const profile = await getUser(userData.localAccountId);
          setUserProfile(profile);
        }
      } catch (error) {
        console.error('Error fetching subscription data:', error);
        if (error.response?.status !== 404) {
          setError('Failed to fetch subscription status');
        }
        setSubscriptionData({
          status: 'inactive',
          planId: null,
          trialEndDate: null,
          cancelAtPeriodEnd: false
        });
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionStatus();
  }, [userData?.localAccountId]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (userData?.localAccountId) {
        try {
          const profile = await getUser(userData.localAccountId);
          setUserProfile(profile);
        } catch (error) {
          console.error('Error fetching user profile:', error);
          setError('Failed to fetch user profile');
        }
      }
    };

    fetchUserProfile();
  }, [userData]);

  const handleSubscriptionSuccess = async (subscriptionData) => {
    try {
      await refreshSubscriptionData();
      setShowPaymentForm(false);
      toast.success('Subscription updated successfully!');
    } catch (error) {
      console.error('Error updating subscription status:', error);
      setError('Subscription updated but failed to refresh status. Please refresh the page.');
    }
  };

  const handlePaymentSubmit = async (paymentMethodId) => {
    setIsProcessing(true);
    setError(null);
    
    try {
      if (!userProfile) {
        throw new Error('No user profile found');
      }

      // Use userProfile data from userService instead of Azure B2C
      const userDetails = {
        firstName: userProfile.firstName || userProfile.givenName || '',
        lastName: userProfile.lastName || userProfile.surname || '',
        email: userProfile.email || '',
        userId: userProfile.id || userData.localAccountId
      };

      // Log the user details for debugging
      console.log('User profile:', userProfile);
      console.log('User details for subscription:', userDetails);

      // Validate required fields
      if (!userDetails.email) {
        throw new Error('Unable to retrieve user email');
      }

      if (!selectedPriceId) {
        throw new Error('No price selected');
      }

      console.log('Creating subscription with:', {
        paymentMethodId,
        priceId: selectedPriceId,
        ...userDetails
      });

      const result = await subscriptionService.createSubscription(
        paymentMethodId,
        selectedPriceId,
        userDetails.firstName,
        userDetails.lastName,
        userDetails.email,
        userDetails.userId
      );

      if (result.success) {
        await handleSubscriptionSuccess(result.data);
      } else {
        throw new Error(result.error?.message || 'Subscription creation failed');
      }
    } catch (error) {
      console.error('Error during subscription creation:', error);
      setError(error.message || 'Failed to create subscription. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const calculateDaysRemaining = (endDate) => {
    const end = new Date(endDate);
    const now = new Date();
    const diffTime = end - now;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays > 0 ? diffDays : 0;
  };

  const handleSubscribe = async (priceId, interval) => {
    if (!priceId) {
      setError('Invalid price ID');
      return;
    }
    console.log('Selected price ID:', priceId, 'Interval:', interval);
    
    // Verify the price ID is active
    const selectedPlan = SUBSCRIPTION_PLANS.find(plan => 
      plan.billing.monthly.id === priceId || plan.billing.annual.id === priceId
    );
    
    if (!selectedPlan) {
      setError('Invalid plan selected');
      return;
    }
    
    setSelectedPriceId(priceId);
    setShowPaymentForm(true);
  };

  const getSubscriptionActions = (currentPlanId) => {
    const currentPlan = SUBSCRIPTION_PLANS.find(p => p.priceId === currentPlanId);
    if (!currentPlan) return [];

    const actions = [];

    // Determine available actions based on current plan
    if (currentPlan.title.includes('Standard')) {
      // Standard plan users can upgrade to Intelligence+
      actions.push({
        type: 'upgrade',
        to: currentPlan.billingPeriod === 'monthly' ? 'Intelligence+ Monthly' : 'Intelligence+ Annual',
        priceId: currentPlan.billingPeriod === 'monthly' ? 
          SUBSCRIPTION_PLANS.find(p => p.title === 'Intelligence+ Monthly').priceId :
          SUBSCRIPTION_PLANS.find(p => p.title === 'Intelligence+ Annual').priceId
      });

      // If on monthly, can upgrade to annual
      if (currentPlan.billingPeriod === 'monthly') {
        actions.push({
          type: 'switch',
          to: 'Standard Annual',
          priceId: SUBSCRIPTION_PLANS.find(p => p.title === 'Standard Annual').priceId
        });
      }
    }

    if (currentPlan.title.includes('Intelligence+')) {
      // If on monthly, can upgrade to annual
      if (currentPlan.billingPeriod === 'monthly') {
        actions.push({
          type: 'switch',
          to: 'Intelligence+ Annual',
          priceId: SUBSCRIPTION_PLANS.find(p => p.title === 'Intelligence+ Annual').priceId
        });
      }
    }

    return actions;
  };

  const renderCallToAction = () => {
    if (!subscriptionData) return null;

    if (subscriptionData.status === 'trial') {
      const daysRemaining = calculateDaysRemaining(subscriptionData.trialEndDate);
      return (
        <CallToActionAlert 
          severity="info" 
          action={
            <Button color="inherit" size="small" onClick={() => handleSubscribe(SUBSCRIPTION_PLANS[0].priceId.monthly)}>
              Subscribe Now
            </Button>
          }
        >
          Your free trial ends in {daysRemaining} days. Subscribe now to continue enjoying our services!
        </CallToActionAlert>
      );
    } else if (!subscriptionData.status || subscriptionData.status === 'inactive') {
      return (
        <CallToActionAlert 
          severity="warning" 
          action={
            <Button color="inherit" size="small" onClick={() => handleSubscribe(SUBSCRIPTION_PLANS[0].priceId.monthly)}>
              Subscribe Now
            </Button>
          }
        >
          You don't have an active subscription. Subscribe now to access our services!
        </CallToActionAlert>
      );
    }
    return null;
  };

  const renderLoadingSkeleton = () => (
    <Container maxWidth="lg">
      <Box my={8}>
        <Skeleton variant="rectangular" width="100%" height={100} sx={{ mb: 4 }} />
        
        <Grid container spacing={4}>
          {[1, 2].map((_, index) => (
            <Grid item xs={12} md={6} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <StyledCard>
                  <CardContent>
                    <Skeleton variant="text" width="60%" height={40} sx={{ mb: 2 }} />
                    <Skeleton variant="text" width="40%" height={30} sx={{ mb: 2 }} />
                    <Skeleton variant="rectangular" width="100%" height={200} sx={{ mb: 2 }} />
                    <Skeleton variant="text" width="80%" />
                    <Skeleton variant="text" width="70%" />
                    <Skeleton variant="text" width="75%" />
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'center', mt: 2 }}>
                    <Skeleton variant="rectangular" width={200} height={40} />
                  </CardActions>
                </StyledCard>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );

  const handleCancelSubscription = async () => {
    if (!subscriptionData?.subscriptionId) {
      setError('No active subscription found');
      return;
    }

    try {
      setIsCancelling(true);
      await subscriptionService.cancelSubscription(subscriptionData.subscriptionId);
      
      // Refresh subscription data immediately
      await refreshSubscriptionData();
      
      toast.success('Subscription cancelled successfully');
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      setError('Failed to cancel subscription. Please try again.');
    } finally {
      setIsCancelling(false);
      setShowCancelDialog(false);
    }
  };

  const CancellationDialog = () => (
    <Dialog open={showCancelDialog} onClose={() => setShowCancelDialog(false)}>
      <DialogTitle>Cancel Subscription</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to cancel your subscription? You will lose access to premium features at the end of your current billing period.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowCancelDialog(false)}>No, Keep Subscription</Button>
        <Button 
          onClick={() => {
            handleCancelSubscription();
            setShowCancelDialog(false);
          }} 
          color="error"
          variant="contained"
        >
          Yes, Cancel Subscription
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderPlans = () => (
    <Grid container spacing={4}>
      {SUBSCRIPTION_PLANS.map((plan) => (
        <Grid item xs={12} md={6} key={plan.title}>
          <PlanCard
            plan={plan}
            currentSubscription={subscriptionData}
            onSubscribe={handleSubscribe}
            onCancel={() => setShowCancelDialog(true)}
            onReactivate={handleReactivateSubscription}
            isProcessingCancel={isCancelling}
            allPlans={SUBSCRIPTION_PLANS}
          />
        </Grid>
      ))}
    </Grid>
  );

  const GlobalStyles = styled('style')({
    '@keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' }
    },
    '.spinning': {
      animation: 'spin 1s linear infinite'
    }
  });

  const handleReactivateSubscription = async (subscriptionId) => {
    try {
      setIsProcessing(true);
      const response = await subscriptionService.reactivateSubscription(subscriptionId);
      
      if (response.success) {
        // Refresh subscription data immediately
        await refreshSubscriptionData();
        toast.success('Subscription reactivated successfully');
      } else {
        throw new Error(response.error?.message || 'Failed to reactivate subscription');
      }
    } catch (error) {
      console.error('Error reactivating subscription:', error);
      setError('Failed to reactivate subscription. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  // Add a refresh function
  const refreshSubscriptionData = async () => {
    try {
      const status = await subscriptionService.getUserSubscriptionStatus(userData.localAccountId);
      
      if (status.status === 'active' && status.subscriptionId) {
        try {
          const stripeSubscription = await subscriptionService.getSubscriptionDetails(status.subscriptionId);
          setSubscriptionData({
            ...status,
            ...stripeSubscription,
            default_payment_method: stripeSubscription.default_payment_method
          });
        } catch (error) {
          console.error('Error fetching Stripe subscription details:', error);
          setSubscriptionData(status);
        }
      } else {
        setSubscriptionData(status);
      }
    } catch (error) {
      console.error('Error refreshing subscription data:', error);
      if (error.response?.status !== 404) {
        setError('Failed to refresh subscription status');
      }
    }
  };

  // Add polling for subscription updates
  useEffect(() => {
    const pollInterval = setInterval(() => {
      if (userData?.localAccountId) {
        refreshSubscriptionData();
      }
    }, 10000); // Poll every 10 seconds

    return () => clearInterval(pollInterval);
  }, [userData?.localAccountId]);

  if (loading) {
    return (
      <Fade in={true} style={{ transitionDelay: '300ms' }}>
        <div>{renderLoadingSkeleton()}</div>
      </Fade>
    );
  }

  return (
    <Container maxWidth="lg">
      {/* Subscription Details Panel */}
      {subscriptionData?.status === 'active' && (
        <SubscriptionDetails 
          subscription={subscriptionData} 
          currentSubscription={subscriptionData}
          onReactivate={handleReactivateSubscription}
          onCancel={() => setShowCancelDialog(true)}
          isProcessing={isProcessing}
        />
      )}

      {/* Page Header */}
      <PageHeader>
        <Typography variant="h3" gutterBottom>
          Choose Your Plan
        </Typography>
        <Typography variant="subtitle1">
          Start growing your business with our powerful tools
        </Typography>
      </PageHeader>

      {/* Error Alert */}
      {error && (
        <Alert severity="error" sx={{ mb: 3 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {/* Subscription Plans */}
      <Grid container spacing={4} sx={{ mb: 6 }}>
        {SUBSCRIPTION_PLANS.map((plan) => (
          <Grid item xs={12} md={6} key={plan.title}>
            <PlanCard
              plan={plan}
              currentSubscription={subscriptionData}
              onSubscribe={handleSubscribe}
              onCancel={() => setShowCancelDialog(true)}
              onReactivate={handleReactivateSubscription}
              isProcessingCancel={isCancelling}
              allPlans={SUBSCRIPTION_PLANS}
            />
          </Grid>
        ))}
      </Grid>

      {/* Feature Comparison Table */}
      <FeatureComparisonTable />

      {/* FAQ Section */}
      <FAQSection />

      {/* Payment Form Dialog */}
      {showPaymentForm && (
        <Dialog 
          open={showPaymentForm} 
          onClose={() => !isProcessing && setShowPaymentForm(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Complete Subscription</DialogTitle>
          <DialogContent>
            <StripeWrapper>
              <PaymentForm 
                onSubscribe={handlePaymentSubmit}
                isLoading={isProcessing}
              />
            </StripeWrapper>
          </DialogContent>
        </Dialog>
      )}

      {/* Cancellation Dialog */}
      <CancellationDialog />
    </Container>
  );
};

export default Subscriptions;