import React, { useState } from 'react';
import {
  List, ListItem, ListItemText, ListItemSecondaryAction, 
  IconButton, Collapse, Typography, Divider, Box,
  ListItemIcon, Tooltip, Paper, Menu, MenuItem,
  Select, FormControl, InputLabel
} from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  ExpandLess, ExpandMore, Add as AddIcon,
  ChevronLeft, ChevronRight,
  Dashboard as DashboardIcon,
  Assignment as TasksIcon,
  Group as TeamIcon,
  Event as TimelineIcon,
  Description as DescriptionIcon,
  AttachMoney as BudgetIcon,
  AccessTime as TimeTrackingIcon,
  Folder as DocumentsIcon,
  Assignment as AssignmentIcon
} from '@mui/icons-material';

const navigationItems = [
  { icon: <DashboardIcon />, label: 'Overview', index: 0 },
  { icon: <AssignmentIcon />, label: 'Bid Progress', index: 1 },
  { icon: <TasksIcon />, label: 'Tasks', index: 2 },
  { icon: <TeamIcon />, label: 'Team', index: 3 },
  { icon: <TimelineIcon />, label: 'Timeline', index: 4 },
  { icon: <DescriptionIcon />, label: 'Bid Canvases', index: 5 },
  { icon: <BudgetIcon />, label: 'Budget', index: 6 },
  { icon: <TimeTrackingIcon />, label: 'Time Tracking', index: 7 },
  { icon: <DocumentsIcon />, label: 'Documents', index: 8 }
];

const BidList = React.memo(({ 
  activeBids, 
  archivedBids, 
  selectedBid, 
  showArchivedBids,
  onSelectBid,
  onCreateBid,
  onMenuOpen,
  onToggleArchivedBids,
  currentTab,
  onTabChange
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedView, setSelectedView] = useState('active');
  const [showBidList, setShowBidList] = useState(false);

  const renderBidList = (bids) => (
    <List dense>
      {bids.map((bid) => (
        <ListItem
          key={bid.id}
          button
          selected={selectedBid?.id === bid.id}
          onClick={() => onSelectBid(bid)}
          sx={{
            borderRadius: 1,
            mb: 0.5,
            '&.Mui-selected': {
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              '&:hover': {
                backgroundColor: 'primary.dark',
              }
            }
          }}
        >
          <ListItemText
            primary={bid.name}
            secondary={bid.status}
            primaryTypographyProps={{
              noWrap: true,
              fontSize: '0.9rem'
            }}
            secondaryTypographyProps={{
              noWrap: true,
              fontSize: '0.8rem'
            }}
            sx={{ 
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          />
          <ListItemSecondaryAction>
            <IconButton 
              edge="end" 
              size="small"
              onClick={(e) => onMenuOpen(e, bid, selectedView === 'archived')}
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );

  const renderNavigation = () => (
    <List>
      {navigationItems.map((item) => (
        <ListItem
          key={item.index}
          button
          selected={currentTab === item.index}
          onClick={() => onTabChange(item.index)}
          sx={{
            minHeight: 48,
            justifyContent: isCollapsed ? 'center' : 'flex-start',
            px: 2.5,
            '&.Mui-selected': {
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
              '& .MuiListItemIcon-root': {
                color: 'primary.contrastText',
              }
            }
          }}
        >
          <Tooltip title={isCollapsed ? item.label : ''} placement="right">
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isCollapsed ? 0 : 2,
                justifyContent: 'center',
              }}
            >
              {item.icon}
            </ListItemIcon>
          </Tooltip>
          {!isCollapsed && <ListItemText primary={item.label} />}
        </ListItem>
      ))}
    </List>
  );

  return (
    <Box 
      sx={{ 
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        transition: 'all 0.2s ease-in-out',
        width: isCollapsed ? '60px' : '300px',
        minWidth: isCollapsed ? '60px' : '300px',
        flexShrink: 0
      }}
    >
      {/* Collapse Control at Top */}
      <Box sx={{ 
        p: 1, 
        display: 'flex', 
        justifyContent: 'flex-end',
        borderBottom: 1,
        borderColor: 'divider',
        bgcolor: 'background.paper',
        minWidth: isCollapsed ? '60px' : '300px',
        width: '100%'
      }}>
        <Tooltip title={isCollapsed ? "Expand" : "Collapse"}>
          <IconButton 
            size="small" 
            onClick={() => setIsCollapsed(!isCollapsed)}
            sx={{ visibility: 'visible' }}
          >
            {isCollapsed ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </Tooltip>
      </Box>

      {/* Navigation */}
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        {renderNavigation()}
      </Box>

      <Divider />

      {/* Compact Bid Selection */}
      <Box>
        <ListItem 
          button 
          onClick={() => setShowBidList(!showBidList)}
          sx={{ 
            justifyContent: isCollapsed ? 'center' : 'space-between',
            minHeight: 48,
            px: 2.5,
            background: 'linear-gradient(45deg, #f3f3f3 30%, #e0e0e0 90%)',
            '&:hover': {
              background: 'linear-gradient(45deg, #e0e0e0 30%, #d0d0d0 90%)',
            }
          }}
        >
          {!isCollapsed && (
            <>
              <ListItemText 
                primary={selectedBid?.name || 'Select Bid'} 
                secondary={`${activeBids.length} active`}
                primaryTypographyProps={{
                  noWrap: true,
                  fontSize: '0.9rem',
                  fontWeight: 'medium',
                  color: 'text.primary'
                }}
                secondaryTypographyProps={{
                  color: 'text.secondary',
                  fontSize: '0.8rem'
                }}
              />
              {showBidList ? <ExpandLess color="action" /> : <ExpandMore color="action" />}
            </>
          )}
          {isCollapsed && (
            <Tooltip title="Select Bid" placement="right">
              <ListItemIcon sx={{ minWidth: 0 }}>
                <DescriptionIcon />
              </ListItemIcon>
            </Tooltip>
          )}
        </ListItem>

        <Collapse in={showBidList && !isCollapsed} timeout="auto">
          <Box sx={{ p: 1 }}>
            <Box sx={{ mb: 1, display: 'flex', gap: 1 }}>
              <FormControl size="small" fullWidth>
                <Select
                  value={selectedView}
                  onChange={(e) => setSelectedView(e.target.value)}
                  variant="outlined"
                  sx={{ fontSize: '0.9rem' }}
                >
                  <MenuItem value="active">Active ({activeBids.length})</MenuItem>
                  <MenuItem value="archived">Archived ({archivedBids.length})</MenuItem>
                </Select>
              </FormControl>
              <Tooltip title="Create New Bid">
                <IconButton size="small" color="primary" onClick={onCreateBid}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Box>
            {renderBidList(selectedView === 'active' ? activeBids : archivedBids)}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
});

export default BidList;
