import React from 'react';
import { Typography, Paper, Box } from '@mui/material';

function Settings() {
  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Settings Page
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Typography variant="body1">
          Here you can add various settings for your application.
        </Typography>
      </Box>
    </Paper>
  );
}

export default Settings;