import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Button, Grid, MenuItem, FormControl,
  InputLabel, Select, Typography, Tooltip, IconButton,
  AppBar, Toolbar, Slide, Box, Stepper, Step, StepLabel,
  Snackbar, Alert, CircularProgress, LinearProgress, Chip
} from '@mui/material';
import { InfoOutlined as InfoIcon, Close as CloseIcon, Save as SaveIcon, ArrowBack, ArrowForward, Edit as EditIcon, Preview as PreviewIcon, ViewList as ViewListIcon, Article as ArticleIcon } from '@mui/icons-material';
import { createCollateral, updateCollateral } from '../../services/collateralService';
import CollateralAssistButton from '../ai/CollateralAssistButton';
import { collateralTypes } from '../../constants/collateralTypes';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const collateralConfig = {
  pitchDeck: {
    fields: [
      { 
        name: 'numberOfSlides', 
        label: 'Number of Slides', 
        type: 'number',
        tooltip: "Aim for 10-15 slides for a concise pitch. Each slide should focus on a key aspect of your business. Remember, less is often more in pitch presentations.",
        examples: ["10 slides for a seed round pitch", "15 slides for a Series A pitch"]
      },
      { 
        name: 'keyPoints', 
        label: 'Key Points', 
        type: 'textarea',
        tooltip: "List 5-7 main points that tell your story. These should cover your problem, solution, market opportunity, business model, competitive advantage, team, and funding ask.",
        examples: [
          "1. Problem: High customer acquisition costs in e-commerce\n2. Solution: AI-powered personalization platform\n3. Market: $4.9 trillion e-commerce industry\n4. Business Model: SaaS with tiered pricing\n5. Competitive Edge: Proprietary AI algorithm\n6. Team: Ex-Google and Amazon engineers\n7. Ask: $2M seed round for market expansion"
        ]
      },
      { 
        name: 'targetAudience', 
        label: 'Target Audience', 
        type: 'text',
        tooltip: "Specify the primary investors or stakeholders you're pitching to. Understanding your audience helps tailor your message effectively.",
        examples: ["Early-stage tech VCs focused on B2B SaaS", "Angel investors interested in sustainability"]
      },
      { 
        name: 'problemStatement', 
        label: 'Problem Statement', 
        type: 'textarea',
        tooltip: "Clearly articulate the problem your product or service solves. Use data or anecdotes to illustrate the pain point's significance.",
        examples: ["E-commerce businesses lose $18B annually due to cart abandonment, largely caused by poor personalization"]
      },
      { 
        name: 'solution', 
        label: 'Solution', 
        type: 'textarea',
        tooltip: "Describe your product or service and how it solves the problem. Focus on its unique features and benefits.",
        examples: ["Our AI-powered platform analyzes user behavior in real-time, delivering personalized product recommendations that increase conversion rates by 35%"]
      },
      { 
        name: 'marketSize', 
        label: 'Market Size', 
        type: 'text',
        tooltip: "Quantify your total addressable market (TAM), serviceable addressable market (SAM), and serviceable obtainable market (SOM). Use credible sources for your figures.",
        examples: ["TAM: $4.9T global e-commerce market, SAM: $245B in personalization solutions, SOM: $24.5B in first year targeting mid-size retailers"]
      },
      { 
        name: 'businessModel', 
        label: 'Business Model', 
        type: 'textarea',
        tooltip: "Explain how your business makes money. Include your pricing strategy, sales channels, and key partnerships if applicable.",
        examples: ["SaaS model with tiered pricing: $499/mo for small businesses, $2499/mo for enterprises. Direct sales and partner channel with e-commerce platforms."]
      },
      { 
        name: 'competitiveAdvantage', 
        label: 'Competitive Advantage', 
        type: 'textarea',
        tooltip: "Highlight what sets you apart from competitors. This could be technology, partnerships, team expertise, or other unique selling points.",
        examples: ["Proprietary AI algorithm achieves 98% accuracy in predictions, 30% higher than leading competitors. Patent-pending technology and exclusive partnership with top e-commerce platform."]
      },
      { 
        name: 'financialProjections', 
        label: 'Financial Projections', 
        type: 'textarea',
        tooltip: "Provide a high-level overview of your financial projections for the next 3-5 years. Include revenue, costs, and profitability milestones.",
        examples: ["Year 1: $2M revenue, -$500K EBITDA\nYear 3: $15M revenue, $3M EBITDA\nYear 5: $50M revenue, $15M EBITDA\nProjecting break-even in Q4 of Year 2"]
      },
      { 
        name: 'teamOverview', 
        label: 'Team Overview', 
        type: 'textarea',
        tooltip: "Highlight key team members, their roles, and relevant experience. Focus on aspects that directly contribute to your business's success.",
        examples: ["CEO: Jane Doe - Ex-Google AI lead, PhD in Machine Learning\nCTO: John Smith - Former Amazon principal engineer, 5 patents in e-commerce tech\nCOO: Alex Johnson - Scaled previous startup to $100M ARR, successful exit"]
      },
      { 
        name: 'fundingAsk', 
        label: 'Funding Ask', 
        type: 'text',
        tooltip: "Clearly state how much funding you're seeking and what it will be used for. Be prepared to justify the amount based on your projections and milestones.",
        examples: ["Seeking $5M Series A to scale sales team, enhance AI capabilities, and expand to European market"]
      },
    ],
    tooltip: "A pitch deck is a concise presentation that provides investors with an overview of your business plan, highlighting key aspects such as the problem you're solving, your solution, market opportunity, team, and funding needs.",
    examples: [
      "A 12-slide deck focusing on problem, solution, market size, business model, competitive advantage, team, and funding ask",
      "A visually striking presentation with clear value proposition, traction metrics, and compelling financial projections"
    ]
  },
  onePager: {
    fields: [
      { 
        name: 'headline', 
        label: 'Headline', 
        type: 'text',
        tooltip: "Create a concise, attention-grabbing headline that encapsulates your value proposition. Use strong, active language and consider incorporating a key benefit or unique selling point.",
        examples: [
          "Revolutionary AI Cuts E-commerce Costs by 40%",
          "Sustainable Fashion Meets Cutting-Edge Style"
        ]
      },
      { 
        name: 'subheadline', 
        label: 'Subheadline', 
        type: 'text',
        tooltip: "Expand on your headline with a brief statement that provides more context or highlights additional key benefits. This is your chance to hook the reader and encourage them to continue.",
        examples: [
          "Our AI-Powered Platform Boosts Conversion Rates and Slashes Customer Acquisition Costs",
          "Eco-Friendly, Trendsetting Apparel for the Conscious Consumer"
        ]
      },
      { 
        name: 'problemStatement', 
        label: 'Problem Statement', 
        type: 'textarea',
        tooltip: "Clearly and concisely describe the problem your product or service solves. Use data or relatable scenarios to illustrate the pain point's significance to your target audience.",
        examples: ["E-commerce businesses struggle with high customer acquisition costs, averaging $45 per new customer, while facing cart abandonment rates of 70%"]
      },
      { 
        name: 'solution', 
        label: 'Solution', 
        type: 'textarea',
        tooltip: "Describe your product or service, focusing on how it addresses the problem. Highlight key features and their corresponding benefits to the user or customer.",
        examples: ["Our AI-powered platform analyzes user behavior in real-time, delivering hyper-personalized product recommendations and optimized pricing strategies, reducing acquisition costs by 40% and increasing conversion rates by 35%"]
      },
      { 
        name: 'keyFeatures', 
        label: 'Key Features', 
        type: 'textarea',
        tooltip: "List 3-5 standout features of your product or service. Focus on what makes you unique and how each feature translates to a tangible benefit for the user.",
        examples: ["1. Real-time AI analysis of user behavior\n2. Hyper-personalized product recommendations\n3. Dynamic pricing optimization\n4. One-click integration with major e-commerce platforms\n5. GDPR-compliant data handling"]
      },
      { 
        name: 'targetMarket', 
        label: 'Target Market', 
        type: 'text',
        tooltip: "Define your ideal customer or user. Include demographic information, industry focus, and any other relevant characteristics that help paint a clear picture of who your solution is for.",
        examples: ["Mid-size to enterprise-level e-commerce businesses in the fashion, electronics, and home goods sectors, with annual revenues of $10M-$500M"]
      },
      { 
        name: 'uniqueSellingProposition', 
        label: 'Unique Selling Proposition', 
        type: 'textarea',
        tooltip: "Articulate what sets you apart from competitors. This should be a clear, compelling statement that combines your unique strengths with the primary benefit to your customers.",
        examples: ["Unlike generic analytics tools, our AI-powered platform offers real-time, actionable insights that directly boost your bottom line, with an average ROI of 300% within the first year"]
      },
      { 
        name: 'socialProof', 
        label: 'Social Proof', 
        type: 'textarea',
        tooltip: "Provide evidence of your success or credibility. This can include customer testimonials, case studies, awards, or impressive metrics from existing clients.",
        examples: ["\"SmartAI increased our conversion rates by 45% in just 3 months\" - Jane Doe, CEO of FashionFront\n\n• 98% customer retention rate\n• Used by 3 of the top 10 US e-commerce retailers\n• Winner of the 2023 Retail Tech Innovation Award"]
      },
      { 
        name: 'callToAction', 
        label: 'Call to Action', 
        type: 'text',
        tooltip: "Create a clear, compelling call to action that guides the reader on what to do next. Make it specific and easy to follow through on.",
        examples: ["Schedule a free demo today and see how we can boost your e-commerce performance", "Visit our website to start your 30-day free trial - no credit card required"]
      },
      { 
        name: 'contactInformation', 
        label: 'Contact Information', 
        type: 'text',
        tooltip: "Provide clear and multiple ways for interested parties to reach you. Include a name, phone number, email, and website at minimum.",
        examples: ["Jane Smith, Founder & CEO\nPhone: (555) 123-4567\nEmail: jane@smartai.com\nWebsite: www.smartai.com"]
      },
    ],
    tooltip: "A one-pager is a concise document that provides a high-level overview of your product, service, or business plan. It should be visually appealing, easy to scan, and contain all the key information a potential investor or partner needs to understand your value proposition.",
    examples: [
      "A visually striking one-pager with a bold headline, 3-5 key benefits, compelling statistics, and a clear call-to-action",
      "An infographic-style one-pager showcasing the problem, your solution, key features, and impressive traction metrics"
    ]
  },
  whitePaper: {
    fields: [
      { 
        name: 'title', 
        label: 'Title', 
        type: 'text',
        tooltip: "Create a compelling, descriptive title that accurately represents the content of your white paper.",
        examples: ["The Future of AI in Healthcare: Revolutionizing Patient Care", "Blockchain Technology: Transforming Supply Chain Management"]
      },
      { 
        name: 'executiveSummary', 
        label: 'Executive Summary', 
        type: 'textarea',
        tooltip: "Provide a brief overview of the main points covered in the white paper. This should entice the reader to continue reading.",
        examples: ["This white paper explores the potential of AI in revolutionizing healthcare, discussing current applications, future possibilities, and potential challenges."]
      },
      { 
        name: 'introduction', 
        label: 'Introduction', 
        type: 'textarea',
        tooltip: "Set the context for your white paper. Introduce the problem or topic and why it's important.",
        examples: ["The healthcare industry stands on the brink of a technological revolution, with Artificial Intelligence poised to transform patient care, diagnosis, and treatment..."]
      },
      { 
        name: 'problemStatement', 
        label: 'Problem Statement', 
        type: 'textarea',
        tooltip: "Clearly define the problem or challenge that your white paper addresses.",
        examples: ["Despite advancements in medical technology, healthcare providers still struggle with inefficiencies in diagnosis, treatment planning, and patient monitoring..."]
      },
      { 
        name: 'methodology', 
        label: 'Methodology', 
        type: 'textarea',
        tooltip: "Explain the approach or research methods used to gather information for the white paper.",
        examples: ["This white paper draws on a comprehensive literature review, expert interviews, and case studies from leading healthcare institutions..."]
      },
      { 
        name: 'findings', 
        label: 'Findings/Main Body', 
        type: 'textarea',
        tooltip: "Present your main arguments, data, and analysis. This should be the bulk of your white paper.",
        examples: ["1. Current AI Applications in Healthcare\n2. Potential Future Developments\n3. Challenges and Ethical Considerations\n4. Case Studies of Successful AI Implementation"]
      },
      { 
        name: 'conclusion', 
        label: 'Conclusion', 
        type: 'textarea',
        tooltip: "Summarize the key points and provide a forward-looking statement or call to action.",
        examples: ["AI has the potential to revolutionize healthcare, but realizing this potential will require collaboration between technologists, healthcare providers, and policymakers..."]
      },
      { 
        name: 'references', 
        label: 'References', 
        type: 'textarea',
        tooltip: "List all sources cited in your white paper to add credibility to your research.",
        examples: ["1. Smith, J. (2022). 'The Impact of AI on Medical Diagnosis'. Journal of Medical Technology, 15(2), 45-60.\n2. World Health Organization. (2023). 'Global Report on AI in Healthcare'."]
      }
    ],
    tooltip: "A white paper is an authoritative, in-depth report on a specific topic that presents a problem and provides a solution.",
    examples: [
      "A 20-page white paper on 'The Future of Renewable Energy' with detailed analysis and case studies",
      "A comprehensive report on 'Cybersecurity in the Age of IoT' with expert insights and recommendations"
    ]
  },
  casestudy: {
    fields: [
      { 
        name: 'title', 
        label: 'Title', 
        type: 'text',
        tooltip: "Create a title that highlights the key achievement or solution presented in the case study.",
        examples: ["How Company X Increased Sales by 200% with Our AI Solution", "Reducing Carbon Emissions: A Green Energy Success Story"]
      },
      { 
        name: 'clientOverview', 
        label: 'Client Overview', 
        type: 'textarea',
        tooltip: "Provide a brief description of the client, their industry, and any relevant background information.",
        examples: ["Company X is a mid-sized e-commerce retailer specializing in eco-friendly home goods, with annual revenue of $50 million..."]
      },
      { 
        name: 'challenge', 
        label: 'Challenge', 
        type: 'textarea',
        tooltip: "Clearly state the problem or challenge the client was facing before implementing your solution.",
        examples: ["Company X was struggling with low conversion rates and high customer acquisition costs, impacting their profitability and growth..."]
      },
      { 
        name: 'solution', 
        label: 'Solution', 
        type: 'textarea',
        tooltip: "Describe the solution you provided, including key features and implementation process.",
        examples: ["We implemented our AI-powered personalization platform, which included real-time user behavior analysis, dynamic product recommendations, and automated email marketing..."]
      },
      { 
        name: 'implementation', 
        label: 'Implementation', 
        type: 'textarea',
        tooltip: "Provide details on how the solution was implemented, including timeline and any challenges overcome.",
        examples: ["The implementation was completed in three phases over 4 months: 1. Initial setup and data integration, 2. AI model training and testing, 3. Full deployment and staff training..."]
      },
      { 
        name: 'results', 
        label: 'Results', 
        type: 'textarea',
        tooltip: "Highlight the quantitative and qualitative results achieved after implementing your solution.",
        examples: ["Within 6 months of implementation, Company X saw:\n- 200% increase in sales\n- 45% reduction in customer acquisition costs\n- 60% improvement in customer retention rates"]
      },
      { 
        name: 'testimonial', 
        label: 'Client Testimonial', 
        type: 'textarea',
        tooltip: "Include a quote from the client about their experience and the impact of your solution.",
        examples: ["\"The AI solution provided by [Your Company] has transformed our business. We've seen unprecedented growth and our customers love the personalized experience.\" - Jane Doe, CEO of Company X"]
      },
      { 
        name: 'conclusion', 
        label: 'Conclusion', 
        type: 'textarea',
        tooltip: "Summarize the key takeaways and how this case study demonstrates the value of your solution.",
        examples: ["This case study demonstrates the power of AI-driven personalization in e-commerce. By leveraging our solution, companies can significantly boost sales, reduce costs, and improve customer satisfaction."]
      }
    ],
    tooltip: "A case study is a detailed account of a project or implementation, showcasing the problem, solution, and results achieved for a specific client.",
    examples: [
      "A 5-page case study on how a startup used your software to scale their operations",
      "An in-depth analysis of how your consulting services helped a Fortune 500 company overcome a major challenge"
    ]
  },
  productBrochure: {
    fields: [
      { 
        name: 'productName', 
        label: 'Product Name', 
        type: 'text',
        tooltip: "The name of your product. It should be clear, memorable, and reflective of the product's purpose or benefits.",
        examples: ["EcoClean Pro", "TurboBoost 3000"]
      },
      { 
        name: 'tagline', 
        label: 'Tagline', 
        type: 'text',
        tooltip: "A catchy phrase that encapsulates the key benefit or unique selling proposition of your product.",
        examples: ["Clean Your Home, Save the Planet", "Boost Your Productivity to New Heights"]
      },
      { 
        name: 'productDescription', 
        label: 'Product Description', 
        type: 'textarea',
        tooltip: "A comprehensive description of your product, including its purpose, key features, and benefits.",
        examples: ["EcoClean Pro is an all-natural, biodegradable cleaning solution that effectively removes tough stains while being gentle on the environment..."]
      },
      { 
        name: 'keyFeatures', 
        label: 'Key Features', 
        type: 'textarea',
        tooltip: "List the main features of your product. Focus on what makes it unique and valuable to the customer.",
        examples: ["1. 100% biodegradable formula\n2. Effective on all surfaces\n3. Pleasant lavender scent\n4. Concentrated formula (1 bottle = 10 regular cleaners)\n5. Child and pet safe"]
      },
      { 
        name: 'benefits', 
        label: 'Benefits', 
        type: 'textarea',
        tooltip: "Describe how the features translate into benefits for the customer. What problems does your product solve?",
        examples: ["1. Reduce your carbon footprint without compromising on cleanliness\n2. Save money with our concentrated formula\n3. Enjoy a clean, fresh-smelling home without harsh chemicals"]
      },
      { 
        name: 'specifications', 
        label: 'Specifications', 
        type: 'textarea',
        tooltip: "Provide technical details about your product. This could include size, weight, materials, compatibility, etc.",
        examples: ["Contents: 500ml\nIngredients: Purified water, plant-based surfactants, essential oils\nShelf life: 24 months\nSuitable for: All washable surfaces"]
      },
      { 
        name: 'pricing', 
        label: 'Pricing', 
        type: 'textarea',
        tooltip: "List your product's price points. If you offer different versions or packages, include all options.",
        examples: ["Single bottle: $19.99\n3-pack: $54.99 (Save 10%)\nSubscription: $17.99/month (Save 20% + Free Shipping)"]
      },
      { 
        name: 'testimonials', 
        label: 'Testimonials', 
        type: 'textarea',
        tooltip: "Include quotes from satisfied customers to build trust and credibility.",
        examples: ["\"EcoClean Pro has transformed my cleaning routine. My house is spotless and I feel good about using an eco-friendly product.\" - Sarah K., Happy Customer"]
      },
      { 
        name: 'callToAction', 
        label: 'Call to Action', 
        type: 'text',
        tooltip: "A clear instruction telling the reader what to do next (e.g., how to purchase).",
        examples: ["Order Now and Get 10% Off Your First Purchase!", "Visit Our Website to Learn More and Buy"]
      }
    ],
    tooltip: "A product brochure is a promotional document that provides detailed information about a specific product or product line.",
    examples: [
      "A sleek, 4-page brochure showcasing a new smartphone with high-quality images and technical specifications",
      "A colorful, informative pamphlet about an eco-friendly cleaning product line, highlighting its benefits and usage instructions"
    ]
  },
  socialMediaPost: {
    fields: [
      { 
        name: 'platform', 
        label: 'Platform', 
        type: 'select',
        options: ['LinkedIn', 'Twitter', 'Facebook', 'Instagram'],
        tooltip: "Select the social media platform for this post. Each platform has its own best practices and character limits.",
        examples: ["LinkedIn for B2B content", "Instagram for visual product showcases"]
      },
      { 
        name: 'postContent', 
        label: 'Post Content', 
        type: 'textarea',
        tooltip: "Write the main content of your post. Keep it concise, engaging, and aligned with your brand voice. Include relevant hashtags if applicable.",
        examples: ["Excited to announce our new AI-powered solution that's revolutionizing customer service! 🚀 #AIInnovation #CustomerExperience"]
      },
      { 
        name: 'callToAction', 
        label: 'Call to Action', 
        type: 'text',
        tooltip: "Include a clear call to action that encourages engagement or directs users to take a specific action.",
        examples: ["Click the link in our bio to learn more!", "Share your thoughts in the comments below."]
      },
      { 
        name: 'imageDescription', 
        label: 'Image Description', 
        type: 'textarea',
        tooltip: "Describe the image or video that will accompany your post. Visual content can significantly boost engagement.",
        examples: ["A smiling customer service representative using our AI chatbot interface", "An infographic showing the key benefits of our new product"]
      },
      { 
        name: 'targetAudience', 
        label: 'Target Audience', 
        type: 'text',
        tooltip: "Specify the primary audience for this post. This helps in tailoring the content and tone.",
        examples: ["Tech-savvy millennials interested in sustainable products", "C-level executives in the finance industry"]
      }
    ],
    tooltip: "A social media post is a short-form content piece designed to engage your audience on social platforms. It should be attention-grabbing, concise, and optimized for the specific platform.",
    examples: [
      "A LinkedIn post announcing a new product launch with a link to a detailed blog post",
      "An Instagram carousel showcasing customer testimonials with eye-catching visuals"
    ]
  },
  emailCampaign: {
    fields: [
      { 
        name: 'subject', 
        label: 'Email Subject', 
        type: 'text',
        tooltip: "Create a compelling subject line that encourages recipients to open the email. Keep it under 50 characters for optimal display on mobile devices.",
        examples: ["Don't Miss Out: Exclusive Offer Inside!", "Your AI-Powered Future Starts Now"]
      },
      { 
        name: 'preheader', 
        label: 'Preheader Text', 
        type: 'text',
        tooltip: "The preheader is a summary that follows the subject line when an email is viewed in the inbox. Use it to complement your subject line.",
        examples: ["Limited time 30% off sale for our loyal customers", "See how our new AI tool can boost your productivity"]
      },
      { 
        name: 'greeting', 
        label: 'Greeting', 
        type: 'text',
        tooltip: "Start your email with a friendly, personalized greeting. Use merge tags if your email platform supports personalization.",
        examples: ["Hello [First Name],", "Dear Valued Customer,"]
      },
      { 
        name: 'mainContent', 
        label: 'Main Content', 
        type: 'textarea',
        tooltip: "The body of your email. Keep it concise, valuable, and aligned with the purpose of your campaign. Use short paragraphs and bullet points for readability.",
        examples: ["We're excited to introduce our latest AI-powered tool designed to streamline your workflow..."]
      },
      { 
        name: 'callToAction', 
        label: 'Call to Action', 
        type: 'text',
        tooltip: "A clear, compelling call to action button or link. Make it stand out and use action-oriented language.",
        examples: ["Get Started Now", "Claim Your Free Trial"]
      },
      { 
        name: 'closingStatement', 
        label: 'Closing Statement', 
        type: 'text',
        tooltip: "End your email on a positive note. Reinforce the value proposition or express appreciation.",
        examples: ["We look forward to helping you achieve your goals.", "Thank you for being a valued customer."]
      }
    ],
    tooltip: "An email campaign is a coordinated set of email messages that are sent to a specific group of recipients. It should have a clear purpose, engaging content, and a strong call to action.",
    examples: [
      "A welcome email series for new subscribers introducing your product features",
      "A promotional campaign announcing a limited-time discount on your services"
    ]
  },
  pressRelease: {
    fields: [
      { 
        name: 'dateline', 
        label: 'Dateline', 
        type: 'text',
        tooltip: "Include the release date and origin city of the news. This adds credibility and context to your press release.",
        examples: ["SAN FRANCISCO, July 15, 2023", "NEW YORK, August 1, 2023"]
      },
      { 
        name: 'headline', 
        label: 'Headline', 
        type: 'text',
        tooltip: "Create a clear, attention-grabbing headline that summarizes the key news. Use active voice and keep it under 100 characters.",
        examples: ["AI Startup Secures $10 Million in Series A Funding", "TechCorp Launches Revolutionary Quantum Computing Platform"]
      },
      { 
        name: 'leadParagraph', 
        label: 'Lead Paragraph', 
        type: 'textarea',
        tooltip: "Summarize the most important information (who, what, when, where, why, and how) in the first paragraph. This should capture the essence of the news.",
        examples: ["TechCorp, a leading AI solutions provider, today announced the launch of its new quantum computing platform, set to revolutionize data processing capabilities for businesses worldwide."]
      },
      { 
        name: 'bodyParagraphs', 
        label: 'Body Paragraphs', 
        type: 'textarea',
        tooltip: "Provide more details about the news, including quotes from key figures, statistics, and background information. Use short paragraphs and include the most important information first.",
        examples: ["The new platform, named QuantumAI, leverages cutting-edge quantum algorithms to process complex data sets at unprecedented speeds. 'QuantumAI represents a quantum leap in computing power,' said Jane Doe, CEO of TechCorp. 'It will enable our clients to solve problems that were previously considered unsolvable.'"]
      },
      { 
        name: 'boilerplate', 
        label: 'Boilerplate', 
        type: 'textarea',
        tooltip: "A brief paragraph that describes your company. This should be standardized across all your press releases.",
        examples: ["About TechCorp: TechCorp is a leading provider of AI and quantum computing solutions, dedicated to pushing the boundaries of what's possible in data processing and analysis. Founded in 2015, TechCorp serves clients across various industries, helping them harness the power of advanced computing to drive innovation and growth."]
      },
      { 
        name: 'contactInformation', 
        label: 'Contact Information', 
        type: 'textarea',
        tooltip: "Provide contact details for media inquiries. This typically includes a name, phone number, email, and website.",
        examples: ["For more information, please contact:\nJohn Smith, PR Manager\nPhone: (555) 123-4567\nEmail: john.smith@techcorp.com\nWebsite: www.techcorp.com"]
      }
    ],
    tooltip: "A press release is an official statement delivered to members of the news media to provide information, an official statement, or make an announcement on a specific matter.",
    examples: [
      "A press release announcing a major product launch, including product details and executive quotes",
      "A press release detailing a significant company milestone, such as an acquisition or reaching 1 million customers"
    ]
  },
  companyProfile: {
    fields: [
      { 
        name: 'companyName', 
        label: 'Company Name', 
        type: 'text',
        tooltip: "The official name of your company as it should appear in formal documents.",
        examples: ["TechCorp, Inc.", "Global Innovations Ltd."]
      },
      { 
        name: 'tagline', 
        label: 'Tagline', 
        type: 'text',
        tooltip: "A brief slogan or phrase that captures the essence of your company. It should be memorable and aligned with your brand identity.",
        examples: ["Innovating for a Smarter Tomorrow", "Empowering Businesses Through AI"]
      },
      { 
        name: 'missionStatement', 
        label: 'Mission Statement', 
        type: 'textarea',
        tooltip: "A concise statement of your company's purpose, goals, and values. It should clearly communicate what your company does and why it exists.",
        examples: ["Our mission is to accelerate the world's transition to sustainable energy through innovative solar solutions and energy storage systems."]
      },
      { 
        name: 'companyHistory', 
        label: 'Company History', 
        type: 'textarea',
        tooltip: "Provide a brief overview of your company's history, including key milestones and achievements. This adds credibility and context to your profile.",
        examples: ["Founded in 2010, TechCorp began as a small startup in San Francisco. Within five years, we had expanded to 3 continents and secured Series B funding of $50 million..."]
      },
      { 
        name: 'productsServices', 
        label: 'Products/Services', 
        type: 'textarea',
        tooltip: "List and briefly describe your main products or services. Focus on their unique features and benefits to customers.",
        examples: ["1. AI-Powered Analytics Platform: Turns complex data into actionable insights\n2. Quantum Encryption Service: Provides unbreakable security for sensitive data\n3. Automated Customer Service Solution: 24/7 support powered by advanced natural language processing"]
      },
      { 
        name: 'leadershipTeam', 
        label: 'Leadership Team', 
        type: 'textarea',
        tooltip: "Introduce key members of your leadership team. Include their names, positions, and a brief description of their expertise or background.",
        examples: ["Jane Doe, CEO: 20+ years of experience in tech, former CTO of Fortune 500 company\nJohn Smith, CTO: PhD in Computer Science, led development of award-winning AI platforms"]
      },
      { 
        name: 'companyculture', 
        label: 'Company Culture', 
        type: 'textarea',
        tooltip: "Describe your company's values, work environment, and what makes it unique. This can help attract talent and partners who align with your culture.",
        examples: ["At TechCorp, we foster a culture of innovation, collaboration, and continuous learning. We believe in work-life balance, offer flexible working arrangements, and encourage our team to dedicate 20% of their time to passion projects."]
      },
      { 
        name: 'contactInformation', 
        label: 'Contact Information', 
        type: 'textarea',
        tooltip: "Provide comprehensive contact details for your company. This should include physical address, phone number, email, website, and social media profiles if applicable.",
        examples: ["TechCorp, Inc.\n123 Innovation Drive, San Francisco, CA 94105\nPhone: (555) 123-4567\nEmail: info@techcorp.com\nWebsite: www.techcorp.com\nLinkedIn: /company/techcorp"]
      }
    ],
    tooltip: "A company profile is a professional introduction of your business to potential customers, investors, or partners. It should provide a comprehensive overview of your company, including its history, products/services, values, and unique selling points.",
    examples: [
      "A detailed 2-page company profile for inclusion in investment pitch materials",
      "A concise company profile for your website's 'About Us' page, highlighting key information and company culture"
    ]
  }
};

const CollateralBuilder = ({ open, onClose, collateral, selectedOrg, mode, onSave }) => {
  const [collateralData, setCollateralData] = useState(collateral || { type: '' });
  const [isAIAssistOpen, setIsAIAssistOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [errors, setErrors] = useState({});
  const [isPreviewMode, setIsPreviewMode] = useState(mode === 'edit');
  const [activeSection, setActiveSection] = useState(null);
  const [newReferenceUrl, setNewReferenceUrl] = useState('');
  const [previewMode, setPreviewMode] = useState('document'); // 'document' or 'sections'

  useEffect(() => {
    if (collateral) {
      setCollateralData(collateral);
    }
  }, [collateral]);

  const handleChange = (field, value) => {
    setCollateralData(prev => ({ ...prev, [field]: value }));
    setErrors(prev => ({ ...prev, [field]: null }));
  };

  const validateForm = () => {
    const newErrors = {};
    const config = collateralConfig[collateralData.type];
    if (config) {
      config.fields.forEach(field => {
        if (field.required && !collateralData[field.name]) {
          newErrors[field.name] = 'This field is required';
        }
      });
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateForm()) return;

    setIsSaving(true);
    try {
      let savedCollateral;
      if (mode === 'create') {
        savedCollateral = await createCollateral(collateralData, selectedOrg);
      } else {
        savedCollateral = await updateCollateral(collateralData.id, collateralData, selectedOrg);
      }
      onSave(savedCollateral);
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        onClose();
      }, 2000);
    } catch (error) {
      console.error('Error saving collateral:', error);
      // Add more specific error handling
      setShowSuccessMessage(false);
      // Show error message to user
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    if (JSON.stringify(collateral) !== JSON.stringify(collateralData)) {
      setShowConfirmDialog(true);
    } else {
      onClose();
    }
  };

  const handleComplete = () => {
    setIsPreviewMode(true);
  };

  const renderDocumentPreview = () => {
    const type = collateralTypes.find(t => t.value === collateralData.type);
    
    return (
      <Box mt={2} p={4} bgcolor="background.paper" borderRadius={1}>
        {/* Press Release specific formatting */}
        {collateralData.type === 'pressRelease' && (
          <>
            <Typography variant="body1" sx={{ mb: 4 }}>
              {collateralData.dateline}
            </Typography>
            <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold' }}>
              {collateralData.headline}
            </Typography>
            <Typography variant="body1" paragraph>
              {collateralData.leadParagraph}
            </Typography>
            <Typography variant="body1" paragraph>
              {collateralData.bodyParagraphs}
            </Typography>
            {/* Add other press release specific fields */}
          </>
        )}
        
        {/* Email Campaign specific formatting */}
        {collateralData.type === 'emailCampaign' && (
          <>
            <Typography variant="h5" sx={{ mb: 3 }}>
              {collateralData.subject}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {collateralData.greeting}
            </Typography>
            <Typography variant="body1" paragraph>
              {collateralData.mainContent}
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              {collateralData.callToAction}
            </Typography>
            <Typography variant="body1">
              {collateralData.closingStatement}
            </Typography>
          </>
        )}
        
        {/* Add other collateral type specific formatting */}
      </Box>
    );
  };

  const renderPreview = () => (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5">
          {collateralTypes.find(t => t.value === collateralData.type)?.label}
        </Typography>
        <Button
          variant="outlined"
          onClick={() => setPreviewMode(prev => prev === 'document' ? 'sections' : 'document')}
          startIcon={previewMode === 'document' ? <ViewListIcon /> : <ArticleIcon />}
        >
          {previewMode === 'document' ? 'View Sections' : 'View Document'}
        </Button>
      </Box>

      {previewMode === 'document' ? (
        renderDocumentPreview()
      ) : (
        // Existing sections view
        collateralConfig[collateralData.type]?.fields.map((field, index) => (
          <Box 
            key={field.name} 
            mb={3}
            sx={{
              position: 'relative',
              '&:hover': {
                bgcolor: 'grey.50',
                '& .edit-button': {
                  opacity: 1
                }
              }
            }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1" color="primary">
                <strong>{field.label}</strong>
              </Typography>
              <IconButton
                className="edit-button"
                size="small"
                onClick={() => {
                  setIsPreviewMode(false);
                  setActiveStep(Math.floor(index / 3));
                  setActiveSection(field.name);
                }}
                sx={{
                  opacity: 0,
                  transition: 'opacity 0.2s',
                  '&:hover': {
                    bgcolor: 'primary.light',
                    color: 'white'
                  }
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Box>
            <Typography 
              variant="body1" 
              sx={{ 
                whiteSpace: 'pre-wrap',
                mt: 1,
                p: 2,
                bgcolor: 'grey.100',
                borderRadius: 1
              }}
            >
              {collateralData[field.name] || 'Not provided'}
            </Typography>
          </Box>
        ))
      )}
    </Box>
  );

  const renderFields = () => {
    const config = collateralConfig[collateralData.type];
    if (!config) return null;

    const fieldsPerStep = 3;
    const start = activeStep * fieldsPerStep;
    const end = start + fieldsPerStep;
    const currentFields = config.fields.slice(start, end);

    return currentFields.map((field) => (
      <Grid item xs={12} key={field.name}>
        <Box display="flex" alignItems="flex-start">
          <Tooltip
            title={
              <React.Fragment>
                <Typography color="inherit">{field.tooltip || 'No tooltip available'}</Typography>
                {field.examples && field.examples.length > 0 && (
                  <>
                    <Typography color="inherit" variant="subtitle2" style={{marginTop: '8px'}}>Examples:</Typography>
                    <ul>
                      {field.examples.map((example, index) => (
                        <li key={index}><Typography variant="body2">{example}</Typography></li>
                      ))}
                    </ul>
                  </>
                )}
              </React.Fragment>
            }
            arrow
            placement="top-start"
          >
            {field.type === 'select' ? (
              <FormControl fullWidth margin="normal" error={!!errors[field.name]}>
                <InputLabel>{field.label}</InputLabel>
                <Select
                  value={collateralData[field.name] || ''}
                  onChange={(e) => handleChange(field.name, e.target.value)}
                  label={field.label}
                >
                  {field.options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
                {errors[field.name] && <Typography color="error">{errors[field.name]}</Typography>}
              </FormControl>
            ) : (
              <>
                <TextField
                  fullWidth
                  label={field.label}
                  value={collateralData[field.name] || ''}
                  onChange={(e) => handleChange(field.name, e.target.value)}
                  margin="normal"
                  multiline={field.type === 'textarea'}
                  rows={field.type === 'textarea' ? 4 : 1}
                  type={field.type}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]}
                />
                <Box ml={1} mt={2}>
                  <CollateralAssistButton
                    fieldName={field.name}
                    currentValue={collateralData[field.name] || ''}
                    onSuggestion={(suggestion) => handleChange(field.name, suggestion)}
                    collateralData={collateralData} // Pass the entire form data
                    collateralType={collateralData.type}
                    tooltip={field.tooltip}
                    examples={field.examples}
                    onOpenChange={setIsAIAssistOpen}
                  />
                </Box>
              </>
            )}
          </Tooltip>
        </Box>
      </Grid>
    ));
  };

  const renderTypeTooltip = () => {
    const config = collateralConfig[collateralData.type];
    if (!config) return null;

    return (
      <Box mt={2} p={2} bgcolor="background.paper" border={1} borderColor="grey.300" borderRadius={1}>
        <Typography variant="body1" gutterBottom><strong>About {collateralTypes.find(t => t.value === collateralData.type)?.label}:</strong></Typography>
        <Typography variant="body2">{config.tooltip || 'No description available'}</Typography>
        {config.examples && config.examples.length > 0 && (
          <>
            <Typography variant="body2" style={{ marginTop: '10px' }}><strong>Great examples:</strong></Typography>
            <ul>
              {config.examples.map((example, index) => (
                <li key={index}><Typography variant="body2">{example}</Typography></li>
              ))}
            </ul>
          </>
        )}
      </Box>
    );
  };

  const getSteps = () => {
    const config = collateralConfig[collateralData.type];
    if (!config) return [];
    return Array(Math.ceil(config.fields.length / 3)).fill('');
  };

  const steps = getSteps();

  const handleAddReferenceUrl = () => {
    if (newReferenceUrl) {
      const currentUrls = collateralData.referenceUrls || [];
      handleChange('referenceUrls', [...currentUrls, newReferenceUrl]);
      setNewReferenceUrl('');
    }
  };

  const handleRemoveReferenceUrl = (urlToRemove) => {
    const currentUrls = collateralData.referenceUrls || [];
    handleChange('referenceUrls', currentUrls.filter(url => url !== urlToRemove));
  };

  return (
    <>
      <Dialog 
        fullScreen 
        open={open} 
        onClose={handleClose} 
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'sticky', top: 0 }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {mode === 'create' ? 'Create New Collateral' : 'Edit Collateral'}
            </Typography>
            <Button 
              color="inherit" 
              onClick={() => setIsPreviewMode(!isPreviewMode)}
              startIcon={isPreviewMode ? <EditIcon /> : <PreviewIcon />}
              sx={{ mr: 2 }}
            >
              {isPreviewMode ? 'Edit' : 'Preview'}
            </Button>
            <Button 
              startIcon={<SaveIcon />}
              color="inherit" 
              onClick={handleSave}
              disabled={isSaving}
            >
              {isSaving ? <CircularProgress size={24} color="inherit" /> : 'Save'}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {isPreviewMode ? (
            renderPreview()
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={collateralData.type}
                    onChange={(e) => {
                      handleChange('type', e.target.value);
                      setActiveStep(0);
                    }}
                    label="Type"
                  >
                    {collateralTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {collateralData.type && (
                <>
                  <Grid item xs={12}>
                    <Box mt={2} mb={2}>
                      <Typography variant="subtitle1" gutterBottom>Reference Content</Typography>
                      <Box mb={2}>
                        <TextField
                          fullWidth
                          label="Add Reference URL"
                          placeholder="Enter a URL to use as reference content for AI suggestions"
                          value={newReferenceUrl}
                          onChange={(e) => setNewReferenceUrl(e.target.value)}
                          helperText="Add URLs to relevant content that can help inform AI suggestions"
                          InputProps={{
                            endAdornment: (
                              <Button
                                size="small"
                                onClick={handleAddReferenceUrl}
                                disabled={!newReferenceUrl}
                              >
                                Add
                              </Button>
                            )
                          }}
                        />
                      </Box>
                      {collateralData.referenceUrls?.length > 0 && (
                        <Box display="flex" flexWrap="wrap" gap={1}>
                          {collateralData.referenceUrls.map((url, index) => (
                            <Chip
                              key={index}
                              label={url}
                              onDelete={() => handleRemoveReferenceUrl(url)}
                              onClick={() => window.open(url, '_blank')}
                              color="primary"
                              variant="outlined"
                              sx={{ maxWidth: '300px' }}
                            />
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    {renderTypeTooltip()}
                  </Grid>
                  <Grid item xs={12}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label, index) => (
                        <Step key={index}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                  {renderFields()}
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" mt={2}>
                      <Button
                        onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                        disabled={activeStep === 0}
                        startIcon={<ArrowBack />}
                      >
                        Back
                      </Button>
                      {activeStep === steps.length - 1 ? (
                        <Button
                          onClick={handleComplete}
                          color="primary"
                          variant="contained"
                        >
                          Complete
                        </Button>
                      ) : (
                        <Button
                          onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                          endIcon={<ArrowForward />}
                        >
                          Next
                        </Button>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <LinearProgress 
                      variant="determinate" 
                      value={(activeStep + 1) / steps.length * 100} 
                    />
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </DialogContent>
      </Dialog>
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Unsaved Changes"}</DialogTitle>
        <DialogContent>
          <Typography>
            You have unsaved changes. Are you sure you want to close without saving?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)}>Cancel</Button>
          <Button onClick={onClose} autoFocus>
            Close Without Saving
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar 
        open={showSuccessMessage} 
        autoHideDuration={2000} 
        onClose={() => setShowSuccessMessage(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setShowSuccessMessage(false)} severity="success" sx={{ width: '100%' }}>
          Collateral saved successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default CollateralBuilder;
