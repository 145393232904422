import React, { useState, useEffect, useRef } from 'react';
import { puawaiAIService } from '../../services/ai/puawaiAIService';
import { 
  Box, 
  TextField, 
  Paper, 
  IconButton, 
  CircularProgress, 
  Typography,
  Chip,
  Avatar,
  Button,
} from '@mui/material';
import {
  Send as SendIcon,
} from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useLanguage } from '../../contexts/LanguageContext';
import { getToneOfVoice } from '../../services/brandBuilderService';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling } from '@fortawesome/free-solid-svg-icons';
import remarkGfm from 'remark-gfm';
import { useNavigate } from 'react-router-dom';

const TypingIndicator = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 24 }}>
      {[0, 1, 2].map((dot) => (
        <motion.div
          key={dot}
          style={{
            width: 8,
            height: 8,
            backgroundColor: 'grey',
            borderRadius: '50%',
            margin: '0 2px',
          }}
          animate={{
            y: ['0%', '-50%', '0%'],
          }}
          transition={{
            duration: 0.6,
            repeat: Infinity,
            repeatType: 'loop',
            delay: dot * 0.2,
          }}
        />
      ))}
    </Box>
  );
};

const ChatInterface = ({ userProfile, organisation, toneOfVoice, isOpen, onClose, theme, bids, canvases, portfolio }) => {
  const navigate = useNavigate();
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTone, setSelectedTone] = useState('default');
  const [suggestedPrompts, setSuggestedPrompts] = useState([]);

  const { language, locale } = useLanguage();
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const welcomeMessage = {
      text: getLocalizedWelcomeMessage(userProfile.givenName, organisation.name, language),
      sender: 'bot'
    };
    setMessages([welcomeMessage]);
    generateSuggestedPrompts();
  }, [userProfile.givenName, organisation.name, language]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const generateSuggestedPrompts = () => {
    const prompts = {
      en: {
        'en-NZ': [
          "Show growth readiness",
          "Highlight urgent bids and tasks",
          "Brand tone of voice status",
          "Portfolio overview",
          "Suggest next steps for improvement"
        ],
        'en-US': [
          "Show growth readiness",
          "Highlight urgent bids and tasks",
          "Brand tone of voice status",
          "Portfolio overview",
          "Suggest next steps for improvement"
        ]
      },
      mi: [
        "Whakaaturia te rite mo te tipu",
        "Tīpakohia ngā tono me ngā mahi whāwhai",
        "Te āhua o te reo ā-waitohu",
        "Tirohanga whānui o te kōpaki",
        "Tūtohua ngā mahi e whai ake hei whakapai ake"
      ]
    };

    setSuggestedPrompts(prompts[language]?.[locale] || prompts[language] || prompts.en['en-NZ']);
  };

  const handleSend = async (messageToSend = input) => {
    if (messageToSend.trim() === '') return;

    const userMessage = { text: messageToSend, sender: 'user' };
    setMessages(prevMessages => [...prevMessages, userMessage]);
    setInput('');
    setIsTyping(true);
    setIsLoading(true);

    const userInfo = {
      userName: userProfile.givenName || 'User',
      orgName: organisation.name || 'Unknown Organisation',
      organisationData: organisation,
      language,
      locale
    };

    const contextData = {
      toneOfVoice: selectedTone === 'custom' ? await getToneOfVoice(organisation.id) : selectedTone,
      bids: bids,
      canvases: canvases.map(canvas => ({
        id: canvas.id,
        type: canvas.type,
        name: canvas.name,
        status: canvas.status,
        importance: canvas.importance,
        priority: canvas.priority,
        optional: canvas.optional,
        exists: canvas.exists
      })),
      portfolio: portfolio
    };

    try {
      const response = await puawaiAIService.sendMessage(messageToSend, userInfo, contextData);
      if (response && response.response) {
        setMessages(prevMessages => [...prevMessages, { text: response.response, sender: 'bot' }]);
      } else {
        throw new Error('Invalid response from AI service');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prevMessages => [...prevMessages, { 
        text: getLocalizedErrorMessage(language),
        sender: 'bot' 
      }]);
    } finally {
      setIsTyping(false);
      setIsLoading(false);
    }
  };

  const handleCanvasLink = (canvasId) => {
    // Close the chat interface
    onClose();
    // Navigate to the canvas page
    navigate(`/organisation/${organisation.id}/canvas/${canvasId}`);
  };

  const renderMessage = (msg) => (
    <ReactMarkdown
      components={{
        h1: ({node, ...props}) => <Typography variant="h6" gutterBottom color="primary" {...props} />,
        h2: ({node, ...props}) => <Typography variant="subtitle1" gutterBottom mt={1} color="secondary.main" {...props} />,
        h3: ({node, ...props}) => <Typography variant="subtitle2" gutterBottom mt={1} color="secondary.main" {...props} />,
        p: ({node, ...props}) => <Typography variant="body2" paragraph {...props} />,
        strong: ({node, ...props}) => <Box component="span" fontWeight="bold" color="primary.main" {...props} />,
        em: ({node, ...props}) => <Box component="span" fontStyle="italic" color="text.secondary" {...props} />,
        ul: ({node, ...props}) => <Box component="ul" pl={2} {...props} />,
        li: ({node, ...props}) => <Box component="li" mb={0.5} {...props} />,
        a: ({node, href, ...props}) => {
          if (href && href.startsWith('canvas:')) {
            const canvasId = href.split(':')[1];
            return (
              <Button
                variant="text"
                color="primary"
                size="small"
                onClick={() => handleCanvasLink(canvasId)}
                {...props}
              />
            );
          }
          return <a href={href} target="_blank" rel="noopener noreferrer" {...props} />;
        },
      }}
      remarkPlugins={[remarkGfm]}
    >
      {msg.text}
    </ReactMarkdown>
  );

  const handlePromptClick = (prompt) => {
    setInput(prompt);
    handleSend(prompt);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', bgcolor: theme.palette.background.default }}>
      {/* Header */}
      <Box sx={{ 
        p: 2, 
        borderBottom: `1px solid ${theme.palette.divider}`,
        bgcolor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      }}>
        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FontAwesomeIcon icon={faSeedling} />
          Puāwai Intelligence
        </Typography>
      </Box>

      {/* Chat Area */}
      <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2 }}>
        <AnimatePresence>
          {messages.map((msg, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.3 }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: msg.sender === 'bot' ? 'flex-start' : 'flex-end',
                  mb: 2,
                }}
              >
                {msg.sender === 'bot' && (
                  <Avatar sx={{ bgcolor: theme.palette.primary.main, mr: 1 }}>
                    <FontAwesomeIcon icon={faSeedling} />
                  </Avatar>
                )}
                <Paper
                  elevation={1}
                  sx={{
                    padding: 1.5,
                    backgroundColor: msg.sender === 'bot' ? theme.palette.grey[100] : theme.palette.primary.light,
                    color: msg.sender === 'bot' ? theme.palette.text.primary : theme.palette.primary.contrastText,
                    maxWidth: '80%',
                    borderRadius: msg.sender === 'bot' ? '15px 15px 15px 0' : '15px 15px 0 15px',
                  }}
                >
                  {renderMessage(msg)}
                </Paper>
                {msg.sender === 'user' && (
                  <Avatar sx={{ bgcolor: theme.palette.secondary.main, ml: 1 }}>
                    {userProfile.givenName[0]}
                  </Avatar>
                )}
              </Box>
            </motion.div>
          ))}
        </AnimatePresence>
        {isTyping && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
            <Paper elevation={1} sx={{ p: 1.5, backgroundColor: 'grey.100', borderRadius: '15px 15px 15px 0' }}>
              <TypingIndicator />
            </Paper>
          </Box>
        )}
        <div ref={messagesEndRef} />
      </Box>

      {/* Suggested Prompts */}
      <Box sx={{ p: 1, borderTop: 1, borderColor: theme.palette.divider }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {suggestedPrompts.map((prompt, index) => (
            <Chip
              key={index}
              label={prompt}
              onClick={() => handlePromptClick(prompt)}
              size="small"
              variant="outlined"
            />
          ))}
        </Box>
      </Box>

      {/* Input Area */}
      <Box sx={{ p: 2, borderTop: 1, borderColor: theme.palette.divider }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            variant="outlined"
            fullWidth
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Ask Puāwai Intelligence anything..."
            onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && handleSend()}
          />
          <IconButton color="primary" onClick={() => handleSend()} disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatInterface;

// Helper functions
const getLocalizedWelcomeMessage = (name, orgName, language) => {
  const messages = {
    en: `Kia ora ${name}! I'm your Puāwai Intelligence assistant for ${orgName}. How can I help you today?`,
    mi: `Kia ora ${name}! Ko ahau tō kaiāwhina Puāwai Intelligence mō ${orgName}. Me pēhea taku āwhina i a koe i tēnei rā?`,
  };
  return messages[language] || messages.en;
};

const getLocalizedErrorMessage = (language) => {
  const messages = {
    en: "Sorry, I'm having trouble responding right now. Please try again later.",
    mi: "Aroha mai, kei te raruraru ahau ki te whakautu i tēnei wā. Tēnā koa, whakamātau anō ā muri ake nei.",
  };
  return messages[language] || messages.en;
};
