// userService.js
//authservice is for front end api service for authorisation and authentication functions which works with msal and azure bsc and azure functions.
//userservice is for user related functions such as getting user details, updating user details, deleting user details, etc.
//organisation service is for organisation related functions such as getting organisation details, updating organisation details, deleting organisation details, etc.
//bid service is for bid related functions such as getting bid details, updating bid details, deleting bid details, etc.
//bidcanvas service is for bidcanvas related functions such as getting bidcanvas details, updating bidcanvas details, deleting bidcanvas details, etc.
//bidteam service is for bidteam related functions such as getting bidteam details, updating bidteam details, deleting bidteam details, etc.
//invitation service is for invitation related functions such as getting invitation details, updating invitation details, deleting invitation details, etc.
//task service is for task related functions such as getting task details, updating task details, deleting task details, etc.
//team service is for team related functions such as getting team details, updating team details, deleting team details, etc.

import axios from 'axios';
import { getToken, getAuthHeader } from './authService';
import { listOrganisations, getOrganisation } from './organisationService';
import { createOrganisation } from './organisationService';
import { getSubscriptionStatus } from './stripeService';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

/**
 * Centralized function to handle API requests.
 * @param {string} method - HTTP method (GET, POST, PUT, DELETE).
 * @param {string} endpoint - API endpoint path.
 * @param {object} [data=null] - Data to send with the request (for POST, PUT).
 * @returns {Promise<object>} - The API response data.
 * @throws {Error} - Throws an error if the API call fails.
 */
const apiCall = async (method, endpoint, data = null) => {
  const token = await getToken();
  if (!token) {
    throw new Error('Authorization token is missing');
  }

  try {
    const response = await axios({
      method,
      url: `${API_BASE_URL}${endpoint}`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Retrieves organisations associated with a user.
 * @param {string} userId - The unique identifier for the user.
 * @returns {Promise<Array>} - Array of organisations.
 */
export const getOrganisationsForUser = async (userId) => {
  if (!userId) {
    throw new Error('getOrganisationsForUser called with undefined userId');
  }
  try {
    const userData = await getUser(userId);

    if (userData && Array.isArray(userData.organisations)) {
      return [...new Set(userData.organisations)];
    }

    const response = await apiCall('get', `/api/users/${userId}/organisations`);
    
    if (Array.isArray(response)) {
      return [...new Set(response)];
    } else if (response && typeof response === 'object') {
      const organisations = response.organisations || [];
      return [...new Set(organisations)];
    } else {
      throw new Error('Unexpected response format from getOrganisationsForUser:');
    }
  } catch (error) {
    throw error;
  }
};

/**
 * Creates a new user or retrieves an existing one.
 * @param {string} userId - The unique identifier for the user.
 * @param {string} username - The user's username.
 * @param {string} email - The user's email address.
 * @param {string} name - The user's name.
 * @returns {Promise<object>} - The user document.
 */
export const createOrGetUser = async (userId, email, name) => {
  try {
    let user = await getUser(userId);
    if (!user) {
      const userData = { id: userId, email, name };
      user = await apiCall('post', '/api/users', userData);
    }
    return user;
  } catch (error) {
    throw error;
  }
};

/**
 * Retrieves a user by ID.
 * @param {string} userId - The unique identifier for the user.
 * @returns {Promise<object>} - The user data.
 */
export const getUser = async (userId) => {
  return apiCall('get', `/api/users/${userId}`);
};

/**
 * Creates a new user and associated organization.
 * @param {string} userId - The unique identifier for the user.
 * @param {string} username - The user's username.
 * @param {string} email - The user's email address.
 * @param {string} organizationName - The name of the organization to create.
 * @returns {Promise<object>} - The created user document and organization.
 */
export const createNewUserWithOrganization = async (userId, username, email, organizationName) => {
  try {
    const user = await createOrGetUser(userId, username, email);

    let organization = await getOrganisation(userId);
    
    if (!organization) {
      const organizationData = {
        name: organizationName,
        ownerId: userId,
        members: [userId]
      };
      organization = await createOrganisation(organizationData);
    }

    if (!user.organisations || !user.organisations.includes(organization.id)) {
      await addUserToOrganisation(userId, organization.id);
      return { user: await getUser(userId), organization };
    }

    return { user, organization };
  } catch (error) {
    throw error;
  }
};

/**
 * Retrieves user activity.
 * @param {string} userId - The unique identifier for the user.
 * @returns {Promise<Array>} - Array of user activities.
 */
export const getUserActivity = async (userId) => {
  return apiCall('get', `/api/users/${userId}/activity`);
};

/**
 * Adds a user to an organisation.
 * @param {string} userId - The unique identifier for the user.
 * @param {string} organisationId - The unique identifier for the organisation.
 * @returns {Promise<object>} - The updated organisation data.
 */
export const addUserToOrganisation = async (userId, organisationId) => {
  return apiCall('post', `/api/organisations/${organisationId}/addUser`, { userId });
};

/**
 * Removes a user from an organisation.
 * @param {string} userId - The unique identifier for the user.
 * @param {string} organisationId - The unique identifier for the organisation.
 * @returns {Promise<object>} - The updated organisation data.
 */
export const removeUserFromOrganisation = async (userId, organisationId) => {
  return apiCall('post', `/api/organisations/${organisationId}/removeUser`, { userId });
};

/**
 * Invites a user to join an organisation via email.
 * @param {string} organisationId - ID of the organisation
 * @param {string} email - Email address of the user to invite
 * @returns {Promise<object>} - Invite response from the server
 */
export const inviteUserToOrganisation = async (organisationId, email) => {
  return apiCall('post', `/api/organisations/${organisationId}/invite`, { email });
};

/**
 * Retrieves user with subscription status.
 * @param {string} userId - The unique identifier for the user.
 * @returns {Promise<object>} - The user data with subscription status.
 */
export const getUserWithSubscriptionStatus = async (userId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/subscriptions?userId=${userId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add this function to the existing userService.js file
export const updateUser = async (userId, updatedProfile) => {
  try {
    const response = await apiCall('put', `/api/users/${userId}`, updatedProfile);
    return response;
  } catch (error) {
    throw error;
  }
};

// Update the default export
export default {
  getOrganisationsForUser,
  createOrGetUser,
  getUserActivity,
  addUserToOrganisation,
  removeUserFromOrganisation,
  inviteUserToOrganisation,
  getUser,
  createNewUserWithOrganization,
  getUserWithSubscriptionStatus,
  updateUser,
};
