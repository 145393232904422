import React, { useState, useEffect } from 'react';
import { Typography, Box, CircularProgress, Button, Avatar, Paper, Grid, Divider, TextField, Skeleton, Fade, useTheme } from '@mui/material';
import { getActiveAccount } from '../../services/authService';
import { getUser, getOrganisationsForUser, updateUser } from '../../services/userService';
import { getOrganisation } from '../../services/organisationService';
import { Link as RouterLink } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import WorkIcon from '@mui/icons-material/Work';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { motion } from 'framer-motion';

function Profile({ userData }) {
  const theme = useTheme();
  const [userProfile, setUserProfile] = useState(null);
  const [organisation, setOrganisation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedProfile, setEditedProfile] = useState(null);

  useEffect(() => {
    if (userData) {
      setUserProfile(userData);
      setEditedProfile(userData);
      fetchUserProfile();
    } else {
      setError('User data is not available. Please try signing in again.');
      setLoading(false);
    }
  }, [userData]);

  const fetchUserProfile = async () => {
    try {
      setLoading(true);
      if (userData && userData.localAccountId) {
        const profile = await getUser(userData.localAccountId);
        setUserProfile(profile);
        setEditedProfile(profile);

        // Fetch user's organisations
        const userOrganisations = await getOrganisationsForUser(userData.localAccountId);

        if (userOrganisations && userOrganisations.length > 0) {
          const org = await getOrganisation(userOrganisations[0]);
          setOrganisation(org);
        } else {
          setOrganisation(null);
        }
      } else {
        throw new Error('User data or ID is missing');
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setError(`An error occurred while fetching the user profile: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      const updatedUser = await updateUser(userProfile.id, editedProfile);
      setUserProfile(updatedUser);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating user profile:', error);
      setError(`An error occurred while updating the user profile: ${error.message}`);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditedProfile(prev => ({ ...prev, [name]: value }));
  };

  const handleSignIn = async () => {
    try {
      await signIn();
      fetchUserProfile();
    } catch (error) {
      console.error('Error signing in:', error);
      setError('Failed to sign in. Please try again.');
    }
  };

  const renderLoadingSkeleton = () => (
    <Box maxWidth="800px" margin="auto" mt={4} px={2}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} display="flex" flexDirection="column" alignItems="center">
            <Skeleton variant="circular" width={120} height={120} sx={{ mb: 2 }} />
            <Skeleton variant="text" width="80%" height={30} sx={{ mb: 1 }} />
            <Skeleton variant="text" width="60%" height={20} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Skeleton variant="text" width="40%" height={40} />
              <Skeleton variant="rectangular" width={100} height={36} />
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={2}>
              {[1, 2, 3, 4, 5, 6].map((_, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Skeleton variant="circular" width={24} height={24} sx={{ mr: 1 }} />
                      <Box flexGrow={1}>
                        <Skeleton variant="text" width="30%" height={20} sx={{ mb: 1 }} />
                        <Skeleton variant="text" width="100%" height={24} />
                      </Box>
                    </Box>
                  </Grid>
                </motion.div>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );

  if (loading) {
    return (
      <Fade in={true} style={{ transitionDelay: '300ms' }}>
        <div>{renderLoadingSkeleton()}</div>
      </Fade>
    );
  }

  if (error) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <Typography color="error" gutterBottom>{error}</Typography>
        <Button onClick={handleSignIn} variant="contained" color="primary">
          Sign In
        </Button>
      </Box>
    );
  }

  if (!userProfile) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <Typography gutterBottom>No user information available. Please sign in.</Typography>
        <Button onClick={handleSignIn} variant="contained" color="primary">
          Sign In
        </Button>
      </Box>
    );
  }

  return (
    <Box maxWidth="900px" margin="auto" mt={6} px={2}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2, backgroundColor: theme.palette.background.paper }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4} display="flex" flexDirection="column" alignItems="center">
            <Avatar
              sx={{ 
                width: 160, 
                height: 160, 
                mb: 3, 
                boxShadow: theme.shadows[3],
                border: `4px solid ${theme.palette.primary.main}`
              }}
              src={userProfile.profilePhoto}
              alt={userProfile.name}
            >
              {userProfile.givenName ? userProfile.givenName[0].toUpperCase() : 'U'}
            </Avatar>
            <Typography variant="h4" gutterBottom align="center" sx={{ wordBreak: 'break-word', fontWeight: 'bold', color: theme.palette.text.primary }}>
              {`${userProfile.givenName || ''} ${userProfile.surname || ''}`}
            </Typography>
            <Typography variant="h6" color="textSecondary" gutterBottom align="center" sx={{ wordBreak: 'break-word' }}>
              {userProfile.jobTitle || 'N/A'}
            </Typography>
            {organisation && (
              <Typography variant="body1" color="primary" align="center" sx={{ mt: 1, fontWeight: 'medium' }}>
                {organisation.name}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>My Profile</Typography>
              {isEditing ? (
                <Button 
                  startIcon={<SaveIcon />} 
                  onClick={handleSave} 
                  variant="contained" 
                  color="primary"
                  sx={{ borderRadius: 2, boxShadow: theme.shadows[2] }}
                >
                  Save Changes
                </Button>
              ) : (
                <Button 
                  startIcon={<EditIcon />} 
                  onClick={handleEdit} 
                  variant="outlined" 
                  color="primary"
                  sx={{ borderRadius: 2 }}
                >
                  Edit Profile
                </Button>
              )}
            </Box>
            <Divider sx={{ mb: 3, backgroundColor: theme.palette.divider }} />
            <Grid container spacing={3}>
              <ProfileItem icon={<EmailIcon />} label="Email" value={userProfile.email} isEditing={false} />
              <ProfileItem 
                icon={<PersonIcon />} 
                label="Given Name" 
                value={editedProfile.givenName} 
                isEditing={isEditing}
                name="givenName"
                onChange={handleChange}
              />
              <ProfileItem 
                icon={<PersonIcon />} 
                label="Surname" 
                value={editedProfile.surname} 
                isEditing={isEditing}
                name="surname"
                onChange={handleChange}
              />
              <ProfileItem 
                icon={<WorkIcon />} 
                label="Job Title" 
                value={editedProfile.jobTitle} 
                isEditing={isEditing}
                name="jobTitle"
                onChange={handleChange}
              />
              <ProfileItem 
                icon={<LocationOnIcon />} 
                label="City" 
                value={editedProfile.city} 
                isEditing={isEditing}
                name="city"
                onChange={handleChange}
              />
              <ProfileItem 
                icon={<LocationOnIcon />} 
                label="Country" 
                value={editedProfile.country} 
                isEditing={isEditing}
                name="country"
                onChange={handleChange}
              />
              {organisation && (
                <ProfileItem 
                  icon={<WorkIcon />} 
                  label="Industry" 
                  value={organisation.industry || 'Not specified'}
                  isEditing={false}
                />
              )}
              <Grid item xs={12}>
                <Box mt={3}>
                  <Button
                    component={RouterLink}
                    to="/my-organisation"
                    variant="contained"
                    color="secondary"
                    startIcon={<BusinessIcon />}
                    fullWidth
                    sx={{ 
                      borderRadius: 2, 
                      py: 1.5, 
                      boxShadow: theme.shadows[2],
                      '&:hover': {
                        backgroundColor: theme.palette.secondary.dark,
                      }
                    }}
                  >
                    View My Organisation
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

function ProfileItem({ icon, label, value, isEditing, name, onChange }) {
  const theme = useTheme();
  return (
    <Grid item xs={12} sm={6}>
      <Box 
        display="flex" 
        alignItems="flex-start" 
        mb={1} 
        p={2} 
        sx={{ 
          backgroundColor: theme.palette.background.default, 
          borderRadius: 2,
          transition: 'box-shadow 0.3s',
          '&:hover': {
            boxShadow: theme.shadows[1],
          },
        }}
      >
        <Box mr={2} color="primary.main">{icon}</Box>
        <Box flexGrow={1}>
          <Typography variant="body2" color="textSecondary" gutterBottom>{label}</Typography>
          {isEditing ? (
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name={name}
              value={value || ''}
              onChange={onChange}
              sx={{ 
                mt: 1,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.primary.light,
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                },
              }}
            />
          ) : (
            <Typography variant="body1" sx={{ wordBreak: 'break-word', color: theme.palette.text.primary }}>
              {value || 'N/A'}
            </Typography>
          )}
        </Box>
      </Box>
    </Grid>
  );
}

export default Profile;
