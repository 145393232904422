import { useState, useEffect } from 'react';

const BASE_PRICE = 49.95;
const BASE_CURRENCY = 'NZD';

export function useCurrency() {
  const [localPrice, setLocalPrice] = useState(BASE_PRICE);
  const [localCurrency, setLocalCurrency] = useState(BASE_CURRENCY);

  useEffect(() => {
    async function fetchCurrencyData() {
      try {
        // Get user's country
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        const userCurrency = data.currency;

        if (userCurrency !== BASE_CURRENCY) {
          // Fetch exchange rate
          const exchangeResponse = await fetch(`https://api.exchangerate-api.com/v4/latest/${BASE_CURRENCY}`);
          const exchangeData = await exchangeResponse.json();
          const rate = exchangeData.rates[userCurrency];

          if (rate) {
            setLocalPrice((BASE_PRICE * rate).toFixed(2));
            setLocalCurrency(userCurrency);
          }
        }
      } catch (error) {
        console.error('Error fetching currency data:', error);
      }
    }

    fetchCurrencyData();
  }, []);

  const formatPrice = (price, currency) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(price);
  };

  return { localPrice, localCurrency, formatPrice };
}