import axios from 'axios';
import { getToken } from './authService';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

/**
 * Centralized function to handle API requests.
 * @param {string} method - HTTP method (GET, POST, PUT, DELETE).
 * @param {string} endpoint - API endpoint path.
 * @param {object} [data=null] - Data to send with the request (for POST, PUT).
 * @returns {Promise<object>} - The API response data.
 * @throws {Error} - Throws an error if the API call fails.
 */
const apiCall = async (method, endpoint, data = null) => {
    const token = await getToken();
    if (!token) {
        throw new Error('Authorization token is missing');
    }

    try {
        const response = await axios({
            method,
            url: `${API_BASE_URL}${endpoint}`,
            data,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error(`API ${method} request to ${endpoint} failed:`, error.response || error.message || error);
        throw error;
    }
};

/**
 * List bid budgets for a specific bid.
 * @param {string} bidId - The bid ID.
 * @returns {Promise<object[]>} - The list of bid budgets.
 */
export const listBidBudgets = async (bidId) => {
    return apiCall('get', `/api/bids/${bidId}/bidbudgets`);
};

/**
 * Get a specific bid budget by ID under a bid.
 * @param {string} bidId - The bid ID.
 * @param {string} bidBudgetId - The bid budget ID.
 * @returns {Promise<object>} - The bid budget data.
 */
export const getBidBudget = async (bidId, bidBudgetId) => {
    return apiCall('get', `/api/bids/${bidId}/bidbudgets/${bidBudgetId}`);
};

/**
 * Create a new bid budget under a specific bid.
 * @param {string} bidId - The bid ID.
 * @param {object} bidBudgetData - The bid budget data to create.
 * @returns {Promise<object>} - The created bid budget data.
 */
const createBidBudget = async (bidId, bidBudgetData) => {
    return apiCall('post', `/api/bids/${bidId}/bidbudgets`, {
        ...bidBudgetData,
        isInternal: bidBudgetData.isInternal || false
    });
};

/**
 * Update a bid budget by ID under a specific bid.
 * @param {string} bidId - The bid ID.
 * @param {string} bidBudgetId - The bid budget ID.
 * @param {object} bidBudgetData - The updated bid budget data.
 * @returns {Promise<object>} - The updated bid budget data.
 */
const updateBidBudget = async (bidId, bidBudgetId, bidBudgetData) => {
    //console.log('Updating bid budget:', { bidId, bidBudgetId, bidBudgetData });
    try {
        const response = await apiCall('put', `/api/bids/${bidId}/bidbudgets/${bidBudgetId}`, {
            ...bidBudgetData,
            isInternal: bidBudgetData.isInternal || false
        });
        //console.log('Update response:', response);
        return response;
    } catch (error) {
        console.error('Error in updateBidBudget:', error);
        if (error.response) {
            console.error('Error response:', error.response.data);
        }
        throw error;
    }
};

/**
 * Delete a bid budget by ID under a specific bid.
 * @param {string} bidId - The bid ID.
 * @param {string} bidBudgetId - The bid budget ID.
 * @returns {Promise<void>}
 */
const deleteBidBudget = async (bidId, bidBudgetId) => {
    return apiCall('delete', `/api/bids/${bidId}/bidbudgets/${bidBudgetId}`);
};

const addTimeEntry = async (bidId, timeEntryData) => {
    return apiCall('post', `/api/bids/${bidId}/bidbudgets?type=timeEntry`, timeEntryData);
};

const getTimeEntries = async (bidId) => {
    return apiCall('get', `/api/bids/${bidId}/bidbudgets?type=timeEntries`);
};

const getBudgetReport = async (bidId) => {
    return apiCall('get', `/api/bids/${bidId}/bidbudgets?type=report`);
};

export default {
    listBidBudgets,
    getBidBudget,
    createBidBudget,
    updateBidBudget,
    deleteBidBudget,
    addTimeEntry,
    getTimeEntries,
    getBudgetReport
};