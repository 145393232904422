//api service is for api related functions such as getting api details, updating api details, deleting api details, etc.
//authservice is for authorisation and authentication functions which works with msal and azure bsc and azure functions.
//userservice is for user related functions such as getting user details, updating user details, deleting user details, etc.
//organisation service is for organisation related functions such as getting organisation details, updating organisation details, deleting organisation details, etc.
//bid service is for bid related functions such as getting bid details, updating bid details, deleting bid details, etc.
//bidcanvas service is for bidcanvas related functions such as getting bidcanvas details, updating bidcanvas details, deleting bidcanvas details, etc.
//bidteam service is for bidteam related functions such as getting bidteam details, updating bidteam details, deleting bidteam details, etc.
//invitation service is for invitation related functions such as getting invitation details, updating invitation details, deleting invitation details, etc.
//task service is for task related functions such as getting task details, updating task details, deleting task details, etc.
//team service is for team related functions such as getting team details, updating team details, deleting team details, etc.

import axios from 'axios';
import { getToken } from './authService';
import api from './api';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

/**
 * Centralized function to handle API requests.
 * Returns the response data directly.
 */
const apiCall = async (method, endpoint, data = null) => {
  const token = await getToken();
  if (!token) {
    throw new Error('Authorization token is missing');
  }

  const config = {
    method,
    url: `${API_BASE_URL}/api/${endpoint}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data,
  };

  try {
    ////console.log(`[API Request] ${method.toUpperCase()} ${config.url}`);
    if (data) {
      ////console.log(`[API Request Data]:`, JSON.stringify(data, null, 2));
    }

    const response = await axios(config);
    ////console.log(`[API Response] ${method.toUpperCase()} ${config.url}`, response.data);
    return response.data; // Directly return response data
  } catch (error) {
    console.error(
      `[API Error] ${method.toUpperCase()} ${config.url}`,
      error.response?.data || error.message
    );
    throw new Error(`API error: ${error.response?.data?.message || error.message}`);
  }
};

/**
 * Get a specific Bid Canvas by ID under a specific Bid.
 *
 * @param {string} bidId - The ID of the bid.
 * @param {string} canvasId - The ID of the canvas.
 * @returns {Object} - The fetched canvas object.
 */
export const getBidCanvas = async (bidId, canvasId) => {
  if (!bidId || !canvasId) {
    throw new Error('bidId and canvasId are required');
  }

  try {
    const canvasData = await apiCall('get', `bids/${bidId}/bidcanvases/${canvasId}`);
    if (Array.isArray(canvasData)) {
      console.warn(`Multiple canvases received for bidId: ${bidId}, canvasId: ${canvasId}. Returning the first one.`);
      return canvasData[0];
    }
    return canvasData;
  } catch (error) {
    console.error('Error fetching bid canvas:', error);
    throw error;
  }
};



/**
 * Create a new Bid Canvas under a specific Bid.
 *
 * @param {string} bidId - The ID of the bid.
 * @param {Object} canvasData - The data for the new canvas.
 * @returns {Object} - The created canvas object.
 */
export const createBidCanvas = async (bidId, canvasData) => {
  if (!bidId || !canvasData) {
    throw new Error('bidId and canvasData are required');
  }

  try {
    const createdCanvas = await apiCall('post', `bids/${bidId}/bidcanvases`, canvasData);
    return createdCanvas; // Expected to be an object
  } catch (error) {
    console.error('Error creating bid canvas:', error);
    throw error;
  }
};

/**
 * Update an existing Bid Canvas by ID under a specific Bid.
 *
 * @param {string} bidId - The ID of the bid.
 * @param {string} canvasId - The ID of the canvas.
 * @param {Object} canvasData - The updated data for the canvas.
 * @returns {Object} - The updated canvas object.
 */
export const updateBidCanvas = async (bidId, canvasId, canvasData) => {
  ////console.log(`[Update Bid Canvas] bidId: ${bidId}, canvasId: ${canvasId}`);
  ////console.log('Canvas data to update:', canvasData);

  if (!bidId || !canvasId || !canvasData) {
    throw new Error('bidId, canvasId, and canvasData are required for updating a canvas');
  }

  // Remove system properties from canvasData
  const { _rid, _self, _etag, _attachments, _ts, ...sanitizedCanvasData } = canvasData;

  // Ensure all properties are of the correct type
  for (const [key, value] of Object.entries(sanitizedCanvasData)) {
    if (typeof value === 'object' && value !== null) {
      sanitizedCanvasData[key] = JSON.stringify(value);
    }
  }

  ////console.log('Sanitized canvas data:', sanitizedCanvasData);

  try {
    const updatedCanvas = await apiCall(
      'put',
      `bids/${bidId}/bidcanvases/${canvasId}`,
      sanitizedCanvasData
    );
    ////console.log('Updated canvas:', updatedCanvas);
    return Array.isArray(updatedCanvas) ? updatedCanvas[0] : updatedCanvas;
  } catch (error) {
    console.error('Error updating bid canvas:', error);
    throw error;
  }
};

/**
 * Delete a Bid Canvas by ID under a specific Bid.
 *
 * @param {string} bidId - The ID of the bid.
 * @param {string} canvasId - The ID of the canvas.
 */
export const deleteBidCanvas = async (bidId, canvasId) => {
  if (!bidId || !canvasId) {
    throw new Error('bidId and canvasId are required');
  }

  ////console.log(`Deleting Bid Canvas: bidId=${bidId}, canvasId=${canvasId}`);

  try {
    await apiCall('delete', `bids/${bidId}/bidcanvases/${canvasId}`);
    ////console.log(`Bid Canvas with ID ${canvasId} deleted successfully`);
    return { status: 'success' };
  } catch (error) {
    console.error('Error deleting bid canvas:', error);
    if (error.response && error.response.status === 404) {
      console.warn(`Bid Canvas with ID ${canvasId} not found. It may have already been deleted.`);
      return { status: 'not_found' };
    }
    throw error;
  }
};

/**
 * List all Bid Canvases under a specific Bid.
 *
 * @param {string} bidId - The ID of the bid.
 * @param {string} authToken - The authentication token.
 * @returns {Array} - An array of canvas objects.
 */
export const listBidCanvases = async (bidId, authToken) => {
  if (typeof bidId !== 'string') {
    throw new Error('Invalid bidId: must be a string');
  }
  try {
    const response = await api.get(`/api/bids/${bidId}/bidcanvases`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error listing bid canvases:', error);
    throw error;
  }
};

/**
 * Mark a Bid Canvas as complete under a specific Bid.
 *
 * @param {string} bidId - The ID of the bid.
 * @param {string} canvasId - The ID of the canvas.
 * @returns {Object} - The updated canvas object with status 'completed'.
 */
export const completeBidCanvas = async (bidId, canvasId) => {
  ////console.log(`[Complete Bid Canvas] bidId: ${bidId}, canvasId: ${canvasId}`);

  if (!bidId || !canvasId) {
    throw new Error('bidId and canvasId are required for completing a canvas');
  }

  try {
    const completedCanvas = await apiCall(
      'put',
      `bids/${bidId}/bidcanvases/${canvasId}/complete`,
      { action: 'complete' }
    );
    return completedCanvas; // Expected to be an object
  } catch (error) {
    console.error('Error completing bid canvas:', error);
    throw error;
  }
};

/**
 * Save a draft of a Bid Canvas under a specific Bid.
 *
 * @param {string} bidId - The ID of the bid.
 * @param {string} canvasId - The ID of the canvas.
 * @param {Object} canvasData - The data to save as draft.
 * @returns {Object} - The saved draft canvas object.
 */
export const saveDraftBidCanvas = async (bidId, canvasId, canvasData) => {
  ////console.log(`[Save Draft Bid Canvas] bidId: ${bidId}, canvasId: ${canvasId}`);

  if (!bidId || !canvasId || !canvasData) {
    throw new Error('bidId, canvasId, and canvasData are required for saving a canvas draft');
  }

  // Validate required fields
  const requiredFields = [
    'id',
    'bidId',
    'createdBy',
    'createdAt',
    'updatedAt',
    'name',
    'type',
    'status',
  ];
  const missingFields = requiredFields.filter((field) => !canvasData[field]);

  if (missingFields.length > 0) {
    console.error('Missing required data for saving draft:', missingFields);
    throw new Error(`Missing required data for saving draft: ${missingFields.join(', ')}`);
  }

  // Remove system properties from canvasData
  const { _rid, _self, _etag, _attachments, _ts, ...sanitizedCanvasData } = canvasData;

  try {
    const savedDraft = await apiCall(
      'put',
      `bids/${bidId}/bidcanvases/${canvasId}/draft`,
      sanitizedCanvasData
    );
    return savedDraft; // Expected to be an object
  } catch (error) {
    console.error('Error saving draft bid canvas:', error);
    throw error;
  }
};
