import React, { useState, useEffect, useMemo } from 'react';
import {
  Typography, Box, CircularProgress, Paper, Grid, Divider, Button,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Snackbar, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, TextField, MenuItem, Chip, IconButton, Skeleton, Fade,
  Select, FormControl, InputLabel, ListItemIcon, ListItemText
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
import GroupIcon from '@mui/icons-material/Group';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import {
  DraftsOutlined, SearchOutlined, CheckCircleOutline, CancelOutlined,
  PlayArrowOutlined, SendOutlined, AssignmentTurnedInOutlined,
  EmojiEventsOutlined, SentimentVeryDissatisfiedOutlined
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import { getOrganisation, updateOrganisation, addUserToOrganisation, createOrganisation, acceptInvitation } from '../../services/organisationService';
import { listBids, createBid, updateBid, deleteBid } from '../../services/bidService';
import { getUser, getOrganisationsForUser } from '../../services/userService';
import { listInvitations, createInvitation, revokeInvitation } from '../../services/invitationService';
import { ANZSIC_CODES } from '../../constants/industryConstants';
import { getInvitationDetails } from '../../services/invitationService';

function MyOrganisation({ userData: initialUserData, selectedOrg, setSelectedOrg }) {
  const [userData, setUserData] = useState(initialUserData);
  const [organisation, setOrganisation] = useState(null);
  const [users, setUsers] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [userOrganisations, setUserOrganisations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bids, setBids] = useState([]);
  const [localSelectedOrg, setLocalSelectedOrg] = useState(selectedOrg || '');
  const [bidOwners, setBidOwners] = useState({});

  const [dialogState, setDialogState] = useState({
    delete: false,
    edit: false,
    create: false,
    invite: false,
    editOrg: false,
    joinOrg: false,
    createOrg: false
  });

  const [formState, setFormState] = useState({
    bidToDelete: null,
    bidToEdit: null,
    newBidName: '',
    newBidStatus: '',
    bidNameError: false,
    inviteEmail: '',
    editedOrgName: '',
    editedOrgIndustry: '',
    inviteCode: '',
    newOrgName: '',
    newOrgIndustry: ''
  });

  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  const [bidData, setBidData] = useState({
    name: '',
    client: '',
    status: 'draft',
    dueDate: new Date().toISOString().split('T')[0],
    visibility: 'invite-only',
  });

  const [invitationCode, setInvitationCode] = useState('');
  const [invitationOrganizationName, setInvitationOrganizationName] = useState('');
  const [loadingInvitation, setLoadingInvitation] = useState(false);
  const [invitationError, setInvitationError] = useState('');

  const navigate = useNavigate();

  const bidStatusOptions = useMemo(() => [
    'draft', 'qualifying', 'qualified', 'qualified out', 'in progress',
    'approved to submit', 'approved to contract', 'won', 'lost'
  ], []);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!initialUserData?.localAccountId) {
        setError('User data is not available');
        setLoading(false);
        return;
      }

      try {
        const fetchedUserData = await getUser(initialUserData.localAccountId);
        setUserData(fetchedUserData);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Failed to load user data');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [initialUserData]);

  useEffect(() => {
    const fetchUserOrganisations = async () => {
      if (!userData?.id) return;

      try {
        setLoading(true);
        const orgIds = await getOrganisationsForUser(userData.id);
        const uniqueOrgIds = [...new Set(orgIds)];
        
        const orgs = await Promise.all(uniqueOrgIds.map(id => getOrganisation(id).catch(() => null)));
        const validOrgs = orgs.filter(Boolean);
        
        setUserOrganisations(validOrgs);
        
        if (validOrgs.length > 0) {
          const newSelectedOrg = validOrgs.some(org => org.id === localSelectedOrg)
            ? localSelectedOrg
            : validOrgs[0].id;
          setLocalSelectedOrg(newSelectedOrg);
          setSelectedOrg(newSelectedOrg);
        } else {
          setLocalSelectedOrg('');
          setSelectedOrg('');
        }
      } catch (error) {
        console.error('Error fetching user organisations:', error);
        setError('Failed to load user organisations');
      } finally {
        setLoading(false);
      }
    };

    fetchUserOrganisations();
  }, [userData, localSelectedOrg, setSelectedOrg]);

  useEffect(() => {
    const fetchOrganisationData = async () => {
      if (!selectedOrg) return;

      try {
        const org = await getOrganisation(selectedOrg);
        setOrganisation(org);
        
        const usersData = await Promise.all((org.members || []).map(id => getUser(id)));
        setUsers(usersData);
        
        await fetchInvitations(org.id);
        
        const fetchedBids = await listBids(selectedOrg);
        setBids(fetchedBids);
      } catch (error) {
        console.error('Error fetching organisation data:', error);
        setError(`Failed to load organisation data: ${error.message}`);
      }
    };

    fetchOrganisationData();
  }, [selectedOrg]);

  useEffect(() => {
    const fetchBidOwners = async () => {
      const ownerIds = [...new Set(bids.map(bid => bid.owner))];
      const ownersData = await Promise.all(
        ownerIds.map(async (ownerId) => {
          const owner = await getUser(ownerId);
          return { [ownerId]: owner };
        })
      );
      const ownersMap = Object.assign({}, ...ownersData);
      setBidOwners(ownersMap);
    };

    if (bids.length > 0) {
      fetchBidOwners();
    }
  }, [bids]);

  const fetchInvitations = async (orgId) => {
    try {
      const invitationsData = await listInvitations(orgId);
      setInvitations(invitationsData);
    } catch (error) {
      console.error('Error fetching invitations:', error);
      setError('Failed to load invitations');
    }
  };

  const handleDialogOpen = (dialogName) => {
    setDialogState(prev => ({ ...prev, [dialogName]: true }));
  };

  const handleDialogClose = (dialogName) => {
    setDialogState(prev => ({ ...prev, [dialogName]: false }));
  };

  const handleFormChange = (name, value) => {
    if (name === 'bidToEdit') {
      setBidData({
        id: value.id,
        name: value.name,
        client: value.client,
        status: value.status,
        dueDate: value.dueDate || new Date().toISOString().split('T')[0],
        visibility: value.visibility || 'invite-only',
      });
    } else {
      setFormState(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSnackbarOpen = (message) => {
    setSnackbar({ open: true, message });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '' });
  };

  const handleBidDataChange = (field, value) => {
    setBidData(prev => ({ ...prev, [field]: value }));
  };

  const handleCreateBid = async () => {
    if (!bidData.name) {
      setFormState(prev => ({ ...prev, bidNameError: true }));
      return;
    }

    try {
      const newBid = await createBid({
        ...bidData,
        owner: userData.id,
        organisationId: selectedOrg,
      });
      setBids([...bids, newBid]);
      handleSnackbarOpen('Bid created successfully');
      handleDialogClose('create');
    } catch (error) {
      console.error('Error creating bid:', error);
      handleSnackbarOpen(`Error: ${error.message}. Please try again.`);
    }
  };

  const handleUpdateBid = async () => {
    if (!bidData.name) {
      setFormState(prev => ({ ...prev, bidNameError: true }));
      return;
    }

    try {
      const updatedBid = await updateBid(bidData.id, {
        ...bidData,
        organisationId: selectedOrg,
      });
      setBids(bids.map((b) => (b.id === updatedBid.id ? updatedBid : b)));
      handleSnackbarOpen('Bid updated successfully');
      handleDialogClose('edit');
    } catch (error) {
      console.error('Error updating bid:', error);
      handleSnackbarOpen(`Error: ${error.message}. Please try again.`);
    }
  };

  const handleDeleteBid = async () => {
    if (!formState.bidToDelete) return;
    try {
      await deleteBid(formState.bidToDelete.id);
      setBids(bids.filter((b) => b.id !== formState.bidToDelete.id));
      handleSnackbarOpen('Bid deleted successfully');
      handleDialogClose('delete');
    } catch (error) {
      handleSnackbarOpen(`Error deleting bid: ${error.message}`);
    }
  };

  const handleInviteUser = async () => {
    try {
      if (!organisation?.id) {
        throw new Error('Organisation ID is not available');
      }
      const invitation = await createInvitation(organisation.id, formState.inviteEmail);
      setInvitations([...invitations, invitation]);
      handleSnackbarOpen('Invite code generated successfully');
      handleDialogClose('invite');
    } catch (error) {
      handleSnackbarOpen(`Failed to generate invite code: ${error.message}`);
    }
  };

  const handleRevokeInvitation = async (invitationId) => {
    try {
      if (!organisation?.id) {
        throw new Error('Organisation ID is not available');
      }
      await revokeInvitation(organisation.id, invitationId);
      handleSnackbarOpen('Invitation revoked successfully');
      setInvitations(prevInvitations => prevInvitations.filter(inv => inv.id !== invitationId));
      await fetchInvitations(organisation.id);
    } catch (error) {
      handleSnackbarOpen(`Failed to revoke invitation: ${error.message}`);
    }
  };

  const handleUpdateOrganisation = async () => {
    try {
      const updatedOrg = await updateOrganisation(organisation.id, {
        name: formState.editedOrgName,
        industry: formState.editedOrgIndustry,
      });
      setOrganisation(updatedOrg);
      handleSnackbarOpen('Organisation updated successfully');
      handleDialogClose('editOrg');
    } catch (error) {
      handleSnackbarOpen(`Error updating organisation: ${error.message}`);
    }
  };

  const handleJoinOrganisation = async () => {
    try {
      const joinedOrg = await addUserToOrganisation(formState.inviteCode);
      setUserOrganisations([...userOrganisations, joinedOrg]);
      setSelectedOrg(joinedOrg.id);
      handleSnackbarOpen('Successfully joined organization');
      handleDialogClose('joinOrg');
    } catch (error) {
      handleSnackbarOpen(`Failed to join organization: ${error.message}`);
    }
  };

  const handleCreateOrganisation = async () => {
    try {
      const newOrg = await createOrganisation({
        name: formState.newOrgName,
        industry: formState.newOrgIndustry,
      });
      setUserOrganisations([...userOrganisations, newOrg]);
      setSelectedOrg(newOrg.id);
      handleSnackbarOpen('Successfully created organization');
      handleDialogClose('createOrg');
    } catch (error) {
      handleSnackbarOpen(`Failed to create organization: ${error.message}`);
    }
  };

  const handleInvitationSubmit = async () => {
    setLoadingInvitation(true);
    setInvitationError('');
    try {
      const invitationDetails = await getInvitationDetails(invitationCode);
      if (invitationDetails && invitationDetails.organisationName) {
        setInvitationOrganizationName(invitationDetails.organisationName);
      } else {
        setInvitationError('Invalid invitation or missing organization details.');
      }
    } catch (error) {
      console.error('Error fetching invitation details:', error);
      setInvitationError(error.message || 'An error occurred while fetching invitation details. Please try again.');
    } finally {
      setLoadingInvitation(false);
    }
  };

  const handleAcceptInvitation = async () => {
    setLoadingInvitation(true);
    setInvitationError('');
    try {
      const joinedOrg = await acceptInvitation(invitationCode);
      if (joinedOrg) {
        setUserOrganisations(prevOrgs => [...prevOrgs, joinedOrg]);
        setSelectedOrg(joinedOrg.id);
        handleSnackbarOpen('Successfully joined organization');
        setInvitationOrganizationName('');
        setInvitationCode('');
        handleDialogClose('joinOrg');
      } else {
        throw new Error('Failed to join organization');
      }
    } catch (error) {
      console.error('Error accepting invitation:', error);
      setInvitationError(error.message || 'Error accepting invitation. Please try again.');
    } finally {
      setLoadingInvitation(false);
    }
  };

  const getStatusIcon = (status) => {
    const icons = {
      draft: <DraftsOutlined fontSize="small" />,
      qualifying: <SearchOutlined fontSize="small" />,
      qualified: <CheckCircleOutline fontSize="small" />,
      'qualified out': <CancelOutlined fontSize="small" />,
      'in progress': <PlayArrowOutlined fontSize="small" />,
      'approved to submit': <SendOutlined fontSize="small" />,
      'approved to contract': <AssignmentTurnedInOutlined fontSize="small" />,
      won: <EmojiEventsOutlined fontSize="small" />,
      lost: <SentimentVeryDissatisfiedOutlined fontSize="small" />,
    };
    return icons[status] || null;
  };

  const handleBidClick = (bid) => {
    navigate(`/bid-management/${bid.id}`);
  };

  if (loading) {
    return (
      <Fade in={true} style={{ transitionDelay: '300ms' }}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      </Fade>
    );
  }

  if (error) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <Typography color="error" gutterBottom>{error}</Typography>
      </Box>
    );
  }

  if (!userData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box maxWidth="1200px" margin="auto" mt={4} px={3}>
      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <FormControl variant="outlined" sx={{ minWidth: 200, width: '40%' }}>
                <InputLabel>Select Organisation</InputLabel>
                <Select
                  value={localSelectedOrg}
                  onChange={(e) => {
                    setLocalSelectedOrg(e.target.value);
                    setSelectedOrg(e.target.value);
                  }}
                  label="Select Organisation"
                >
                  {userOrganisations.length === 0 ? (
                    <MenuItem value="">
                      <em>No organisations available</em>
                    </MenuItem>
                  ) : (
                    userOrganisations.map((org) => (
                      <MenuItem key={org.id} value={org.id}>
                        <Box display="flex" alignItems="center">
                          <BusinessIcon fontSize="small" sx={{ mr: 1 }} />
                          <Typography>{org.name}</Typography>
                        </Box>
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
              <Box>
                <Button
                  variant="outlined"
                  onClick={() => handleDialogOpen('joinOrg')}
                  sx={{ mr: 2 }}
                >
                  Join Organisation
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleDialogOpen('createOrg')}
                >
                  Create Organisation
                </Button>
              </Box>
            </Box>
          </Grid>

          {/* Join Organisation Dialog */}
          <Dialog open={dialogState.joinOrg} onClose={() => handleDialogClose('joinOrg')}>
            <DialogTitle>Join Organisation</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Invitation Code"
                fullWidth
                value={invitationCode}
                onChange={(e) => setInvitationCode(e.target.value)}
              />
              {invitationOrganizationName && (
                <Typography variant="body1" sx={{ mt: 2 }}>
                  You're invited to join: <strong>{invitationOrganizationName}</strong>
                </Typography>
              )}
              {invitationError && (
                <Typography color="error" sx={{ mt: 2 }}>
                  {invitationError}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleDialogClose('joinOrg')}>Cancel</Button>
              <Button
                onClick={invitationOrganizationName ? handleAcceptInvitation : handleInvitationSubmit}
                color="primary"
                disabled={loadingInvitation}
              >
                {loadingInvitation ? <CircularProgress size={24} /> : invitationOrganizationName ? 'Accept Invitation' : 'Check Invitation'}
              </Button>
            </DialogActions>
          </Dialog>

          {organisation && (
            <>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  My Organisation
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={() => {
                      handleFormChange('editedOrgName', organisation.name);
                      handleFormChange('editedOrgIndustry', organisation.industry);
                      handleDialogOpen('editOrg');
                    }}
                    sx={{ ml: 2 }}
                  >
                    Edit Organisation
                  </Button>
                </Typography>
                <Divider sx={{ mb: 3 }} />
                <Grid container spacing={3}>
                  <OrganisationItem icon={<BusinessIcon />} label="Name" value={organisation.name} />
                  <OrganisationItem icon={<WorkIcon />} label="Industry" value={organisation.industry} />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom sx={{ mt: 2, mb: 2 }}>
                  <GroupIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                  Users in Organisation
                </Typography>
                <TableContainer component={Paper} sx={{ mb: 4 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Role</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((user) => (
                        <TableRow key={user.id}>
                          <TableCell>
                            {user.givenName && user.surname
                              ? `${user.givenName} ${user.surname}`
                              : user.displayName || user.email || 'N/A'}
                          </TableCell>
                          <TableCell>{user.email}</TableCell>
                          <TableCell>{user.id === organisation.ownerId ? 'Owner' : 'Member'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom sx={{ mt: 2, mb: 2 }}>
                  <EmailIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                  Invited Users
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => handleDialogOpen('invite')}
                  sx={{ mb: 3 }}
                >
                  Invite User
                </Button>
                <TableContainer component={Paper} sx={{ mb: 4 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Invite Code</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invitations.map((invitation) => (
                        <TableRow key={invitation.id}>
                          <TableCell>{invitation.email}</TableCell>
                          <TableCell>
                            <Chip 
                              label={invitation.status} 
                              color={invitation.status === 'pending' ? 'warning' : 'success'} 
                            />
                          </TableCell>
                          <TableCell>{invitation.id}</TableCell>
                          <TableCell>
                            <IconButton 
                              onClick={() => handleRevokeInvitation(invitation.id)}
                              color="error"
                              aria-label="revoke invitation"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom sx={{ mt: 2, mb: 2 }}>
                  Bids
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => handleDialogOpen('create')}
                    sx={{ ml: 2 }}
                  >
                    Create Bid
                  </Button>
                </Typography>
                {bids.length > 0 ? (
                  <TableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table aria-label="bids table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Bid ID</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Owner</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bids.map((bid) => (
                          <TableRow 
                            key={bid.id}
                            onClick={() => handleBidClick(bid)}
                            sx={{ 
                              cursor: 'pointer',
                              '&:hover': { backgroundColor: 'action.hover' },
                            }}
                          >
                            <TableCell>{bid.id}</TableCell>
                            <TableCell>{bid.name}</TableCell>
                            <TableCell>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {getStatusIcon(bid.status)}
                                <Typography sx={{ ml: 1 }}>
                                  {bid.status.charAt(0).toUpperCase() + bid.status.slice(1)}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              {bidOwners[bid.owner] ? 
                                `${bidOwners[bid.owner].givenName} ${bidOwners[bid.owner].surname}` : 
                                'Loading...'}
                            </TableCell>
                            <TableCell>
                              {bid.status === 'draft' && (
                                <>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<EditIcon />}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleFormChange('bidToEdit', bid);
                                      handleDialogOpen('edit');
                                    }}
                                    sx={{ mr: 1 }}
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="error"
                                    startIcon={<DeleteIcon />}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleFormChange('bidToDelete', bid);
                                      handleDialogOpen('delete');
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography color="textSecondary" sx={{ mt: 2 }}>
                    No bids found. Create one to get started!
                  </Typography>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Paper>

      {/* Dialogs */}
      <DeleteBidDialog
        open={dialogState.delete}
        onClose={() => handleDialogClose('delete')}
        onDelete={handleDeleteBid}
        bidName={formState.bidToDelete?.name}
      />

      <CreateEditBidDialog
        open={dialogState.create || dialogState.edit}
        onClose={() => handleDialogClose(dialogState.create ? 'create' : 'edit')}
        onCreate={handleCreateBid}
        onUpdate={handleUpdateBid}
        bidData={bidData}
        onBidDataChange={handleBidDataChange}
        bidNameError={formState.bidNameError}
        bidStatusOptions={bidStatusOptions}
        isEdit={dialogState.edit}
      />

      <InviteUserDialog
        open={dialogState.invite}
        onClose={() => handleDialogClose('invite')}
        onInvite={handleInviteUser}
        inviteEmail={formState.inviteEmail}
        onInviteEmailChange={(e) => handleFormChange('inviteEmail', e.target.value)}
      />

      <EditOrganisationDialog
        open={dialogState.editOrg}
        onClose={() => handleDialogClose('editOrg')}
        onUpdate={handleUpdateOrganisation}
        orgName={formState.editedOrgName}
        orgIndustry={formState.editedOrgIndustry}
        onOrgNameChange={(e) => handleFormChange('editedOrgName', e.target.value)}
        onOrgIndustryChange={(e) => handleFormChange('editedOrgIndustry', e.target.value)}
      />

      <CreateOrganisationDialog
        open={dialogState.createOrg}
        onClose={() => handleDialogClose('createOrg')}
        onCreate={handleCreateOrganisation}
        orgName={formState.newOrgName}
        orgIndustry={formState.newOrgIndustry}
        onOrgNameChange={(e) => handleFormChange('newOrgName', e.target.value)}
        onOrgIndustryChange={(e) => handleFormChange('newOrgIndustry', e.target.value)}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
      />
    </Box>
  );
}

// Helper components
const DeleteBidDialog = ({ open, onClose, onDelete, bidName }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Delete Bid?</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to delete the bid "{bidName}"? This action cannot be undone.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onDelete} color="error">Delete</Button>
    </DialogActions>
  </Dialog>
);

const CreateEditBidDialog = ({ open, onClose, onCreate, onUpdate, bidData, onBidDataChange, bidNameError, bidStatusOptions, isEdit }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{isEdit ? 'Edit Bid' : 'Create New Bid'}</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        label="Bid Name"
        fullWidth
        value={bidData.name}
        onChange={(e) => onBidDataChange('name', e.target.value)}
        error={bidNameError}
        helperText={bidNameError ? 'Bid name is required' : ''}
      />
      <TextField
        margin="dense"
        label="Client"
        fullWidth
        value={bidData.client}
        onChange={(e) => onBidDataChange('client', e.target.value)}
      />
      <TextField
        select
        margin="dense"
        label="Status"
        fullWidth
        value={bidData.status}
        onChange={(e) => onBidDataChange('status', e.target.value)}
      >
        {bidStatusOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Due Date"
        type="date"
        value={bidData.dueDate}
        onChange={(e) => onBidDataChange('dueDate', e.target.value)}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        select
        margin="dense"
        label="Visibility"
        fullWidth
        value={bidData.visibility}
        onChange={(e) => onBidDataChange('visibility', e.target.value)}
      >
        <MenuItem value="organisation">Visible to entire organisation</MenuItem>
        <MenuItem value="invite-only">Invite-only</MenuItem>
      </TextField>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={isEdit ? onUpdate : onCreate} color="primary">
        {isEdit ? 'Update' : 'Create'}
      </Button>
    </DialogActions>
  </Dialog>
);

const InviteUserDialog = ({ open, onClose, onInvite, inviteEmail, onInviteEmailChange }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Invite User</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        label="Email Address"
        type="email"
        fullWidth
        value={inviteEmail}
        onChange={onInviteEmailChange}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onInvite} color="primary">Invite</Button>
    </DialogActions>
  </Dialog>
);

const EditOrganisationDialog = ({ open, onClose, onUpdate, orgName, orgIndustry, onOrgNameChange, onOrgIndustryChange }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Edit Organisation</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        label="Organisation Name"
        fullWidth
        value={orgName}
        onChange={onOrgNameChange}
      />
      <TextField
        select
        margin="dense"
        label="Industry"
        fullWidth
        value={orgIndustry}
        onChange={onOrgIndustryChange}
      >
        {ANZSIC_CODES.map((industry) => (
          <MenuItem key={industry.code} value={industry.code}>
            {industry.code} - {industry.description}
          </MenuItem>
        ))}
      </TextField>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onUpdate} color="primary">Update</Button>
    </DialogActions>
  </Dialog>
);

const CreateOrganisationDialog = ({ open, onClose, onCreate, orgName, orgIndustry, onOrgNameChange, onOrgIndustryChange }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Create New Organisation</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        label="Organisation Name"
        fullWidth
        value={orgName}
        onChange={onOrgNameChange}
      />
      <TextField
        select
        margin="dense"
        label="Industry"
        fullWidth
        value={orgIndustry}
        onChange={onOrgIndustryChange}
      >
        {ANZSIC_CODES.map((industry) => (
          <MenuItem key={industry.code} value={industry.code}>
            {industry.code} - {industry.description}
          </MenuItem>
        ))}
      </TextField>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onCreate} color="primary">Create</Button>
    </DialogActions>
  </Dialog>
);

// Helper component to render organisation details
const OrganisationItem = ({ icon, label, value }) => {
  const displayValue = label === 'Industry' 
    ? ANZSIC_CODES.find(industry => industry.code === value)?.description || value
    : value;

  return (
    <Grid item xs={12} sm={6}>
      <Box display="flex" alignItems="flex-start" mb={2}>
        <Box mr={2} mt={0.5}>
          {icon}
        </Box>
        <Box>
          <Typography variant="body2" color="textSecondary">
            {label}
          </Typography>
          <Typography variant="body1">{displayValue || 'Not specified'}</Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default MyOrganisation;
