import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Paper, 
  Button, 
  Chip,
  Card,
  CardContent,
  CardActions,
  Divider,
  useTheme,
  Fab,
  Zoom,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions
} from '@mui/material';
import {
  Edit as EditIcon,
  CheckCircle as CheckCircleIcon,
  BrandingWatermark as BrandingWatermarkIcon,
  AutoStories as AutoStoriesIcon,
  Palette as PaletteIcon,
  Description as DescriptionIcon,
  Collections as CollectionsIcon,
  Close as CloseIcon,
  SmartToy as AIIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getBrandProfile, getToneOfVoice } from '../services/brandBuilderService';
import { createOrgCanvas, updateOrgCanvas, getOrgCanvas, listOrgCanvases } from '../services/organisationcanvasService';
import BrandIntelligenceInterface from '../components/ai/BrandIntelligenceInterface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling } from '@fortawesome/free-solid-svg-icons';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import StoryBrandCanvas from '../components/orgcanvases/StoryBrandCanvas';

const WelcomePanel = ({ title, subtitle }) => {
  const theme = useTheme();
  
  return (
    <Paper 
      sx={{ 
        p: 3, 
        background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
        color: 'white',
        mb: 3 
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <EmojiEventsIcon sx={{ fontSize: '3rem', marginRight: '1rem' }} />
        <Box>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle1">
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

const BrandPage = ({ selectedOrg, userData, brandData: initialBrandData, toneOfVoiceData: initialToneOfVoiceData }) => {
  const [aiOpen, setAiOpen] = useState(false);
  const [showWelcomeDialog, setShowWelcomeDialog] = useState(false);
  const [brandData, setBrandData] = useState(initialBrandData);
  const [toneOfVoiceData, setToneOfVoiceData] = useState(initialToneOfVoiceData);
  const [showStoryBrandCanvas, setShowStoryBrandCanvas] = useState(false);
  const [canvases, setCanvases] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      const orgId = selectedOrg?.id || selectedOrg;
      if (!orgId) {
        console.log('No valid organization selected:', selectedOrg);
        return;
      }

      try {
        const brandProfile = await getBrandProfile(orgId);
        console.log('Fetched Brand Profile:', brandProfile);
        setBrandData(brandProfile);
        
        const fetchedCanvases = await listOrgCanvases(orgId);
        console.log('All fetched canvases:', fetchedCanvases);
        setCanvases(fetchedCanvases);
        
        const storyBrandCanvas = fetchedCanvases.find(canvas => {
          const isMatch = (
            canvas.type?.toLowerCase() === 'storybrand' || 
            canvas.type === 'StoryBrand Canvas'
          ) && canvas.exists;
          
          console.log('Checking canvas:', {
            id: canvas.id,
            type: canvas.type,
            exists: canvas.exists,
            isMatch
          });
          
          return isMatch;
        });
        
        if (storyBrandCanvas?.id) {
          console.log('Found existing StoryBrand Canvas:', storyBrandCanvas);
          const fullCanvas = await getOrgCanvas(orgId, storyBrandCanvas.id);
          console.log('Fetched full canvas details:', fullCanvas);
          setBrandData(prev => ({
            ...prev,
            storyBrandCanvas: fullCanvas
          }));
        } else {
          console.log('No existing StoryBrand Canvas found in canvases:', 
            fetchedCanvases.map(c => ({ id: c.id, type: c.type, exists: c.exists }))
          );
        }

        if (brandProfile?.id) {
          const toneOfVoice = await getToneOfVoice(brandProfile.id);
          setToneOfVoiceData(toneOfVoice);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [selectedOrg]);

  if (!selectedOrg) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error">
          Please select an organization to view brand information.
        </Typography>
      </Box>
    );
  }

  const isComplete = (toneOfVoice) => {
    console.log('Checking tone of voice:', toneOfVoice);

    if (!toneOfVoice) {
      console.log('No tone of voice data');
      return false;
    }

    const hasContent = Object.keys(toneOfVoice).length > 0;
    console.log('Has content:', hasContent, 'Content keys:', Object.keys(toneOfVoice));
    if (!hasContent) return false;

    if (toneOfVoice.status === 'completed') {
      console.log('Status is completed');
      return true;
    }

    const sections = {
      toneQuadrants: toneOfVoice.toneQuadrants ? Object.keys(toneOfVoice.toneQuadrants).length : 0,
      channelAdaptations: toneOfVoice.channelAdaptations ? Object.keys(toneOfVoice.channelAdaptations).length : 0,
      toneDimensions: toneOfVoice.toneDimensions ? Object.keys(toneOfVoice.toneDimensions).length : 0
    };
    console.log('Section counts:', sections);

    const isInProgress = sections.toneQuadrants > 0 || 
                        sections.channelAdaptations > 0 || 
                        sections.toneDimensions > 0;
    console.log('Is in progress:', isInProgress);

    return isInProgress;
  };

  const CompletedSection = ({ title, status, description, onClick, icon: Icon }) => {
    const getButtonText = () => {
      switch (status) {
        case 'Complete':
          return 'View & Edit';
        case 'In Progress':
          return 'Continue';
        default:
          return 'Start';
      }
    };

    const getStatusColor = () => {
      switch (status) {
        case 'Complete':
          return 'success';
        case 'In Progress':
          return 'warning';
        default:
          return 'default';
      }
    };

    return (
      <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Icon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h6">{title}</Typography>
          </Box>
          <Chip 
            label={status} 
            color={getStatusColor()}
            icon={status === 'Complete' ? <CheckCircleIcon /> : null}
            sx={{ mb: 2 }}
          />
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button 
            startIcon={status === 'Complete' ? <EditIcon /> : null}
            onClick={onClick}
            variant="outlined"
            size="small"
            color={getStatusColor()}
          >
            {getButtonText()}
          </Button>
        </CardActions>
      </Card>
    );
  };

  const InDevelopmentSection = ({ title, description, icon: Icon }) => (
    <Card sx={{ height: '100%', opacity: 0.7 }}>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Icon sx={{ mr: 1, color: 'text.secondary' }} />
          <Typography variant="h6">{title}</Typography>
        </Box>
        <Chip label="Coming Soon" color="default" sx={{ mb: 2 }} />
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );

  const renderAIAssistant = () => (
    <BrandIntelligenceInterface 
      userProfile={userData}
      organisation={selectedOrg}
      brandData={brandData}
      toneOfVoiceData={toneOfVoiceData}
      currentStep="dashboard"
      currentStepData={{
        label: 'Brand Dashboard',
        description: 'Manage and develop your brand identity across all touchpoints'
      }}
    />
  );

  const handleCanvasSave = async (orgId, id, data) => {
    try {
      console.log('Saving canvas:', { orgId, id, data });
      
      let savedCanvas;
      if (data.id) {
        // Update existing canvas
        savedCanvas = await updateOrgCanvas(orgId, data.id, data);
      } else {
        // Create new canvas
        savedCanvas = await createOrgCanvas(orgId, data);
      }
      
      setBrandData(prev => ({
        ...prev,
        storyBrandCanvas: savedCanvas
      }));
      
      return true;
    } catch (error) {
      console.error('Error saving canvas:', error);
      throw error;
    }
  };

  const handleStoryBrandClick = async () => {
    try {
      const orgId = selectedOrg?.id || selectedOrg;
      if (!orgId) {
        console.error('No valid organization selected');
        return;
      }

      const existingCanvas = brandData?.storyBrandCanvas || 
        canvases.find(canvas => 
          (canvas.type?.toLowerCase() === 'storybrand' || 
           canvas.type === 'StoryBrand Canvas') && 
          canvas.exists
        );

      if (existingCanvas?.id) {
        console.log('Loading existing StoryBrand Canvas:', existingCanvas.id);
        const latestCanvas = await getOrgCanvas(orgId, existingCanvas.id);
        console.log('Loaded latest canvas:', latestCanvas);
        if (latestCanvas) {
          setBrandData(prev => ({
            ...prev,
            storyBrandCanvas: latestCanvas
          }));
        }
      } else {
        console.log('No existing canvas found in state:', {
          brandDataCanvas: brandData?.storyBrandCanvas,
          allCanvases: canvases.map(c => ({ id: c.id, type: c.type, exists: c.exists }))
        });
      }
      
      setShowStoryBrandCanvas(true);
    } catch (error) {
      console.error('Error loading StoryBrand canvas:', error);
    }
  };

  if (showStoryBrandCanvas) {
    return (
      <StoryBrandCanvas
        user={userData}
        selectedOrg={selectedOrg}
        canvas={brandData?.storyBrandCanvas}
        onClose={() => setShowStoryBrandCanvas(false)}
        onSave={handleCanvasSave}
      />
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <WelcomePanel 
        title="Brand Dashboard"
        subtitle="Manage and develop your brand identity across all touchpoints"
      />

      <Grid container spacing={3} sx={{ mt: 2 }}>
        {/* Completed/Active Sections */}
        <Grid item xs={12} md={6}>
          <CompletedSection 
            title="Tone of Voice"
            status={
              isComplete(toneOfVoiceData) ? 'Complete' :
              toneOfVoiceData && Object.keys(toneOfVoiceData).length > 0 ? 'In Progress' : 
              'Not Started'
            }
            description="Your brand voice guidelines and communication style"
            onClick={() => navigate('/brand/tone-of-voice')}
            icon={BrandingWatermarkIcon}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CompletedSection 
            title="StoryBrand Framework"
            status={
              brandData?.storyBrandCanvas?.status === 'completed' ? 'Complete' :
              brandData?.storyBrandCanvas ? 'In Progress' : 
              'Not Started'
            }
            description="Craft your brand story using the proven StoryBrand framework"
            onClick={handleStoryBrandClick}
            icon={AutoStoriesIcon}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ my: 3 }}>
            <Chip label="In Development" />
          </Divider>
        </Grid>

        {/* In Development Sections */}
        <Grid item xs={12} md={4}>
          <InDevelopmentSection 
            title="Visual Identity"
            description="Manage your logos, colors, typography, and design elements"
            icon={PaletteIcon}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InDevelopmentSection 
            title="Brand Guidelines"
            description="Create and manage comprehensive brand guidelines"
            icon={DescriptionIcon}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InDevelopmentSection 
            title="Brand Assets"
            description="Manage and organize all your brand assets"
            icon={CollectionsIcon}
          />
        </Grid>
      </Grid>

      {renderAIAssistant()}
    </Box>
  );
};

export default BrandPage; 