import React, { useState, useEffect } from 'react';
import BaseCanvas from './BaseCanvas';
import BusinessIcon from '@mui/icons-material/Business';
import { Box, Button, Typography, useTheme, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const BusinessModelCanvas = ({ user, selectedOrg, canvas, onClose, onSave }) => {
  const [canvasData, setCanvasData] = useState(canvas || {});
  const theme = useTheme();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showUnsavedDialog, setShowUnsavedDialog] = useState(false);

  useEffect(() => {
    if (canvas) {
      setCanvasData(canvas);
    }
  }, [canvas]);

  const handleFieldChange = (field, value) => {
    setCanvasData(prev => ({ ...prev, [field]: value }));
    setHasUnsavedChanges(true);
  };

  const handleCloseAttempt = () => {
    if (hasUnsavedChanges) {
      setShowUnsavedDialog(true);
    } else {
      onClose();
    }
  };

  const handleConfirmedClose = () => {
    setShowUnsavedDialog(false);
    onClose();
  };

  const handleSave = async (updatedData) => {
    try {
      const dataToSave = {
        ...updatedData,
        orgId: selectedOrg.id || selectedOrg
      };
      await onSave(dataToSave);
      setCanvasData(dataToSave);
      setHasUnsavedChanges(false);
      if (updatedData.status) {
        onClose();
      }
      return true;
    } catch (error) {
      console.error('Error saving canvas:', error);
      return false;
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasUnsavedChanges]);

  const sections = [
    { field: 'customerSegments', title: 'Customer Segments', rows: 4 },
    { field: 'valuePropositions', title: 'Value Propositions', rows: 4 },
    { field: 'channels', title: 'Channels', rows: 4 },
    { field: 'customerRelationships', title: 'Customer Relationships', rows: 4 },
    { field: 'revenueStreams', title: 'Revenue Streams', rows: 4 },
    { field: 'keyResources', title: 'Key Resources', rows: 4 },
    { field: 'keyActivities', title: 'Key Activities', rows: 4 },
    { field: 'keyPartners', title: 'Key Partners', rows: 4 },
    { field: 'costStructure', title: 'Cost Structure', rows: 4 },
  ];

  const tooltips = {
    customerSegments: "Identify your target customers. Format: Bullet points with brief descriptions. Example: '• Small to medium enterprises in the tech sector\n• Fortune 500 companies in manufacturing industry'",
    valuePropositions: "Describe the value you deliver to customers. Format: Concise statements. Example: '• Increase operational efficiency by 30%\n• Reduce carbon footprint by 50%'",
    channels: "List how you reach and communicate with your customers. Format: Bullet points. Example: '• Direct sales team\n• Online marketplace\n• Partner network'",
    customerRelationships: "Explain how you interact with customers. Format: Short descriptions. Example: 'Dedicated account managers for enterprise clients\nSelf-service portal for SMEs'",
    revenueStreams: "Identify how your business generates income. Format: List with percentages. Example: 'Product sales: 60%\nSubscription services: 30%\nConsulting: 10%'",
    keyResources: "List crucial assets required for your business model. Format: Categorized list. Example: 'Physical: Manufacturing plants\nIntellectual: Patents\nHuman: Engineering team'",
    keyActivities: "Describe the most important things your company must do. Format: Bullet points. Example: '• Product development\n• Quality assurance\n• Customer support'",
    keyPartners: "Identify essential business partners and suppliers. Format: Bullet points. Example: '• Component suppliers\n• Distribution partners\n• Research institutions'",
    costStructure: "Outline your major cost categories. Format: Percentage breakdown. Example: 'Manufacturing: 40%\nR&D: 20%\nMarketing: 15%\nAdministration: 25%'",
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      height: '100vh',
      bgcolor: theme.palette.background.default,
      color: theme.palette.text.primary,
    }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        p: 2, 
        borderBottom: `1px solid ${theme.palette.divider}`
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <BusinessIcon sx={{ mr: 1, fontSize: 28, color: theme.palette.secondary.main }} />
          <Typography variant="h5" sx={{ fontWeight: 500 }}>Business Model Canvas</Typography>
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 3 }}>
        <BaseCanvas
          user={user}
          selectedOrg={selectedOrg}
          canvas={canvasData}
          onClose={handleCloseAttempt}
          onUpdate={(field, value) => handleFieldChange(field, value)}
          onSave={handleSave}
          canvasType="Business Model Canvas"
          sections={sections}
          tooltips={tooltips}
          icon={BusinessIcon}
        />
      </Box>

      <Dialog open={showUnsavedDialog} onClose={() => setShowUnsavedDialog(false)}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          <Typography>
            You have unsaved changes. What would you like to do?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowUnsavedDialog(false)}>
            Continue Editing
          </Button>
          <Button onClick={handleConfirmedClose} color="error">
            Discard Changes
          </Button>
          <Button 
            onClick={async () => {
              const success = await handleSave(canvasData);
              if (success) {
                onClose();
              }
            }} 
            variant="contained" 
            color="primary"
          >
            Save & Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BusinessModelCanvas;
