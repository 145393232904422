import React from 'react';
import { Paper, Typography, Button } from '@mui/material';

const QuickActions = React.memo(({ 
  bidStrategyCanvases, 
  rfpQualificationCanvases, 
  teamMembers, 
  selectedBid,
  onActionClick,
  onTabChange 
}) => {
  const renderActions = () => {
    const actions = [];

    // Bid Strategy Canvas actions
    if (!bidStrategyCanvases.length) {
      actions.push(
        <Button
          key="createBidStrategy"
          variant="contained"
          color="primary"
          onClick={() => onActionClick('bidStrategy')}
          sx={{ mr: 1, mb: 1 }}
        >
          Create Bid Strategy Canvas
        </Button>
      );
    } else {
      actions.push(
        <Button
          key="updateBidStrategy"
          variant="outlined"
          color="primary"
          onClick={() => onActionClick('bidStrategy', bidStrategyCanvases[0].id)}
          sx={{ mr: 1, mb: 1 }}
        >
          Update Bid Strategy Canvas
        </Button>
      );
    }

    // RFP Qualification Canvas actions
    if (!rfpQualificationCanvases.length) {
      actions.push(
        <Button
          key="createRFPQualification"
          variant="contained"
          color="secondary"
          onClick={() => onActionClick('rfpQualification')}
          sx={{ mb: 1 }}
        >
          Create RFP Qualification Canvas
        </Button>
      );
    } else {
      actions.push(
        <Button
          key="updateRFPQualification"
          variant="outlined"
          color="secondary"
          onClick={() => onActionClick('rfpQualification', rfpQualificationCanvases[0].id)}
          sx={{ mb: 1 }}
        >
          Update RFP Qualification Canvas
        </Button>
      );
    }

    // Team actions
    if (!teamMembers.length) {
      actions.push(
        <Button
          key="addTeamMembers"
          variant="contained"
          color="info"
          onClick={() => onTabChange(3)}
          sx={{ mr: 1, mb: 1 }}
        >
          Add Team Members
        </Button>
      );
    }

    // Task actions
    const overdueTasks = selectedBid.tasks ? selectedBid.tasks.filter(task => 
      new Date(task.dueDate) < new Date() && task.status !== 'completed'
    ) : [];

    if (overdueTasks.length > 0) {
      actions.push(
        <Button
          key="reviewOverdueTasks"
          variant="contained"
          color="error"
          onClick={() => onTabChange(2)}
          sx={{ mb: 1 }}
        >
          Review Overdue Tasks ({overdueTasks.length})
        </Button>
      );
    } else if (!selectedBid.tasks || selectedBid.tasks.length === 0) {
      actions.push(
        <Button
          key="createTasks"
          variant="contained"
          color="success"
          onClick={() => onTabChange(2)}
          sx={{ mb: 1 }}
        >
          Create Tasks
        </Button>
      );
    }

    return actions;
  };

  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>Quick Actions</Typography>
      {renderActions()}
    </Paper>
  );
});

export default QuickActions; 