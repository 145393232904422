import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Paper,
  Divider,
  Breadcrumbs,
  Link,
  Tooltip,
  Snackbar,
  Alert,
  Chip,
} from '@mui/material';
import {
  FolderOutlined,
  InsertDriveFileOutlined,
  CreateNewFolderOutlined,
  UploadFileOutlined,
  DeleteOutline,
  ArrowBackIosNewOutlined,
} from '@mui/icons-material';
import { uploadDocuments, listDocuments, createFolder, deleteItem } from '../../services/documentService';

const BidDocuments = ({ bidId, bidName }) => {
  const [documents, setDocuments] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [currentFolderName, setCurrentFolderName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [newItemName, setNewItemName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [openCreateFolderDialog, setOpenCreateFolderDialog] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [folderHierarchy, setFolderHierarchy] = useState([{ id: null, name: 'Root' }]);
  const [error, setError] = useState(null);

  const fetchDocuments = useCallback(async () => {
    setIsLoading(true);
    try {
        const folderToFetch = currentFolder === '' ? 'root' : currentFolder;
        const docs = await listDocuments(bidId, folderToFetch);
        
        const docsArray = Array.isArray(docs) ? docs : [];
        setDocuments(docsArray);
        
        if (currentFolder && currentFolder !== '') {
            const currentFolderInfo = folderHierarchy[folderHierarchy.length - 1];
            setCurrentFolderName(currentFolderInfo.name);
        } else {
            setCurrentFolderName('');
        }
        setError(null);  // Clear any previous errors
    } catch (error) {
        setError('Error fetching documents. Please try again.');
    } finally {
        setIsLoading(false);
    }
  }, [bidId, currentFolder, folderHierarchy]);

  useEffect(() => {
    fetchDocuments();
  }, [bidId, currentFolder, fetchDocuments]);

  const handleCreateFolder = async () => {
    try {
      await createFolder(bidId, currentFolder, newFolderName);
      setOpenCreateFolderDialog(false);
      fetchDocuments();
      setError('Error creating folder. Please try again.');
    } catch (error) {
      setError('Error creating folder. Please try again.');
    }
  };

  const handleUploadDocument = async () => {
    if (!selectedFile) return;
    try {
      await uploadDocument(bidId, currentFolder, selectedFile);
      setOpenUploadDialog(false);
      fetchDocuments();
      setError('Error uploading document. Please try again.');
    } catch (error) {
      setError('Error uploading document. Please try again.');
    }
  };

  const handleDeleteItem = async (item) => {
    try {
      await deleteItem(bidId, item.id);
      fetchDocuments();
      setError('Error deleting item. Please try again.');
    } catch (error) {
      setError('Error deleting item. Please try again.');
    }
  };

  const handleOpenFolder = (folder) => {
    setDocuments([]); // Reset documents before changing folder
    setCurrentFolder(folder.id);
    setFolderHierarchy(prev => [...prev, { id: folder.id, name: folder.name }]);
  };

  const handleBackToParent = () => {
    if (folderHierarchy.length > 1) {
      const newHierarchy = folderHierarchy.slice(0, -1);
      const parentFolder = newHierarchy[newHierarchy.length - 1];
      setDocuments([]); // Reset documents before going back to parent
      setCurrentFolder(parentFolder.id);
      setFolderHierarchy(newHierarchy);
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const renderListItem = (item) => {
    return (
      <ListItem
        key={item.id}
        button={item.type === 'folder'}
        onClick={() => item.type === 'folder' && handleOpenFolder(item)}
        sx={{
          borderRadius: 1,
          mb: 1,
          '&:hover': {
            backgroundColor: 'action.hover',
          },
        }}
      >
        <ListItemIcon>
          {item.type === 'folder' ? <FolderOutlined color="primary" /> : <InsertDriveFileOutlined color="secondary" />}
        </ListItemIcon>
        <ListItemText 
          primary={item.name} 
          secondary={
            item.type === 'file' 
              ? `Last modified: ${new Date(item.lastModified).toLocaleString()} | Size: ${formatFileSize(item.size)}`
              : 'Folder'
          }
        />
        <Tooltip title="Delete">
          <IconButton onClick={(e) => { e.stopPropagation(); handleDeleteItem(item); }}>
            <DeleteOutline />
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const handleFileChange = (event) => {
    setSelectedFiles(Array.from(event.target.files));
  };

  const handleUploadDocuments = async () => {
    if (selectedFiles.length === 0) return;
    try {
      await uploadDocuments(bidId, currentFolder, selectedFiles);
      setOpenUploadDialog(false);
      fetchDocuments();
      setError('Error uploading documents. Please try again.');
      setSelectedFiles([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      setError('Error uploading documents. Please try again.');
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, borderRadius: 2, backgroundColor: 'background.paper' }}>
      <Typography variant="h5" gutterBottom sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}>
        Bid Documents
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Breadcrumbs aria-label="breadcrumb">
          {folderHierarchy.map((folder, index) => (
            <Link
              key={folder.id}
              color="inherit"
              href="#"
              onClick={() => {
                if (index < folderHierarchy.length - 1) {
                  setCurrentFolder(folder.id);
                  setFolderHierarchy(folderHierarchy.slice(0, index + 1));
                }
              }}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <FolderOutlined sx={{ mr: 0.5 }} fontSize="inherit" />
              {folder.name}
            </Link>
          ))}
        </Breadcrumbs>
        <Chip label={bidName} color="primary" variant="outlined" />
      </Box>
      {currentFolder && (
        <Button
          startIcon={<ArrowBackIosNewOutlined />}
          onClick={handleBackToParent}
          sx={{ mb: 2 }}
        >
          Back to Parent Folder
        </Button>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Button
          startIcon={<CreateNewFolderOutlined />}
          onClick={() => setOpenCreateFolderDialog(true)}
        >
          Create Folder
        </Button>
        <Button
          startIcon={<UploadFileOutlined />}
          onClick={() => setOpenUploadDialog(true)}
        >
          Upload Document
        </Button>
      </Box>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : documents.length > 0 ? (
        <List>
          {documents.map(renderListItem)}
        </List>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 4 }}>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            {currentFolder ? `The folder "${currentFolderName}" is empty.` : "No documents found in the root folder."}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {currentFolder ? "Upload a document or create a new folder here." : "Create a folder or upload a document to get started."}
          </Typography>
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      {/* Create Folder Dialog */}
      <Dialog open={openCreateFolderDialog} onClose={() => setOpenCreateFolderDialog(false)}>
        <DialogTitle>Create New Folder</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Folder Name"
            fullWidth
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCreateFolderDialog(false)}>Cancel</Button>
          <Button onClick={handleCreateFolder} color="primary">Create</Button>
        </DialogActions>
      </Dialog>

      {/* Upload Document Dialog */}
      <Dialog open={openUploadDialog} onClose={() => setOpenUploadDialog(false)}>
        <DialogTitle>Upload Documents</DialogTitle>
        <DialogContent>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            ref={fileInputRef}
          />
          {selectedFiles.length > 0 && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              Selected files: {selectedFiles.map(file => file.name).join(', ')}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUploadDialog(false)}>Cancel</Button>
          <Button onClick={handleUploadDocuments} color="primary" disabled={selectedFiles.length === 0}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Debug output */}
      <Box sx={{ mt: 2, p: 2, backgroundColor: 'grey.100', borderRadius: 1 }}>
        <Typography variant="subtitle2">Debug Info:</Typography>
        <pre>{JSON.stringify({ 
          bidId, 
          currentFolder, 
          currentFolderName,
          documentsCount: documents.length, 
          documents 
        }, null, 2)}</pre>
      </Box>
    </Paper>
  );
};

export default BidDocuments;
