import React from 'react';
import ReactDOM from 'react-dom/client'; // For React 18
import { MsalProvider } from '@azure/msal-react';
import { initializeMsal, msalInstance } from './services/authService'; // Adjust the path if necessary
import App from './App';

// Initialize MSAL instance
initializeMsal.then(() => {
  // Handle redirect response after initialization
  msalInstance.handleRedirectPromise()
    .then((response) => {
      if (response) {
        //console.log("MSAL redirect response:", response);
        // You can handle successful login or response processing here
      }

      // Now proceed with rendering the app
      const container = document.getElementById('root');
      const root = ReactDOM.createRoot(container);

      root.render(
        <React.StrictMode>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </React.StrictMode>
      );
    })
    .catch((error) => {
      console.error('MSAL redirect error:', error);
      // Optionally render an error component or handle the error state
    });
}).catch((error) => {
  console.error('MSAL initialization error:', error);
  // Handle initialization error (e.g., render an error message)
});
