export const callApi = async (apiFunction, ...args) => {
  if (!navigator.onLine) {
    throw new Error('You are offline. Please check your internet connection and try again.');
  }

  try {
    return await apiFunction(...args);
  } catch (error) {
    if (!navigator.onLine) {
      throw new Error('Lost internet connection. Please check your connection and try again.');
    }
    throw error;
  }
};