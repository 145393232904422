import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { theme } from '../../theme';

// Register fonts
Font.register({
  family: 'Roboto',
  fonts: [
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf', fontWeight: 300 },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf', fontWeight: 400 },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf', fontWeight: 500 },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf', fontWeight: 700 },
  ]
});

const BrandBuilderPDF = ({ brandData, toneOfVoiceData }) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#FFFFFF',
      padding: 30,
      fontFamily: 'Roboto',
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 20,
      borderBottom: `2 solid ${theme.palette.primary.main}`,
      paddingBottom: 10,
    },
    logoContainer: {
      width: 150,
      height: 75,
    },
    logo: {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
    title: {
      fontSize: 24,
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    section: {
      margin: '10 0',
      padding: 10,
      borderLeft: `4 solid ${theme.palette.primary.main}`,
    },
    sectionTitle: {
      fontSize: 16,
      fontWeight: 500,
      color: theme.palette.primary.main,
      marginBottom: 5,
    },
    text: {
      fontSize: 12,
      lineHeight: 1.5,
    },
    footer: {
      position: 'absolute',
      bottom: 30,
      left: 30,
      right: 30,
      textAlign: 'center',
      color: theme.palette.text.secondary,
      fontSize: 10,
      borderTop: `1 solid ${theme.palette.divider}`,
      paddingTop: 10,
    },
  });

  const renderSection = (title, content) => (
    <View style={styles.section}>
      <Text style={styles.sectionTitle}>{title}</Text>
      <Text style={styles.text}>{content}</Text>
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.logoContainer}>
            <Image 
              src="/images/Puawai_Logo_Green_wText.png" 
              style={styles.logo} 
            />
          </View>
          <Text style={styles.title}>Brand Builder Guide</Text>
        </View>
        
        {renderSection('Brand Personality Traits', brandData.personalityTraits)}
        {renderSection('Brand Archetype', brandData.brandArchetype)}
        {renderSection('Mission', brandData.mission)}
        {renderSection('Vision', brandData.vision)}
        {renderSection('Target Audience Demographics', brandData.demographics)}
        {renderSection('Key Audience Values', brandData.values?.join(', '))}
        {renderSection('Communication Preferences', brandData.communicationPreferences)}
        
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Tone Dimensions</Text>
          {Object.entries(toneOfVoiceData.toneDimensions || {}).map(([dimension, value]) => (
            <Text key={dimension} style={styles.text}>
              {dimension}: {value}/10
            </Text>
          ))}
        </View>
        
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Tone Quadrants</Text>
          {Object.entries(toneOfVoiceData.toneQuadrants || {}).map(([quadrant, description]) => (
            <Text key={quadrant} style={styles.text}>
              {quadrant}: {description}
            </Text>
          ))}
        </View>
        
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Channel Adaptations</Text>
          {Object.entries(toneOfVoiceData.channelAdaptations || {}).map(([channel, adaptation]) => (
            <Text key={channel} style={styles.text}>
              {channel}: {adaptation}
            </Text>
          ))}
        </View>
        
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Do's and Don'ts</Text>
          <Text style={styles.text}>Do's:</Text>
          {toneOfVoiceData.dosAndDonts?.dos?.map((item, index) => (
            <Text key={index} style={styles.text}>• {item}</Text>
          ))}
          <Text style={styles.text}>Don'ts:</Text>
          {toneOfVoiceData.dosAndDonts?.donts?.map((item, index) => (
            <Text key={index} style={styles.text}>• {item}</Text>
          ))}
        </View>

        <Text style={styles.footer}>
          © {new Date().getFullYear()} StepFWD Limited. All rights reserved.
        </Text>
      </Page>
    </Document>
  );
};

export default BrandBuilderPDF;
