import React, { useState, useEffect } from 'react';
import BaseCanvas from './BaseCanvas';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { 
  Box, 
  Button,
  Typography, 
  useTheme, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions
} from '@mui/material';

const GrowthStrategyCanvas = ({ user, selectedOrg, canvas, onClose, onSave }) => {
  console.log('GrowthStrategyCanvas - Component Mounted');

  if (!user || !selectedOrg) {
    console.error('GrowthStrategyCanvas - Missing required props:', { user, selectedOrg });
    return <Typography color="error">Missing required data</Typography>;
  }

  const [canvasData, setCanvasData] = useState(canvas || {});
  const theme = useTheme();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showUnsavedDialog, setShowUnsavedDialog] = useState(false);

  useEffect(() => {
    if (canvas) {
      setCanvasData(canvas);
    }
  }, [canvas]);

  useEffect(() => {
    console.log('GrowthStrategyCanvas - Props:', { user, selectedOrg, canvas });
    console.log('GrowthStrategyCanvas - State:', { canvasData });
    console.log('GrowthStrategyCanvas - Sections:', sections);
  }, [user, selectedOrg, canvas, canvasData]);

  const handleFieldChange = (field, value) => {
    setCanvasData(prev => ({ ...prev, [field]: value }));
    setHasUnsavedChanges(true);
  };

  const handleCloseAttempt = () => {
    if (hasUnsavedChanges) {
      setShowUnsavedDialog(true);
    } else {
      onClose();
    }
  };

  const handleConfirmedClose = () => {
    setShowUnsavedDialog(false);
    onClose();
  };

  const handleSave = async (updatedData) => {
    try {
      await onSave(updatedData);
      setCanvasData(updatedData);
      setHasUnsavedChanges(false);
      if (updatedData.status) {
        onClose();
      }
      return true;
    } catch (error) {
      console.error('Error saving canvas:', error);
      return false;
    }
  };

  const sections = [
    { 
      field: 'situationalAnalysis', 
      title: 'Situational Analysis (SWOT)', 
      rows: 8, 
      inputType: 'swot',
      hideAIAssist: true,
      preventWarning: true,
      subSections: [
        { field: 'strengths', title: 'Strengths', rows: 2, preventWarning: true },
        { field: 'weaknesses', title: 'Weaknesses', rows: 2, preventWarning: true },
        { field: 'opportunities', title: 'Opportunities', rows: 2, preventWarning: true },
        { field: 'threats', title: 'Threats', rows: 2, preventWarning: true },
      ]
    },
    { field: 'growthObjectives', title: 'Growth Objectives', rows: 4, inputType: 'richtext' },
    { field: 'targetMarkets', title: 'Target Markets', rows: 4, inputType: 'richtext' },
    { field: 'valueProposition', title: 'Value Proposition', rows: 4, inputType: 'richtext' },
    { field: 'growthStrategies', title: 'Growth Strategies', rows: 6, inputType: 'richtext' },
    { field: 'marketingTactics', title: 'Marketing Tactics', rows: 4, inputType: 'richtext' },
    { field: 'salesTactics', title: 'Sales Tactics', rows: 4, inputType: 'richtext' },
    { field: 'keyMetrics', title: 'Key Performance Indicators (KPIs)', rows: 4, inputType: 'richtext' },
    { 
      field: 'resourceAllocation', 
      title: 'Resource Allocation',
      rows: 4,
      inputType: 'richtext'
    },
    { 
      field: 'timeline', 
      title: 'Implementation Timeline', 
      rows: 4, 
      inputType: 'richtext'
    },
  ];

  // Validate sections
  if (!sections || sections.length === 0) {
    console.error('GrowthStrategyCanvas - Sections not defined properly');
    return <Typography color="error">Error loading canvas sections</Typography>;
  }

  console.log('GrowthStrategyCanvas - Sections defined:', sections);

  const tooltips = {
    situationalAnalysis: "Conduct a comprehensive SWOT analysis of your organization's current position.",
    strengths: "List internal factors that give your organization an advantage over others. Consider unique resources, capabilities, and competitive advantages. Format: Bullet points. Example: '• Strong brand recognition\n• Proprietary technology\n• Experienced leadership team'",
    weaknesses: "Identify internal factors that place your organization at a disadvantage relative to others. Consider areas for improvement or limitations. Format: Bullet points. Example: '• Limited market presence in certain regions\n• High production costs\n• Lack of digital marketing expertise'",
    opportunities: "List external factors that your organization could capitalize on to its advantage. Consider market trends, industry changes, or new customer needs. Format: Bullet points. Example: '• Growing demand for sustainable products\n• Emerging markets in Asia\n• Potential for strategic partnerships'",
    threats: "Identify external factors that could cause trouble for your organization. Consider competitive pressures, regulatory changes, or economic factors. Format: Bullet points. Example: '• Increasing competition from new market entrants\n• Changing consumer preferences\n• Potential economic downturn'",
    growthObjectives: "Define specific, measurable growth goals. Format: SMART goals. Example: '1. Increase market share by 5% in the next 12 months\n2. Enter 2 new geographic markets within 18 months'",
    targetMarkets: "Identify and describe your ideal customer segments for growth. Format: Detailed profiles. Example: 'Segment A: Small businesses in tech sector, 10-50 employees, $1-5M annual revenue\nSegment B: Mid-size manufacturers, 100-500 employees, $10-50M annual revenue'",
    valueProposition: "Articulate your unique value proposition for each target market. Format: Concise statements. Example: 'For small tech businesses: Streamline operations and reduce costs by 30%\nFor mid-size manufacturers: Increase productivity and quality control by 40%'",
    growthStrategies: "Outline your main strategies for achieving growth. Format: Strategy statements with brief explanations. Example: '1. Market Penetration: Increase sales to existing customers through upselling and cross-selling\n2. Product Development: Launch 2 new product lines tailored to our target markets'",
    marketingTactics: "Detail your marketing plans to support your growth strategies. Format: Tactical plan. Example: '1. Content Marketing: Develop industry-specific whitepapers and case studies\n2. Account-Based Marketing: Implement targeted campaigns for top 100 prospects'",
    salesTactics: "Describe your sales approach to drive growth. Format: Sales process outline. Example: '1. Lead Qualification: Implement BANT criteria\n2. Sales Enablement: Provide weekly product training\n3. Follow-up: Implement 5-touch follow-up process'",
    keyMetrics: "List the key metrics you'll use to measure growth progress. Format: Metrics with targets. Example: '• Customer Acquisition Cost (CAC): Reduce by 15%\n• Customer Lifetime Value (CLV): Increase by 25%\n• Net Promoter Score (NPS): Achieve score of 50+'",
    resourceAllocation: "Specify how you'll allocate resources to support growth. Format: Percentage breakdown. Example: 'Marketing: 30%\nSales: 25%\nProduct Development: 20%\nCustomer Success: 15%\nOperations: 10%'",
    timeline: "Create a timeline for implementing your growth strategies and tactics. Format: Quarterly plan. Example: 'Q1: Market research and strategy refinement\nQ2: New product development and marketing preparation\nQ3: Product launch and sales push\nQ4: Evaluation and adjustment'",
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      height: '100vh',
      bgcolor: theme.palette.background.default,
      color: theme.palette.text.primary,
      overflow: 'hidden'
    }}>
      {!canvasData && <Typography color="error">No canvas data available</Typography>}

      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        p: 2, 
        borderBottom: `1px solid ${theme.palette.divider}`,
        flexShrink: 0
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TrendingUpIcon sx={{ mr: 1, fontSize: 28, color: theme.palette.secondary.main }} />
          <Typography variant="h5" sx={{ fontWeight: 500 }}>Growth Strategy Canvas</Typography>
        </Box>
      </Box>

      <Box sx={{ 
        flexGrow: 1, 
        overflowY: 'auto', 
        p: 3,
        height: 'calc(100vh - 64px)'
      }}>
        <BaseCanvas
          user={user}
          userData={user}
          selectedOrg={selectedOrg}
          canvas={canvasData}
          onClose={handleCloseAttempt}
          onUpdate={(field, value) => {
            console.log('GrowthStrategyCanvas - Field Update:', { field, value });
            handleFieldChange(field, value);
          }}
          onSave={(data) => {
            console.log('GrowthStrategyCanvas - Save:', data);
            return handleSave(data);
          }}
          canvasType="Growth Strategy Canvas"
          sections={sections}
          tooltips={tooltips}
          icon={TrendingUpIcon}
          preventPropagation={true}
        />
      </Box>

      <Dialog open={showUnsavedDialog} onClose={() => setShowUnsavedDialog(false)}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          <Typography>
            You have unsaved changes. What would you like to do?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowUnsavedDialog(false)}>
            Continue Editing
          </Button>
          <Button onClick={handleConfirmedClose} color="error">
            Discard Changes
          </Button>
          <Button 
            onClick={async () => {
              const success = await handleSave(canvasData);
              if (success) {
                onClose();
              }
            }} 
            variant="contained" 
            color="primary"
          >
            Save & Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GrowthStrategyCanvas;
