import React from 'react';
import { Box, Paper, Typography, Divider } from '@mui/material';

const TemplatePreview = ({ sections }) => {
  const renderSection = (section) => {
    switch (section.type) {
      case 'header':
        return (
          <Box sx={{ mb: 3 }}>
            <Typography variant="h4">{section.title}</Typography>
            {section.content.subtitle && (
              <Typography variant="subtitle1" color="text.secondary">
                {section.content.subtitle}
              </Typography>
            )}
          </Box>
        );

      case 'text':
        return (
          <Box sx={{ mb: 3 }}>
            {section.title && (
              <Typography variant="h6" gutterBottom>
                {section.title}
              </Typography>
            )}
            <Typography>
              {section.content.text || 'Text content will appear here'}
            </Typography>
          </Box>
        );

      case 'image':
        return (
          <Box sx={{ mb: 3 }}>
            <Paper 
              sx={{ 
                height: 200, 
                display: 'flex', 
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'grey.100'
              }}
            >
              <Typography color="text.secondary">
                Image placeholder
              </Typography>
            </Paper>
          </Box>
        );

      // Add more section type renderers

      default:
        return (
          <Box sx={{ mb: 3 }}>
            <Typography color="text.secondary">
              {section.type} section preview
            </Typography>
          </Box>
        );
    }
  };

  return (
    <Paper sx={{ p: 4, maxWidth: '800px', mx: 'auto' }}>
      {sections.map((section, index) => (
        <React.Fragment key={section.id}>
          {renderSection(section)}
          {index < sections.length - 1 && <Divider sx={{ my: 3 }} />}
        </React.Fragment>
      ))}
    </Paper>
  );
};

export default TemplatePreview; 