import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Alert
} from '@mui/material';

/**
 * Dialog component for confirming account deletion
 * @param {Object} props
 * @param {boolean} props.open - Controls dialog visibility
 * @param {function} props.onClose - Handler for dialog close
 * @param {function} props.onDelete - Handler for delete action
 * @param {string} props.accountName - Name of the account to delete
 * @param {boolean} props.isLoading - Loading state for delete action
 */
const DeleteAccountDialog = ({ 
  open, 
  onClose, 
  onDelete, 
  accountName,
  isLoading = false
}) => {
  if (!accountName) return null;

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Delete Account</DialogTitle>
      <DialogContent>
        <Alert severity="warning" sx={{ mb: 2 }}>
          This action cannot be undone.
        </Alert>
        <Typography>
          Are you sure you want to delete <strong>{accountName}</strong>?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>Cancel</Button>
        <Button 
          onClick={onDelete} 
          color="error" 
          variant="contained"
          disabled={isLoading}
          startIcon={isLoading ? <CircularProgress size={20} /> : null}
        >
          {isLoading ? 'Deleting...' : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteAccountDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  accountName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool
};

export default DeleteAccountDialog; 