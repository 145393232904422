import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, Box, Typography, Stepper, Step, StepLabel,
  Paper, IconButton, AppBar, Toolbar, Slide,
  TextField, Grid, FormControl, InputLabel, Select,
  MenuItem, Divider, Chip, Alert, LinearProgress, CircularProgress, Snackbar
} from '@mui/material';
import {
  Close as CloseIcon,
  Save as SaveIcon,
  Preview as PreviewIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  ViewColumn as ViewColumnIcon,
  ArrowBack,
  ArrowForward
} from '@mui/icons-material';
import { collateralTypes } from '../../../constants/collateralTypes';
import CollateralAssistButton from '../../ai/CollateralAssistButton';
import VisualTemplateBuilder from './components/VisualTemplateBuilder';
import TemplatePreview from './components/TemplatePreview';
import { SectionTypes, TemplateTypes } from './config/templateConfig';
import { createTemplate, updateTemplate, getTemplate } from '../../../services/templateService';
import DataMappingEditor from './components/DataMappingEditor';
import BrandAISettings from './components/BrandAISettings';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const steps = [
  {
    label: 'Basic Information',
    description: 'Define template name and type'
  },
  {
    label: 'Structure & Sections',
    description: 'Design your template structure'
  },
  {
    label: 'Data Mapping',
    description: 'Connect to CRM and portfolio data'
  },
  {
    label: 'Brand & AI Settings',
    description: 'Configure branding and AI assistance'
  }
];

const TemplateBuilder = ({ open, onClose, selectedOrg, mode = 'create', template = null, onSave }) => {
  const initialState = {
    metadata: {
      name: '',
      description: '',
      type: '',
      version: '1.0'
    },
    structure: {
      sections: []
    },
    dataSources: {
      crm: {},
      portfolio: {}
    },
    aiConfig: {
      rules: {
        useCustomerContext: true,
        adaptToneOfVoice: true,
        includeCaseStudies: true
      }
    }
  };

  const [templateData, setTemplateData] = useState(initialState);
  const [activeStep, setActiveStep] = useState(0);
  const [errors, setErrors] = useState({});
  const [previewMode, setPreviewMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [completedSteps, setCompletedSteps] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [loading, setLoading] = useState(false);

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  useEffect(() => {
    if (template) {
      setTemplateData(template);
    }
  }, [template]);

  const isTemplateType = (data) => {
    return data?.metadata?.type === 'template' || 
           data?.metadata?.type === 'proposal';
  };

  const validateStep = (step) => {
    if (!templateData) return false;

    switch (step) {
      case 0:
        return !!(templateData.metadata?.name && templateData.metadata?.type);
      case 1:
        return !!(templateData.structure?.sections?.length > 0);
      case 2:
        if (isTemplateType(templateData)) {
          return !!(templateData.dataSources?.crm || templateData.dataSources?.portfolio);
        }
        return true;
      case 3:
        if (isTemplateType(templateData)) {
          return !!(templateData.aiConfig?.rules);
        }
        return true;
      default:
        return false;
    }
  };

  useEffect(() => {
    const validateCurrentStep = () => {
      const newErrors = {};
      
      switch (activeStep) {
        case 0:
          if (!templateData?.metadata?.name) {
            newErrors.name = 'Template name is required';
          }
          if (!templateData?.metadata?.type) {
            newErrors.type = 'Template type is required';
          }
          break;
        case 1:
          if (!templateData?.structure?.sections?.length) {
            newErrors.sections = 'At least one section is required';
          }
          break;
        case 2:
          if (isTemplateType(templateData) && !templateData?.dataSources) {
            newErrors.dataSources = 'Data mapping is required for templates';
          }
          break;
        case 3:
          if (isTemplateType(templateData) && !templateData?.aiConfig?.rules) {
            newErrors.aiConfig = 'AI configuration is required for templates';
          }
          break;
      }

      setErrors(newErrors);
      setCompletedSteps(prev => ({
        ...prev,
        [activeStep]: validateStep(activeStep)
      }));
    };

    validateCurrentStep();
  }, [activeStep, templateData]);

  useEffect(() => {
    const loadTemplate = async () => {
      if (mode === 'edit' && template?.id) {
        try {
          setLoading(true);
          const loadedTemplate = await getTemplate(selectedOrg, template.id);
          setTemplateData(loadedTemplate || initialState);
          
          const initialCompletedSteps = {
            0: !!(loadedTemplate?.metadata?.name && loadedTemplate?.metadata?.type),
            1: !!(loadedTemplate?.structure?.sections?.length > 0),
            2: true,
            3: true
          };
          setCompletedSteps(initialCompletedSteps);
        } catch (error) {
          console.error('Error loading template:', error);
          showSnackbar('Failed to load template', 'error');
          setTemplateData(initialState);
        } finally {
          setLoading(false);
        }
      } else {
        setTemplateData(initialState);
        setCompletedSteps({});
      }
    };

    loadTemplate();
  }, [mode, template, selectedOrg]);

  const handleNext = () => {
    if (completedSteps[activeStep]) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSave = async () => {
    if (!validateStep(activeStep)) {
      showSnackbar('Please complete all required fields', 'error');
      return;
    }

    if (!selectedOrg) {
      showSnackbar('No organization selected', 'error');
      return;
    }

    try {
      setSaving(true);
      
      const templateToSave = {
        ...templateData,
        organisationId: selectedOrg,
        updatedAt: new Date().toISOString(),
        status: 'active',
        version: '1.0',
        type: templateData.metadata.type || 'custom'
      };

      let savedTemplate;
      if (mode === 'create') {
        savedTemplate = await createTemplate(selectedOrg, templateToSave);
      } else {
        if (!templateData.id) {
          throw new Error('Template ID is required for updates');
        }
        savedTemplate = await updateTemplate(selectedOrg, templateData.id, templateToSave);
      }

      showSnackbar(`Template ${mode === 'create' ? 'created' : 'updated'} successfully`);
      if (onSave) {
        onSave(savedTemplate);
      }
      onClose();
    } catch (error) {
      console.error('Error saving template:', error);
      const errorMessage = error.response?.data?.message || error.message;
      setErrors({ submit: `Failed to save template: ${errorMessage}` });
      showSnackbar(`Failed to save template: ${errorMessage}`, 'error');
    } finally {
      setSaving(false);
    }
  };

  const handlePreview = async () => {
    try {
      setLoading(true);
      const previewData = await previewTemplate(selectedOrg, templateData.id, templateData);
      setTemplateData(prev => ({
        ...prev,
        preview: previewData
      }));
      setShowPreview(true);
    } catch (error) {
      console.error('Error generating preview:', error);
      showSnackbar('Failed to generate preview', 'error');
    } finally {
      setLoading(false);
    }
  };

  const proposalTemplate = {
    metadata: {
      name: 'Client Proposal',
      type: 'proposal',
      description: 'Professional proposal template with automatic data integration'
    },
    structure: {
      sections: [
        {
          id: 'header',
          type: 'header',
          title: 'Cover Page',
          content: {
            title: 'Proposal for ${customer.name}',
            subtitle: 'Prepared by ${organization.name}',
            alignment: 'center',
            includeLogo: true
          }
        },
        {
          id: 'executive-summary',
          type: 'executiveSummary',
          title: 'Executive Summary',
          content: SectionTypes.EXECUTIVE_SUMMARY.defaultContent
        },
        {
          id: 'solution-overview',
          type: 'solutionOverview',
          title: 'Our Solution',
          content: SectionTypes.SOLUTION_OVERVIEW.defaultContent
        },
        {
          id: 'case-studies',
          type: 'caseStudies',
          title: 'Success Stories',
          content: SectionTypes.CASE_STUDIES.defaultContent
        },
        {
          id: 'pricing',
          type: 'pricingTable',
          title: 'Investment',
          content: SectionTypes.PRICING_TABLE.defaultContent
        },
        {
          id: 'next-steps',
          type: 'nextSteps',
          title: 'Next Steps',
          content: SectionTypes.NEXT_STEPS.defaultContent
        }
      ]
    }
  };

  const renderBasicInformation = () => (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Template Name"
            value={templateData?.metadata?.name || ''}
            onChange={(e) => setTemplateData(prev => ({
              ...prev,
              metadata: { 
                ...(prev.metadata || {}), 
                name: e.target.value 
              }
            }))}
            error={!!errors.name}
            helperText={errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Template Type</InputLabel>
            <Select
              value={templateData?.metadata?.type || ''}
              onChange={(e) => {
                const type = e.target.value;
                const templateType = TemplateTypes[type.toUpperCase()];
                if (templateType) {
                  setTemplateData(prev => ({
                    ...prev,
                    metadata: {
                      ...(prev.metadata || {}),
                      type: templateType.value,
                      name: templateType.label,
                      description: templateType.description
                    },
                    structure: {
                      sections: [...templateType.defaultSections]
                    }
                  }));
                }
              }}
              label="Template Type"
            >
              {Object.entries(TemplateTypes).map(([key, type]) => (
                <MenuItem key={key} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Description"
            value={templateData?.metadata?.description || ''}
            onChange={(e) => setTemplateData(prev => ({
              ...prev,
              metadata: { 
                ...(prev.metadata || {}), 
                description: e.target.value 
              }
            }))}
          />
        </Grid>
      </Grid>

      {templateData?.metadata?.name && templateData?.metadata?.type && (
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Button
            variant="contained"
            size="large"
            startIcon={<ViewColumnIcon />}
            onClick={() => {
              setActiveStep(1);
            }}
            sx={{ 
              py: 2,
              px: 4,
              background: theme => `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
            }}
          >
            Design Template Structure
          </Button>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            Start designing your template sections visually
          </Typography>
        </Box>
      )}
    </Box>
  );

  const renderStructureBuilder = () => (
    <Box>
      <VisualTemplateBuilder
        sections={templateData.structure.sections}
        onSectionsChange={(newSections) => {
          setTemplateData(prev => ({
            ...prev,
            structure: {
              ...prev.structure,
              sections: newSections
            }
          }));
        }}
      />
    </Box>
  );

  const renderDataMapping = () => (
    <DataMappingEditor
      templateData={templateData}
      onUpdate={(updatedData) => {
        setTemplateData(updatedData);
      }}
    />
  );

  const renderBrandAndAI = () => (
    <BrandAISettings
      templateData={templateData}
      onUpdate={(updatedData) => {
        setTemplateData(updatedData);
      }}
    />
  );

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return renderBasicInformation();
      case 1:
        return renderStructureBuilder();
      case 2:
        return renderDataMapping();
      case 3:
        return renderBrandAndAI();
      default:
        return null;
    }
  };

  const renderStepNavigation = () => (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between',
      mt: 4,
      pt: 2,
      borderTop: 1,
      borderColor: 'divider'
    }}>
      <Button
        onClick={handleBack}
        disabled={activeStep === 0}
        startIcon={<ArrowBack />}
      >
        Back
      </Button>
      <Box sx={{ display: 'flex', gap: 2 }}>
        {activeStep < steps.length - 1 ? (
          <Button
            variant="contained"
            onClick={handleNext}
            endIcon={<ArrowForward />}
            disabled={!completedSteps[activeStep]}
          >
            Next
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={saving || !completedSteps[activeStep]}
            startIcon={<SaveIcon />}
          >
            {saving ? 'Saving...' : 'Save Template'}
          </Button>
        )}
      </Box>
    </Box>
  );

  const renderStepProgress = () => (
    <Box sx={{ width: '100%', mb: 4 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => (
          <Step 
            key={step.label}
            completed={index < activeStep && completedSteps[index]}
          >
            <StepLabel
              optional={
                <Typography variant="caption" color="text.secondary">
                  {step.description}
                </Typography>
              }
              error={index === activeStep && Object.keys(errors).length > 0}
              onClick={() => {
                // Allow jumping to previous steps if they're completed
                if (index < activeStep || completedSteps[activeStep]) {
                  setActiveStep(index);
                }
              }}
              sx={{ cursor: 'pointer' }}
            >
              <Typography 
                variant="subtitle2"
                color={index === activeStep ? 'primary' : 'inherit'}
              >
                {step.label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <LinearProgress 
        variant="determinate" 
        value={(activeStep + 1) / steps.length * 100}
        sx={{ mt: 2 }}
      />
    </Box>
  );

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
            {mode === 'create' ? 'Create Template' : 'Edit Template'}
          </Typography>
          <Button
            color="inherit"
            startIcon={<PreviewIcon />}
            onClick={handlePreview}
            disabled={loading || !templateData.id}
            sx={{ mr: 2 }}
          >
            {showPreview ? 'Edit' : 'Preview'}
          </Button>
          <Button
            color="inherit"
            startIcon={<SaveIcon />}
            onClick={handleSave}
            disabled={saving || loading}
          >
            {saving ? 'Saving...' : 'Save'}
          </Button>
        </Toolbar>
      </AppBar>

      <DialogContent>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : showPreview ? (
          <TemplatePreview 
            sections={templateData.structure.sections}
            preview={templateData.preview}
          />
        ) : (
          <Box sx={{ width: '100%', mt: 3 }}>
            {renderStepProgress()}
            <Paper sx={{ p: 3 }}>
              {renderStepContent(activeStep)}
              {renderStepNavigation()}
            </Paper>
            {errors.submit && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {errors.submit}
              </Alert>
            )}
          </Box>
        )}
      </DialogContent>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default TemplateBuilder; 